import React, { useCallback, useRef, useState } from 'react';
import styles from './header.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { Button, ButtonKind } from '../../button';
import { ControlSize } from '../../../model/ControlSize';
import { ControlColor } from '../../../model/ControlColor';
import GymsPopup from './popup';
import useFirebaseEntity from '../../../hooks/use-firebase-entity';
import { Gym, mapGymDtoToGym } from '../../../model/Gym';
import useOnClickOutside from '../../../hooks/use-click-outside';
import { gymActions } from '../../../store/actions';
import { push } from 'connected-react-router';

const Header = () => {
  const dispatch = useDispatch();

  const { gym } = useSelector((state: RootState) => state.gym);

  let [gyms] = useFirebaseEntity('gyms', [], mapGymDtoToGym) as [Gym[]];

  gyms = gyms.filter(g => !g.deleted);

  const [showPopup, setShowPopup] = useState(false);

  const ref = useRef();

  useOnClickOutside(ref, () => setShowPopup(false));

  const handleShowPopup = () => {
    setShowPopup(true);
  };

  const handleChange = useCallback((value: Gym) => {
    dispatch(gymActions.changeActive(value));
    setShowPopup(false);
  }, []);

  const handleManageGyms = () => {
    dispatch(push('/gyms-list'));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <div className={styles.title}>
          You are viewing as <span className={styles.bold}>{gym && gym.name}</span>
        </div>
        <Button
          className={styles.titleBtn}
          onClick={handleManageGyms}
          title={'Manage gyms'}
          size={ControlSize.ExtraSmall}
          color={ControlColor.Dark}
          kind={ButtonKind.Secondary}
        />
      </div>

      <div className={styles.popupWrapper}>
        <Button
          onClick={handleShowPopup}
          title={'Switch Gym'}
          size={ControlSize.ExtraSmall}
          color={ControlColor.Dark}
          kind={ButtonKind.Secondary}
        />
        {showPopup && (
          <GymsPopup onChange={handleChange} innerRef={ref} gyms={gyms}/>
        )}
      </div>
    </div>
  )
};

export default Header;
