import React, { ChangeEvent } from 'react';
import { Dispatch } from 'redux';
import styles from './title-editable.module.scss';
import { connect } from 'react-redux';
import { RootState } from '../../../../store/reducers';
import { editorBlockActions } from '../../../../store/actions';
import { getProgramEditorPresentState } from '../../../../store/reducers/program';

type Props = {} & ReturnType<typeof mapState> & ReturnType<typeof mapActions>;

type State = {
  focused: boolean;
};

class TitleEditable extends React.PureComponent<Props, State> {
  state = {
    focused: false,
  };

  handleFocus = () => {
    this.setState({ focused: true });
  };

  handleBlur = () => {
    this.setState({ focused: false });
  };

  handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (this.props.isBlockEditorPage) {
      this.props.updateSelectedBlockValue('name', value);
    } else {
      this.props.updateCanvasName(value);
    }
  };

  render() {
    const {
      value,
    } = this.props;

    const {
      focused,
    } = this.state;

    const classes = [styles.wrapper];

    if (focused) {
      classes.push(styles.focused)
    }

    return (
      <div className={classes.join(' ')}>
        <input
          className={styles.input}
          value={value}
          type={'text'}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

const mapState = (state: RootState) => {

  const {
    isBlockEditorPage,
    selectedBlock,
    canvas,
  } = getProgramEditorPresentState(state);
  return {
    isBlockEditorPage,
    value: isBlockEditorPage ? (selectedBlock && selectedBlock.info.name) || 'Untitled' : canvas.name,
  };
};

const mapActions = (dispatch: Dispatch) => ({
  updateCanvasName: (value: string) => dispatch(editorBlockActions.updatePropertyForCanvas('name', value)),
  updateSelectedBlockValue: (path: string, value: string | number) => dispatch(editorBlockActions.updateSelectedBlockInfo(path, value)),
});

export default connect(mapState, mapActions)(TitleEditable);
