import React from 'react';
import classnames from 'classnames';
import { Dispatch } from 'redux';
import styles from './program-editor.module.scss';
import Header from './header';
import Canvas from './canvas';
import Settings from './settings';
import { connect } from 'react-redux';
import { editorBlockActions } from '../../store/actions';
import { RootState } from '../../store/reducers';
import { isTimerSelected } from '../../model/Timer';
import { getProgramEditorPresentState } from '../../store/reducers/program';

const defaultProps = {
  save: (resolve: Function) => { console.log('Save not implemented') },
  replace: (id: string) => { console.log('Replace not implemented') },
  goToList: () => { console.log('goToList not implemented') },
  goToBlockList: () => { console.log('goToBlockList not implemented') },
  saveExistingBlock: (resolve: Function) => { console.log('saveExistingBlock not implemented') },
  getExercisesList: () => { console.log('getExercisesList not implemented') },
  createNewBlock: (name: string, resolve: Function) => console.log('createNewBlock not implemented'),
}

type DefaultProps = Readonly<typeof defaultProps>;

type Props = {
} & ReturnType<typeof mapState> & ReturnType<typeof mapActions> & DefaultProps;

class EditorBlock extends React.PureComponent<Props> {
  static defaultProps = defaultProps;

  componentWillUnmount(): void {
    this.props.clearData();
  }

  componentDidMount() {
    this.props.getExercisesList();
  }

  render() {
    const {
      isBlockSelected,
      selectedComponent,
      save,
      saveExistingBlock,
      goToBlockList,
      goToList,
      replace,
      createNewBlock,
    } = this.props;

    const classes = classnames({
      [styles.content]: true,
      [styles.contentBlock]: isBlockSelected,
      [styles.timer]: isTimerSelected(selectedComponent),
    });

    return (
      <div className={styles.wrapper}>
        <Header
          goToBlockList={goToBlockList}
          goToList={goToList}
          replace={replace}
          save={save}
          saveExistingBlock={saveExistingBlock}
        />
        <div className={classes}>
          <Canvas/>
          <Settings
            createNewBlock={createNewBlock}
          />
        </div>
      </div>
    );
  }
}

const mapState = (state: RootState) => {
  const {
    isBlockSelected,
    selectedComponent,
  } = getProgramEditorPresentState(state);

  return {
    isBlockSelected,
    selectedComponent,
  };
};

const mapActions = (dispatch: Dispatch) => ({
  clearData: () => dispatch(editorBlockActions.clearData()),
});

export default connect(mapState, mapActions)(EditorBlock);
