export const ADD_NEW_TO_CANVAS = 'EDITOR__ADD_NEW_TO_CANVAS';
export const ADD_TO_CANVAS = 'EDITOR__ADD_TO_CANVAS';
export const SELECT_COMPONENT = 'EDITOR__SELECT_COMPONENT';
export const DESELECT_COMPONENT = 'EDITOR__DESELECT_COMPONENT';
export const UPDATE_PROPERTIES_FOR_SELECTED = 'EDITOR__UPDATE_PROPERTIES_FOR_SELECTED';
export const UPDATE_PROPERTY_FOR_SELECTED = 'EDITOR__UPDATE_PROPERTY_FOR_SELECTED';
export const INCREASE_Z_INDEX_FOR_SELECTED = 'EDITOR__INCREASE_Z_INDEX_FOR_SELECTED';
export const DECREASE_Z_INDEX_FOR_SELECTED = 'EDITOR__DECREASE_Z_INDEX_FOR_SELECTED';
export const REMOVE_SELECTED_COMPONENT = 'EDITOR__REMOVE_SELECTED_COMPONENT';
export const UPDATE_PROPERTY_FOR_CANVAS = 'EDITOR__PROPERTY_FOR_CANVAS';
export const UPDATE_PROPERTIES_FOR_CANVAS = 'EDITOR__PROPERTIES_FOR_CANVAS';
export const SAVE_CANVAS = 'EDITOR__SAVE_CANVAS';
export const SAVE_TEMPLATE_CANVAS = 'EDITOR__SAVE_TEMPLATE_CANVAS';
export const SAVE_CANVAS_PROCESSING = 'EDITOR__SAVE_CANVAS_PROCESSING';
export const LOAD_CANVAS_PROCESSING = 'EDITOR__LOAD_CANVAS_PROCESSING';
export const FETCH_DATA = 'EDITOR__FETCH_DATA';
export const FETCH_TEMPLATES_DATA = 'EDITOR__FETCH_TEMPLATES_DATA';
export const FETCH_DATA_SUCCESS = 'EDITOR__FETCH_DATA_SUCCESS';
export const UPLOAD_IMAGE_FOR_SELECTED = 'EDITOR__UPLOAD_IMAGE_FOR_SELECTED';
export const CLEAR_DATA = 'EDITOR__CLEAR_DATA';
export const IS_PROGRAM_SAVED = 'EDITOR__IS_EDITOR_SAVED';
export const CHANGE_MULTI_MODE = 'EDITOR__CHANGE_MULTI_MODE';
export const CREATE_NEW_BLOCK = 'EDITOR__CREATE_NEW_BLOCK';
export const CREATE_NEW_TEMPLATE_BLOCK = 'EDITOR__CREATE_NEW_TEMPLATE_BLOCK';
export const REMOVE_COMPONENTS = 'EDITOR__REMOVE_COMPONENTS';
export const ADD_BLOCK_TO_LIST = 'EDITOR__ADD_BLOCK_TO_LIST';
export const ADD_BLOCKS_TO_LIST = 'EDITOR__ADD_BLOCKS_TO_LIST';
export const ADD_EXERCISES_TO_LIST = 'EDITOR__ADD_EXERCISES_TO_LIST';
export const FETCH_BLOCKS = 'EDITOR__FETCH_BLOCKS';
export const FETCH_TEMPLATE_BLOCKS = 'EDITOR__FETCH_TEMPLATE_BLOCKS';
export const FETCH_EXERCISES = 'EDITOR__FETCH_EXERCISES';
export const TOGGLE_EDITOR_EDIT_BLOCK = 'EDITOR__TOGGLE_EDITOR_EDIT_BLOCK';
export const TOGGLE_PROGRAM_PREVIEW = 'EDITOR__TOGGLE_PROGRAM_PREVIEW';
export const SAVE_EXISTING_BLOCK = 'EDITOR__SAVE_EXISTING_BLOCK';
export const SAVE_EXISTING_TEMPLATE_BLOCK = 'EDITOR__SAVE_EXISTING_TEMPLATE_BLOCK';
export const UPDATE_SELECTED_BLOCK_INFO = 'EDITOR__SELECTED_UPDATE_BLOCK_INFO';
export const UPDATE_SELECTED_BLOCK_COMPONENT_VALUE = 'EDITOR__UPDATE_SELECTED_BLOCK_COMPONENT_VALUE';
export const UPDATE_EDITOR_INFO = 'EDITOR__UPDATE_EDITOR_INFO';
export const UPDATE_BLOCKS_ON_CANVAS = 'EDITOR__UPDATE_BLOCKS_ON_CANVAS';
export const UPDATE_PREFERENCES = 'EDITOR__UPDATE_PREFERENCES';

export const SET_EDITOR_TO_BLOCK_PAGE = 'EDITOR__SET_EDITOR_TO_BLOCK_PAGE';
export const FETCH_BLOCK_FOR_EDIT = 'EDITOR__FETCH_BLOCK_FOR_EDIT';
export const FETCH_TEMPLATES_BLOCK_FOR_EDIT = 'EDITOR__FETCH_TEMPLATES_BLOCK_FOR_EDIT';

export const ADD_TIMER = 'EDITOR__ADD_TIMER';
export const SELECT_TIMER = 'EDITOR__SELECT_TIMER';
export const UPDATE_TIMER_PROPS = 'EDITOR__UPDATE_TIMER_PROPS';
export const UPDATE_TIMER_PROP = 'EDITOR__UPDATE_TIMER_PROP';
export const REMOVE_TIMER = 'EDITOR__REMOVE_TIMER';
export const UPDATE_WARM_UP_PROPERTIES = 'EDITOR__UPDATE_WARM_UP_PROPERTIES';
export const UPDATE_WARM_UP_PROPERTY = 'EDITOR__UPDATE_WARM_UP_PROPERTY';

export const ProgramUndoRedoActions = [
  ADD_NEW_TO_CANVAS,
  ADD_TO_CANVAS,
  UPDATE_PROPERTY_FOR_CANVAS,
  UPDATE_PROPERTIES_FOR_CANVAS,
  INCREASE_Z_INDEX_FOR_SELECTED,
  DECREASE_Z_INDEX_FOR_SELECTED,
  REMOVE_SELECTED_COMPONENT,
  UPDATE_PROPERTY_FOR_SELECTED,
  UPDATE_PROPERTIES_FOR_SELECTED,
  FETCH_DATA_SUCCESS,
  UPLOAD_IMAGE_FOR_SELECTED,
  CLEAR_DATA,
  UPDATE_SELECTED_BLOCK_INFO,
  UPDATE_SELECTED_BLOCK_COMPONENT_VALUE,
  UPDATE_EDITOR_INFO,
  ADD_TIMER,
  UPDATE_TIMER_PROP,
  UPDATE_TIMER_PROPS,
  REMOVE_TIMER,
  UPDATE_WARM_UP_PROPERTIES,
  UPDATE_WARM_UP_PROPERTY,
];

export const ProgramGroupActions = [
  INCREASE_Z_INDEX_FOR_SELECTED,
  DECREASE_Z_INDEX_FOR_SELECTED,
  UPDATE_PROPERTY_FOR_SELECTED,
  UPDATE_PROPERTIES_FOR_SELECTED,
  UPDATE_PROPERTY_FOR_CANVAS,
  UPDATE_PROPERTIES_FOR_CANVAS,
  UPDATE_WARM_UP_PROPERTIES,
  UPDATE_WARM_UP_PROPERTY,
];
