import React from 'react';
import styles from './loading.module.scss';

type Props = {
  className?: string;
};

function Loading(props: Props) {
  const { className = '' } = props;
  return (
    <div className={`${styles.loading} ${className}`}>
      Loading<span>.</span><span>.</span><span>.</span>
    </div>
  );
}

export default Loading;
