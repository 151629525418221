import { MeasureUnit } from '../Unit';
import { numberToFixedValue } from '../../utils/formatter';

export type PositionType = {
  x: number;
  y: number;
  unit: MeasureUnit
};

export const getPosition = (x: number, y: number, unit: MeasureUnit = 'px', onlyPositive = true): PositionType => {
  let newX = numberToFixedValue(x);
  let newY = numberToFixedValue(y);

  if (onlyPositive) {
    newX = Math.max(newX, 0);
    newY = Math.max(newY, 0);
  }

  return {
    x: newX,
    y: newY,
    unit,
  };
};

export const isPositionEqual = (a: PositionType, b: PositionType): boolean => {
  return +a.x === +b.x &&
    +a.y === +b.y &&
    a.unit === b.unit;
};
