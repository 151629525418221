import React, { Dispatch } from 'react';
import styles from './login.module.scss';
import InputField from '../../../components/input-field';
import { NavLink } from 'react-router-dom';
import { Button } from '../../../components/button';
import { connect } from 'react-redux';
import { authActions } from '../../../store/actions';
import { RootState } from '../../../store/reducers';
import FormWrapper from '../form-wrapper';
import FooterText from '../form-wrapper/footer-text';
import FooterLink from '../form-wrapper/footer-link';

type Props = {
  logIn: (email: string, password: string) => void;
} & ReturnType<typeof mapState>;

type State = {
  email: string;
  password: string;
};

class LoginPage extends React.PureComponent<Props, State> {
  state = {
    email: '',
    password: '',
  };

  handleChange = (key: string) => (value: string) => {
    this.setState(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  handleSubmit = () => {
    const {
      email,
      password,
    } = this.state;

    this.props.logIn(email, password);
  };

  render() {
    const {
      email,
      password,
    } = this.state;

    const {
      loading,
      error,
    } = this.props;

    return (
      <FormWrapper
        title={'Log in to your account'}
        footerText={
          <FooterText text={'Don’t have an account yet?'}/>
        }
        footerLink={
          <FooterLink to={'/sign-up'} text={'Sign up as a gym owner'}/>
        }
      >
        {!!error &&
        <div className={styles.error}>{error}</div>
        }
        <InputField className={styles.email} onChange={this.handleChange('email')} value={email}
                    placeholder={'Email...'}/>
        <InputField type={'password'} onChange={this.handleChange('password')} value={password}
                    placeholder={'Password...'}/>
        <NavLink className={styles.link} to={'/reset-password'}>
          Forgot your password?
        </NavLink>
        <div className={styles.action}>
          <Button
            disabled={loading}
            title="Log In"
            onClick={this.handleSubmit}
          />
        </div>
      </FormWrapper>
    );
  }
}

const mapState = (state: RootState) => ({
  loading: state.auth.loading,
  error: state.auth.error,
});

const mapActions = (dispatch: Dispatch<any>) => ({
  logIn: (email: string, password: string) => dispatch(authActions.login(email, password)),
});

export default connect(mapState, mapActions)(LoginPage);
