import React from 'react';
import { Dispatch } from 'redux';
import styles from './scale-input.module.scss';
import { RootState } from '../../../../store/reducers';
import { connect } from 'react-redux';
import { editorBlockActions } from '../../../../store/actions';
import { IconType, SvgIcon } from '../../../icon';
import { getProgramEditorPresentState } from '../../../../store/reducers/program';

type Props = {} &
  ReturnType<typeof mapState> &
  ReturnType<typeof mapActions>;

class ScaleInput extends React.PureComponent<Props> {
  increase = () => {
    const {
      canvas: { scale },
    } = this.props;

    const value = Math.min(100, scale + 5);

    if (scale === 100 && value === 100) {
      return;
    }

    this.props.updateScale(value);
  };

  decrease = () => {
    const {
      canvas: { scale },
    } = this.props;

    const value = Math.max(0, scale - 5);

    if (scale === 0 && value === 0) {
      return;
    }

    this.props.updateScale(value);
  };

  render() {
    const {
      canvas,
    } = this.props;

    return (
      <div className={styles.wrapper}>
        <div className={styles.icon}>
          <SvgIcon type={IconType.MinusSign} onClick={this.decrease}/>
        </div>
        <div className={styles.text}>
          {canvas.scale}%
        </div>
        <div className={styles.icon}>
          <SvgIcon type={IconType.PlusSign} onClick={this.increase}/>
        </div>
      </div>
    );
  }
}

const mapState = (state: RootState) => ({
  canvas: getProgramEditorPresentState(state).canvas,
});

const mapActions = (dispatch: Dispatch) => ({
  updateScale: (value: number) => dispatch(editorBlockActions.updatePropertyForCanvas('scale', value)),
});

export default connect(mapState, mapActions)(ScaleInput);
