import React, { ChangeEvent } from 'react';
import styles from './checkbox.module.scss';

type Props = {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label?: string;
  className?: string;
};

class Checkbox extends React.PureComponent<Props> {
  handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(e.target.checked);
  };
  render() {
    const {
      label,
      checked,
      className = '',
    } = this.props;

    return (
      <div className={`${styles.wrapper} ${className}`}>
        <label className={styles.label}>
          <input checked={checked} onChange={this.handleChange} className={styles.input} type="checkbox"/>
          {label}
        </label>
      </div>
    );
  }
}

export default Checkbox;
