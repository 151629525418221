import React, { useState } from 'react';
import FormWrapper from '../form-wrapper';
import FooterText from '../form-wrapper/footer-text';
import FooterLink from '../form-wrapper/footer-link';
import styles from './sign-up.module.scss';
import InputField from '../../../components/input-field';
import { Button } from '../../../components/button';
import { AccessLevel } from '../../../model/AccessLevel';
import { Modal } from '../../../components/modal';
import { API } from '../../../api';

const SignUpPage = () => {
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [gymName, setGymName] = useState('');
  const [email, setEmail] = useState('');

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleSignUp = async () => {

    try {
      setLoading(true);

      const user = await API.user.createUser(email, password, {
        name: firstName,
        lastName,
        email,
        roles: [AccessLevel.owner],
      });
      await API.invite.sendCreateGymConfirmation(email, {
        createdBy: user.uid,
        name: firstName,
        gymName,
      });

      setShowConfirmationModal(true);
    } catch (e) {
      window.alert(e.message);
    } finally {
      setLoading(false);
    }
  };

  const formValid = (() => {
    return firstName &&
      lastName &&
      password && password.length >= 8 &&
      gymName &&
      email;
  })();

  if (showConfirmationModal) {
    return (
      <Modal color="#fff">
        <div className={styles.header}>Almost done!</div>
        <div className={styles.content}>
          Please check your inbox for a confirmation email.
        </div>
      </Modal>
    );
  }

  return (
    <FormWrapper
      title={'Create an Account'}
      footerText={
        <FooterText text={'Already have an account?'}/>
      }
      footerLink={
        <FooterLink to={'/login'} text={'Log back in'}/>
      }
    >
      <div className={styles.form}>
        <div className={styles.row}>
          <div className={styles.col}>
            <InputField value={firstName} placeholder={'First Name...'} onChange={setFirstName}/>
          </div>
          <div className={styles.col}>
            <InputField value={lastName} placeholder={'Last Name...'} onChange={setLastName}/>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <InputField placeholder={'Email...'} value={email} onChange={setEmail}/>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <InputField
              type={'password'}
              placeholder={'Password...'}
              value={password}
              onChange={setPassword}
              helperText={'Enter a strong password with at least 8 characters.'}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <InputField placeholder={'Gym name...'} value={gymName} onChange={setGymName}/>
          </div>
        </div>
        <div className={styles.row}>
          <Button
            disabled={loading || !formValid}
            title="Sign Up"
            onClick={handleSignUp}
          />
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.policy}>By signin up you agree to our Privacy Policy & Terms</div>
          </div>
        </div>
      </div>
    </FormWrapper>
  );
};

export default SignUpPage;
