import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { UserActionType } from '../../action-types';
import { Action } from '../../utils';
import { authActions, userActions } from '../../actions';
import { RootState } from '../../reducers';
import { User, UserCreateParams } from '../../../model/User';
import { API, LocalStorage } from '../../../api';
import { AccessLevel } from '../../../model/AccessLevel';
import { Gym } from '../../../model/Gym';

function* uploadImage() {
  yield takeEvery(UserActionType.UPLOAD_AVATAR, function* ({ payload }: Action) {
    const { file, resolve } = payload;

    try {
      const user = (yield select((state: RootState) => state.auth.user)) as User;
      const oldAvatarUrl = user && user.avatarUrl;

      if (oldAvatarUrl) {
        yield call(API.storage.removeImage, oldAvatarUrl);
      }

      let newUrl = '';

      if (file) {
        newUrl = yield call(API.storage.uploadImage, file, 'users');
      }

      yield put(userActions.updateUser({ avatarUrl: newUrl }));
      resolve && resolve(null);
    } catch (e) {
      console.log('Error Upload avatar', e);
      resolve && resolve(e);
    }
  });
}

function* updateUser() {
  yield takeEvery(UserActionType.UPDATE_USER, function* ({ payload }: Action) {
    const { user: data, resolve } = payload;
    const userOld = (yield select((state: RootState) => state.auth.user)) as User;

    yield call(API.user.updateUser, userOld.uid, data);

    yield put(userActions.updateUserSuccess(data));

    const user = (yield select((state) => state.auth.user)) as User;

    LocalStorage.storage.setItem(LocalStorage.StorageKeys.userData, user);

    resolve && resolve(null);
  });
}

function* createUserWithGym() {
  yield takeEvery(UserActionType.CREATE_USER_WITH_GYM, function* ({ payload }: Action) {
    const {
      email,
      password,
      user,
      gymName,
      resolve
    } = payload;

    try {
      const createdUser = (yield call(API.user.createUser, email, password, {
        ...user,
        email,
        roles: [AccessLevel.owner],
      } as UserCreateParams)) as User;

      const gym = (yield call(API.gyms.createGym, gymName, createdUser.uid)) as Gym;

      yield call(API.user.updateUser, createdUser.uid, { gymId: gym.id });

      yield put(authActions.login(email, password));
    } catch (e) {
      resolve && resolve(e);
    }
  });
}

export default function* () {
  yield all([
    updateUser(),
    uploadImage(),
    createUserWithGym(),
  ]);
}
