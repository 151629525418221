import React from 'react';
import styles from './page-wrapper.module.scss';
import Menu from '../menu';
import Header from './header';
import OnlyRole from '../only-role';
import { AccessLevel } from '../../model/AccessLevel';
import useOnlyRoles from '../../hooks/use-only-roles';

type Props = {
  children: React.ReactNode;
};

const PageWrapper = ({ children }: Props) => {
  const adminStyle = useOnlyRoles([AccessLevel.admin], styles.admin, '');

  return (
    <>
      <OnlyRole roles={[AccessLevel.admin]}>
        <Header/>
      </OnlyRole>
      <div className={`${styles.wrapper} ${adminStyle}`}>
        <div className={styles.menu}>
          <Menu/>
        </div>
        <div className={styles.content}>
          {children}
        </div>
      </div>
    </>
  );
};

export default PageWrapper;
