import React, { useState } from 'react';
import styles from './profile.module.scss';
import PageWrapper from '../../components/page-wrapper';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import PhotoUploader from './photo-uploader';
import InputField from '../../components/input-field';
import { Button } from '../../components/button';
import { useDispatch, useStore } from 'react-redux';
import { RootState } from '../../store/reducers';
import { User } from '../../model/User';
import { userActions } from '../../store/actions';

const ProfilePage = () => {
  const dispatch = useDispatch();
  const { auth } = useStore().getState() as RootState;
  const user = auth.user as User;

  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState(user.name || '');
  const [lastName, setLastName] = useState(user.lastName || '');
  const [email] = useState(user.email || '');
  const [phone, setPhone] = useState(user.phone || '');

  const handleSaveChanges = () => {
    setLoading(true);

    dispatch(userActions.updateUser({
      name: firstName,
      lastName,
      phone,
    }, (err: Error) => {
      if (err) {
        window.alert(err.message);
      }
      setLoading(false);
    }));
  };

  return (
    <PageWrapper>
      <PageTitle className={styles.title} title={'Your Profile'}/>
      <PageContent className={styles.wrapper}>
        <PhotoUploader/>
        <div className={styles.form}>
          <div className={styles.row}>
            <div className={styles.col}>
              <InputField value={firstName} label={'First Name'} onChange={setFirstName}/>
            </div>
            <div className={styles.col}>
              <InputField value={lastName} label={'Last Name'} onChange={setLastName}/>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <InputField disabled={true} label={'Email'} value={email} onChange={() => {}}/>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <InputField label={'Phone (optional)'} value={phone} onChange={setPhone}/>
            </div>
          </div>
          <div className={styles.row}>
            <Button
              disabled={loading}
              title="Save Changes"
              onClick={handleSaveChanges}
            />
          </div>
        </div>
      </PageContent>
    </PageWrapper>
  );
};

export default ProfilePage;
