import React, { DragEvent } from 'react';
import styles from './list-item.module.scss';
import { ComponentType } from '../../../model/program/Type';
import { IconType, SvgIcon } from '../../icon';

type Props = {
  type: ComponentType;
  className?: string;
  id?: string;
};

class ListItem extends React.PureComponent<Props> {
  handleDragStart = (e: DragEvent) => {
    e.stopPropagation();

    const {
      type,
    } = this.props;

    e.dataTransfer.setData('type', type);
  };

  render() {
    const {
      type,
      id,
      className,
    } = this.props;

    let component;

    switch (type) {
      case ComponentType.Square: {
        component = (
          <SvgIcon type={IconType.Square} style={{ width: 32, height: 32 }}/>
        );
        break;
      }
      case ComponentType.Circle: {
        component = (
          <SvgIcon type={IconType.Ellipse} style={{ width: 32, height: 32 }}/>
        );
        break;
      }
      case ComponentType.Label: {
        component = (
          <SvgIcon type={IconType.Text} style={{ width: 32, height: 32 }}/>
        );
        break;
      }
      case ComponentType.Image: {
        component = (
          <SvgIcon type={IconType.Image} style={{width: 32, height: 32}}/>
        );
        break;
      }
      default: {
        return null;
      }
    }

    return (
      <div
        id={id}
        draggable={true}
        onDragStart={this.handleDragStart}
        className={`${styles.item} ${className || ''}`}
      >
        {component}
      </div>
    );
  }
}

export default ListItem;
