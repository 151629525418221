import { Canvas, CanvasType } from './Canvas';
import { Component, ComponentBase, createComponentId } from './Component';
import { ComponentType } from './Type';
import { ComponentLabel } from './ComponentLabel';
import { ComponentShape } from './ComponentShape';
import { ComponentImage } from './ComponentImage';
import { ComponentBlock } from './ComponentBlock';
import { BlockType } from './Block';
import { Timestamp } from '../Firebase';
import { Timer } from '../Timer';

export type Program = {
  editor: ProgramInfoType;
  canvas: Canvas;
  components: ComponentBase[];
  timer?: Timer;
  id?: string;
  lastPlayed?: number;
}

export type ProgramPlayedHistory = {
  id: string;
  playedAt: number;
  screenId: string;
  screenName: string;
}

export type ProgramInfoType = {
  id?: string;
  dateCreated?: Date;
  dateUpdated?: Date;
  dateLastPlayed?: Date;
  createdBy?: string;
  updatedBy?: string;
  duration?: string;
  focus?: string | string[];
  tags?: string[];
  format?: string;
  gymId?: string;
  _copiedFromTemplate?: boolean;
  _copiedFromId?: string;
};

export const getDefaultProgramInfo = (): ProgramInfoType => {
  return {
    id: undefined,
    dateCreated: undefined,
    dateUpdated: undefined,
    createdBy: undefined,
    updatedBy: undefined,
    gymId: undefined,
  };
};

export type ProgramType = {
  canvas: CanvasType;
  components: Component[];
  editor: ProgramInfoType;
  timer?: Timer;
};

export function createNewComponent(type: ComponentType, data: any, blocks: BlockType[] = []) {
  let component: ComponentBase;

  switch (type) {
    case ComponentType.Label: {
      component = new ComponentLabel({ ...data });
      break;
    }
    case ComponentType.Square: {
      component = new ComponentShape({ ...data, type: ComponentType.Square });
      break;
    }
    case ComponentType.Circle: {
      component = new ComponentShape({ ...data, type: ComponentType.Circle });
      break;
    }
    case ComponentType.Image: {
      component = new ComponentImage({ ...data, type: ComponentType.Image });
      break;
    }
    case ComponentType.Block: {
      // Block components use references,
      // so we need to find components in blocks before create them
      const d = data as ComponentBlock;

      const block = blocks.find(b => b.info.id === d.blockId);

      if (block) {
        data.id = data.id || createComponentId();
        const components = convertComponentsStructureToComponentsBase(block.component, blocks, { parentId: data.id });
        component = new ComponentBlock({
          ...data,
          // Block Style more important
          style: {
            ...data.style,
            ...block.component.style,
          },
          components,
          type: ComponentType.Block,
        });
      } else {
        // In data we can have components, it is case when create new block
        if (data.components) {
          data.components = convertComponentsStructureToComponentsBase(data, blocks);
        }
        component = new ComponentBlock({ components: [], ...data, type: ComponentType.Block });
      }
      break;
    }
    default: {
      throw new Error('Unknown component type');
    }
  }

  return component;
}

export const convertProgramBlockTypeToState = (data: ProgramType, blocks: BlockType[] = []): Program => {
  const components: ComponentBase[] = [];

  (data.components || []).forEach((c: Component) => {
    const component = createNewComponent(c.type, c, blocks);
    if (component) {
      components.push(component);
    }
  });

  return {
    editor: {
      ...data.editor,
    },
    canvas: new Canvas(data.canvas),
    components,
    ...(data.timer ? {timer: data.timer} : {}),
  };
};

const convertComponentsStructureToComponentsBase = (data: { components: Component[] }, blocks: BlockType[] = [], extra = {}): ComponentBase[] => {
  return data.components.map((c: any) => {
    if (c.components) {
      c.components = convertComponentsStructureToComponentsBase(c, blocks, extra);
    }
    return createNewComponent(c.type, { ...c, ...extra }, blocks);
  });
};

export const mapProgramDtoToProgram = ({ id, editor, ...rest }: { id: string, editor: ProgramInfoType }): Program => {
  return {
    ...rest as Program,
    editor: {
      ...editor,
      dateUpdated: (editor.dateUpdated as unknown as Timestamp).toDate(),
      dateCreated: (editor.dateCreated as unknown as Timestamp).toDate(),
      id,
    },
    id,
  };
};
