import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';
import isEqual from 'lodash/isEqual';
import { EditorBlockActionType } from '../../action-types';
import { ComponentBase } from '../../../model/program/Component';
import { designerActions, editorBlockActions } from '../../actions';
import { RootState } from '../../reducers';
import { getProgramEditorPresentState, ProgramBlockState } from '../../reducers/program';
import { Action } from '../../utils';
import {
  convertProgramBlockTypeToState,
  createNewComponent,
  getDefaultProgramInfo,
  ProgramType,
} from '../../../model/program/Program';
import { User } from '../../../model/User';
import { API } from '../../../api';
import { BlocksBuilder, ComponentBlock } from '../../../model/program/ComponentBlock';
import { getPosition } from '../../../model/program/Position';
import { ComponentType } from '../../../model/program/Type';
import { getSize } from '../../../model/program/Size';
import { Block, BlockInfo, BlockType } from '../../../model/program/Block';
import { uniqueId } from '../../../utils/helper';
import { Canvas } from '../../../model/program/Canvas';
import { Gym } from '../../../model/Gym';
import { getDefaultLabelStyle } from '../../../model/program/ComponentLabel';

function* addNew() {
  while (true) {
    const { payload } = yield take(EditorBlockActionType.ADD_NEW_TO_CANVAS);
    const {
      type,
      position,
      ...rest
    } = payload;

    const blockList = (yield select((state: RootState) => getProgramEditorPresentState(state).blocks)) as BlockType[];

    const comp = { ...rest, position };

    delete comp.id;

    if (type === ComponentType.Label) {
      const prefs = yield select((state: RootState) => getProgramEditorPresentState(state).preferences);
      comp.style = getDefaultLabelStyle({
        color: prefs.fontColor,
        fontFamily: prefs.fontFamily,
      });
    }

    const component = createNewComponent(type, comp, blockList);

    if (component) {
      yield put(editorBlockActions.addComponentToCanvas(component));
      yield put(designerActions.designerTourAddComponentToCanvas(component));
    } else {
      console.log('Unrecognized component type', type);
    }
  }
}

function getSelectedComponentSelector(state: RootState) {
  const {
    isBlockEditorActive,
    selectedComponentInsideBlock,
    selectedComponent,
  } = getProgramEditorPresentState(state);
  if (isBlockEditorActive) {
    return (selectedComponentInsideBlock as ComponentBase).style.zIndex;
  }
  return (selectedComponent as ComponentBase).style.zIndex;
}

function* zIndex() {
  yield takeEvery(EditorBlockActionType.INCREASE_Z_INDEX_FOR_SELECTED, function* () {
    const zIndex = (yield select(getSelectedComponentSelector)) + 1;
    const min = Math.min(zIndex, 1000);
    yield put(editorBlockActions.updatePropertyForSelected('style.zIndex', min));
  });

  yield takeEvery(EditorBlockActionType.DECREASE_Z_INDEX_FOR_SELECTED, function* () {
    const zIndex = (yield select(getSelectedComponentSelector)) - 1;
    const max = Math.max(zIndex, 0);
    yield put(editorBlockActions.updatePropertyForSelected('style.zIndex', max));
  });
}

function* saveFlow() {
  yield takeEvery(EditorBlockActionType.SAVE_CANVAS, function* ({ payload }: Action) {
    const { resolve } = payload;

    try {
      yield put(editorBlockActions.saveCanvasProcessing(true));
      const editorBlock: ProgramBlockState = (yield select((state: RootState) => getProgramEditorPresentState(state)));
      const user = (yield select(((state: RootState) => state.auth.user))) as User;
      const gym = (yield select(((state: RootState) => state.gym.gym))) as Gym;
      const { canvas, components, editor, timer } = editorBlock;

      const editorId = editor.id;

      const dataToSave: ProgramType = {
        editor,
        timer,
        canvas: canvas.getStructure(),
        components: [],
      };

      if (!dataToSave.timer) {
        delete dataToSave.timer;
      }

      components.forEach(c => {
        dataToSave.components.push(c.getStructure());
      });

      let response;

      if (editorId) {
        dataToSave.editor = {
          ...editor,
          dateUpdated: new Date(),
          updatedBy: user.uid,
        };

        response = yield call(API.program.updateOrInsert, dataToSave);
      } else {
        dataToSave.editor = {
          dateCreated: new Date(),
          dateUpdated: new Date(),
          createdBy: user.uid,
          updatedBy: user.uid,
          gymId: gym.id,
        };

        response = yield call(API.program.updateOrInsert, dataToSave);
      }

      const blocks: BlockType[] = (yield select((state: RootState) => getProgramEditorPresentState(state).blocks));

      const newEditor = convertProgramBlockTypeToState(response, blocks);

      // We take updated data from state and merge it with saved version
      // Data could be changed while saving, so we don't need to lost them
      const e2: ProgramBlockState = (yield select((state: RootState) => getProgramEditorPresentState(state)));
      yield put(editorBlockActions.fetchDataSuccess({ ...newEditor, ...e2.editor, ...e2.canvas, components: e2.components }));

      yield put(editorBlockActions.saveCanvasProcessing(false));
      resolve(null, newEditor);
    } catch (e) {
      yield put(editorBlockActions.saveCanvasProcessing(false));
      resolve(e);
    }
  });
}

function* saveTemplateFlow() {
  yield takeEvery(EditorBlockActionType.SAVE_TEMPLATE_CANVAS, function* ({ payload }: Action) {
    const { resolve } = payload;

    try {
      yield put(editorBlockActions.saveCanvasProcessing(true));

      const editorBlock: ProgramBlockState = (yield select((state: RootState) => getProgramEditorPresentState(state)));
      const user = (yield select(((state: RootState) => state.auth.user))) as User;
      const { canvas, components, editor, timer } = editorBlock;

      const editorId = editor.id;

      const dataToSave: ProgramType = {
        editor,
        timer,
        canvas: canvas.getStructure(),
        components: [],
      };

      if (!dataToSave.timer) {
        delete dataToSave.timer;
      }

      components.forEach(c => {
        dataToSave.components.push(c.getStructure());
      });

      let response;

      if (editorId) {
        dataToSave.editor = {
          ...editor,
          dateUpdated: new Date(),
          updatedBy: user.uid,
        };

        response = yield call(API.program.updateOrInsertTemplateProgram, dataToSave);
      } else {
        dataToSave.editor = {
          dateCreated: new Date(),
          dateUpdated: new Date(),
          createdBy: user.uid,
          updatedBy: user.uid,
        };

        response = yield call(API.program.updateOrInsertTemplateProgram, dataToSave);
      }

      const blocks: BlockType[] = (yield select((state: RootState) => getProgramEditorPresentState(state).blocks));

      const newEditor = convertProgramBlockTypeToState(response, blocks);

      const e2: ProgramBlockState = (yield select((state: RootState) => getProgramEditorPresentState(state)));
      yield put(editorBlockActions.fetchDataSuccess({ ...newEditor, ...e2.editor, ...e2.canvas, components: e2.components }));

      yield put(editorBlockActions.saveCanvasProcessing(false));
      resolve(null, newEditor);
    } catch (e) {
      yield put(editorBlockActions.saveCanvasProcessing(false));
      resolve(e);
    }
  });
}

function* fetchBlocks() {
  yield takeEvery(EditorBlockActionType.FETCH_BLOCKS, _fetchBlocks)
}

function* fetchTemplateBlocks() {
  yield takeEvery(EditorBlockActionType.FETCH_TEMPLATE_BLOCKS, _fetchTemplateBlocks)
}

function* _fetchBlocks() {
  try {
    const user = (yield select((state: RootState) => state.auth.user)) as User;
    const gym = (yield select((state: RootState) => state.gym.gym)) as Gym;
    const blockList: BlockType[] = yield call(API.block.getList, (gym && gym.id) || user.gymId || user.uid);
    const list: Block[] = blockList.map(b => ({
      info: b.info,
      component: createNewComponent(ComponentType.Block, { ...b.component }) as ComponentBlock,
    }));

    yield put(editorBlockActions.addBlocksToList(list));
  } catch (e) {
    console.log('Error get blocks', e);
    throw e;
  }
}

function* _fetchTemplateBlocks() {
  try {
    const blockList: BlockType[] = yield call(API.block.getTemplateList);
    const list: Block[] = blockList.map(b => ({
      info: b.info,
      component: createNewComponent(ComponentType.Block, { ...b.component }) as ComponentBlock
    }));

    yield put(editorBlockActions.addBlocksToList(list));
  } catch (e) {
    console.log('Error get template blocks', e);
    throw e;
  }
}

function* fetchExercises() {
  yield takeEvery(EditorBlockActionType.FETCH_EXERCISES, function* () {
    try {
      const user = (yield select((state: RootState) => state.auth.user)) as User;
      const gym = (yield select((state: RootState) => state.gym.gym)) as Gym;
      const exercises = yield call(API.exercise.getList, (gym && gym.id) || user.gymId || user.uid);

      yield put(editorBlockActions.addExercises(exercises));
    } catch (e) {
      console.log('Error get blocks', e);
      throw e;
    }
  });
}

function* _createNewEditor(apiCaller: any, id: string) {
  const response = (yield call(apiCaller, id)) as ProgramType;
  const blockList: Block[] = (yield select((state: RootState) => getProgramEditorPresentState(state).blocks));
  const newEditor = convertProgramBlockTypeToState(response, blockList);
  yield put(editorBlockActions.loadCanvasProcessing(false));
  yield put(editorBlockActions.fetchDataSuccess(newEditor));
  return newEditor;
}

function* fetch() {
  yield takeEvery(EditorBlockActionType.FETCH_DATA, function* ({ payload }: Action) {
    const { resolve, id } = payload;
    try {
      if (!id) {
        throw new Error('Id not provided');
      }

      yield put(editorBlockActions.loadCanvasProcessing(true));

      yield _fetchBlocks();

      const newEditor = yield _createNewEditor(API.program.getProgram, id);
      resolve && resolve(null, newEditor);
    } catch (e) {
      yield put(editorBlockActions.loadCanvasProcessing(false));
      resolve && resolve(e);
    }
  })
}

function* fetchTemplateEditor() {
  yield takeEvery(EditorBlockActionType.FETCH_TEMPLATES_DATA, function* ({ payload }: Action) {
    const { resolve, id } = payload;

    try {
      if (!id) {
        throw new Error('Id not provided');
      }

      yield put(editorBlockActions.loadCanvasProcessing(true));
      yield _fetchTemplateBlocks();

      const newEditor = yield _createNewEditor(API.program.getTemplateProgram, id);

      resolve && resolve(null, newEditor);
    } catch (e) {
      yield put(editorBlockActions.loadCanvasProcessing(false));
      resolve && resolve(e);
    }
  });
}

function* uploadImage() {
  yield takeEvery(EditorBlockActionType.UPLOAD_IMAGE_FOR_SELECTED, function* ({ payload }: Action) {
    const { resolve, file, imageUrl: imageUrlOld } = payload;
    try {
      if (imageUrlOld) {
        try {
          yield call(API.storage.removeImage, imageUrlOld);
        } catch (e) {
          console.log('Error remove old file', e);
        }
      }

      const imageUrl = yield call(API.storage.uploadImage, file, 'editor');
      yield put(editorBlockActions.updatePropertyForSelected('imageUrl', imageUrl));

      resolve && resolve(null, imageUrl);
    } catch (e) {
      resolve && resolve(e);
    }
  })
}

function* checkEverythingSaved() {
  yield takeEvery(EditorBlockActionType.IS_PROGRAM_SAVED, function* ({ payload }: Action) {
    const { resolve } = payload;

    try {
      const editorBlock: ProgramBlockState = (yield select((state: RootState) => getProgramEditorPresentState(state)));
      const { notSavedVersion, editor, canvas, components, timer } = editorBlock;

      const equal = isEqual(notSavedVersion.editor, editor) &&
        isEqual(notSavedVersion.canvas, canvas) &&
        isEqual(notSavedVersion.timer, timer) &&
        isEqual(notSavedVersion.components, components);

      resolve(null, equal);
    } catch (e) {
      resolve(e);
    }
  });
}

function* _createNewBlock(name: string, apiFn: any) {
  const builder: BlocksBuilder = (yield select((state: RootState) => getProgramEditorPresentState(state).selectedComponents));
  const user = (yield select((state: RootState) => state.auth.user)) as User;
  const gym = (yield select((state: RootState) => state.gym.gym)) as Gym;

  // Get X,Y position for Main block
  const [x, y, width, height] = builder.calculateBlockCoordinates();

  // Calculate new components position related to main block
  const components = builder.components
    .map(c => {
      const newPosition = getPosition(c.position.x - x, c.position.y - y);
      return createNewComponent(c.type, { ...c, position: newPosition });
    });

  const newId = uniqueId('C');
  const blockComponent = createNewComponent(ComponentType.Block, {
    id: newId,
    position: getPosition(x, y),
    components: components.map(c => ({ ...c, parentId: newId })),
    size: getSize(width, height),
    meta: {
      name,
    },
  }) as ComponentBlock;

  const info: BlockInfo = {
    gymId: gym.id,
    createdBy: user.uid,
    dateCreated: new Date(),
    dateUpdated: new Date(),
    updatedBy: user.uid,
    name,
  };

  let newComponent: BlockType = {
    info,
    component: blockComponent.getStructureToSaveBlock(),
  };

  newComponent = (yield call(apiFn, newComponent)) as BlockType;

  yield put(editorBlockActions.addBlockToList(newComponent));

  blockComponent.blockId = newComponent.info.id;

  yield put(editorBlockActions.changeMultiMode(false));
  yield put(editorBlockActions.removeComponents(blockComponent.components.map(c => c.id)));

  yield put(editorBlockActions.addComponentToCanvas(blockComponent));
  yield put(editorBlockActions.selectComponent(blockComponent));

  return blockComponent;
}

function* createNewBlock() {
  yield takeEvery(EditorBlockActionType.CREATE_NEW_BLOCK, function* ({ payload }: Action) {
    const {
      name,
      resolve,
    } = payload;

    try {
      const blockComponent = yield _createNewBlock(name, API.block.updateOrInsert);

      resolve && resolve(null, blockComponent);
    } catch (e) {
      resolve && resolve(e);
    }
  });
}

function* createNewTemplateBlock() {
  yield takeEvery(EditorBlockActionType.CREATE_NEW_TEMPLATE_BLOCK, function* ({ payload }: Action) {
    const {
      name,
      resolve,
    } = payload;

    try {
      const blockComponent = yield _createNewBlock(name, API.block.updateOrInsertTemplate);

      resolve && resolve(null, blockComponent);
    } catch (e) {
      resolve && resolve(e);
    }
  });
}

function* _updateBlock(fetchBlocksApi: any, updateApi: any) {
  yield put(editorBlockActions.saveCanvasProcessing(true));

  const user = (yield select((state: RootState) => state.auth.user)) as User;
  const gym = (yield select((state: RootState) => state.gym.gym)) as Gym;
  const block: BlockType = (yield select((state: RootState) => getProgramEditorPresentState(state).selectedBlock));
  const selectedComponent: ComponentBlock = (yield select((state: RootState) => getProgramEditorPresentState(state).selectedComponent));

  if (block.component.type !== ComponentType.Block) {
    throw new Error('Selected component is not a block type');
  }

  let newBlock: BlockType = {
    component: selectedComponent.getStructureToSaveBlock(),
    info: {
      // Add default values if info is empty
      createdBy: user.uid,
      dateCreated: new Date(),
      name: 'New block',
      gymId: gym.id,
      ...block.info,
      dateUpdated: new Date(),
      updatedBy: user.uid,
    },
  };

  yield call(updateApi, newBlock);
  yield fetchBlocksApi();
  yield put(editorBlockActions.updateBlocksOnCanvas(selectedComponent));
  yield put(editorBlockActions.saveCanvasProcessing(false));
  return selectedComponent;
}

function* updateExistingBlock() {
  yield takeEvery(EditorBlockActionType.SAVE_EXISTING_BLOCK, function* ({ payload = {} }: Action) {
    const { resolve } = payload;

    if (!window.confirm('Edit existing block will change all occurrences of this dynamic block. Are you sure?')) {
      return;
    }

    try {
      const block = yield _updateBlock(_fetchBlocks, API.block.updateOrInsert);
      resolve && resolve(null, block);
    } catch (e) {
      yield put(editorBlockActions.saveCanvasProcessing(false));
      resolve && resolve(e);
    }
  });
}

function* updateExistingTemplateBlock() {
  yield takeEvery(EditorBlockActionType.SAVE_EXISTING_TEMPLATE_BLOCK, function* ({ payload = {} }: Action) {
    const { resolve } = payload;

    if (!window.confirm('Edit existing block will change all occurrences of this dynamic block. Are you sure?')) {
      return;
    }

    try {
      const block = yield _updateBlock(_fetchTemplateBlocks, API.block.updateOrInsertTemplate);
      resolve && resolve(null, block);
    } catch (e) {
      yield put(editorBlockActions.saveCanvasProcessing(false));
      resolve && resolve(e);
    }
  });
}

function* _blockEdit(id: string, fetchBlocksApi: any, getBlockApi: any) {
  yield put(editorBlockActions.setEditorToBlockPage());

  yield put(editorBlockActions.loadCanvasProcessing(true));

  yield fetchBlocksApi();

  const blockList: Block[] = (yield select((state: RootState) => getProgramEditorPresentState(state).blocks));

  const blockResponse = (yield call(getBlockApi, id)) as BlockType;

  // Increase canvas size a little bit more
  const shift = 10;

  const size = blockResponse.component.size;

  const canvas = new Canvas();
  canvas.updateProperties({
    size: getSize(size.width + shift * 2, size.height + shift * 2, blockResponse.component.size.unit),
  });

  const newId = uniqueId('C');
  const newComponent = createNewComponent(blockResponse.component.type, {
    ...blockResponse.component,
    id: newId,
    components: blockResponse.component.components.map(c => ({ ...c, parentId: newId })),
    // Set position with small shift
    position: getPosition(shift, shift),
  });

  const editorStructure: ProgramType = {
    editor: getDefaultProgramInfo(),
    canvas,
    components: [newComponent],
  };

  const newEditor = convertProgramBlockTypeToState(editorStructure, blockList);

  yield put(editorBlockActions.fetchDataSuccess(newEditor));
  yield put(editorBlockActions.selectComponent(newComponent));
  yield put(editorBlockActions.toggleEditorEditBlock(true));
  yield put(editorBlockActions.loadCanvasProcessing(false));
  return newEditor;
}

function* blockEditFlow() {
  yield takeEvery(EditorBlockActionType.FETCH_BLOCK_FOR_EDIT, function* ({ payload }: Action) {
    const { id, resolve } = payload;

    try {
      const newEditor = yield _blockEdit(id, _fetchBlocks, API.block.getBlock);

      resolve && resolve(null, newEditor);
    } catch (e) {
      yield put(editorBlockActions.loadCanvasProcessing(false));
      resolve && resolve(e);
    }
  });
}

function* blockTemplatesEditFlow() {
  yield takeEvery(EditorBlockActionType.FETCH_TEMPLATES_BLOCK_FOR_EDIT, function* ({ payload }: Action) {
    const { id, resolve } = payload;

    try {
      const newEditor = yield _blockEdit(id, _fetchTemplateBlocks, API.block.getTemplateBlock);

      resolve && resolve(null, newEditor);
    } catch (e) {
      yield put(editorBlockActions.loadCanvasProcessing(false));
      resolve && resolve(e);
    }
  });
}

export default function* () {
  yield all([
    addNew(),
    zIndex(),
    saveFlow(),
    saveTemplateFlow(),
    fetch(),
    fetchTemplateEditor(),
    uploadImage(),
    checkEverythingSaved(),
    createNewBlock(),
    createNewTemplateBlock(),
    updateExistingBlock(),
    updateExistingTemplateBlock(),
    fetchBlocks(),
    fetchTemplateBlocks(),
    fetchExercises(),
    blockEditFlow(),
    blockTemplatesEditFlow(),
  ]);
}
