import React, { useEffect, useState } from 'react';
import styles from './table.module.scss';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import { Header, Body } from '../../model/Table';
import Paging from '../paging';

type Props<T = any> = {
  header: Header[];
  body: Body<T>[];
  data: T[];
  isLoading?: boolean;
};

const ItemsPerPage = 20;

function Table<T>(props: Props<T>) {
  const {
    header,
    body,
    data,
    isLoading
  } = props;

  const [items, setItems] = useState<T[]>([]);

  const [page, setPage] = useState(0);

  const handleChangePage = (page: number) => setPage(page);

  useEffect(() => {
    const _items = data.slice(page * ItemsPerPage, (page + 1) * ItemsPerPage);

    setItems(_items);
  }, [page, data])

  return (
    <>
      <div className={styles.table}>
        <div className={styles.header}>
          <div className={styles.headerRow}>
            {header.map((h, index) => {
              return (
                <div key={index} className={`${styles.headerCell} ${h.className || ''}`}>
                  {h.title || ''}
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.body}>
          {items.map((d, rowIndex) => {
            return (
              <div key={rowIndex} className={styles.bodyRowWrapper}>
                <div className={styles.bodyRow}>
                  {body.map((b, cellIndex) => {
                    return (
                      <div key={cellIndex} className={`${styles.bodyCell} ${b.className || ''}`}>
                        {isFunction(b.key) ? b.key(d) : get(d, b.key)}
                      </div>
                    );
                  })}
                </div>
              </div>
            )
          })}
          {
            isLoading && (
              <div className={styles.message}>Loading...</div>
            )
          }
          {
            !isLoading && (data.length < 1) && (
              <div className={styles.message}>No Data</div>
            )
          }
        </div>
      </div>
      <Paging data={data} onPageChanged={handleChangePage} itemsPerPage={ItemsPerPage}/>
    </>
  );
}

export {
  Table
}
