const defaultOptions = {
  year: 'numeric', month: 'numeric', day: 'numeric',
  hour: 'numeric', minute: 'numeric', second: 'numeric',
  hour12: false,
};

export const displayDate = (date: Date, format = defaultOptions) => {
  const formatter = new Intl.DateTimeFormat('default', format);

  return formatter.format(date);
};
