import { AbstractComponent } from './program/Component';
import { ComponentType } from './program/Type';
import { getPosition } from './program/Position';
import { uniqueId } from '../utils/helper';
import { getSize } from './program/Size';
import { Style } from './program/Style';
import { Option } from './Option';

export enum TimerType {
  Simple = 'simple',
  Complex = 'complex',
}

export enum IntervalType {
  Low = 'low',
  High = 'high',
}

export type IntervalCycle = {
  numberOfSets: number;
  firstInterval: IntervalType;
  lowDuration: string;
  highDuration: string;
  id: string;
  highIntervalFirst: boolean;
};

export type Timer = {
  timerType: TimerType;
  warmUp: string;
  intervals: IntervalCycle[];
  restTime?: string;
  showWarmUpComponent: boolean;
  warmUpComponent: AbstractComponent & {
    text: string;
  },
} & AbstractComponent;

export type TimerStyle = {
  fontSize: number;
  fontFamily: string;
} & Style;

export type WarmupModuleStyle = {
  borderWidth: string,
  borderColor: string,
  borderStyle: string,
} & TimerStyle;

const defaultStyle: TimerStyle = {
  zIndex: 1,
  opacity: 1,
  color: '#ffffff',
  backgroundColor: '#000000',
  border: 'none',
  fontFamily: 'Arial, Helvetica, sans-serif',
  fontSize: 40,
};

const defaultWarmupStyle = {
  ...defaultStyle,
  borderWidth: `0px`,
  borderColor: '#ffffff',
  borderStyle: 'solid',
}

export const mergeTimerStyleWithDefault = (style: any): TimerStyle => {
  return {
    ...defaultStyle,
    ...style,
  };
};

export const mergeTimerWarmupStyleWithDefault = (style: any): WarmupModuleStyle => {
  return {
    ...defaultWarmupStyle,
    ...style,
  };
};

export const getDefaultTimerIntervalCycle = (): IntervalCycle => ({
  firstInterval: IntervalType.Low,
  lowDuration: '01:15',
  highDuration: '01:00',
  numberOfSets: 10,
  id: uniqueId('Interval'),
  highIntervalFirst: false,
});

export const getDefaultTimer = (): Timer => {
  return {
    timerType: TimerType.Simple,
    warmUp: '00:10',
    intervals: [getDefaultTimerIntervalCycle()],
    type: ComponentType.Timer,
    position: getPosition(0, 0),
    size: getSize(200, 155),
    id: uniqueId('Timer'),
    style: defaultStyle,
    restTime: '00:20',
    showWarmUpComponent: false,
    warmUpComponent: {
      position: getPosition(10, 10),
      style: defaultWarmupStyle,
      size: getSize(270, 50),
      type: ComponentType.TimerWarmUp,
      id: uniqueId('WUC'),
      text: 'Warm up text',
    }
  };
};

export const isTimerSelected = (component: AbstractComponent | null): boolean => {
  return !!component && component.type === ComponentType.Timer;
};

export const TimerTypeOptions: Option[] = [
  { label: 'Simple', value: TimerType.Simple },
  { label: 'Complex', value: TimerType.Complex },
];
