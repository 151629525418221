import React from 'react';
import styles from './user-avatar.module.scss';

type Props = {
  url: string | undefined;
  size?: 'sm';
};

const UserAvatar = ({ url, size }: Props) => {
  return (
    <div className={`${styles.wrapper} ${styles[size || '']}`}>
      <div className={styles.avatar} style={{backgroundImage: `url(${url || ''})`}}/>
    </div>
  );
};

export default UserAvatar;
