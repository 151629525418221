import firebase from 'firebase/app';
import { Exercise } from '../../model/Exercise';

const collectionExercises = 'exercises';

const db = firebase.firestore();

export const getList = async (gymId: string): Promise<Exercise[]> => {
  try {
      const response = await db.collection(collectionExercises)
        .where('gymId', '==', gymId)
        .get();

      return response.docs.map((doc) => {
        const d = doc.data();

        return {
          ...d,
          id: doc.id
        } as Exercise;
      }) as Exercise[];
  } catch (e) {
    throw e;
  }
};
