import React from 'react';
import styles from '../form-wrapper.module.scss';

type Props = {
  text: string;
};

const FooterText = ({ text }: Props) => {
  return (
    <div className={styles.footerText}>{text}</div>
  );
};

export default FooterText;
