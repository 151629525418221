import firebase from 'firebase/app';
import { Gym } from '../../model/Gym';

const collectionGyms = 'gyms';

const db = firebase.firestore();

export const getGym = async (id: string): Promise<Gym> => {
  try {
    if (!id) {
      throw new Error('Id not provided');
    }

    const response = await db.collection(collectionGyms)
      .doc(id)
      .get();

    const data = response.data();

    if (!data) {
      throw new Error('Gym not found');
    }

    return {
      ...data as Gym,
      dateCreated: data.dateCreated.toDate(),
      dateUpdated: data.dateUpdated.toDate(),
      id: response.id,
    } as Gym;
  } catch (e) {
    throw e;
  }
};

export const createGym = async (name: string, createdBy: string): Promise<Gym> => {
  try {
    const ref = db.collection(collectionGyms)
      .doc();

    const toSave = {
      name,
      createdBy,
      dateCreated: new Date(),
      dateUpdated: new Date(),
    };

    await ref.set(toSave);

    return {
      ...toSave,
      id: ref.id,
    };
  } catch (e) {
    throw e;
  }
};

export const updateGym = async (gymId: string, params: Partial<Gym>): Promise<void> => {
  try {
    if (!gymId) {
      throw new Error('Gym Id not provided');
    }

    if (!params) {
      throw new Error('Params not provided');
    }

    const toSave = {
      ...params,
      dateUpdated: new Date(),
    };

    await db.collection(collectionGyms)
      .doc(gymId)
      .set(toSave, { merge: true });
  } catch (e) {
    throw e;
  }
};

export const listenGymChanges = (gymId: string, callback: (err: Error | null, gym?: Gym) => void) => {
  return db.collection(collectionGyms)
    .doc(gymId)
    .onSnapshot((snapshot) => {
      const data = snapshot.data();
      if (data) {
        callback(null, {
          ...data as Gym,
          dateCreated: data.dateCreated.toDate(),
          dateUpdated: data.dateUpdated.toDate(),
          id: snapshot.id,
        });
      } else {
        callback(new Error('Not exist.'));
      }
    }, (err) => {
      callback(err, undefined);
    });
};
