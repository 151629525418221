import React from 'react';
import styles from './expandable.module.scss';

type Props = {
  title?: string;
  component?: React.ReactNode;
  children: React.ReactNode;
  controls?: React.ReactNode;
  defaultOpened?: boolean;
  id?: string;
};

type State = {
  opened: boolean;
};

class Expandable extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      opened: props.defaultOpened || false,
    };
  }

  handleToggle = () => {
    this.setState(({ opened }) => ({ opened: !opened }));
  };

  render() {
    const {
      title,
      component,
      children,
      controls,
      id,
    } = this.props;

    const {
      opened,
    } = this.state;

    return (
      <div className={styles.wrapper} id={id}>
        <div className={styles.header}>
          {title ? (
            <div className={styles.titleWrapper}>
              <div onClick={this.handleToggle} className={styles.title}>{title}</div>
              {controls && (
                <div>{controls}</div>
              )}
            </div>
          ) : (
            component
          )}
        </div>
        {
          opened && (
            <div className={styles.content}>
              {children}
            </div>
          )
        }
      </div>
    );
  }
}

export default Expandable;
