import { ComponentType } from './Type';
import { PositionType } from './Position';
import { Meta } from './Meta';
import { getSize, Size } from './Size';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import merge from 'lodash/merge';
import { Style } from './Style';
import { getDefaultVariable, mergeVariable, Variable } from './Variable';
import { uniqueId } from '../../utils/helper';

export type AbstractComponent = {
  id: string;
  type: ComponentType;
  position: PositionType;
  size: Size;
  style: Style;
};

export type Component = {
  meta: Meta;
  parentId?: string;
  variable: Variable;
} & AbstractComponent;

export type ComponentNew = {
  type: ComponentType;
  position: PositionType;
}

export interface Structure<T = Component> {
  getStructure: () => T;
}

export const createComponentId = () => {
  return uniqueId('C');
};

type Params = {
  position: PositionType;
  id: string;
  meta: Meta;
  size?: Size;
  style?: Style;
  parentId?: string;
  variable?: Variable;
};

const defaultSize = getSize(100, 50);
const defaultStyle: Style = {
  zIndex: 1,
  color: '#ffffff',
  border: 'none',
  backgroundColor: '#ffffff',
  opacity: 1,
};

export type ComponentBaseUpdatePropertyKey = 'size' | 'position';
export type ComponentBaseUpdatePropertyValue = Size | PositionType;
export type ComponentBaseUpdateParams = {
  [key: string]: Object;
};

export abstract class ComponentBase implements Component, Structure {
  protected constructor(
    {
      position,
      id,
      meta,
      parentId = '',
      variable = getDefaultVariable(),
      style = defaultStyle,
      size = defaultSize,
    }: Params) {

    this.id = id;
    this.position = position;
    this.meta = meta;
    this.size = size;
    this.style = {
      ...defaultStyle,
      ...style,
    };
    this.parentId = parentId;
    this.variable = mergeVariable(variable);

    return this;
  }

  getStructure(): Component {
    return {
      id: this.id,
      position: this.position,
      meta: this.meta,
      type: this.type,
      size: this.size,
      style: this.style,
      variable: this.variable,
    };
  };

  updateProperties(props: ComponentBaseUpdateParams): void {
    Object.keys(props)
      .forEach((key) => {
        const k = key as ComponentBaseUpdatePropertyKey;
        const properties = props[k];

        // @ts-ignore
        merge(this[key], properties);
      });
  }

  updateProperty(path: string, value: any): void {
    set(this, path, value);
  }

  clone() {
    return cloneDeep(this);
  }

  id: string;
  meta: Meta;
  position: PositionType;
  size: Size;
  style: Style;
  parentId?: string;
  variable: Variable;

  abstract type: ComponentType;

}
