import React, { Dispatch } from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

type Props = {
  navigate: (url: string) => void;
};

export type WithNavigation = {
  navigate: (url: string) => void;
}

function withNavigation<P = any>(WrappedComponent: React.ComponentType<any>) {
  class WithNavigation extends React.Component<Props> {
    static displayName: string;

    handleNavigate = (url: string) => this.props.navigate(url);

    render() {
      return (
        <WrappedComponent
          {...this.props}
          navigate={this.handleNavigate}
        />
      );
    }
  }

  function getDisplayName() {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
  }

  WithNavigation.displayName = `WithNavigation(${getDisplayName()})`;

  hoistNonReactStatic(WithNavigation, WrappedComponent);

  const mapActions = (dispatch: Dispatch<any>) => ({
    navigate: (url: string) => dispatch(push(url)),
  });

  return connect(null, mapActions)(WithNavigation);
}

export default withNavigation;
