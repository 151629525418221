import { Option } from './Option';

export enum AccessLevel {
  admin = 'admin',
  owner = 'owner',
  manager = 'manager',
  coach = 'coach',
}
export const AccessLevelOptions: Option<AccessLevel>[] = [
  { label: 'Manager', value: AccessLevel.manager },
  { label: 'Coach', value: AccessLevel.coach },
];

export const AccessLevelFullOptions: Option<AccessLevel>[] = [
  { label: 'Admin', value: AccessLevel.admin },
  { label: 'Owner', value: AccessLevel.owner },
  ...AccessLevelOptions,
];

export const AccessLevelOptionsDefaultCoach = AccessLevelOptions[1];

const AccessLevelMaps = {
  [AccessLevel.admin]: 'Admin',
  [AccessLevel.owner]: 'Owner',
  [AccessLevel.manager]: 'Manager',
  [AccessLevel.coach]: 'Coach',
};
export const mapAccessLevelToLabel = (value: AccessLevel) => {
  return AccessLevelMaps[value];
};
