import React from 'react';
import withUpdateProperty, { WithUpdatePropertyProps } from '../../component-settings/withUpdateProperty';
import ValueWrapper from '../../value-wrapper';

type Props = {
  value: number | string;
  postfix: string | React.ReactNode;
  path?: string;
  className?: string;
  type?: 'text' | 'number';
  multi?: boolean;
  onChange?: (value: string | number) => void;
} & WithUpdatePropertyProps;

type State = {
  focused: boolean;
};

class ComponentValueWrapper extends React.PureComponent<Props, State> {
  handleUpdate = (value: string | number) => {
    const {
      path,
      onChange,
    } = this.props;

    if (path) {
      if (onChange) {
        onChange(value);
      } else {
        this.props.updateProperty(path, value);
      }
    }
  };

  render() {
    const {
      value,
      ...rest
    } = this.props;

    return (
      <ValueWrapper
        value={value}
        onUpdate={this.handleUpdate}
        {...rest}
      />
    );
  }
}

export default withUpdateProperty(ComponentValueWrapper);
