import React, { ChangeEvent } from 'react';
import classNames from 'classnames';

import { ControlColor } from '../../model/ControlColor';
import { ControlSize } from '../../model/ControlSize';
import { TintColor } from '../../model/TintColor';

import { IconType, SvgIcon } from '../icon';
import Label from '../label';

import styles from './input-field.module.scss';

type Props = {
  className?: string;
  color?: ControlColor;
  disabled?: boolean;
  icon?: IconType;
  label?: string;
  placeholder?: string;
  size?: ControlSize;
  style?: object;
  tintColor?: TintColor;
  type?: 'text' | 'number' | 'password';
  helperText?: string;
  value: string;
  onChange: (value: string) => void;
  onIconClick?: () => void;
};

const InputField = (props: Props) => {
  const {
    className,
    color = ControlColor.Default,
    disabled = false,
    icon,
    label,
    placeholder,
    size = ControlSize.Normal,
    style,
    tintColor,
    type = 'text',
    value,
    onChange,
    onIconClick,
    helperText,
  } = props;

  const labelClassNames = classNames({
    [styles.label]: true,
    [styles.sizeNormal]: size === ControlSize.Normal,
    [styles.sizeSmall]: size === ControlSize.Small,
    [styles.sizeExtraSmall]: size === ControlSize.ExtraSmall
  });

  const inputContainerClassNames = classNames({
    [styles.inputContainer]: true,
    [styles.colorDark]: color === ControlColor.Dark,
    [styles.colorDefault]: color === ControlColor.Default,
    [styles.sizeNormal]: size === ControlSize.Normal,
    [styles.sizeSmall]: size === ControlSize.Small,
    [styles.sizeExtraSmall]: size === ControlSize.ExtraSmall
  });

  const iconWrapperClassNames = classNames({
    [styles.iconWrapper]: true,
    [styles.sizeNormal]: size === ControlSize.Normal,
    [styles.sizeSmall]: size === ControlSize.Small,
    [styles.sizeExtraSmall]: size === ControlSize.ExtraSmall
  });

  const iconClassNames = classNames({
    [styles.icon]: true,
    [styles.sizeNormal]: size === ControlSize.Normal,
    [styles.sizeSmall]: size === ControlSize.Small,
    [styles.sizeExtraSmall]: size === ControlSize.ExtraSmall
  });

  const inputClassNames = classNames({
    [styles.input]: true,
    [styles.colorDark]: color === ControlColor.Dark,
    [styles.colorDefault]: color === ControlColor.Default,
    [styles.sizeNormal]: size === ControlSize.Normal,
    [styles.sizeSmall]: size === ControlSize.Small,
    [styles.sizeExtraSmall]: size === ControlSize.ExtraSmall
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className={classNames([styles.component, className as any])} style={style}>
      {
        (label !== undefined) && (
          <Label className={labelClassNames} color={color} size={size} text={label}/>
        )
      }
      <div className={inputContainerClassNames}>
        {
          icon && (
            <div className={iconWrapperClassNames}>
              <SvgIcon className={iconClassNames} tintColor={tintColor} type={icon} onClick={onIconClick}/>
            </div>
          )
        }
        <input
          className={inputClassNames}
          placeholder={placeholder}
          readOnly={disabled}
          type={type}
          value={value}
          onChange={handleChange}
        />
      </div>
      {
        helperText && (
          <div className={styles.helperText}>{helperText}</div>
        )
      }
    </div>
  );
};

export default InputField;
