import { combineReducers } from 'redux';
import undoable, { StateWithHistory, includeAction } from 'redux-undo';
import { connectRouter, RouterRootState } from 'connected-react-router'

import auth, { AuthState } from './auth';
import editorBlock, { ProgramBlockState } from './program';
import listPage, { ListPageState } from './list';
import gym, { GymState } from './gym';
import designerTour, { DesignerTourState } from './designer-tour';
import { ProgramUndoRedoActions } from '../action-types/program';

export type RootState = {
  router: RouterRootState;
  auth: AuthState;
  editorBlock: StateWithHistory<ProgramBlockState>;
  listPage: ListPageState;
  gym: GymState,
  designerTour: DesignerTourState;
};

const createRootReducer = (history: any) => combineReducers({
  router: connectRouter(history),
  auth,
  editorBlock: undoable(editorBlock, {
    limit: 20,
    filter: includeAction(ProgramUndoRedoActions),
    // groupBy: groupByActionTypes(ProgramGroupActions),
  }),
  listPage,
  gym,
  designerTour,
});

export default createRootReducer;
