import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { findElement } from '../../utils/helper';

import styles from './menu-container.module.scss';

type Props<T> = {
  className?: string;
  disabled?: boolean;
  style?: object;
  wrapperClassName?: string;
  wrapperStyle?: object;
  renderButton: (isVisible: boolean, setVisible: (visible: boolean) => void, toggleMenu: () => void) => React.ReactNode;
  renderMenu: (isVisible: boolean, setVisible: (visible: boolean) => void) => React.ReactNode;
};

const MenuContainer = <T extends any>(props: Props<T>) => {
  const {
    className,
    disabled = false,
    style,
    wrapperClassName,
    wrapperStyle,
    renderButton,
    renderMenu
  } = props;

  const componentRef = useRef(null as HTMLDivElement | null);

  const [isMenuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => setMenuVisible(!isMenuVisible);

  useEffect(() => {
    const handleClickOutside = (e: Event) => {
      if (!componentRef.current || findElement(e.target as HTMLElement, componentRef.current)) {
        return;
      }

      setMenuVisible(false);
    };

    window.document.addEventListener('click', handleClickOutside);

    return () => {
      window.document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={componentRef}
      className={classNames([styles.menuContainer, className])}
      style={style}
    >
      {renderButton(isMenuVisible, setMenuVisible, disabled ? () => {} : toggleMenu)}
      {
        isMenuVisible && (
          <div className={classNames([styles.menuWrapper, wrapperClassName])} style={wrapperStyle}>
            {renderMenu(isMenuVisible, setMenuVisible)}
          </div>
        )
      }
    </div>
  );
};

export default MenuContainer;
