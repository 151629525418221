import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Tour from 'reactour';
import styles from './app-editor-tour.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import { designerActions, userActions } from '../../store/actions';
import { DesignerTourStep } from '../../store/reducers/designer-tour';
import { Button } from '../button';
import { ControlSize } from '../../model/ControlSize';
import { User } from '../../model/User';

type Props = {};

type Step = {
  content: string | (() => React.ReactNode);
  selector?: string;
  highlightedSelectors?: string[];
};

const _steps: Step[] = [
  {
    content: () => {
      return (
        <div>
          Screen recording youtube clip of using templates. (
          <a target="_blank" rel="noopener noreferrer" href="https://youtu.be/y0iKsDJ7PBs"
             className={styles.link}>YouTube</a>)
        </div>
      )
    }
  },
  {
    selector: '#editor__canvas-settings__program__name',
    content: 'Name your program.',
  },
  {
    selector: '#editor__canvas-settings__program__tags',
    content: 'Tags - this will allow you to search for specific programs.'
  },
  {
    selector: '#editor__canvas-settings__canvas',
    content: 'Change canvas properties.'
  },
  {
    selector: '#editor__component-label',
    content: 'Please drag and drop the highlighted TextBox to the canvas.',
    highlightedSelectors: ['#canvas'],
  }
];

function AppEditorTour(props: Props) {
  const dispatch = useDispatch();
  const tourRef = useRef<any>(null);

  const user = useSelector<RootState, User>(state => state.auth.user as User);

  useEffect(() => {
    if (!user.designerTourSeen) {
      dispatch(designerActions.setActive(true));
    }
  }, []);

  const handleCloseTour = useCallback(() => {
    dispatch(userActions.updateUser({
      designerTourSeen: true,
    }));
    dispatch(designerActions.setActive(false));
  }, [dispatch]);

  const initialSteps = useMemo(() => {
    return _steps;
  }, []);

  const addingLabelSteps = useMemo(() => {
    return initialSteps.concat([{
      selector: '#editor__component-settings__label',
      content: 'This is where all the changes take place.',
    }, {
      selector: '#editor__component-image',
      content: 'Please drag and drop the highlighted Image to the canvas.',
      highlightedSelectors: ['#canvas'],
    }]);
  }, [initialSteps]);

  const addingImageSteps = useMemo(() => {
    return addingLabelSteps.concat([{
      selector: '#editor__component-settings__image',
      content: 'This is where all the changes take place.',
    }, {
      selector: '#editor__component-timer',
      content: 'Please drag and drop the highlighted Timer to the canvas.',
      highlightedSelectors: ['#canvas'],
    }]);
  }, [addingLabelSteps]);

  const addingTimeSteps = useMemo(() => {
    return addingImageSteps.concat([{
      selector: '#editor__component-settings__timer',
      content: 'This is where all the changes take place for Timer.'
    }, {
      content: () => {
        return (
          <div className={styles.closeWrapper}>
            <div>Now you can continue to use the app.</div>
            <Button size={ControlSize.ExtraSmall} onClick={handleCloseTour} title={'Close'}/>
          </div>
        );
      }
    }]);
  }, [addingImageSteps, handleCloseTour]);

  const [steps, setSteps] = useState(initialSteps);

  const isOpen = useSelector<RootState, boolean>(state => state.designerTour.active);
  const designerState = useSelector<RootState, DesignerTourStep>(state => state.designerTour.state);


  useEffect(() => {
    if (designerState === DesignerTourStep.initial) {
      setSteps(initialSteps);
    } else if (designerState === DesignerTourStep.addingLabel) {
      setSteps(addingLabelSteps);
      setTimeout(() => {
        tourRef.current?.gotoStep(addingLabelSteps.length - 2);
      }, 100);
    } else if (designerState === DesignerTourStep.addingImage) {
      setSteps(addingImageSteps);
      setTimeout(() => {
        tourRef.current?.gotoStep(addingImageSteps.length - 2);
      }, 100);
    } else if (designerState === DesignerTourStep.addingTimer) {
      setSteps(addingTimeSteps);
      setTimeout(() => {
        tourRef.current?.gotoStep(addingTimeSteps.length - 2);
      }, 100);
    }
    else {
      setSteps(initialSteps);
    }
  }, [designerState, addingLabelSteps, initialSteps, addingImageSteps, addingTimeSteps]);

  const handleChangeStep = useCallback((curr) => {
    dispatch(designerActions.designerTourSetStepNumber(curr))
  }, []);

  return (
    <Tour
      ref={tourRef}
      isOpen={isOpen}
      steps={steps}
      closeWithMask={false}
      onRequestClose={handleCloseTour}
      showNavigation={false}
      showCloseButton={false}
      getCurrentStep={handleChangeStep}
    />
  );
}

export default AppEditorTour;
