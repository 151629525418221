import { Action, createReducer } from '../../utils';
import { DesignerTourType } from '../../action-types';
import { Component } from '../../../model/program/Component';
import { ComponentType } from '../../../model/program/Type';

export enum DesignerTourStep {
  initial = 'initial',
  addingLabel = 'addingLabel',
  addingImage = 'addingImage',
  addingTimer = 'addingTimer',
}

export type DesignerTourState = {
  active: boolean;
  state: DesignerTourStep;
  stepNumber: number;
};

const initialState: DesignerTourState = {
  active: false,
  state: DesignerTourStep.initial,
  stepNumber: 0,
};

const setActive = (state: DesignerTourState, action: Action): DesignerTourState => {
  return {
    ...state,
    active: action.payload,
  };
};

const addComponentToCanvas = (state: DesignerTourState, action: Action<Component>): DesignerTourState => {
  let currentState = state.state;
  let nextState = currentState;
  const comp = action.payload as Component;

  if (currentState === DesignerTourStep.initial && comp.type === ComponentType.Label) {
    nextState = DesignerTourStep.addingLabel;
  } else if (currentState === DesignerTourStep.addingLabel && comp.type === ComponentType.Image) {
    nextState = DesignerTourStep.addingImage;
  }

  return {
    ...state,
    state: nextState,
  };
};

const addTimerToCanvas = (state: DesignerTourState, action: Action<Component>): DesignerTourState => {
  return {
    ...state,
    state: DesignerTourStep.addingTimer,
  };
}

const setStepNumber = (state: DesignerTourState, action: Action): DesignerTourState => {
  return {
    ...state,
    stepNumber: action.payload,
  };
}

export default createReducer(initialState, {
  [DesignerTourType.DESIGNER_TOUR_SET_ACTIVE]: setActive,
  [DesignerTourType.DESIGNER_TOUR_ADD_COMPONENT_TO_CANVAS]: addComponentToCanvas,
  [DesignerTourType.DESIGNER_TOUR_ADD_TIMER_TO_CANVAS]: addTimerToCanvas,
  [DesignerTourType.DESIGNER_TOUR_SET_STEP_NUMBER]: setStepNumber,
});
