import React from 'react';
import PageWrapper from '../../components/page-wrapper';
import useFirebaseEntity from '../../hooks/use-firebase-entity';
import { Gym, mapGymDtoToGym } from '../../model/Gym';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import { Table } from '../../components/table';
import { formatDate } from '../../utils/formatter';
import { TableAction } from '../../components/table/table-action';
import { API } from '../../api';

const HEADER = [
  { title: 'Name' },
  { title: 'Date created' },
  { title: 'Date updated' },
  { title: 'Actions' },
];

function GymsListPage() {
  let [items] = useFirebaseEntity('gyms', [], mapGymDtoToGym) as [Gym[]];

  const handleRemoveGym = (gym: Gym) => async () => {
    try {
      if (window.confirm(`Are you sure you want to delete gym "${gym.name}"`)) {
        await API.gyms.updateGym(gym.id, {
          deleted: true,
        });
      }
    } catch (e) {
      window.alert(e.message);
    }
  }

  const rowActionsHandler = (row: Gym) => {
    return [
      {
        title: 'Remove',
        onClick: handleRemoveGym(row),
      },
    ]
  };

  const body = [
    { key: (row: Gym) => row.name },
    { key: (row: Gym) => formatDate(row.dateCreated) },
    { key: (row: Gym) => formatDate(row.dateUpdated) },
    {
      key: (row: Gym) => {
        return (
          <TableAction actions={rowActionsHandler(row)}/>
        );
      }
    }
  ];

  items = items.filter(v => !v.deleted);

  return (
    <PageWrapper>
      <PageTitle title={'Gyms'}/>
      <PageContent>
        <Table header={HEADER} body={body} data={items}/>
      </PageContent>
    </PageWrapper>
  );
}

export default GymsListPage;
