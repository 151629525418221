import { AccessLevel } from './AccessLevel';
import { DocumentData, DocumentSnapshot, QueryDocumentSnapshot, Timestamp } from './Firebase';

export enum InviteStatus {
  requested = 'requested',
  accepted = 'accepted',
}

export type Invite = {
  id: string;
} & InviteParams & InviteSaveDTO;

export type InviteSaveDTO = {
  dateCreated: Date;
  dateUpdated: Date;
  status: InviteStatus;
} & InviteParams;

export type InviteDTO = {
  dateCreated: Timestamp;
  dateUpdated: Timestamp;
  status: InviteStatus;
} & InviteParams;

export type InviteParams = {
  email: string;
  fromUserId: string;
  accessLevel: AccessLevel;
  gymId: string;
};

export const mapInviteFromDTO = (invite: QueryDocumentSnapshot<DocumentData> | DocumentSnapshot): Invite => {
  const data = invite.data() as InviteDTO;

  return {
    ...data,
    dateCreated: data.dateCreated.toDate(),
    dateUpdated: data.dateUpdated.toDate(),
    id: invite.id,
  };
};

const InviteMaps = {
  [InviteStatus.requested]: 'Requested',
  [InviteStatus.accepted]: 'Accepted',
};

export const mapInviteStatusToLabel = (status: InviteStatus) => {
  return InviteMaps[status];
};

export const mapInviteDtoToInvite = (data: Invite): Invite => {
  return {
    ...data,
    dateCreated: (data.dateCreated as unknown as Timestamp).toDate(),
    dateUpdated: (data.dateUpdated as unknown as Timestamp).toDate(),
  };
};
