import { Component, ComponentBase, createComponentId, Structure } from './Component';
import { PositionType } from './Position';
import { ComponentType } from './Type';
import { Meta } from './Meta';
import { getSize, Size } from './Size';
import { Style } from './Style';
import { Option } from '../Option';
import { Variable } from './Variable';

export const ComponentFontStyleOptions: Option[] = [
  { label: 'Initial', value: 'initial' },
  { label: 'Italic', value: 'italic' },
];

export const ComponentAlignOptions: Option[] = [
  { label: 'Left', value: 'start' },
  { label: 'Center', value: 'center' },
  { label: 'Right', value: 'end' },
  { label: 'Justify', value: 'justify' },
];

type LabelStyle = {
  fontSize: number;
  fontStyle: 'italic' | 'normal' | 'initial' | 'inherit';
  fontFamily: string;
  opacity: number;
  textAlign: 'start' | 'center' | 'end' | 'justify';
  lineHeight: number;
} & Style;

type Params = {
  position: PositionType;
  value?: string;
  id?: string;
  meta?: Meta;
  size?: Size;
  style?: LabelStyle;
  parentId?: string;
  variable?: Variable;
};

const defaultMeta: Meta = {
  name: 'Unnamed Label',
};

const defaultSize = getSize(180, 28);

const defaultStyle: LabelStyle = {
  zIndex: 1,
  color: '#ffffff',
  backgroundColor: 'transparent',
  border: 'none',
  fontSize: 24,
  fontFamily: 'Arial, Helvetica, sans-serif',
  fontStyle: 'initial',
  opacity: 1,
  textAlign: 'start',
  lineHeight: 1.4,
};

export const getDefaultLabelStyle = (style?: any): LabelStyle => {
  if (!style) {
    return defaultStyle;
  }
  return {
    ...defaultStyle,
    ...style,
  };
};

export class ComponentLabel extends ComponentBase {
  constructor(
    {
      position,
      parentId,
      value = 'Placeholder text',
      id = createComponentId(),
      meta = defaultMeta,
      size = defaultSize,
      style = defaultStyle,
      ...rest
    }: Params) {

    super({
      position,
      id,
      meta,
      size,
      style,
      parentId,
      ...rest,
    });

    this.style = {
      ...defaultStyle,
      ...style,
    };
    this.value = value;
  }

  value: string = '';
  type: ComponentType = ComponentType.Label;
  style: LabelStyle;

  getStructure() {
    return {
      ...super.getStructure(),
      value: this.value,
    };
  }
}
