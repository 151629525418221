import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { Program } from '../../../model/program/Program';
import ProgramPreview from '../../editor-preview';
import { getProgramEditorPresentState } from '../../../store/reducers/program';

type Props = {
  onClose: () => void;
} & ReturnType<typeof mapState>;

class SelectedProgramPreview extends React.PureComponent<Props> {
  render() {
    const {
      program,
      onClose,
    } = this.props;

    return (
      <ProgramPreview onClose={onClose} program={program}/>
    );
  }
}

const mapState = (state: RootState) => {
  const {
    editor,
    canvas,
    components,
    timer,
  } = getProgramEditorPresentState(state);

  return {
    program: {
      editor,
      canvas,
      components,
      timer,
    } as Program,
  };
};
export default connect(mapState)(SelectedProgramPreview);
