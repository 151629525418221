import React from 'react';
import withUpdateProperty, { WithUpdatePropertyProps } from '../withUpdateProperty';
import {
  ComponentAlignOptions,
  ComponentFontStyleOptions,
  ComponentLabel
} from '../../../../../model/program/ComponentLabel';
import ColorPicker from '../../../../color-picker';
import Row from '../../row';
import Expandable from '../../../expandable';
import Select from '../../../../select';
import { ComponentFontFamilyOptions } from '../../../../../model/program/Font';
import ComponentValueWrapper from '../component-value-wrapper';
import { ControlSize } from '../../../../../model/ControlSize';
import { ControlColor } from '../../../../../model/ControlColor';

type Props = {
  component: ComponentLabel,
} & WithUpdatePropertyProps;

class LabelSettings extends React.PureComponent<Props> {
  handleChangeColor = (value: string) => {
    this.props.updateProperty('style.color', value);
  };

  handleChangeSelect = (key: string) => (value: any) => {
    this.props.updateProperty(`style.${key}`, value);
  };

  render() {
    const {
      component,
    } = this.props;

    return (
      <Expandable id={'editor__component-settings__label'} title={'Text'} defaultOpened={true}>
        <Row
          title={'Value'}
          col1={
            <ComponentValueWrapper
              value={component.value}
              path={'value'}
              multi={true}
            />
          }
        />
        <Row
          title={'Font'}
          col1={
            <Select
              size={ControlSize.ExtraSmall}
              color={ControlColor.Dark}
              options={ComponentFontFamilyOptions}
              value={component.style.fontFamily}
              onChange={this.handleChangeSelect('fontFamily')}
            />
          }
        />
        <Row
          title={'Style'}
          col1={
            <Select
              size={ControlSize.ExtraSmall}
              color={ControlColor.Dark}
              options={ComponentFontStyleOptions}
              value={component.style.fontStyle}
              onChange={this.handleChangeSelect('fontStyle')}
            />
          }
        />
        <Row
          title={'Size'}
          col1={
            <ComponentValueWrapper
              value={component.style.fontSize}
              path={'style.fontSize'}
              type={'number'}
              postfix={'PX'}
            />
          }
        />
        <Row
          title={'Color'}
          col1={
            <ComponentValueWrapper
              value={component.style.color}
              path={'style.color'}
              postfix={<ColorPicker value={component.style.color} onChange={this.handleChangeColor}/>}
            />
          }
        />
        <Row
          title={'Line height'}
          col1={
            <ComponentValueWrapper
              value={component.style.lineHeight}
              path={'style.lineHeight'}
              type={'number'}
              postfix={'PX'}
            />
          }
        />
        <Row
          title={'Align'}
          col1={
            <Select
              size={ControlSize.ExtraSmall}
              color={ControlColor.Dark}
              options={ComponentAlignOptions}
              value={component.style.textAlign}
              onChange={this.handleChangeSelect('textAlign')}
            />
          }
        />
      </Expandable>
    );
  }
}

export default withUpdateProperty(LabelSettings);
