import React from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import withNavigation, { WithNavigation } from '../../hoc/withNavigation';
import { getInvite } from '../../api/firebase/invite';
import { Invite } from '../../model/Invite';
import { mapAccessLevelToLabel } from '../../model/AccessLevel';
import InputField from '../../components/input-field';
import { Button } from '../../components/button';
import { API } from '../../api';
import FormWrapper from '../auth/form-wrapper';
import FooterText from '../auth/form-wrapper/footer-text';
import FooterLink from '../auth/form-wrapper/footer-link';
import styles from './invites.module.scss';

type Params = {
  id: string;
}

type Props = {} & WithNavigation & RouteChildrenProps<Params>;

type State = {
  invite?: Invite;
  loading: boolean;
  name: string;
  password: string;
};

class InviteConfirmPage extends React.PureComponent<Props, State> {
  state = {
    invite: undefined,
    loading: true,
    name: '',
    password: '',
  };

  componentDidMount = async () => {
    try {
      const match = this.props.match as any;

      const { id } = match.params;

      const invite = await getInvite(id);

      this.setState({ invite, loading: false });
    } catch (e) {
      this.setState({ invite: undefined, loading: false });
      window.alert(`Error:\n${e.message}`);
    }
  };

  handleChange = (key: string) => (value: string) => {
    this.setState(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  handleSave = async () => {
    const {
      invite,
      name,
      password,
    } = this.state;

    try {
      this.setState({ loading: true });
      await API.invite.createUserFromInvite(invite as unknown as Invite, name, password);
      this.props.navigate('/login');
    } catch (e) {
      this.setState({ loading: false });
      window.alert(`Error:\n${e.message}`);
    }
  };

  render() {
    const {
      invite,
      loading,
      name,
      password,
    } = this.state;

    if (loading) {
      return (
        <div className={styles.center}>Loading...</div>
      );
    }

    if (!invite) {
      return (
        <div className={styles.center}>Invite not found</div>
      );
    }

    const item = invite as unknown as Invite;

    return (
      <FormWrapper
        title={'Invitation'}
        footerText={
          <FooterText text={'Already have an account?'}/>
        }
        footerLink={
          <FooterLink to={'/login'} text={'Log back in'}/>
        }
      >
        <div className={styles.description}>
          You was invited as {mapAccessLevelToLabel(item.accessLevel)}
        </div>
        <InputField className={styles.row} disabled label="Email" value={item.email} onChange={() => {
        }}/>
        <InputField className={styles.row} label="Name" value={name} onChange={this.handleChange('name')}/>
        <InputField className={styles.row} label="Password" type={'password'} value={password}
                    onChange={this.handleChange('password')}/>
        <div className={styles.action}>
          <Button
            disabled={loading}
            title="Save"
            onClick={this.handleSave}
          />
        </div>
      </FormWrapper>
    );
  }
}

export default withNavigation(InviteConfirmPage);
