import React, { useCallback } from 'react';
import styles from './timer-settings.module.scss';
import fill from 'lodash/fill';
import Expandable from '../../expandable';
import Row from '../row';
import ValueWrapper from '../value-wrapper';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducers';
import {
  getDefaultTimerIntervalCycle,
  IntervalCycle,
  mergeTimerStyleWithDefault,
  mergeTimerWarmupStyleWithDefault,
  Timer,
  TimerType,
  TimerTypeOptions
} from '../../../../model/Timer';
import { editorBlockActions } from '../../../../store/actions';
import Select from '../../../select';
import { ControlColor } from '../../../../model/ControlColor';
import { ControlSize } from '../../../../model/ControlSize';
import { Option } from '../../../../model/Option';
import CommonSettings from '../component-settings/common-settings';
import RadioGroup from '../../../radio-group';
import { Button } from '../../../button';
import { IconType, SvgIcon } from '../../../icon';
import Checkbox from '../../../checkbox';
import { ComponentFontFamilyOptions } from '../../../../model/program/Font';
import ColorPicker from '../../../color-picker';
import { getProgramEditorPresentState } from '../../../../store/reducers/program';
import { getTotalTimeFromTimer } from '../../../../utils/helper';

const IntervalsOptions: Option[] = fill(Array(100), undefined).map((v, i) => ({
  value: i + 1,
  label: `${i + 1} Sets`
}));

const BorderSizeOptions: Option[] = [
  { label: '0px', value: '0px' },
  { label: '1px', value: '1px' },
  { label: '2px', value: '2px' },
  { label: '3px', value: '3px' },
  { label: '4px', value: '4px' },
  { label: '5px', value: '5px' },
  { label: '6px', value: '6px' },
  { label: '7px', value: '7px' },
  { label: '8px', value: '8px' },
  { label: '9px', value: '9px' },
];

const BorderStyleOptions: Option[] = [
  { label: 'Dotted', value: 'dotted' },
  { label: 'Dashed', value: 'dashed' },
  { label: 'Solid', value: 'solid' },
  { label: 'Double', value: 'double' },
];

const TimerSettings = () => {
  const dispatch = useDispatch();

  const timer = useSelector((state: RootState) => getProgramEditorPresentState(state).timer) as Timer;
  const isSimpleTimer = timer && timer.timerType === TimerType.Simple;

  const updateProperty = (path: string) => (value: any) => {
    _updateProperty(path, value);
  };

  const _updateProperty = useCallback((path: string, value: any) => {
    dispatch(editorBlockActions.updateTimerProperty(path, value));
  }, [dispatch]);

  const handleIncreaseZIndex = () => {
    const min = Math.min(timer.style.zIndex + 1, 1000);
    _updateProperty('style.zIndex', min);
  };

  const handleDecreaseZIndex = () => {
    const max = Math.max(timer.style.zIndex - 1, 0);
    _updateProperty('style.zIndex', max);
  };

  const handleRemoveTimer = () => {
    dispatch(editorBlockActions.removeTimer());
  };

  const renderSimpleSettings = (interval: IntervalCycle, position: number) => {
    return (
      <>
        <Row
          title={'Intervals'}
          col1={
            <Select
              options={IntervalsOptions}
              value={interval.numberOfSets}
              onChange={updateProperty(`intervals.${position}.numberOfSets`)}
              color={ControlColor.Dark}
              size={ControlSize.ExtraSmall}
            />
          }
        />
        <div className={styles.row}>
            <Checkbox
              label={'High interval first'}
              className={styles.checkbox}
              checked={interval.highIntervalFirst || false}
              onChange={updateProperty(`intervals.${position}.highIntervalFirst`)}
            />
        </div>
        <Row
          title={'Low'}
          col1={
            <ValueWrapper
              postfix={'mins'}
              value={interval.lowDuration}
              onUpdate={updateProperty(`intervals.${position}.lowDuration`)}
              validate={handleValidateTime}
            />
          }
        />
        <Row
          title={'High'}
          col1={
            <ValueWrapper
              postfix={'mins'}
              value={interval.highDuration}
              onUpdate={updateProperty(`intervals.${position}.highDuration`)}
              validate={handleValidateTime}
            />
          }
        />
      </>
    );
  };

  const handleAddMore = useCallback(() => {
    _updateProperty(`intervals.${timer.intervals.length}`, getDefaultTimerIntervalCycle());
  }, [timer]);

  const handleRemoveCycle = useCallback((index: number) => {
    return () => {
      if (timer.intervals.length <= 1) {
        return;
      }

      const intervals = [...timer.intervals];
      intervals.splice(index, 1);

      _updateProperty('intervals', intervals);
    }
  }, [timer]);

  const renderComplexSettings = () => {
    const totalTime = getTotalTimeFromTimer(timer);
    return (
      <div>
        <Row
          title={'Rest'}
          col1={
            <ValueWrapper
              postfix={'mins'}
              value={timer.restTime}
              onUpdate={updateProperty('restTime')}
              validate={handleValidateTime}
            />
          }
        />
        {timer.intervals.map((interval, index) => {
          return (
            <div key={index}>
              <div className={styles.cycleAction}>
                <div className={styles.cycleText}>Cycle #{index + 1}</div>
                <SvgIcon className={styles.cycleRemove} type={IconType.Trash} onClick={handleRemoveCycle(index)}/>
              </div>
              <div>
                {renderSimpleSettings(interval, index)}
              </div>
            </div>
          );
        })}
        <div className={styles.action}>
          <Button color={ControlColor.Amber} size={ControlSize.ExtraSmall} title={'Add Cycle'} onClick={handleAddMore}/>
        </div>
        <Row
          title={'Total Time'}
          col1={
            <div className={styles.totalTime}>
              {totalTime}
            </div>
          }
        />
      </div>
    );
  };

  const renderWarmUpSettings = () => {
    if (!timer.showWarmUpComponent) {
      return null;
    }

    const wStyle = mergeTimerWarmupStyleWithDefault(timer.warmUpComponent.style);

    const updateWUProperty = (path: string) => {
      return updateProperty(`warmUpComponent.${path}`)
    };

    return (
      <Expandable title={'WarmUp Style'}>
        <Row
          title={'Font'}
          col1={
            <Select
              size={ControlSize.ExtraSmall}
              color={ControlColor.Dark}
              options={ComponentFontFamilyOptions}
              value={wStyle.fontFamily}
              onChange={updateWUProperty('style.fontFamily')}
            />
          }
        />
        <Row
          title={'Size'}
          col1={
            <ValueWrapper
              value={wStyle.fontSize}
              type={'number'}
              postfix={'PX'}
              onUpdate={updateWUProperty('style.fontSize')}
            />
          }
        />
        <Row
          title={'Color'}
          col1={
            <ValueWrapper
              value={wStyle.color}
              onUpdate={updateWUProperty('style.color')}
              postfix={<ColorPicker value={wStyle.color} onChange={updateWUProperty('style.color')}/>}
            />
          }
        />
        <Row
          title={'Bg Color'}
          col1={
            <ValueWrapper
              value={wStyle.backgroundColor}
              onUpdate={updateWUProperty('style.backgroundColor')}
              postfix={<ColorPicker value={wStyle.backgroundColor} onChange={updateWUProperty('style.backgroundColor')}/>}
            />
          }
        />
        <Row
          title={'Border width'}
          col1={
            <Select
              size={ControlSize.ExtraSmall}
              color={ControlColor.Dark}
              options={BorderSizeOptions}
              value={wStyle.borderWidth}
              onChange={updateWUProperty('style.borderWidth')}
            />
          }
        />
        <Row
          title={'Border style'}
          col1={
            <Select
              size={ControlSize.ExtraSmall}
              color={ControlColor.Dark}
              options={BorderStyleOptions}
              value={wStyle.borderStyle}
              onChange={updateWUProperty('style.borderStyle')}
            />
          }
        />
        <Row
          title={'Border color'}
          col1={
            <ValueWrapper
              value={wStyle.borderColor}
              onUpdate={updateWUProperty('style.borderColor')}
              postfix={<ColorPicker value={wStyle.borderColor} onChange={updateWUProperty('style.borderColor')}/>}
            />
          }
        />
      </Expandable>
    );
  };

  const handleValidateTime = useCallback((value) => {
    const errorText = 'The time should be formatted as **:**. For example 10:59';
    if (!value) {
      return errorText;
    }

    const reg = /^\d\d:[0-5][0-9]$/;

    if (reg.test(value)) {
      return null;
    }

    return errorText;
  }, []);


  if (!timer) {
    return null;
  }

  const tStyle = mergeTimerStyleWithDefault(timer.style);

  return (
    <>
      <CommonSettings
        component={timer}
        onChange={_updateProperty}
        onIncreaseZIndex={handleIncreaseZIndex}
        onDecreaseZIndex={handleDecreaseZIndex}
        onRemoveComponent={handleRemoveTimer}
      />
      <Expandable id="editor__component-settings__timer" defaultOpened={true} title={'Timer Settings'}>
        <Row
          title={'Type'}
          col1={
            <RadioGroup
              items={TimerTypeOptions}
              value={timer.timerType}
              onChange={updateProperty('timerType')}
            />
          }
        />
        <Row
          title={'Warm Up'}
          col1={
            <ValueWrapper
              postfix={'mins'}
              value={timer.warmUp}
              onUpdate={updateProperty('warmUp')}
              validate={handleValidateTime}
            />
          }
        />
        <div className={styles.row}>
          <Checkbox className={styles.checkbox} label={'Show warm up module'} checked={timer.showWarmUpComponent}
                    onChange={updateProperty('showWarmUpComponent')}/>
        </div>
        {timer.showWarmUpComponent &&
        <Row
          title={'Value'}
          column={true}
          col1={
            <ValueWrapper
              value={timer.warmUpComponent.text}
              onUpdate={updateProperty('warmUpComponent.text')}
              multi={true}
            />
          }
        />
        }
        {isSimpleTimer ? renderSimpleSettings(timer.intervals[0], 0) : renderComplexSettings()}
      </Expandable>
      <Expandable title={'Timer Style'}>
        <Row
          title={'Font'}
          col1={
            <Select
              size={ControlSize.ExtraSmall}
              color={ControlColor.Dark}
              options={ComponentFontFamilyOptions}
              value={tStyle.fontFamily}
              onChange={updateProperty('style.fontFamily')}
            />
          }
        />
        <Row
          title={'Size'}
          col1={
            <ValueWrapper
              value={tStyle.fontSize}
              type={'number'}
              postfix={'PX'}
              onUpdate={updateProperty('style.fontSize')}
            />
          }
        />
        <Row
          title={'Color'}
          col1={
            <ValueWrapper
              value={tStyle.color}
              onUpdate={updateProperty('style.color')}
              postfix={<ColorPicker value={tStyle.color} onChange={updateProperty('style.color')}/>}
            />
          }
        />
        <Row
          title={'Bg Color'}
          col1={
            <ValueWrapper
              value={tStyle.backgroundColor}
              onUpdate={updateProperty('style.backgroundColor')}
              postfix={<ColorPicker value={tStyle.backgroundColor} onChange={updateProperty('style.backgroundColor')}/>}
            />
          }
        />
      </Expandable>
      {timer.showWarmUpComponent &&
        renderWarmUpSettings()
      }
    </>
  );
};

export default TimerSettings;
