import React from 'react';
import ReactDOM from 'react-dom';
import hoistNonReactStatic from 'hoist-non-react-statics';

export function withClickOutside(WrappedComponent: React.ComponentType<any>) {
  class EnhancedComponent extends React.Component<any> {
    __domNode?: Element | Text | null;
    __wrappedInstance: any;
    handleClickOutside: any;

    constructor(props: any) {
      super(props);

      this._handleClickOutside = this._handleClickOutside.bind(this);
    }

    componentDidMount() {
      document.addEventListener('click', this._handleClickOutside, true);
    }

    componentWillUnmount() {
      document.removeEventListener('click', this._handleClickOutside, true);
    }

    _handleClickOutside(e: any) {
      const domNode = this.__domNode;
      if (
        (!domNode || !domNode.contains(e.target)) &&
        this.__wrappedInstance &&
        typeof this.__wrappedInstance.handleClickOutside === 'function'
      ) {
        this.__wrappedInstance.handleClickOutside(e);
      }
    }

    render() {
      const { wrappedRef, ...rest } = this.props;

      return (
        <WrappedComponent
          {...rest}
          ref={(c: any) => {
            this.__wrappedInstance = c;
            this.__domNode = ReactDOM.findDOMNode(c);
            wrappedRef && wrappedRef(c);
          }}
        />
      );
    }
  }

  //@ts-ignore
  EnhancedComponent.displayName = `withClickOutside(${WrappedComponent.displayName || WrappedComponent.name})`;

  return hoistNonReactStatic(EnhancedComponent, WrappedComponent);
}
