import React from 'react';
import { AbstractComponent } from '../../../../../model/program/Component';
import Expandable from '../../../expandable';
import Row from '../../row';
import styles from '../component-settings.module.scss';
import ValueWrapper from '../../value-wrapper';
import { IconType, SvgIcon } from '../../../../icon';
import { numberToFixedValue } from '../../../../../utils/formatter';
import { ComponentType } from '../../../../../model/program/Type';

type Props = {
  component: AbstractComponent;
  onChange: (path: string, value: number | string) => void;
  onIncreaseZIndex: () => void;
  onDecreaseZIndex: () => void;
  onRemoveComponent: () => void;
}

const CommonSettings = (props: Props) => {
  const {
    component,
    onChange,
    onIncreaseZIndex,
    onDecreaseZIndex,
    onRemoveComponent,
  } = props;

  const {
    position,
    size,
    style,
  } = component;

  const increaseZIndex = () => {
    onIncreaseZIndex();
  };

  const decreaseZIndex = () => {
    onDecreaseZIndex();
  };

  const removeComponent = () => {
    onRemoveComponent();
  };

  const renderActions = () => {
    const isWarmUpSelected = component.type === ComponentType.TimerWarmUp;

    if (isWarmUpSelected) {
      return null;
    }

    return (
      <div className={styles.actions}>
        <SvgIcon className={styles.up} type={IconType.ZIndexUp} onClick={increaseZIndex}/>
        <SvgIcon className={styles.down} type={IconType.ZIndexDown} onClick={decreaseZIndex}/>
        <SvgIcon className={styles.trash} type={IconType.Trash} onClick={removeComponent}/>
      </div>
    );
  };

  const handleChange = (path: string) => (value: any) => {
    if (path === 'style.opacity') {
      onChange(path, value / 100);
    } else {
      onChange(path, numberToFixedValue(value));
    }
  };

  return (
    <Expandable
      title={'Arrange'}
      controls={renderActions()}
      defaultOpened={true}
    >
      <Row
        title={'Position'}
        col1={<ValueWrapper onUpdate={handleChange('position.x')} value={position.x}
                                     postfix={'X'}/>}
        col2={<ValueWrapper onUpdate={handleChange('position.y')} value={position.y}
                                     postfix={'Y'}/>}
      />
      <Row
        title={'Size'}
        col1={<ValueWrapper onUpdate={handleChange('size.width')} value={size.width}
                                     postfix={'W'}/>}
        col2={<ValueWrapper onUpdate={handleChange('size.height')} value={size.height}
                                     postfix={'H'}/>}
      />
      <Row
        title={'Opacity'}
        col1={<ValueWrapper onUpdate={handleChange('style.opacity')}
                                     value={Math.round(style.opacity * 100)}
                                     type={'number'}
                                     postfix={'%'}/>}
      />
    </Expandable>
  )
};

export default CommonSettings;
