import React, { ChangeEvent } from 'react';
import styles from './picker.module.scss';
import Label from '../../../components/label';
import { ControlColor } from '../../../model/ControlColor';
import { ControlSize } from '../../../model/ControlSize';

type Props = {
  value: string;
  onChange: (value: string) => void;
  label?: string;
};
const ColorPicker = ({ onChange, value, label }: Props) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <>
      {(label !== undefined) && (
        <Label color={ControlColor.Default} className={styles.label} size={ControlSize.Normal} text={label}/>
      )}
      <div className={styles.wrapper}>
        <div className={styles.value}>{value}</div>
        <input className={styles.input} type="color" onChange={handleChange}/>
        <div className={styles.color} style={{ backgroundColor: value }}/>
      </div>
    </>
  );
};

export default ColorPicker;
