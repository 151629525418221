import { Option } from '../Option';

const items = [
  'HIIT',
  'Partners',
  'Teams',
  'EMOM',
  'Circuit',
  'AMRAP',
  'Other',
];

export const ProgramFormatOptions: Option[] = items.map(s => ({ label: s, value: s }));
