import React from 'react';
import styles from './components.module.scss';
import ListItem from '../list-item';
import { ComponentType } from '../../../model/program/Type';
import BlocksListItem from '../list-item/blocks';
import Timer from '../list-item/timer';
import { RootState } from '../../../store/reducers';
import { connect } from 'react-redux';
import { getProgramEditorPresentState } from '../../../store/reducers/program';

type Props = {} & ReturnType<typeof mapState>;

class Components extends React.PureComponent<Props> {
  render() {
    const {
      isBlockEditorActive,
      isDesignerTourActive,
      designerStep,
    } = this.props;

    let labelStyle = '';
    let imageStyle = '';
    let timerStyle = '';

    if (isDesignerTourActive) {
      if (designerStep === 4) {
        labelStyle = styles.active;
      }

      if (designerStep === 6) {
        imageStyle = styles.active;
      }

      if (designerStep === 8) {
        timerStyle = styles.active;
      }
    }

    return (
      <div className={styles.wrapper}>
        <ListItem type={ComponentType.Square}/>
        <ListItem type={ComponentType.Circle}/>
        <ListItem className={labelStyle} id="editor__component-label" type={ComponentType.Label}/>
        <ListItem className={imageStyle} id="editor__component-image" type={ComponentType.Image}/>
        {!isBlockEditorActive && (
          <>
            <BlocksListItem/>
            <Timer className={timerStyle}/>
          </>
        )}
      </div>
    );
  }
}

const mapState = (state: RootState) => ({
  isDesignerTourActive: state.designerTour.active,
  designerStep: state.designerTour.stepNumber,
  isBlockEditorActive: getProgramEditorPresentState(state).isBlockEditorActive,
});

export default connect(mapState)(Components);
