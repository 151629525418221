import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './menu.module.scss';
import Logo from './logo';
import { ReactComponent as Blocks } from './svg/menu-icon-blocks-default.svg';
import { ReactComponent as Exercises } from './svg/menu-icon-exercises-default.svg';
import { ReactComponent as Programs } from './svg/menu-icon-programs-default.svg';
import { ReactComponent as Screens } from './svg/menu-icon-screens-default.svg';
import { ReactComponent as Team } from './svg/menu-icon-team-default.svg';
import UserInfo from './user-info';

const Menu = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.logoWrapper}>
        <Logo/>
      </div>
      <nav className={styles.navList}>
        <NavLink id="menu__screens" className={styles.link} to={'/screens'} activeClassName={styles.linkActive}>
          <Screens className={styles.icon}/>
          <div className={styles.label}>Screens</div>
        </NavLink>
        <NavLink className={styles.link} to={'/exercises'} activeClassName={styles.linkActive}>
          <Exercises className={styles.icon}/>
          <div className={styles.label}>Exercises</div>
        </NavLink>
        <NavLink id="menu__programs" className={styles.link} to={'/programs'} activeClassName={styles.linkActive}>
          <Programs className={styles.icon}/>
          <div className={styles.label}>Programs</div>
        </NavLink>
        <NavLink className={styles.link} to={'/blocks'} activeClassName={styles.linkActive}>
          <Blocks className={styles.icon}/>
          <div className={styles.label}>Blocks</div>
        </NavLink>
        <NavLink id="menu__teams" className={styles.link} to={'/team'} activeClassName={styles.linkActive}>
          <Team className={styles.icon}/>
          <div className={styles.label}>Team</div>
        </NavLink>
      </nav>
      <UserInfo/>
    </div>
  );
};

export default Menu;
