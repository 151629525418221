import React from 'react';
import styles from './user-info.module.scss';
import { connect } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { withClickOutside } from '../../../hoc/withClickOutside';
import UserPopup from './user-popup';
import UserAvatar from '../../user-avatar';

type Props = {} & ReturnType<typeof mapState>;

type State = {
  showPopup: boolean;
};

class UserInfo extends React.PureComponent<Props, State> {
  state = {
    showPopup: false,
  };

  handleClickOutside = () => {
    const { showPopup } = this.state;
    if (showPopup) {
      this.setState({ showPopup: false });
    }
  };

  handleShowPopup = () => {
    this.setState({ showPopup: true });
  };

  render() {
    const {
      user,
      gym,
    } = this.props;

    const {
      showPopup,
    } = this.state;

    return (
      <div id="user-info__menu__popup-wrapper" className={styles.wrapper} onClick={this.handleShowPopup}>
        <UserAvatar url={(user && user.avatarUrl) || undefined}/>
        <div className={styles.names}>
          <div className={styles.name}>
            {user && user.name}
          </div>
          {(user && user.gymId) === (gym && gym.id) &&
          <div className={styles.gym}>
            {gym && gym.name}
          </div>
          }
        </div>
        {showPopup && <UserPopup/>}
      </div>
    );
  }
}

const mapState = (state: RootState) => ({
  gym: state.gym.gym,
  user: state.auth.user,
});

export default connect(mapState)(withClickOutside(UserInfo));
