import React, { useState } from 'react';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import PageWrapper from '../../../components/page-wrapper';
import { createNewComponent, mapProgramDtoToProgram, Program, ProgramType } from '../../../model/program/Program';
import PageTitle from '../../../components/page-title';
import styles from './list.module.scss';
import InputField from '../../../components/input-field';
import { Button } from '../../../components/button';
import { Table } from '../../../components/table';
import { TableAction } from '../../../components/table/table-action';
import useFirebaseEntity from '../../../hooks/use-firebase-entity';
import { capitalizeFirstLetter } from '../../../utils/helper';
import { TagList } from '../../../components/tag-list';
import { ControlSize } from '../../../model/ControlSize';
import { IconType } from '../../../components/icon';
import PageContent from '../../../components/page-content';
import { formatDate } from '../../../utils/formatter';
import { TableActionKind, TableActionType } from '../../../components/table/table-action-list';
import { RootState } from '../../../store/reducers';
import { User } from '../../../model/User';
import { Tag } from '../../../components/tags-field';
import { mapTagDtoToTag } from '../../../model/Tag';
import ProgramPreview from '../../../components/editor-preview';
import { BlockType, mapBlockDtoToBlock } from '../../../model/program/Block';
import OnlyRole from '../../../components/only-role';
import { AccessLevel } from '../../../model/AccessLevel';
import useOnlyRoles from '../../../hooks/use-only-roles';
import { API } from '../../../api';
import { Gym } from '../../../model/Gym';
import isArray from 'lodash/isArray';

const HEADER = [
  { title: 'Name' },
  { title: 'Duration' },
  { title: 'Focus' },
  { title: 'Tags' },
  { title: 'Created' },
  { title: 'Updated' },
  { className: styles.tableAction }
];

const ProgramListTemplatesPage = () => {
  const dispatch = useDispatch();

  const isAdmin = useOnlyRoles<boolean>([AccessLevel.admin], true, false);
  const user = useSelector((state: RootState) => state.auth.user) as User;
  const gym = useSelector((state: RootState) => state.gym.gym) as Gym;
  const [search, setSearch] = useState('');
  const [items, { hasFetched }, { remove }] = useFirebaseEntity(
    'programTemplates',
    undefined, mapProgramDtoToProgram) as [Program[], any, any];

  const copy = async (id: string) => {
    try {
      await API.program.copyProgramFromTemplates(id, user, gym);
      alert('The program has been copied!');
    } catch (e) {
      console.log(e);
      alert(e.message || 'Error has been occurred.');
    }
  };

  const [
    blocks,
  ] = useFirebaseEntity('blockTemplates', undefined, mapBlockDtoToBlock) as [BlockType[], any];

  const [showPreview, setShowPreview] = useState(false);

  const [selectedProgram, setSelectedProgram] = useState();

  const filteredItems = items
    .filter(item => !search || item.canvas.name.toLowerCase().includes(search.toLowerCase()))
    .sort((a, b) =>
      ((b.editor.dateUpdated?.valueOf() || 0) || (b.editor.dateCreated?.valueOf() || 0)) -
      ((a.editor.dateUpdated?.valueOf() || 0) || (a.editor.dateCreated?.valueOf() || 0)));

  const [tags] = useFirebaseEntity('programTags', undefined, mapTagDtoToTag) as [Tag[]];

  const handleAddProgram = () => dispatch(push('/programs/templates/editor'));
  const handleClosePreview = () => {
    setSelectedProgram(undefined);
    setShowPreview(false);
  };

  const handleShowPreview = (data: Program) => {
    data.components = data.components.map(c => createNewComponent(c.type, c, blocks));

    setSelectedProgram(data);
    setShowPreview(true);
  };

  const rowActionsHandler = (data: Program): TableActionType[] => {
    return [
      {
        title: 'Preview',
        onClick: () => {
          handleShowPreview(data);
        }
      },
      isAdmin ? {
        title: 'Edit',
        onClick: () => dispatch(push(`/programs/templates/editor/${data.editor.id}`)),
      } : null,
      {
        title: 'Copy to my gym',
        onClick: () => {
          if (window.confirm('Are you sure you want to copy program?')) {
            copy(data.editor.id as string);
          }
        },
      },
      isAdmin ? {
        title: 'Delete',
        onClick: () => {
          if (window.confirm('Are you sure you want to delete program?')) {
            remove(data.editor.id);
          }
        },
        kind: TableActionKind.Warning,
      } : null,
    ].filter(a => !!a) as TableActionType[];
  };

  const body = [
    {
      key: (row: Program) => {
        return (
          <div className={styles.link} onClick={() => handleShowPreview(row)}>{row.canvas.name}</div>
        )
      }
    },
    { key: (row: Program) => row.editor.duration },
    { key: (row: Program) => row.editor.focus ? isArray(row.editor.focus) ? <TagList className={styles.tagList} tags={row.editor.focus}/> : capitalizeFirstLetter(row.editor.focus) : null},
    {
      key: (row: Program) => {
        const rTags = row.editor.tags;
        if (!rTags) {
          return null;
        }

        const items = tags.filter(t => rTags.includes(t.value)).map(i => i.label);
        return (
          <TagList className={styles.tagList} tags={items}/>
        );
      }
    },
    {
      key: (row: Program) => formatDate(row.editor.dateCreated as Date),
    },
    {
      key: (row: Program) => formatDate(row.editor.dateUpdated as Date),
    },
    {
      key: (data: Program) => {
        return (
          <TableAction actions={rowActionsHandler(data)}/>
        );
      },
      className: styles.tableAction,
    },
  ];

  return (
    <PageWrapper>
      <PageTitle title={'Program Templates'}>
        <OnlyRole roles={[AccessLevel.admin]}>
          <Button
            size={ControlSize.Small}
            title="Add Template"
            onClick={handleAddProgram}
          />
        </OnlyRole>
      </PageTitle>
      <PageContent className={styles.content}>
        <div className={styles.actions}>
          <div>
            <InputField
              icon={IconType.Search}
              className={styles.search}
              placeholder="Search programs..."
              size={ControlSize.Small}
              value={search}
              onChange={setSearch}
            />
          </div>
        </div>
        <Table header={HEADER} body={body} data={filteredItems} isLoading={!hasFetched}/>
      </PageContent>
      {showPreview && <ProgramPreview program={selectedProgram} onClose={handleClosePreview}/>}
    </PageWrapper>
  );
};

export default ProgramListTemplatesPage;
