import React from 'react';
import { Dispatch } from 'redux';
import styles from './popup.module.scss';
import Popup from '../../../popup';
import { authActions } from '../../../../store/actions';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import OnlyRole from '../../../only-role';
import { AccessLevel } from '../../../../model/AccessLevel';

type Props = {} & ReturnType<typeof mapActions>;

class UserPopup extends React.PureComponent<Props> {
  handleNavigateToProfile = () => {
    this.props.navigateToProfile();
  };

  handleNavigateToGymSettings = () => {
    this.props.navigateToGymSettings();
  };

  render() {
    return (
      <Popup id={'user-popup'} className={styles.popup}>
        <ul className={styles.list}>
          <OnlyRole roles={[AccessLevel.owner, AccessLevel.admin]}>
            <li id="user-popup__gym-settings" className={styles.item} onClick={this.handleNavigateToGymSettings}>
              Gym Settings
            </li>
          </OnlyRole>
          <li className={styles.item} onClick={this.handleNavigateToProfile}>
            Profile
          </li>
          <li className={styles.item} onClick={this.props.logOut}>
            Log Out
          </li>
        </ul>
      </Popup>
    );
  }
}

const mapActions = (dispatch: Dispatch) => ({
  logOut: () => dispatch(authActions.logOut()),
  navigateToProfile: () => dispatch(push('/profile')),
  navigateToGymSettings: () => dispatch(push('/gym-settings')),
});


export default connect(null, mapActions)(UserPopup);
