import React from 'react';
import { Dispatch } from 'redux';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { editorBlockActions } from '../../../../store/actions';
import { connect } from 'react-redux';

interface InjectedProps {
  updateProperty: (path: string, value: string) => void;
}

export interface WithUpdatePropertyProps {
  updateProperty: (path: string, value: string | number) => void;
}

const mapActions = (dispatch: Dispatch) => ({
  updateProperty: (path: string, value: string) =>
    dispatch(editorBlockActions.updatePropertyForSelected(path, value)),
});

export default function<BaseProps extends InjectedProps>(WrappedComponent: React.ComponentType<BaseProps>) {
  type HocProps = BaseProps & WithUpdatePropertyProps;

  class WithUpdateProperty extends React.PureComponent<BaseProps & HocProps> {
    static displayName: string;

    handleUpdateProperty = (path: string, value: string) => {
      this.props.updateProperty(path, value);
    };

    render() {
      const {
        updateProperty,
        ...rest
      } = this.props;
      return (
        <WrappedComponent
          {...(rest as BaseProps)}
          updateProperty={this.handleUpdateProperty}
        />
      );
    }
  }

  function getDisplayName() {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
  }

  WithUpdateProperty.displayName = `WithUpdateProperty(${getDisplayName()})`;

  hoistNonReactStatic(WithUpdateProperty, WrappedComponent);

  // @ts-ignore
  return connect<{}, WithUpdatePropertyProps, BaseProps>(null, mapActions)(WithUpdateProperty);
};
