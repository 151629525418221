import React from 'react';
import styles from './page-title.module.scss';

type Props = {
  title: string;
  children?: React.ReactNode;
  className?: string;
};

const PageTitle = (props: Props) => {
  const {
    title,
    children,
    className = '',
  } = props;

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <div className={styles.title}>{title}</div>
      {children && <div>{children}</div>}
    </div>
  );
};

export default PageTitle;
