import React from 'react';
import { ComponentShape } from '../../../../../model/program/ComponentShape';
import throttle from 'lodash/throttle';
import Row from '../../row';
import Expandable from '../../../expandable';
import ColorPicker from '../../../../../components/color-picker';
import withUpdateProperty, { WithUpdatePropertyProps } from '../withUpdateProperty';
import ComponentValueWrapper from '../component-value-wrapper';

type Props = {
  component: ComponentShape;
} & WithUpdatePropertyProps;

class ShapeSettings extends React.PureComponent<Props> {
  handleChangeColor = throttle((value: string) => {
    this.props.updateProperty('style.backgroundColor', value);
  }, 300);

  render() {
    const {
      component,
    } = this.props;


    return (
      <>
        <Expandable title={'Fill'}>
          <Row
            title={'Color'}
            col1={
              <ComponentValueWrapper
                value={component.style.backgroundColor}
                path={'style.backgroundColor'}
                postfix={<ColorPicker value={component.style.backgroundColor} onChange={this.handleChangeColor}/>}
              />
            }
          />
        </Expandable>
      </>
    );
  }
}

export default withUpdateProperty<Props>(ShapeSettings);
