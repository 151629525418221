import { useSelector } from 'react-redux';

import { AccessLevel } from '../model/AccessLevel';
import { RootState } from '../store/reducers';
import { User } from "../model/User";

const useOnlyRoles = <T>(roles: AccessLevel[], value: T | ((user: User) => T), defaultValue: T) => {
  const user = useSelector((state: RootState) => state.auth.user);

  if (!user || !user.roles.some(role => roles.includes(role))) {
    return defaultValue;
  }

  return (typeof value === 'function') ? (value as (user: User) => T)(user) : value;
};

export default useOnlyRoles;
