import { createAction } from '../utils';
import { GymActionType } from '../action-types';
import { Gym } from '../../model/Gym';

export const fetchGym = () => createAction(GymActionType.FETCH_GYM);
export const fetchGymStart = () => createAction(GymActionType.FETCH_GYM_START);
export const fetchGymSuccess = (gym: Gym) => createAction(GymActionType.FETCH_GYM_SUCCESS, gym);
export const fetchGymError = (error: Error) => createAction(GymActionType.FETCH_GYM_ERROR, error);
export const uploadGymLogo = (file: File | undefined, resolve: Function) => createAction(GymActionType.UPLOAD_LOGO, { file, resolve });
export const updateGym = (gym: any, resolve?: Function) => createAction(GymActionType.UPDATE_GYM, { gym, resolve });
export const updateGymSuccess = (gym: any) => createAction(GymActionType.UPDATE_GYM_SUCCESS, gym);
export const changeActive = (gym: Gym) => createAction(GymActionType.CHANGE_ACTIVE_GYM, gym);
