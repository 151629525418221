import React, { MouseEvent, useRef, useState } from 'react';
import * as firebase from 'firebase/app';

import { ControlSize } from '../../../model/ControlSize';
import { Program } from '../../../model/program/Program';
import { Screen } from '../../../model/Screen';

import useFirebaseEntity from '../../../hooks/use-firebase-entity';

import { IconType } from '../../../components/icon';
import InputField from '../../../components/input-field';
import Popup from '../../../components/popup';

import styles from './change-program-modal.module.scss';

type Props = {
  screenId: string;
  onCloseRequested: () => void;
};

const ChangeProgramModal = ({screenId, onCloseRequested}: Props) => {
  const [search, setSearch] = useState('');

  const overlayRef = useRef(null as HTMLDivElement | null);

  const screensQuery = [[firebase.firestore.FieldPath.documentId(), screenId]];

  const [
    [screen],
    {isUpdating: isUpdatingScreen},
    {update: updateScreen}
  ] = useFirebaseEntity('screens', screensQuery) as [Screen[], any, any];

  const programsQuery = screen ? [
    ['editor.gymId', screen.gymId],
    ['canvas.mode', screen.orientation]
  ] : [['canvas.mode', null]];

  const [
    programs,
    {isUpdating: isUpdatingProgram},
    {update: updateProgram}
  ] = useFirebaseEntity('programs', programsQuery) as [Program[], any, any];

  const isUpdating = isUpdatingScreen || isUpdatingProgram;

  const handleChange = (id: string) => async () => {
    try {
      const program = programs.find(item => item.id === id) as Program;

      await updateScreen(screenId, {programId: id});

      await updateProgram(id, {
        editor: {
          ...program.editor,
          dateLastPlayed: new Date()
        }
      });

      onCloseRequested();
    } catch (err) {
      console.error(err);

      window.alert('Error. please, try again later.');
    }
  };

  const handleOverlayClick = (e: MouseEvent) => {
    if (e.target === overlayRef.current) {
      onCloseRequested();
    }
  };

  const filtered = search ? programs.filter((p) => (p.canvas.name || '').toLowerCase().includes(search.toLowerCase())) : programs;

  return (
    <div ref={overlayRef} className={styles.overlay} onClick={isUpdating ? undefined : handleOverlayClick}>
      <Popup className={styles.wrapper}>
        <InputField
          className={styles.search}
          icon={IconType.Search}
          placeholder="Search programs..."
          size={ControlSize.ExtraSmall}
          value={search}
          onChange={setSearch}
        />
        {
          filtered.map(program => {
            const active = screen && screen.programId === program.id;

            return (
              <div
                key={program.id}
                className={`${styles.item} ${active ? styles.active : ''}`}
                onClick={isUpdating ? undefined : handleChange(program.id as string)}
              >
                <div className={styles.title}>{program.canvas.name}</div>
              </div>
            );
          })
        }
      </Popup>
    </div>
  );
};

export default ChangeProgramModal;
