import React from 'react';
import classes from 'classnames';
import { Dispatch } from 'redux';
import styles from './wrapper.module.scss';
import helpersStyles from '../helpers.module.scss';
import { Rnd } from 'react-rnd';
import { connect } from 'react-redux';
import { RootState } from '../../../../store/reducers';
import { ComponentBase, ComponentBaseUpdateParams } from '../../../../model/program/Component';
import { editorBlockActions } from '../../../../store/actions';
import { getSize } from '../../../../model/program/Size';
import { getPosition, isPositionEqual } from '../../../../model/program/Position';
import { ComponentType } from '../../../../model/program/Type';
import { getResizingObj, GRID, ResizeBox } from '../helpers';
import { getProgramEditorPresentState } from '../../../../store/reducers/program';

export type WrapperBaseProps = {
  component: ComponentBase;
  children: React.ReactNode;
  className?: string;
  editable?: boolean;
  scale?: number;
  preview?: boolean;
};

type Props = ReturnType<typeof mapState> &
  ReturnType<typeof mapActions> &
  WrapperBaseProps;

class Wrapper extends React.PureComponent<Props> {
  handleClick = () => {
    const {
      isEdit,
      selectComponent,
      editable = true,
      multiMode,
      component,
    } = this.props;

    if (!editable) {
      return;
    }

    if (!isEdit) {
      if (multiMode && component.type === ComponentType.Block) {
        window.alert(`Selecting a dynamic block to be a part of another block is not allowed`);
        return;
      } else {
        selectComponent(component);
      }
    }
  };

  handleResizeStop = (e: MouseEvent | TouchEvent, dir: any, element: HTMLDivElement, delta: any, position: any) => {
    const { size } = this.props.component;
    const width = size.width + delta.width;
    const height = size.height + delta.height;

    this.updateProps({
      size: getSize(width, height),
      position: getPosition(position.x, position.y),
    });
  };

  handleDragStop = (e: any, data: any) => {
    const { position: componentPosition } = this.props.component;
    const position = getPosition(data.x, data.y);
    const isEqual = isPositionEqual(componentPosition, position);
    if (!isEqual) {
      this.updateProps({
        position,
      });
    }
  };

  updateProps = (properties: ComponentBaseUpdateParams) => {
    this.props.updatePropertiesForSelected(properties);
  };

  renderVariableName = () => {
    const { component } = this.props;

    return (
      <div className={styles.variableName}>
        {component.variable.name}
      </div>
    );
  };

  render() {
    const {
      component,
      children,
      isEdit,
      scale,
      isHidden,
      className = '',
    } = this.props;

    const resizing = isEdit ? getResizingObj(true) : getResizingObj(false);
    const style = {
      zIndex: component.style.zIndex,
    };

    const classNames = classes({
      [styles.hidden]: isHidden,
    }, className);

    return (
      <Rnd
        className={classNames}
        style={style}
        onClick={this.handleClick}
        enableResizing={resizing}
        disableDragging={!isEdit}
        bounds={'parent'}
        resizeGrid={GRID}
        dragGrid={GRID}
        position={{
          ...component.position
        }}
        size={{
          ...component.size,
        }}
        resizeHandleClasses={{
          topRight: helpersStyles.resizeClass,
          topLeft: helpersStyles.resizeClass,
          bottomLeft: helpersStyles.resizeClass,
          bottomRight: helpersStyles.resizeClass,
          right: helpersStyles.lineRight,
          left: helpersStyles.lineLeft,
          top: helpersStyles.lineTop,
          bottom: helpersStyles.lineBottom,
        }}
        resizeHandleComponent={{
          topRight: <ResizeBox/>,
          topLeft: <ResizeBox/>,
          bottomLeft: <ResizeBox/>,
          bottomRight: <ResizeBox/>,
        }}
        onResizeStop={this.handleResizeStop}
        onDragStop={this.handleDragStop}
        scale={scale}
      >
        {children}
        {isEdit && component.variable.active && (
          this.renderVariableName()
        )}
      </Rnd>
    );
  }
}

const mapState = (state: RootState, props: WrapperBaseProps) => {
  const {
    selectedComponent,
    isBlockEditorActive,
    selectedComponentInsideBlock,
    preview,
    canvas,
    multiMode,
  } = getProgramEditorPresentState(state);

  const {
    component,
    editable = true,
    scale,
    preview: componentPreview,
  } = props;

  let isEdit = editable && selectedComponent && selectedComponent.id === component.id;
  let isInsideBlockEditing = false;

  if (isBlockEditorActive && selectedComponent && selectedComponent.type === ComponentType.Block) {
    isEdit = false;
  }

  if (isBlockEditorActive && selectedComponentInsideBlock &&
    selectedComponentInsideBlock.id === component.id && selectedComponentInsideBlock.type !== ComponentType.Block) {
    isInsideBlockEditing = true;
    isEdit = true;
  }

  let isHidden = false;

  if (isBlockEditorActive) {
    if (selectedComponent && (selectedComponent.id === component.id || component.parentId === selectedComponent.id)) {
      isHidden = false;
    } else {
      isHidden = true;
    }
  }

  return {
    isHidden,
    multiMode,
    isEdit: isEdit && !preview && !componentPreview,
    scale: scale ? scale : (preview ? 1 : canvas.scale / 100),
    isInsideBlockEditing: isInsideBlockEditing && !preview,
  };
};

const mapActions = (dispatch: Dispatch) => ({
  selectComponent: (component: ComponentBase) => dispatch(editorBlockActions.selectComponent(component)),
  updatePropertiesForSelected: (properties: ComponentBaseUpdateParams) => dispatch(editorBlockActions.updatePropertiesForSelected(properties)),
});

export default connect(mapState, mapActions)(Wrapper);
