import React, { useState } from 'react';
import classNames from 'classnames';

import { ControlColor } from '../../model/ControlColor';
import { ControlSize } from '../../model/ControlSize';
import { Option } from '../../model/Option';
import { TintColor } from '../../model/TintColor';

import { IconType, SvgIcon } from '../icon';
import Label from '../label';
import MenuContainer from '../menu-container';

import styles from './select.module.scss';
import SearchInput from './search-input';

type Props<T> = {
  className?: string;
  color?: ControlColor;
  disabled?: boolean;
  label?: string;
  options: Option<T>[];
  placeholder?: string;
  size?: ControlSize;
  style?: object;
  tintColor?: TintColor;
  value?: T | null;
  onChange: (value: T) => void;
  search?: boolean;
};

const Select = <T extends any>(props: Props<T>) => {
  const {
    className,
    color = ControlColor.Default,
    disabled = false,
    label,
    options,
    placeholder = 'Select...',
    size = ControlSize.Normal,
    style,
    tintColor = TintColor.Green,
    value,
    onChange,
    search = false,
  } = props;

  const selectedOption = options.find(item => item.value === value);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const clearFilter = () => setFilteredOptions(options);

  const labelClassNames = classNames({
    [styles.label]: true,
    [styles.sizeNormal]: size === ControlSize.Normal,
    [styles.sizeSmall]: size === ControlSize.Small,
    [styles.sizeExtraSmall]: size === ControlSize.ExtraSmall
  });

  const valueClassNames = classNames({
    [styles.value]: true,
    [styles.colorDark]: color === ControlColor.Dark,
    [styles.colorDefault]: color === ControlColor.Default,
    [styles.sizeNormal]: size === ControlSize.Normal,
    [styles.sizeSmall]: size === ControlSize.Small,
    [styles.sizeExtraSmall]: size === ControlSize.ExtraSmall
  });

  const placeholderClassNames = classNames({
    [styles.placeholder]: true,
    [styles.colorDark]: color === ControlColor.Dark,
    [styles.colorDefault]: color === ControlColor.Default,
    [styles.sizeNormal]: size === ControlSize.Normal,
    [styles.sizeSmall]: size === ControlSize.Small,
    [styles.sizeExtraSmall]: size === ControlSize.ExtraSmall
  });

  const menuWrapperClassNames = classNames({
    [styles.menuWrapper]: true,
    [styles.sizeNormal]: size === ControlSize.Normal,
    [styles.sizeSmall]: size === ControlSize.Small,
    [styles.sizeExtraSmall]: size === ControlSize.ExtraSmall
  });

  const menuClassNames = classNames({
    [styles.menu]: true,
    [styles.colorDark]: color === ControlColor.Dark,
    [styles.colorDefault]: color === ControlColor.Default,
    [styles.sizeNormal]: size === ControlSize.Normal,
    [styles.sizeSmall]: size === ControlSize.Small,
    [styles.sizeExtraSmall]: size === ControlSize.ExtraSmall
  });

  const iconClassNames = classNames({
    [styles.icon]: true,
    [styles.sizeNormal]: size === ControlSize.Normal,
    [styles.sizeSmall]: size === ControlSize.Small,
    [styles.sizeExtraSmall]: size === ControlSize.ExtraSmall
  });

  const renderButton = (isMenuVisible: boolean, _: any, toggleMenu: () => void) => {
    const selectClassNames = classNames({
      [styles.select]: true,
      [styles.disabled]: disabled,
      [styles.active]: isMenuVisible,
      [styles.colorDark]: color === ControlColor.Dark,
      [styles.colorDefault]: color === ControlColor.Default,
      [styles.sizeNormal]: size === ControlSize.Normal,
      [styles.sizeSmall]: size === ControlSize.Small,
      [styles.sizeExtraSmall]: size === ControlSize.ExtraSmall,
      [styles.tintGreen]: tintColor === TintColor.Green,
      [styles.tintOrange]: tintColor === TintColor.Orange,
      [styles.tintPurple]: tintColor === TintColor.Purple
    });

    const toggle = () => {
      clearFilter();
      toggleMenu();
    };

    return (
      <div className={selectClassNames} onClick={toggle}>
        {
          selectedOption ? (
            <div className={valueClassNames}>{selectedOption.label}</div>
          ) : (
            <div className={placeholderClassNames}>{placeholder}</div>
          )
        }
        <div>
          <SvgIcon active={isMenuVisible} className={iconClassNames} tintColor={tintColor} type={IconType.DownArrow}/>
        </div>
      </div>
    );
  };

  const handleSearchChanged = (text: string) => {
    if (!text) {
      clearFilter();
    } else {
      const s = text.toLowerCase();
      setFilteredOptions(options.filter(o => (o.label || '').toLowerCase().includes(s)));
    }
  };

  const renderMenu = (isMenuVisible: boolean, setMenuVisible: (visible: boolean) => void) => {
    const handleOptionClick = (val: T) => () => {
      setMenuVisible(false);
      clearFilter();

      onChange(val);
    };

    return (
      <div className={menuClassNames}>
        {search && <SearchInput className={styles.searchInput} onChanged={handleSearchChanged}/>}
        {
          filteredOptions.map(option => {
            const optionClassNames = classNames({
              [styles.option]: true,
              [styles.selected]: option.value === value,
              [styles.colorDark]: color === ControlColor.Dark,
              [styles.colorDefault]: color === ControlColor.Default,
              [styles.sizeNormal]: size === ControlSize.Normal,
              [styles.sizeSmall]: size === ControlSize.Small,
              [styles.sizeExtraSmall]: size === ControlSize.ExtraSmall
            });

            return (
              <div
                key={option.value}
                className={optionClassNames}
                onClick={handleOptionClick(option.value)}
              >
                {option.label}
              </div>
            );
          })
        }
      </div>
    );
  };

  return (
    <div className={classNames([styles.component, className as any])}>
      {
        (label !== undefined) && (
          <Label className={labelClassNames} color={color} size={size} text={label}/>
        )
      }
      <MenuContainer
        className={styles.menuContainer}
        disabled={disabled}
        style={style}
        wrapperClassName={menuWrapperClassNames}
        renderButton={renderButton}
        renderMenu={renderMenu}
      />
    </div>
  );
};

export default Select;
