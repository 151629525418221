import { all } from 'redux-saga/effects';
import login from './login';
import logout from './logout';
import program from './program';
import listPage from './list-page';
import user from './user';
import gym from './gym';

export default function* () {
  yield all([
    login(),
    logout(),
    program(),
    listPage(),
    user(),
    gym(),
  ]);
}
