import React, { useEffect, useState } from 'react';
import styles from './gym-settings.module.scss';
import PageWrapper from '../../components/page-wrapper';
import PageContent from '../../components/page-content';
import PageTitle from '../../components/page-title';
import InputField from '../../components/input-field';
import { Button } from '../../components/button';
import AvatarUploader from '../../components/avatar-uploader';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { gymActions } from '../../store/actions';
import { RootState } from '../../store/reducers';
import { getGymPreferences, Gym, GymPreferences } from '../../model/Gym';
import Select from '../../components/select';
import ColorPicker from './picker';
import { ComponentFontFamilyOptions } from '../../model/program/Font';
import { CanvasModeOptions, Mode } from '../../model/program/Canvas';

const GymSettingsPage = () => {
  const dispatch = useDispatch();

  const imageUrl = useSelector((state: RootState) => state.gym.gym && state.gym.gym.imageUrl);
  const { gym: { gym } } = useStore().getState() as RootState;

  const [gymName, setGymName] = useState('');
  const [addressLine, setAddressLine] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [suburb, setSuburb] = useState('');
  const [postCode, setPostCode] = useState('');
  const [state, setState] = useState('');
  const [loading, setLoading] = useState(false);

  const [fontColor, setFontColor] = useState('#ffffff');
  const [bgColor, setBgColor] = useState('#000000');
  const [fontFamily, setFontFamily] = useState('Arial, Helvetica, sans-serif');
  const [mode, setMode] = useState(Mode.landscape);

  useEffect(() => {
    if (gym) {
      setGymName(gym.name);
      setAddressLine(gym.addressLine || '');
      setAddressLine2(gym.addressLine2 || '');
      setSuburb(gym.suburb || '');
      setState(gym.state || '');
      setPostCode(gym.postCode || '');
    }
  }, [!!gym]);

  useEffect(() => {
    if (gym) {
      const { mode, fontFamily, fontColor, bgColor } = getGymPreferences(gym);
      setMode(mode);
      setFontFamily(fontFamily);
      setFontColor(fontColor);
      setBgColor(bgColor);
    }
  }, [!!gym]);

  const formValid = true;

  const handleSave = () => {
    if (gym && gym.name) {
      setLoading(true);
      dispatch(gymActions.updateGym({
        name: gymName,
        addressLine,
        addressLine2,
        state,
        suburb,
        postCode,
      } as Gym, () => {
        setLoading(false);
      }));
    }
  };

  const handleSavePref = () => {
    setLoading(true);
    dispatch(gymActions.updateGym({
      preferences: {
        bgColor,
        fontColor,
        fontFamily,
        mode,
      } as GymPreferences,
    }, () => setLoading(false)));
  };

  const handleChangeLogo = (file: File) => {
    if (file) {
      setLoading(true);
      dispatch(gymActions.uploadGymLogo(file, (err: Error) => {
        if (err) {
          window.alert(err.message);
        }
        setLoading(false);
      }))
    }
  };

  const handleRemoveLogo = () => {
    setLoading(true);
    dispatch(gymActions.uploadGymLogo(undefined, (err: Error) => {
      if (err) {
        window.alert(err.message);
      }
      setLoading(false);
    }));
  };

  const handleChangeColor = (setter: Function) => (value: string) => {
    setter(value);
  };

  const handleChangeSelect = (setter: Function) => (value: string) => {
    setter(value);
  };

  return (
    <PageWrapper>
      <PageTitle className={styles.title} title={'Gym Details '}/>
      <PageContent className={`${styles.first} ${styles.wrapper}`}>
        <AvatarUploader id="gym-settings__avatar-uploader" url={imageUrl || undefined} loading={loading} onChange={handleChangeLogo}
                        onRemove={handleRemoveLogo}/>
        <div className={styles.row}>
          <div className={styles.col}>
            <InputField value={gymName} placeholder={'Gym Name...'} label={'Gym Name'} onChange={setGymName}/>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <InputField value={addressLine} placeholder={'Address Line...'} label={'Address Line'}
                        onChange={setAddressLine}/>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <InputField value={addressLine2} placeholder={'Address Line 2...'} label={'Address Line 2 (Optional)'}
                        onChange={setAddressLine2}/>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <InputField value={suburb} placeholder={'Suburb...'} label={'Suburb'} onChange={setSuburb}/>
          </div>
          <div className={styles.col}>
            <InputField value={state} placeholder={'State...'} label={'State'} onChange={setState}/>
          </div>
          <div className={styles.col}>
            <InputField value={postCode} placeholder={'Postcode...'} label={'Postcode'} onChange={setPostCode}/>
          </div>
        </div>
        <div className={styles.row}>
          <Button
            className={styles.action}
            disabled={loading || !formValid}
            title="Save Changes"
            onClick={handleSave}
          />
        </div>
      </PageContent>
      <PageTitle className={styles.title2} title={'Default Preferences'}/>
      <div className={styles.subTitle}>
        Set your default preferences to save time when creating new programs. These settings can be changed at any time
        from inside the program editor.
      </div>
      <PageContent className={styles.wrapper}>
        <div className={styles.formWrapper}>
          <div className={styles.row}>
            <div className={styles.col}>
              <Select
                label={'Default Font'}
                options={ComponentFontFamilyOptions}
                value={fontFamily}
                onChange={handleChangeSelect(setFontFamily)}
              />
            </div>
            <div className={styles.col}>
              <ColorPicker
                label={'Default Font Colour'}
                value={fontColor}
                onChange={handleChangeColor(setFontColor)}
              />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.col}>
              <Select
                label={'Default Screen Orientation'}
                options={CanvasModeOptions}
                onChange={handleChangeSelect(setMode)}
                value={mode}
              />
            </div>
            <div className={styles.col}>
              <ColorPicker
                label={'Default Screen Background Colour'}
                value={bgColor}
                onChange={handleChangeColor(setBgColor)}
              />
            </div>
          </div>
          <div className={styles.row}>
            <Button
              className={styles.action}
              disabled={loading}
              title="Save Changes"
              onClick={handleSavePref}
            />
          </div>
        </div>
      </PageContent>
    </PageWrapper>
  );
};

export default GymSettingsPage;
