import React from 'react';
import styles from './folder-link.module.scss';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { ReactComponent as Folder } from './folder.svg';

type Props = {
  title: string;
  link: string;
  className?: string;
};
export default (props: Props) => {
  const { title, link, className = '' } = props;

  const classes = classNames({
    [styles.wrapper]: true,
    [className]: true,
  });

  return (
    <div className={classes}>
      <Link className={styles.link} to={link}>
        <Folder className={styles.folder}/>
        {title}
      </Link>
    </div>
  );
};
