import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Modal } from '../../components/modal';
import styles from './creating-confirmation.module.scss';
import Loading from '../../components/loading';
import { API } from '../../api';
import { Button } from '../../components/button';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { ControlSize } from '../../model/ControlSize';

type Props = RouteComponentProps<{ id: string }>;
function CreatingConfirmationPage(props: Props) {
  const { match: { params } } = props;

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const id = params.id;

  useEffect(() => {
    if (id) {
      const getData = async () => {
        try {
          setLoading(true);
          await API.invite.completeGymConfirmation(id);
        } catch (e) {
          setError(e.message);
        } finally {
          setLoading(false);
        }
      };

      getData();
    }
  }, [id]);

  const handleNavigateToLogin = () => {
    dispatch(push('/login'));
  };

  if (loading) {
    return (
      <div className={styles.loadingWrapper}>
        <Loading/>
      </div>
    );
  }

  if (error) {
    return (
      <Modal color={'#fff'}>
        <div className={styles.header}>
          Error
        </div>
        <div className={styles.content}>
          {error}
        </div>
        <div className={styles.actions}>
          <Button size={ControlSize.Small} onClick={handleNavigateToLogin} title={'Log in'}/>
        </div>
      </Modal>
    )
  }

  return (
    <Modal color={'#fff'}>
      <div className={styles.header}>
        Competed!
      </div>
      <div className={styles.content}>
        Your gym has been created.
      </div>
      <div className={styles.actions}>
        <Button size={ControlSize.Small} onClick={handleNavigateToLogin} title={'Log in'}/>
      </div>
    </Modal>
  );
}

export default CreatingConfirmationPage;
