import { Timestamp } from './Firebase';

export enum ExerciseSpeciality {
  StrengthAndConditioning = 'strength_and_conditioning',
  Pilates = 'pilates',
  Cardiovascular = 'cardiovascular',
  MusculoskeletalRehab = 'musculoskeletal_rehab',
  Yoga = 'yoga',
  OccupationalHealthAndSafety = 'occupational_health_and_safety'
}

export enum ExerciseObjective {
  Strength = 'strength',
  Stretching = 'stretching',
  SpeedAndAgility = 'speed_and_agility',
  Balance = 'balance',
  Cardiovascular = 'cardiovascular',
  Endurance = 'endurance',
  Plyometrics = 'plyometrics',
  Recovery = 'recovery',
  Other = 'other'
}

export enum ExerciseMovement {
  LowerBodyPull = 'lower_body_pull',
  UpperBodyPull = 'upper_body_pull',
  LowerBodyPush = 'lower_body_push',
  UpperBodyPush = 'upper_body_push',
  Rotation = 'rotation',
  Core = 'core',
  FullBody = 'full_body',
  Flexion = 'flexion',
  Extension = 'extension',
  Abduction = 'abduction',
  Adduction = 'adduction',
  Other = 'other'
}

export enum ExerciseBodyRegion {
  Arms = 'arms',
  Back = 'back',
  Chest = 'chest',
  Core = 'core',
  Hips = 'hips',
  Legs = 'legs',
  Shoulders = 'shoulders',
  TotalBody = 'total_body'
}

export enum ExerciseEquipment {
  BodyWeight = 'body_weight',
  Barbell = 'barbell',
  Kettlebell = 'kettlebell',
  Dumbell = 'dumbell',
  MedicineBall = 'medicine_ball',
  SuspensionTrainer = 'suspension_trainer',
  Rings = 'rings',
  Box = 'box',
  TherraBand = 'therra_band',
  PullUpBar = 'pull_up_bar',
  Rope = 'rope',
  StallBars = 'stall_bars',
  BalanceBoard = 'balance_board',
  PegBoard = 'peg_board',
  CardioMachine = 'cardio_machine',
  Parallettes = 'parallettes',
  Sled = 'sled',
  TorsionBar = 'torsion_bar',
  AgilityLadder = 'agility_ladder',
  SlidingDisc = 'sliding_disc',
  WeightPlate = 'weight_plate',
  AtlasStone = 'atlas_stone',
  Log = 'log',
  Bench = 'bench',
  Step = 'step',
  WeightsMachine = 'weights_machine',
  Ball = 'ball',
  FoamRoller = 'foam_roller',
  Chair = 'chair',
  Mat = 'mat',
  Towel = 'towel',
  Other = 'other'
}

export function humanReadableSpeciality(value: ExerciseSpeciality) {
  switch (value) {
    case ExerciseSpeciality.StrengthAndConditioning:
      return 'Strength and Conditioning';
    case ExerciseSpeciality.Pilates:
      return 'Pilates';
    case ExerciseSpeciality.Cardiovascular:
      return 'Cardiovascular';
    case ExerciseSpeciality.MusculoskeletalRehab:
      return 'Musculoskeletal/Rehab';
    case ExerciseSpeciality.Yoga:
      return 'Yoga';
    case ExerciseSpeciality.OccupationalHealthAndSafety:
      return 'Occupational Health and Safety';
  }
}

export function humanReadableObjective(value: ExerciseObjective) {
  switch (value) {
    case ExerciseObjective.Strength:
      return 'Strength / Power';
    case ExerciseObjective.Stretching:
      return 'Stretching';
    case ExerciseObjective.SpeedAndAgility:
      return 'Speed & Agility';
    case ExerciseObjective.Balance:
      return 'Balance';
    case ExerciseObjective.Cardiovascular:
      return 'Cardiovascular';
    case ExerciseObjective.Endurance:
      return 'Endurance';
    case ExerciseObjective.Plyometrics:
      return 'Plyometrics';
    case ExerciseObjective.Recovery:
      return 'Recovery';
    case ExerciseObjective.Other:
      return 'Other';
  }
}

export function humanReadableMovement(value: ExerciseMovement) {
  switch (value) {
    case ExerciseMovement.LowerBodyPull:
      return 'Lower body pull';
    case ExerciseMovement.UpperBodyPull:
      return 'Upper body pull';
    case ExerciseMovement.LowerBodyPush:
      return 'Lower body push';
    case ExerciseMovement.UpperBodyPush:
      return 'Upper body push';
    case ExerciseMovement.Rotation:
      return 'Rotation';
    case ExerciseMovement.Core:
      return 'Core';
    case ExerciseMovement.FullBody:
      return 'Full Body';
    case ExerciseMovement.Flexion:
      return 'Flexion';
    case ExerciseMovement.Extension:
      return 'Extension';
    case ExerciseMovement.Abduction:
      return 'Abduction';
    case ExerciseMovement.Adduction:
      return 'Adduction';
    case ExerciseMovement.Other:
      return 'Other';
  }
}

export function humanReadableBodyRegion(value: ExerciseBodyRegion) {
  switch (value) {
    case ExerciseBodyRegion.Arms:
      return 'Arms';
    case ExerciseBodyRegion.Back:
      return 'Back';
    case ExerciseBodyRegion.Chest:
      return 'Chest';
    case ExerciseBodyRegion.Core:
      return 'Core';
    case ExerciseBodyRegion.Hips:
      return 'Hips';
    case ExerciseBodyRegion.Legs:
      return 'Legs';
    case ExerciseBodyRegion.Shoulders:
      return 'Shoulders';
    case ExerciseBodyRegion.TotalBody:
      return 'Total body';
  }
}

export function humanReadableEquipment(value: ExerciseEquipment) {
  switch (value) {
    case ExerciseEquipment.BodyWeight:
      return 'Body weight';
    case ExerciseEquipment.Barbell:
      return 'Barbell';
    case ExerciseEquipment.Kettlebell:
      return 'Kettlebell';
    case ExerciseEquipment.Dumbell:
      return 'Dumbell';
    case ExerciseEquipment.MedicineBall:
      return 'Medicine Ball';
    case ExerciseEquipment.SuspensionTrainer:
      return 'Suspension trainer (TRX)';
    case ExerciseEquipment.Rings:
      return 'Rings';
    case ExerciseEquipment.Box:
      return 'Box';
    case ExerciseEquipment.TherraBand:
      return 'Therra band';
    case ExerciseEquipment.PullUpBar:
      return 'Pull Up Bar';
    case ExerciseEquipment.Rope:
      return 'Rope';
    case ExerciseEquipment.StallBars:
      return 'Stall Bars';
    case ExerciseEquipment.BalanceBoard:
      return 'Balance board';
    case ExerciseEquipment.PegBoard:
      return 'Peg Board';
    case ExerciseEquipment.CardioMachine:
      return 'Cardio machine';
    case ExerciseEquipment.Parallettes:
      return 'Parallettes';
    case ExerciseEquipment.Sled:
      return 'Sled';
    case ExerciseEquipment.TorsionBar:
      return 'Torsion bar';
    case ExerciseEquipment.AgilityLadder:
      return 'Agility Ladder';
    case ExerciseEquipment.SlidingDisc:
      return 'Sliding disc';
    case ExerciseEquipment.WeightPlate:
      return 'Weight plate';
    case ExerciseEquipment.AtlasStone:
      return 'Atlas Stone';
    case ExerciseEquipment.Log:
      return 'Log';
    case ExerciseEquipment.Bench:
      return 'Bench';
    case ExerciseEquipment.Step:
      return 'Step';
    case ExerciseEquipment.WeightsMachine:
      return 'Weights machine';
    case ExerciseEquipment.Ball:
      return 'Ball';
    case ExerciseEquipment.FoamRoller:
      return 'Foam Roller';
    case ExerciseEquipment.Chair:
      return 'Chair';
    case ExerciseEquipment.Mat:
      return 'Mat';
    case ExerciseEquipment.Towel:
      return 'Towel';
    case ExerciseEquipment.Other:
      return 'Other';
  }
}

export type Exercise = {
  id: string;
  name: string;
  speciality: ExerciseSpeciality[];
  objective: ExerciseObjective[];
  movement: ExerciseMovement[];
  bodyRegion: ExerciseBodyRegion[];
  equipment: ExerciseEquipment[];
  gymId: string;
  dateCreated: Date;
  dateUpdated: Date;
  createdBy: string;
};

export const mapExerciseDtoToExercise = (data: Exercise): Exercise => {
  return {
    ...data,
    speciality: Array.isArray(data.speciality) ? data.speciality : [data.speciality],
    objective: Array.isArray(data.objective) ? data.objective : [data.objective],
    movement: Array.isArray(data.movement) ? data.movement : [data.movement],
    bodyRegion: Array.isArray(data.bodyRegion) ? data.bodyRegion : [data.bodyRegion],
    equipment: Array.isArray(data.equipment) ? data.equipment : [data.equipment],
    dateCreated: (data.dateCreated as unknown as Timestamp).toDate(),
    dateUpdated: (data.dateUpdated as unknown as Timestamp).toDate(),
  };
};
