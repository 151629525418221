import set from 'lodash/set';
import { ComponentBlock, ComponentBlockType } from './ComponentBlock';

export type BlockInfo = {
  id?: string;
  dateCreated: Date;
  dateUpdated: Date;
  createdBy: string;
  updatedBy: string;
  name: string;
  tags?: string[];
  gymId: string;
  _copiedFromTemplate?: boolean;
  _copiedFromId?: string;
};

export type BlockType = {
  info: BlockInfo;
  component: ComponentBlockType;
};

export type Block = {
  info: BlockInfo;
  component: ComponentBlock;
  id?: string;
};

export const updateBlockInfoValue = (block: Block, path: string, value: string | number): Block => {
  const newInfo = { ...block.info };
  set(newInfo, path, value);

  return {
    info: newInfo,
    component: block.component,
  };
};

export const mapBlockDtoToBlock = ({info, id, component, ...rest}: any): Block => ({
  info: {
    ...info,
    dateCreated: info.dateCreated.toDate(),
    dateUpdated: info.dateUpdated.toDate(),
    id,
  },
  component: {
    ...component,
    blockId: id,
  },
  ...rest,
  id,
});
