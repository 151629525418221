import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authActions, gymActions } from '../../store/actions';
import { RootState } from '../../store/reducers';
import { API } from '../../api';
import { AccessLevel } from '../../model/AccessLevel';
import Header from '../page-wrapper/header';
import OnlyRole from '../only-role';
import styles from './gym-listener.module.scss';
import { Button } from '../button';
import Loading from '../loading';

type Props = {
  children: React.ReactNode;
}

const GymListener = (props: Props) => {
  const dispatch = useDispatch();
  const [showDeletedError, setShowDeletedError] = useState(false);
  const { gym, loading, error } = useSelector((state: RootState) => state.gym);

  const id = gym?.id;

  useEffect(() => {
    dispatch(gymActions.fetchGym());
  }, []);

  useEffect(() => {
    let unsubscribe: Function | void;
    if (id) {
      unsubscribe = API.gyms.listenGymChanges(id, (err, gym) => {
        if (gym && gym.deleted === true) {
          setShowDeletedError(true);
        } else {
          setShowDeletedError(false);
        }
      });
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [id, dispatch]);

  const handleLogout = () => {
    dispatch(authActions.logOut());
  }

  if (loading) {
    return (
      <>
        <OnlyRole roles={[AccessLevel.admin]}>
          <Header/>
        </OnlyRole>
        <div className={styles.errorWrapper}>
          <Loading/>
        </div>
      </>
    );
  }

  if (error) {
    return (
      <>
        <OnlyRole roles={[AccessLevel.admin]}>
          <Header/>
        </OnlyRole>
        <div className={styles.errorWrapper}>
          <div>
            <h2>
              Sorry.
            </h2>
            <h3>{error.message}</h3>
            <Button onClick={handleLogout} title={'Logout'}/>
          </div>
        </div>
      </>
    );
  }

  if (showDeletedError) {
    return (
      <>
        <OnlyRole roles={[AccessLevel.admin]}>
          <Header/>
        </OnlyRole>
        <div className={styles.errorWrapper}>
          <div>
            <h2>
              Sorry.
            </h2>
            <h3>The gym has been deleted.</h3>
            <Button onClick={handleLogout} title={'Logout'}/>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {props.children}
    </>
  );
}

export default GymListener;
