import React, { Dispatch, MouseEvent } from 'react';
import styles from './canvas.module.scss';
import { connect } from 'react-redux';
import { getPosition, PositionType } from '../../../model/program/Position';
import { RootState } from '../../../store/reducers';
import { designerActions, editorBlockActions } from '../../../store/actions';
import { ComponentType } from '../../../model/program/Type';
import { ComponentBase } from '../../../model/program/Component';
import ScaleInput from './scale-input';
import { Size } from '../../../model/program/Size';
import Component from '../component';
import { getDefaultTimer, Timer } from '../../../model/Timer';
import TimerComponent from './timer';
import { getProgramEditorPresentState } from '../../../store/reducers/program';

type Props = {
  components: ComponentBase[];
} & ReturnType<typeof mapActions> & ReturnType<typeof mapState>;

class Canvas extends React.PureComponent<Props> {
  canvas = React.createRef<HTMLDivElement>();
  handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  handleDrop = (e: React.DragEvent) => {
    e.preventDefault();

    const { nativeEvent } = e;

    const type = e.dataTransfer.getData('type') as ComponentType;

    const {
      addToCanvas,
      addTimer,
      canvas,
    } = this.props;

    const scale = canvas.scale / 100;

    const { x: kX, y: kY } = (document.getElementById('canvas') as HTMLDivElement).getBoundingClientRect();

    const x = (nativeEvent.x - kX) / scale;
    const y = (nativeEvent.y - kY) / scale;

    const position: PositionType = getPosition(x, y);

    const extraData = JSON.parse(e.dataTransfer.getData('data') || '{}');

    if (type === ComponentType.Timer) {
      const newTimer = getDefaultTimer();
      newTimer.position = position;
      addTimer(newTimer);
    } else {
      addToCanvas(type, position, extraData);
    }
  };

  renderComponent = (component: ComponentBase) => {
    return (
      <Component key={component.id} component={component} />
    );
  };

  renderComponents = () => {
    const { components } = this.props;

    if (!components.length) {
      return null;
    }

    return components.map((component: ComponentBase) => this.renderComponent(component));
  };

  handleClickOutside = (e: MouseEvent) => {
    const target = e.target as HTMLDivElement;
    const canvas = target.closest('#canvas');

    // Check target between canvas and editor
    if (canvas) {
      return;
    }

    // If element found, clear selection
    const editor = target.closest('#canvas-editor');

    if (this.props.isBlockEditorActive) {
      if (this.props.selectedComponentInsideBlock) {
        this.props.deselectComponent();
      }
    } else {
      if (editor && (this.props.selectedComponent || this.props.selectedComponents.length)) {
        this.props.deselectComponent();
      }
    }
  };

  getSize = (): Size => {
    const {
      canvas,
    } = this.props;

    return {
      ...canvas.size,
    };
  };

  renderMultiSelect = () => {
    const { selectedComponents } = this.props;

    if (!selectedComponents.length) {
      return;
    }

    const summary = selectedComponents.components.map(c => ([+c.position.x, +c.position.y, +c.size.width, +c.size.height]));

    return summary.map(([x, y, w, h], index) => {
      return (
        <div key={index} style={{left: x, top:y, width: w, height: h}} className={styles.multiLine}/>
      );
    });
  };

  renderTimer = () => {
    const {
      timer,
    } = this.props;

    if (!timer) {
      return null;
    }

    return (
      <TimerComponent timer={timer}/>
    )
  };

  render() {
    const {
      canvas,
      multiMode,
      timer,
    } = this.props;

    const size = this.getSize();

    const scale = canvas.scale / 100;
    // Trying to add additional scrolling
    const rbMargin = Math.floor(150 / scale);
    const ltMargin = scale > 0.7 ? Math.floor(100 * scale) : - Math.floor(100 * scale);
    return (
      <div className={styles.wrapper} id="canvas-editor" onClick={this.handleClickOutside}>
        <div>
          <div className={styles.outer} style={{ transform: `scale(${scale})`, marginBottom: rbMargin, marginRight: rbMargin, marginTop: ltMargin, marginLeft: ltMargin }}>
            <div
              id="canvas"
              ref={this.canvas}
              className={styles.inner}
              onDragOver={this.handleDragOver}
              onDrop={this.handleDrop}
              style={{
                width: size.width,
                height: size.height,
                backgroundColor: canvas.color,
              }}
            >
              {this.renderComponents()}
              {multiMode && this.renderMultiSelect()}
              {timer && this.renderTimer()}
            </div>
          </div>
        </div>
        <div className={styles.range}>
          <ScaleInput/>
        </div>
      </div>
    );
  }
}

const mapState = (state: RootState) => {
  const {
    components,
    selectedComponent,
    selectedComponents,
    canvas,
    multiMode,
    isBlockEditorActive,
    selectedComponentInsideBlock,
    timer,
  } = getProgramEditorPresentState(state);

  return {
    components,
    selectedComponent,
    selectedComponents,
    canvas,
    multiMode,
    isBlockEditorActive,
    selectedComponentInsideBlock,
    timer,
  };
};

const mapActions = (dispatch: Dispatch<any>) => ({
  addToCanvas: (
    type: ComponentType,
    position: PositionType,
    data: any
  ) => dispatch(editorBlockActions.addNewComponentToCanvas(type, position, data)),
  deselectComponent: () => dispatch(editorBlockActions.deselectComponent()),
  addTimer: (timer: Timer) => {
    dispatch(editorBlockActions.addTimer(timer));
    dispatch(designerActions.designerTourAddTimerToCanvas(timer));
  },
});

export default connect(mapState, mapActions)(Canvas);
