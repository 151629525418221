import React from 'react';

import classNames from 'classnames';

import { ControlColor } from '../../model/ControlColor';
import { ControlSize } from '../../model/ControlSize';

import styles from './button.module.scss';

export enum ButtonKind {
  Primary = 'primary',
  Secondary = 'secondary',
  SecondaryAlternate = 'secondary-alternate'
}

type Props = {
  children?: React.ReactNode;
  className?: string;
  color?: ControlColor;
  disabled?: boolean;
  kind?: ButtonKind;
  size?: ControlSize;
  title?: string;
  type?: 'button' | 'submit';
  onClick: () => void;
};

export const Button = (props: Props) => {
  const {
    children,
    className = '',
    color = ControlColor.Default,
    disabled = false,
    kind = ButtonKind.Primary,
    size = ControlSize.Normal,
    title,
    type = 'button',
    onClick
  } = props;

  const componentClassNames = classNames({
    [styles.component]: true,
    [styles.disabled]: disabled,
    [styles.colorDark]: color === ControlColor.Dark,
    [styles.colorDefault]: color === ControlColor.Default,
    [styles.colorAmber]: color === ControlColor.Amber,
    [styles.kindPrimary]: kind === ButtonKind.Primary,
    [styles.kindSecondary]: kind === ButtonKind.Secondary,
    [styles.kindSecondaryAlternate]: kind === ButtonKind.SecondaryAlternate,
    [styles.sizeNormal]: size === ControlSize.Normal,
    [styles.sizeSmall]: size === ControlSize.Small,
    [styles.sizeExtraSmall]: size === ControlSize.ExtraSmall,
    [className]: className
  });

  return (
    <button
      className={componentClassNames}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {
        (title !== undefined) ? title : children
      }
    </button>
  );
};
