import React, { useCallback, useState } from 'react';
import styles from './select.module.scss';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import InputField from '../input-field';
import { IconType } from '../icon';
import { ControlSize } from '../../model/ControlSize';
import { ControlColor } from '../../model/ControlColor';

type Props = {
  onChanged: (text: string) => void;
  className?: string;
};

const SearchInput = (props: Props) => {
  const { onChanged, className = '' } = props;
  const [search, setSearch] = useState<string>('');

  const handleChanged = useCallback(debounce((text: string) => {
    onChanged(text);
  }, 300), [onChanged]);

  const handleChange = useCallback((text) => {
    setSearch(text);
    handleChanged(text)
  }, [setSearch]);

  const classes = classNames({
    [styles.input]: true,
    [className]: true,
  });

  return (
    <InputField
      className={classes}
      value={search}
      onChange={handleChange}
      icon={IconType.Search}
      size={ControlSize.ExtraSmall}
      color={ControlColor.Dark}
    />
  );
};

export default SearchInput;
