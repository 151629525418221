export enum ComponentType {
  TextInput = 'TextInput',
  Label = 'Label',
  Square = 'Square',
  Circle = 'Circle',
  Image = 'Image',
  Block = 'Block',
  Timer = 'Timer',
  TimerWarmUp = 'TimerWarmUp',
}

const componentTypeMap = {
  [ComponentType.Label]: 'Label',
  [ComponentType.Square]: 'Square',
  [ComponentType.Circle]: 'Circle',
  [ComponentType.Image]: 'Image',
};
