import React, { useState } from 'react';
import Popup from '../../../popup';
import styles from './popup.module.scss';
import { IconType } from '../../../icon';
import { ControlSize } from '../../../../model/ControlSize';
import InputField from '../../../input-field';
import { Gym } from '../../../../model/Gym';
import UserAvatar from '../../../user-avatar';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducers';

type Props = {
  gyms: Gym[];
  innerRef: any;
  onChange: (value: Gym) => void;
};

const GymsPopup = ({ gyms = [], innerRef, onChange }: Props) => {
  const [search, setSearch] = useState('');
  const current = useSelector((state: RootState) => state.gym.gym);

  const handleChangeSearch = (value: string) => setSearch(value);

  const handleChange = (gym: Gym) => () => {
    onChange(gym);
  };

  return (
    <Popup innerRef={innerRef} className={styles.wrapper}>
      <InputField
        className={styles.search}
        icon={IconType.Search}
        size={ControlSize.ExtraSmall}
        value={search}
        onChange={handleChangeSearch}
        placeholder={'Search gyms...'}
      />
      {gyms.map(gym => {
        const active = current && current.id === gym.id;

        return (
          <div className={`${styles.item} ${active ? styles.active : ''}`} key={gym.id} onClick={handleChange(gym)}>
            <UserAvatar size={'sm'} url={gym.imageUrl}/>
            <div className={styles.title}>{gym.name}</div>
          </div>
        );
      })}
    </Popup>
  );
};

export default GymsPopup;
