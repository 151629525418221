import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import PageTitle from '../../../components/page-title';
import PageWrapper from '../../../components/page-wrapper';
import { Program, ProgramPlayedHistory } from '../../../model/program/Program';
import { API } from '../../../api';
import PageContent from '../../../components/page-content';
import { formatDateTime } from '../../../utils/formatter';
import { Table } from '../../../components/table';
import styles from './played-history.module.scss';

const HEADER = [
  { title: 'Name' },
  { title: 'Played at' },
  { title: 'Screen' },
];

function PlayedHistory() {
  const history = useHistory();
  const params = useLocation<{program: Program}>();
  const program = params.state?.program;

  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState<ProgramPlayedHistory[]>([]);

  useEffect(() => {
    async function getData() {
      try {
        setIsLoading(true);
        const list = await API.program.getProgramPlayedHistory(program.id as string);
        setList(list);
        setIsLoading(false);
      } catch (e) {
        setList(list);
        setIsLoading(false);
      }
    }

    if (!program) {
      history.push('/programs');
    } else {
     getData();
    }
  }, [program]);

  const body = [
    {
      key: () => program.canvas.name,
    },
    {
      key: (row: ProgramPlayedHistory) => formatDateTime(row.playedAt),
    },
    {
      key: (row: ProgramPlayedHistory) => row.screenName,
    },
  ];

  return (
    <PageWrapper>
      <PageTitle title={'Played history'}>
      </PageTitle>
      <PageContent className={styles.content}>
        <Table header={HEADER} body={body} data={list} isLoading={isLoading}/>
      </PageContent>
    </PageWrapper>
  );
}

export default PlayedHistory;
