import React from 'react';
import classNames from 'classnames';

import { ControlColor } from '../../model/ControlColor';
import { ControlSize } from '../../model/ControlSize';

import styles from './label.module.scss';

type Props = {
  className?: string;
  color?: ControlColor;
  size?: ControlSize;
  style?: object;
  text: string;
}

const Label = (props: Props) => {
  const {
    className,
    color = ControlColor.Default,
    size = ControlSize.Normal,
    style,
    text
  } = props;

  const labelClassNames = classNames({
    [styles.label]: true,
    [styles.colorDark]: color === ControlColor.Dark,
    [styles.colorDefault]: color === ControlColor.Default,
    [styles.sizeNormal]: size === ControlSize.Normal,
    [styles.sizeSmall]: size === ControlSize.Small,
    [styles.sizeExtraSmall]: size === ControlSize.ExtraSmall,
    [className as any]: className
  });

  return (
    <div className={labelClassNames} style={style}>{text}</div>
  );
};

export default Label;
