import { all, takeEvery, call, select, put } from 'redux-saga/effects';
import { ListPageActionType } from '../../action-types';
import { Action } from '../../utils';
import { RootState } from '../../reducers';
import { User } from '../../../model/User';
import { listPageActions } from '../../actions';

function* listPageRequest () {
  yield takeEvery(ListPageActionType.LOAD_DATA, function* ({ payload }: Action) {
    try {
      const {
        apiRequest,
        ...rest
      } = payload;

      const user = (yield select((state: RootState) => state.auth.user)) as User;

      const list = yield call.apply(null, [apiRequest, user.uid, ...Object.values(rest)]);

      yield put(listPageActions.getListDataSuccess(list));
    } catch (e) {
      console.log('Error', e);
    }
  });
}

export default function* () {
  yield all([
    listPageRequest(),
  ]);
}
