import { ComponentType } from '../../model/program/Type';
import { PositionType } from '../../model/program/Position';
import { createAction } from '../utils';
import { Component, ComponentBase, ComponentBaseUpdateParams, ComponentNew } from '../../model/program/Component';
import { EditorBlockActionType } from '../action-types';
import { ProgramType } from '../../model/program/Program';
import { BlockType } from '../../model/program/Block';
import { Exercise } from '../../model/Exercise';
import { GymPreferences } from '../../model/Gym';
import { Timer } from '../../model/Timer';

export const addNewComponentToCanvas = (type: ComponentType, position: PositionType, data: any) =>
  createAction<ComponentNew>(EditorBlockActionType.ADD_NEW_TO_CANVAS, {
    ...data,
    type,
    position,
  });

export const addComponentToCanvas = (component: Component) =>
  createAction<Component>(EditorBlockActionType.ADD_TO_CANVAS, component);

export const selectComponent = (component: Component) =>
  createAction<Component>(EditorBlockActionType.SELECT_COMPONENT, component);

export const deselectComponent = () => createAction<void>(EditorBlockActionType.DESELECT_COMPONENT);

export const updatePropertiesForSelected = (properties: ComponentBaseUpdateParams) =>
  createAction(EditorBlockActionType.UPDATE_PROPERTIES_FOR_SELECTED, properties);

export const updatePropertyForSelected = (path: string, value: string | number) =>
  createAction(EditorBlockActionType.UPDATE_PROPERTY_FOR_SELECTED, { path, value });

export const increaseZIndexForSelected = () => createAction(EditorBlockActionType.INCREASE_Z_INDEX_FOR_SELECTED);
export const decreaseZIndexForSelected = () => createAction(EditorBlockActionType.DECREASE_Z_INDEX_FOR_SELECTED);
export const removeSelectedComponent = () => createAction(EditorBlockActionType.REMOVE_SELECTED_COMPONENT);

export const updatePropertyForCanvas = (path: string, value: any) =>
  createAction<{path: string, value: any}>(EditorBlockActionType.UPDATE_PROPERTY_FOR_CANVAS, { path, value });

export const updatePropertiesForCanvas = (properties: ComponentBaseUpdateParams) =>
  createAction(EditorBlockActionType.UPDATE_PROPERTIES_FOR_CANVAS, properties);

export const saveCanvas = (resolve: Function) => createAction(EditorBlockActionType.SAVE_CANVAS, { resolve });
export const saveTemplateCanvas = (resolve: Function) => createAction(EditorBlockActionType.SAVE_TEMPLATE_CANVAS, { resolve });

export const saveCanvasProcessing = (saving: boolean) => createAction(EditorBlockActionType.SAVE_CANVAS_PROCESSING, saving);
export const loadCanvasProcessing = (loading: boolean) => createAction(EditorBlockActionType.LOAD_CANVAS_PROCESSING, loading);

export const fetchDataSuccess = (data: ProgramType) => createAction(EditorBlockActionType.FETCH_DATA_SUCCESS, data);

export const fetchEditor = (id: string, resolve: Function) => createAction(EditorBlockActionType.FETCH_DATA, { id, resolve });
export const fetchTemplatesEditor = (id: string, resolve: Function) => createAction(EditorBlockActionType.FETCH_TEMPLATES_DATA, { id, resolve });

export const uploadImageForSelected = (file: File, oldImageUrl?: string, resolve?: Function) =>
  createAction(EditorBlockActionType.UPLOAD_IMAGE_FOR_SELECTED, { file, resolve, imageUrl: oldImageUrl });

export const clearData = () => createAction(EditorBlockActionType.CLEAR_DATA);

export const isProgramSaved = (resolve: Function) => createAction(EditorBlockActionType.IS_PROGRAM_SAVED, { resolve });

export const changeMultiMode = (mode: boolean) => createAction<boolean>(EditorBlockActionType.CHANGE_MULTI_MODE, mode);

export const createNewBlock = (name: string, resolve: Function) => createAction(EditorBlockActionType.CREATE_NEW_BLOCK, { name, resolve });
export const createNewTemplateBlock = (name: string, resolve: Function) => createAction(EditorBlockActionType.CREATE_NEW_TEMPLATE_BLOCK, { name, resolve });
export const removeComponents = (ids: string[]) => createAction<string[]>(EditorBlockActionType.REMOVE_COMPONENTS, ids);

export const addBlockToList = (block: BlockType) => createAction<BlockType>(EditorBlockActionType.ADD_BLOCK_TO_LIST, block);
export const addBlocksToList = (blocks: BlockType[]) => createAction<BlockType[]>(EditorBlockActionType.ADD_BLOCKS_TO_LIST, blocks);
export const addExercises = (exercises: Exercise[]) => createAction<Exercise[]>(EditorBlockActionType.ADD_EXERCISES_TO_LIST, exercises);

export const fetchBlocks = () => createAction(EditorBlockActionType.FETCH_BLOCKS);
export const fetchTemplateBlocks = () => createAction(EditorBlockActionType.FETCH_TEMPLATE_BLOCKS);
export const fetchExercises = () => createAction(EditorBlockActionType.FETCH_EXERCISES);

export const toggleEditorEditBlock = (active: boolean) => createAction<boolean>(EditorBlockActionType.TOGGLE_EDITOR_EDIT_BLOCK, active);

export const toggleShowPreview = (show: boolean) => createAction<boolean>(EditorBlockActionType.TOGGLE_PROGRAM_PREVIEW, show);

export const saveExistingBlock = (resolve: Function) => createAction(EditorBlockActionType.SAVE_EXISTING_BLOCK, { resolve });
export const saveExistingTemplateBlock = (resolve: Function) => createAction(EditorBlockActionType.SAVE_EXISTING_TEMPLATE_BLOCK, { resolve });
export const updateSelectedBlockInfo = (path: string, value: string | number) =>
  createAction(EditorBlockActionType.UPDATE_SELECTED_BLOCK_INFO, { path, value });

export const updateSelectedBlockComponentValue = (path: string, value: string | number) =>
  createAction(EditorBlockActionType.UPDATE_SELECTED_BLOCK_COMPONENT_VALUE, { path, value });

export const updateEditorInfo = (path: string, value: string) => createAction(EditorBlockActionType.UPDATE_EDITOR_INFO, { path, value });

export const setEditorToBlockPage = () => createAction(EditorBlockActionType.SET_EDITOR_TO_BLOCK_PAGE);
export const fetchBlockForEdit = (id: string, resolve: Function) => createAction(EditorBlockActionType.FETCH_BLOCK_FOR_EDIT, { id, resolve });
export const fetchTemplatesBlockForEdit = (id: string, resolve: Function) => createAction(EditorBlockActionType.FETCH_TEMPLATES_BLOCK_FOR_EDIT, { id, resolve });

export const updateBlocksOnCanvas = (component: ComponentBase) => createAction(EditorBlockActionType.UPDATE_BLOCKS_ON_CANVAS, component);
export const updatePreferences = (preferences?: GymPreferences) => createAction(EditorBlockActionType.UPDATE_PREFERENCES, preferences);

export const addTimer = (timer: Timer) => createAction(EditorBlockActionType.ADD_TIMER, timer);
export const selectTimer = (timer: Timer | any) => createAction(EditorBlockActionType.SELECT_TIMER, timer);
export const updateTimerProperties = (props: Object) => createAction(EditorBlockActionType.UPDATE_TIMER_PROPS, props);
export const updateTimerProperty = (path: string, value: any) => createAction(EditorBlockActionType.UPDATE_TIMER_PROP, { path, value });
export const removeTimer = () => createAction(EditorBlockActionType.REMOVE_TIMER);
export const updateWarmUpProperties = (props: Object) => createAction(EditorBlockActionType.UPDATE_WARM_UP_PROPERTIES, props);
export const updateWarmUpProperty = (path: string, value: any) => createAction(EditorBlockActionType.UPDATE_WARM_UP_PROPERTY, { path, value });
