import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import './api/firebase/inittialize';

import './App.scss';

import { createStore, history } from './store';
import LoginPage from './pages/auth/login';
import PrivateRoute from './components/private-route';
import MainPage from './pages/main';
import InviteConfirmPage from './pages/invites-confirm';
import ForgotPasswordPage from './pages/auth/forgot-password';
import SignUpPage from './pages/auth/sign-up';
import ClientPage from './pages/client';
import CreatingConfirmationPage from './pages/creating-confirmation';

const { store, persistor } = createStore();

function App() {
  return (
    <div className="app">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ConnectedRouter history={history}>
            <Switch>
              <Route exact={true} path={'/login'} component={LoginPage}/>
              <Route exact={true} path={'/sign-up'} component={SignUpPage}/>
              <Route exact={true} path={'/reset-password'} component={ForgotPasswordPage}/>
              <Route exact={true} path={'/invites/:id'} component={InviteConfirmPage}/>
              <Route exact={true} path={'/client'} component={ClientPage}/>
              <Route exact={true} path={'/creating/:id'} component={CreatingConfirmationPage}/>
              <PrivateRoute exact={true} component={MainPage}/>
              <Redirect to={'/login'}/>
            </Switch>
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
