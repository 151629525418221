import React, { RefObject } from 'react';
import styles from './popup.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
  innerRef?: RefObject<any>;
  id?: string;
};
export default (props: Props) => {
  return (
    <div ref={props.innerRef} className={`${styles.popup} ${props.className || ''}`} id={props.id}>
      {props.children}
    </div>
  );
};
