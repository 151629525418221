import React, { ChangeEvent } from 'react';
import styles from './avatar-uploader.module.scss';
import { Button, ButtonKind } from '../button';

const noop = () => {};
type Props = {
  loading: boolean;
  url?: string;
  onChange: (file: File) => void;
  onRemove: () => void;
  id?: string;
};

const AvatarUploader = (props: Props) => {
  const {
    url,
    loading,
    onChange,
    onRemove,
    id,
  } = props;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      onChange(file);
    }
  };


  return (
    <div className={styles.wrapper} id={id}>
      <div className={styles.photo} style={{backgroundImage: `url(${url})`}}/>
      <div className={styles.btnWrapper}>
        <Button
          className={styles.btn}
          disabled={loading}
          title="Upload New Photo"
          onClick={noop}
        />
        <input
          accept="image/*"
          className={styles.input}
          disabled={loading}
          title=""
          type="file"
          onChange={handleChange}
        />
      </div>
      <Button
        disabled={loading}
        kind={ButtonKind.SecondaryAlternate}
        title="Remove Photo"
        onClick={onRemove}
      />
    </div>
  );
};

export default AvatarUploader;
