import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { User } from '../../../model/User';
import { userActions } from '../../../store/actions';
import AvatarUploader from '../../../components/avatar-uploader';

const PhotoUploader = () => {
  const user = useSelector((s: RootState) => s.auth.user) as User;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleChange = useCallback((file) => {
    if (file) {
      setLoading(true);
      dispatch(userActions.uploadAvatar(file, (err: Error) => {
        if (err) {
          window.alert(err.message);
        }
        setLoading(false);
      }));
    }
  }, []);

  const handleRemove = () => {
    setLoading(true);
    dispatch(userActions.uploadAvatar(undefined, (err: Error) => {
      if (err) {
        window.alert(err.message);
      }
      setLoading(false);
    }));
  };

  return (
    <AvatarUploader
      url={user.avatarUrl}
      loading={loading}
      onChange={handleChange}
      onRemove={handleRemove}
    />
  );
};

export default PhotoUploader;
