import React from 'react';
import styles from './shape.module.scss';
import Wrapper from '../wrapper';
import { ComponentShape } from '../../../../model/program/ComponentShape';
import { VariableValue } from '../../../../model/program/Variable';

type Props = {
  component: ComponentShape;
  type: 'square' | 'circle';
  editable?: boolean;
  variableValue?: VariableValue;
  scale?: number;
};

class Shape extends React.PureComponent<Props> {
  render() {
    const {
      component,
      type,
      editable = true,
      scale,
    } = this.props;

    const {
      style,
    } = component;

    const shapeClasses = [styles.shape];
    if (type === 'circle') {
      shapeClasses.push(styles.circle);
    } else {
      shapeClasses.push(styles.square);
    }

    return (
      <Wrapper scale={scale} editable={editable} component={component}>
        <div className={shapeClasses.join(' ')} style={style}/>
      </Wrapper>
    );
  }
}

export default Shape;
