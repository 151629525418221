import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PageWrapper from '../../components/page-wrapper';
import PageTitle from '../../components/page-title';
import styles from './exercises.module.scss';
import InputField from '../../components/input-field';
import { IconType } from '../../components/icon';
import { Button, ButtonKind } from '../../components/button';
import { Table } from '../../components/table';
import { TableAction } from '../../components/table/table-action';
import {
  Exercise,
  ExerciseBodyRegion,
  ExerciseEquipment,
  ExerciseMovement,
  ExerciseObjective,
  ExerciseSpeciality,
  humanReadableBodyRegion,
  humanReadableEquipment,
  humanReadableMovement,
  humanReadableObjective,
  humanReadableSpeciality,
  mapExerciseDtoToExercise
} from '../../model/Exercise';
import useFirebaseEntity from '../../hooks/use-firebase-entity';
import { Modal } from '../../components/modal';
import Select from '../../components/select';
import MultiSelect from '../../components/multi-select';
import { ControlSize } from '../../model/ControlSize';
import { RootState } from '../../store/reducers';
import { arraysEqual, createOptions } from '../../utils/helper';
import { Gym } from '../../model/Gym';
import { TableActionKind, TableActionType } from '../../components/table/table-action-list';

const HEADER = [
  { title: 'Name' },
  { title: 'Speciality' },
  { title: 'Objective' },
  { title: 'Movement' },
  { title: 'Region' },
  { title: 'Equipment' },
  { className: styles.tableAction }
];

const ExercisesPage = () => {
  const user = useSelector((state: RootState) => state.auth.user);
  const gym = useSelector((state: RootState) => state.gym);
  const [isModalVisible, setModalVisible] = useState(false);
  const [search, setSearch] = useState('');
  const [searchExerciseSpeciality, setSearchExerciseSpeciality] = useState(null as ExerciseSpeciality | null);
  const [searchExerciseObjective, setSearchExerciseObjective] = useState(null as ExerciseObjective | null);
  const [searchExerciseMovement, setSearchExerciseMovement] = useState(null as ExerciseMovement | null);
  const [searchExerciseBodyRegion, setSearchExerciseBodyRegion] = useState(null as ExerciseBodyRegion | null);
  const [searchExerciseEquipment, setSearchExerciseEquipment] = useState(null as ExerciseEquipment | null);
  const exercisesQuery = [['gymId', gym.gym ? gym.gym.id : null]];
  const [
    items,
    {hasFetched, isCreating, isUpdating},
    {create, update, remove}
  ] = useFirebaseEntity('exercises', exercisesQuery, mapExerciseDtoToExercise) as [Exercise[], any, any];
  const FilteredItems = items.filter(item => {
    if (search && !item.name.toLowerCase().includes(search.toLowerCase())) {
      return false;
    }

    if (searchExerciseSpeciality && !item.speciality.includes(searchExerciseSpeciality)) {
      return false;
    }

    if (searchExerciseObjective && !item.objective.includes(searchExerciseObjective)) {
      return false;
    }

    if (searchExerciseMovement && !item.movement.includes(searchExerciseMovement)) {
      return false;
    }

    if (searchExerciseBodyRegion && !item.bodyRegion.includes(searchExerciseBodyRegion)) {
      return false;
    }

    return !searchExerciseEquipment || !item.equipment.includes(searchExerciseEquipment);
  }).sort((a, b) => {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  });

  const [selectedExercise, setSelectedExercise] = useState(null as Exercise | null);
  const [newExerciseName, setNewExerciseName] = useState('');
  const [newExerciseSpeciality, setNewExerciseSpeciality] = useState([] as ExerciseSpeciality[]);
  const [newExerciseObjective, setNewExerciseObjective] = useState([] as ExerciseObjective[]);
  const [newExerciseMovement, setNewExerciseMovement] = useState([] as ExerciseMovement[]);
  const [newExerciseBodyRegion, setNewExerciseBodyRegion] = useState([] as ExerciseBodyRegion[]);
  const [newExerciseEquipment, setNewExerciseEquipment] = useState([] as ExerciseEquipment[]);

  const closeModal = () => setModalVisible(false);

  const handleAddExercise = () => {
    setSelectedExercise(null);

    setNewExerciseName('');
    setNewExerciseSpeciality([]);
    setNewExerciseObjective([]);
    setNewExerciseMovement([]);
    setNewExerciseBodyRegion([]);
    setNewExerciseEquipment([]);

    setModalVisible(true);
  };

  const handleSubmit = () => {
    if (!user) {
      return;
    }

    const date = new Date();

    const data = {
      bodyRegion: newExerciseBodyRegion,
      dateUpdated: date,
      equipment: newExerciseEquipment,
      movement: newExerciseMovement,
      name: newExerciseName,
      objective: newExerciseObjective,
      speciality: newExerciseSpeciality,
      gymId: selectedExercise ? selectedExercise.gymId : (gym.gym as Gym).id,
      updatedBy: user.uid
    };

    const promise = selectedExercise ? update(selectedExercise.id, data) : create({
      ...data,
      dateCreated: date,
      createdBy: user.uid
    });

    promise.then(closeModal);
  };

  const rowActionsHandler = (data: Exercise): TableActionType[] => {
    return [
      {
        title: 'Edit',
        onClick: () => {
          setSelectedExercise(data);

          setNewExerciseName(data.name);
          setNewExerciseSpeciality(data.speciality);
          setNewExerciseObjective(data.objective);
          setNewExerciseMovement(data.movement);
          setNewExerciseBodyRegion(data.bodyRegion);
          setNewExerciseEquipment(data.equipment);

          setModalVisible(true);
        },
      },
      {
        title: 'Delete',
        onClick: () => {
          if (window.confirm('Are you sure you want to remove exercise?')) {
            remove(data.id);
          }
        },
        kind: TableActionKind.Warning,
      }
    ];
  };

  const body = [
    { key: (row: Exercise) => row.name },
    { key: (row: Exercise) => row.speciality.map(item => humanReadableSpeciality(item)).join(', ') },
    { key: (row: Exercise) => row.objective.map(item => humanReadableObjective(item)).join(', ') },
    { key: (row: Exercise) => row.movement.map(item => humanReadableMovement(item)).join(', ') },
    { key: (row: Exercise) => row.bodyRegion.map(item => humanReadableBodyRegion(item)).join(', ') },
    { key: (row: Exercise) => row.equipment.map(item => humanReadableEquipment(item)).join(', ') },
    {
      key: (data: Exercise) => {
        return (
          <TableAction actions={rowActionsHandler(data)}/>
        );
      },
      className: styles.tableAction,
    },
  ];

  const isButtonEnabled = !isCreating && !isUpdating && Boolean(
      newExerciseName &&
      (newExerciseSpeciality.length > 0) &&
      (newExerciseObjective.length > 0) &&
      (newExerciseMovement.length > 0) &&
      (newExerciseBodyRegion.length > 0) &&
      (newExerciseEquipment.length > 0)
    ) && (
      !selectedExercise || (selectedExercise.name !== newExerciseName) ||
        !arraysEqual(selectedExercise.speciality, newExerciseSpeciality) ||
        !arraysEqual(selectedExercise.objective, newExerciseObjective) ||
        !arraysEqual(selectedExercise.movement, newExerciseMovement) ||
        !arraysEqual(selectedExercise.bodyRegion, newExerciseBodyRegion) ||
        !arraysEqual(selectedExercise.equipment, newExerciseEquipment)
    );

  return (
    <PageWrapper>
      <PageTitle title={'Exercises'}>
        <Button
          disabled={!gym.gym}
          size={ControlSize.Small}
          title="Add Exercise"
          onClick={handleAddExercise}
        />
      </PageTitle>
      <div className={styles.content}>
        <div className={styles.actions}>
          <div className={styles.actionsRow}>
            <div className={styles.actionWrapper}>
              <InputField
                className={styles.search}
                icon={IconType.Search}
                placeholder="Search exercises..."
                size={ControlSize.Small}
                value={search}
                onChange={setSearch}
              />
            </div>
            <div className={styles.actionWrapper}>
              <Select
                placeholder="Select Speciality..."
                options={createOptions(ExerciseSpeciality, humanReadableSpeciality, 'Any Speciality')}
                size={ControlSize.Small}
                value={searchExerciseSpeciality}
                onChange={setSearchExerciseSpeciality}
              />
            </div>
            <div className={styles.actionWrapper}>
              <Select
                placeholder="Select Objective..."
                options={createOptions(ExerciseObjective, humanReadableObjective, 'Any Objective')}
                size={ControlSize.Small}
                value={searchExerciseObjective}
                onChange={setSearchExerciseObjective}
              />
            </div>
            <div className={styles.actionWrapper}>
              <Select
                placeholder="Select Movement..."
                options={createOptions(ExerciseMovement, humanReadableMovement, 'Any Movement')}
                size={ControlSize.Small}
                value={searchExerciseMovement}
                onChange={setSearchExerciseMovement}
              />
            </div>
            <div className={styles.actionWrapper}>
              <Select
                placeholder="Select Region..."
                options={createOptions(ExerciseBodyRegion, humanReadableBodyRegion, 'Any Region')}
                size={ControlSize.Small}
                value={searchExerciseBodyRegion}
                onChange={setSearchExerciseBodyRegion}
              />
            </div>
            <div className={styles.actionWrapper}>
              <Select
                placeholder="Select Equipment..."
                options={createOptions(ExerciseEquipment, humanReadableEquipment, 'Any Equipment')}
                size={ControlSize.Small}
                value={searchExerciseEquipment}
                onChange={setSearchExerciseEquipment}
              />
            </div>
          </div>
        </div>
        <Table header={HEADER} body={body} data={FilteredItems} isLoading={!hasFetched}/>
      </div>
      {
        isModalVisible && (
          <Modal color="#fff">
            <div className={styles.modalContent}>
              <div className={styles.modalTitle}>
                {
                  selectedExercise ? 'Edit Exercise' : 'Create an Exercise'
                }
              </div>
              <div className={styles.modalBody}>
                <div className={styles.modalBodyRow}>
                  <InputField
                    label="Name"
                    placeholder="Name..."
                    value={newExerciseName}
                    onChange={setNewExerciseName}
                  />
                </div>
                <div className={styles.modalBodyRow}>
                  <MultiSelect
                    label="Speciality"
                    placeholder="Select Speciality..."
                    options={createOptions(ExerciseSpeciality, humanReadableSpeciality)}
                    value={newExerciseSpeciality}
                    onChange={setNewExerciseSpeciality}
                  />
                </div>
                <div className={styles.modalBodyRow}>
                  <MultiSelect
                    label="Objective"
                    placeholder="Select Objective..."
                    options={createOptions(ExerciseObjective, humanReadableObjective)}
                    value={newExerciseObjective}
                    onChange={setNewExerciseObjective}
                  />
                </div>
                <div className={styles.modalBodyRow}>
                  <MultiSelect
                    label="Movement"
                    placeholder="Select Movement..."
                    options={createOptions(ExerciseMovement, humanReadableMovement)}
                    value={newExerciseMovement}
                    onChange={setNewExerciseMovement}
                  />
                </div>
                <div className={styles.modalBodyRow}>
                  <MultiSelect
                    label="Region"
                    placeholder="Select Region..."
                    options={createOptions(ExerciseBodyRegion, humanReadableBodyRegion)}
                    value={newExerciseBodyRegion}
                    onChange={setNewExerciseBodyRegion}
                  />
                </div>
                <div className={styles.modalBodyRow}>
                  <MultiSelect
                    label="Equipment"
                    placeholder="Select Equipment..."
                    options={createOptions(ExerciseEquipment, humanReadableEquipment)}
                    value={newExerciseEquipment}
                    onChange={setNewExerciseEquipment}
                  />
                </div>
              </div>
              <div className={styles.modalActionContainer}>
                <Button
                  kind={ButtonKind.Secondary}
                  title="Cancel"
                  onClick={closeModal}
                />
                <Button
                  disabled={!isButtonEnabled}
                  title={selectedExercise ? 'Save Changes' : 'Create Exercise'}
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </Modal>
        )
      }
    </PageWrapper>
  );
};

export default ExercisesPage;
