import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from '../form-wrapper.module.scss';

type Props = {
  to: string;
  text: string;
};

const FooterLink = ({ text, to }: Props) => {
  return (
    <NavLink className={styles.footerLink} to={to}>
      {text}
    </NavLink>
  )
};

export default FooterLink;
