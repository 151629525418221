import React from 'react';
import classNames from 'classnames';

import { ControlColor } from '../../model/ControlColor';
import { ControlSize } from '../../model/ControlSize';
import { Option } from '../../model/Option';
import { TintColor } from '../../model/TintColor';

import { IconType, SvgIcon } from '../icon';
import Label from '../label';
import MenuContainer from '../menu-container';

import styles from './multi-select.module.scss';

type Props<T> = {
  className?: string;
  color?: ControlColor;
  disabled?: boolean;
  label?: string;
  options: Option<T>[];
  placeholder?: string;
  size?: ControlSize;
  style?: object;
  tintColor?: TintColor;
  value?: T[];
  onChange: (value: T[]) => void;
};

const MultiSelect = <T extends any>(props: Props<T>) => {
  const {
    className,
    color = ControlColor.Default,
    disabled = false,
    label,
    options,
    placeholder = 'Select...',
    size = ControlSize.Normal,
    style,
    tintColor = TintColor.Green,
    value = [],
    onChange
  } = props;

  const selectedOptions = options.filter(item => value.includes(item.value));

  const labelClassNames = classNames({
    [styles.label]: true,
    [styles.sizeNormal]: size === ControlSize.Normal,
    [styles.sizeSmall]: size === ControlSize.Small,
    [styles.sizeExtraSmall]: size === ControlSize.ExtraSmall
  });

  const valueClassNames = classNames({
    [styles.value]: true,
    [styles.colorDark]: color === ControlColor.Dark,
    [styles.colorDefault]: color === ControlColor.Default,
    [styles.sizeNormal]: size === ControlSize.Normal,
    [styles.sizeSmall]: size === ControlSize.Small,
    [styles.sizeExtraSmall]: size === ControlSize.ExtraSmall
  });

  const placeholderClassNames = classNames({
    [styles.placeholder]: true,
    [styles.colorDark]: color === ControlColor.Dark,
    [styles.colorDefault]: color === ControlColor.Default,
    [styles.sizeNormal]: size === ControlSize.Normal,
    [styles.sizeSmall]: size === ControlSize.Small,
    [styles.sizeExtraSmall]: size === ControlSize.ExtraSmall
  });

  const menuWrapperClassNames = classNames({
    [styles.menuWrapper]: true,
    [styles.sizeNormal]: size === ControlSize.Normal,
    [styles.sizeSmall]: size === ControlSize.Small,
    [styles.sizeExtraSmall]: size === ControlSize.ExtraSmall
  });

  const menuClassNames = classNames({
    [styles.menu]: true,
    [styles.colorDark]: color === ControlColor.Dark,
    [styles.colorDefault]: color === ControlColor.Default,
    [styles.sizeNormal]: size === ControlSize.Normal,
    [styles.sizeSmall]: size === ControlSize.Small,
    [styles.sizeExtraSmall]: size === ControlSize.ExtraSmall
  });

  const iconClassNames = classNames({
    [styles.icon]: true,
    [styles.sizeNormal]: size === ControlSize.Normal,
    [styles.sizeSmall]: size === ControlSize.Small,
    [styles.sizeExtraSmall]: size === ControlSize.ExtraSmall
  });

  const renderButton = (isMenuVisible: boolean, _: any, toggleMenu: () => void) => {
    const selectClassNames = classNames({
      [styles.select]: true,
      [styles.disabled]: disabled,
      [styles.active]: isMenuVisible,
      [styles.colorDark]: color === ControlColor.Dark,
      [styles.colorDefault]: color === ControlColor.Default,
      [styles.sizeNormal]: size === ControlSize.Normal,
      [styles.sizeSmall]: size === ControlSize.Small,
      [styles.sizeExtraSmall]: size === ControlSize.ExtraSmall,
      [styles.tintGreen]: tintColor === TintColor.Green,
      [styles.tintOrange]: tintColor === TintColor.Orange,
      [styles.tintPurple]: tintColor === TintColor.Purple
    });

    return (
      <div className={selectClassNames} onClick={toggleMenu}>
        {
          (selectedOptions.length > 0) ? (
            <div className={valueClassNames}>{selectedOptions.map(({label}) => label).join(', ')}</div>
          ) : (
            <div className={placeholderClassNames}>{placeholder}</div>
          )
        }
        <div>
          <SvgIcon active={isMenuVisible} className={iconClassNames} tintColor={tintColor} type={IconType.DownArrow}/>
        </div>
      </div>
    );
  };

  const renderMenu = (isMenuVisible: boolean, setMenuVisible: (visible: boolean) => void) => {
    const handleOptionClick = (val: T) => () => {
      setMenuVisible(false);

      const nvalue = [...value];

      const index = value.indexOf(val);

      if (index < 0) {
        nvalue.push(val);
      } else {
        nvalue.splice(index, 1);
      }

      onChange(nvalue);
    };

    return (
      <div className={menuClassNames}>
        {
          options.map(option => {
            const optionClassNames = classNames({
              [styles.option]: true,
              [styles.selected]: value.includes(option.value),
              [styles.colorDark]: color === ControlColor.Dark,
              [styles.colorDefault]: color === ControlColor.Default,
              [styles.sizeNormal]: size === ControlSize.Normal,
              [styles.sizeSmall]: size === ControlSize.Small,
              [styles.sizeExtraSmall]: size === ControlSize.ExtraSmall
            });

            return (
              <div
                key={option.value}
                className={optionClassNames}
                onClick={handleOptionClick(option.value)}
              >
                {option.label}
              </div>
            );
          })
        }
      </div>
    );
  };

  return (
    <div className={classNames([styles.component, className as any])}>
      {
        (label !== undefined) && (
          <Label className={labelClassNames} color={color} size={size} text={label}/>
        )
      }
      <MenuContainer
        className={styles.menuContainer}
        disabled={disabled}
        style={style}
        wrapperClassName={menuWrapperClassNames}
        renderButton={renderButton}
        renderMenu={renderMenu}
      />
    </div>
  );
};

export default MultiSelect;
