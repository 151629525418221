import firebase from 'firebase/app';
import { ProgramPlayedHistory, ProgramType } from '../../model/program/Program';
import { Timestamp } from '../../model/Firebase';
import { User } from '../../model/User';
import { Gym } from '../../model/Gym';
import { Screen } from '../../model/Screen';
import set from 'lodash/set';
import get from 'lodash/get';
import { ComponentType } from '../../model/program/Type';
import { ComponentBlock } from '../../model/program/ComponentBlock';
import { API } from '../index';

const collectionPrograms = 'programs';
const collectionProgramsPlayed = 'played';
const collectionProgramTemplates = 'programTemplates';
const screenCollection = 'screens';

const db = firebase.firestore();

const _updateOrInsert = async (block: ProgramType, collection: string): Promise<ProgramType> => {
  const id = block.editor.id;

  let ref;
  if (id) {
    ref = db.collection(collection)
      .doc(id);

    delete block.editor.id;

    await ref.set(block);
  } else {
    ref = db.collection(collection)
      .doc();

    await ref.set(block);
  }

  return {
    ...block,
    editor: {
      ...block.editor,
      id: ref.id,
    },
  };
};

export const updateOrInsert = async (block: ProgramType): Promise<ProgramType> => {
  return await _updateOrInsert(block, collectionPrograms);
};

export const updateOrInsertTemplateProgram = async (block: ProgramType): Promise<ProgramType> => {
  return await _updateOrInsert(block, collectionProgramTemplates);
};

const _getProgram = async (id: string, collection: string): Promise<ProgramType> => {
  try {
    if (!id) {
      throw new Error('Id not provided');
    }

    const ref = db.collection(collection)
      .doc(id);

    const response = await ref.get();

    const data = response.data() as ProgramType;

    if (!data) {
      throw new Error('Editor not found');
    }

    data.editor.id = ref.id;

    return data;
  } catch (e) {
    throw e;
  }
};

export const getProgram = async (id: string): Promise<ProgramType> => {
  return await _getProgram(id, collectionPrograms);
};

export const getTemplateProgram = async (id: string): Promise<ProgramType> => {
  return await _getProgram(id, collectionProgramTemplates);
}

export const getPrograms = async (userId: string): Promise<ProgramType[]> => {
  try {
    const ref = db.collection(collectionPrograms)
      .where('editor.createdBy', '==', userId);

    const response = await ref.get();

    return response.docs.map(d => {
      const data = d.data() as ProgramType;
      data.editor.id = d.id;

      data.editor = {
        ...data.editor,
        dateCreated: (data.editor.dateCreated as unknown as Timestamp).toDate(),
        dateUpdated: (data.editor.dateUpdated as unknown as Timestamp).toDate(),
      };

      return data;
    });
  } catch (e) {
    throw e;
  }
};

export const copyProgramFromTemplates = async (id: string, user: User, gym: Gym) => {
  if (!id) {
    throw new Error('Id not provided');
  }

  if (!user) {
    throw new Error('User not provided');
  }

  return await db.runTransaction(async (transaction) => {
    const templateRef = db.collection(collectionProgramTemplates).doc(id);
    const templateResponse = await transaction.get(templateRef);
    const data = templateResponse.data() as ProgramType;

    if (templateResponse.exists && data) {
      for (let ii = 0; ii < (data.components || []).length; ii++) {
        const c = data.components[ii];

        if (c.type === ComponentType.Block && (c as ComponentBlock).blockId) {
          try {
            // Copy every block from templates
            const newBlockRef = await API.block.copyBlockFromTemplateIfNeeded(c as ComponentBlock, user, gym);
            c.meta.name = `${c.meta.name} (Copied from templates)`;
            (c as ComponentBlock).blockId = newBlockRef;
          } catch (e) {
            console.log('Error copy block', e);
          }
        }
      }

      const newRef = db.collection(collectionPrograms).doc();
      set(data, 'canvas.name', `${get(data, 'canvas.name')} (Copied from template)`);
      set(data, 'editor.dateUpdated', new Date());
      set(data, 'editor.updatedBy', user.uid);
      set(data, 'editor.gymId', gym.id);

      data.editor._copiedFromTemplate = true;
      data.editor._copiedFromId = id;

      await transaction.set(newRef, data);
      return;
    }
    throw new Error(`Data doesn't exist`);
  });
};

export const copyProgramToTemplates = async (id: string, user: User, gym: Gym) => {
  if (!id) {
    throw new Error('Id not provided');
  }

  if (!user) {
    throw new Error('User not provided');
  }

  return await db.runTransaction(async (transaction) => {
    const templateRef = db.collection(collectionPrograms).doc(id);
    const templateResponse = await transaction.get(templateRef);
    const data = templateResponse.data() as ProgramType;

    if (templateResponse.exists && data) {
      for (let ii = 0; ii < (data.components || []).length; ii++) {
        const c = data.components[ii];

        if (c.type === ComponentType.Block && (c as ComponentBlock).blockId) {
          try {
            // Copy every block from templates
            const newBlockRef = await API.block.copyBlockToTemplateIfNeeded(c as ComponentBlock, user, gym);
            c.meta.name = `${c.meta.name} (Copied from gym)`;
            (c as ComponentBlock).blockId = newBlockRef;
          } catch (e) {
            console.log('Error copy block', e);
          }
        }
      }

      const newRef = db.collection(collectionProgramTemplates).doc();
      set(data, 'canvas.name', `${get(data, 'canvas.name')} (Copied from gym)`);
      set(data, 'editor.dateUpdated', new Date());
      set(data, 'editor.updatedBy', user.uid);
      set(data, 'editor.gymId', gym.id);

      data.editor._copiedFromId = id;

      await transaction.set(newRef, data);
      return;
    }
    throw new Error(`Data doesn't exist`);
  });
};

export const addProgramLastPlayedAndUpdateHistory = async (screenId: string) => {
  const screen = await db.collection(screenCollection)
    .doc(screenId)
    .get();

  if (!screen.exists) {
    throw new Error('Screen not found.');
  }

  const screenData = screen.data() as Screen;
  const programId = screenData.programId;

  if (!programId) {
    throw new Error('Program id not specified.');
  }

  return db.runTransaction(async (transaction) => {
    const date = Date.now();

    const programRef = db.collection(collectionPrograms)
      .doc(programId);

    await programRef.set({
      lastPlayed: date,
    }, { merge: true });

    const doc = programRef.collection(collectionProgramsPlayed)
      .doc();

    await doc
      .set({
        id: doc.id,
        screenId,
        screenName: screenData.name,
        playedAt: date,
      });
  });
};

export const getProgramPlayedHistory = async (programId: string): Promise<ProgramPlayedHistory[]> => {
  return (await db.collection(collectionPrograms)
    .doc(programId)
    .collection(collectionProgramsPlayed)
    .get())
    .docs
    .map((d) => {
      const data = d.data() as ProgramPlayedHistory;
      return {
        ...data,
        id: d.id,
      };
    });
}
