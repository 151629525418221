import React from 'react';
import reactDom from 'react-dom';
import styles from './modal.module.scss';
import { IconType, SvgIcon } from '../icon';

type Props = {
  children: React.ReactNode;
  color?: string;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  onClose?: () => void;
  closeBtn?: boolean;
};

const ModalComponent = (props: Props) => {
  const {
    children,
    color,
    header,
    footer,
    onClose,
    closeBtn,
  } = props;

  const style = {} as {backgroundColor?: string};

  if (color) {
    style.backgroundColor = color;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.backdrop}>
        <div className={styles.dialog} style={style}>
          {closeBtn &&
          <div className={styles.closeWrapper}>
            <SvgIcon type={IconType.PreviewClose} onClick={onClose}/>
          </div>
          }
          <div className={styles.content}>
            <div className={styles.header}>
              {header}
            </div>
            {children &&
            <div className={styles.body}>
              {children}
            </div>
            }
            {footer &&
            <div className={styles.footer}>
              {footer}
            </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export const Modal = (props: Props) => {
  return reactDom.createPortal(<ModalComponent {...props}/>, window.document.body);
};
