const defaultDateFormatOptions = {
  year: 'numeric', month: 'numeric', day: 'numeric',
  hour12: false,
};

export const formatDate = (date: Date | number) => {
  return new Intl.DateTimeFormat('en-US', defaultDateFormatOptions).format(date);
};

export const formatDateTime = (date: Date | number) => {
  return new Intl.DateTimeFormat('en', {
    ...defaultDateFormatOptions,
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  }).format(date);
}

export const numberToFixedValue = (value: number | string): number => {
  let fixed = value;

  if (!Number.isNaN(+fixed)) {
    fixed = Math.floor(+fixed);
  } else {
    return 0;
  }

  return fixed;
};
