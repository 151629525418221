import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import BlocksPage from '../blocks/list';
import BlockTemplatesPage from '../blocks/list-templates';
import BlocksEditorPage from '../blocks/edit';
import BlocksEditorTemplatesPage from '../blocks/edit-templates';
import ExercisesPage from '../exercises';
import InvitesPage from '../invites';
import ProgramEdit from '../program/edit';
import ProgramTemplatesEdit from '../program/edit-templates';
import ProgramListPage from '../program/list';
import ProgramListTemplatesPage from '../program/list-templates';
import ProfilePage from '../profile';
import ScreensPage from '../screens';
import TeamPage from '../team';
import GymSettingsPage from '../gym-settings';
import RolesRoute from '../../components/roles-route';
import { AccessLevel } from '../../model/AccessLevel';
import PlayedHistory from '../program/played-history';
import GymListener from '../../components/gym-listener';
import GymsListPage from '../gyms-list';
import AppTour from '../../components/app-tour';

const MainPage = () => {
  return (
    <GymListener>
      <Switch>
        <Route exact={true} path={'/blocks'} component={BlocksPage}/>
        <Route exact={true} path={'/blocks/templates'} component={BlockTemplatesPage}/>
        <RolesRoute roles={[AccessLevel.admin]} exact={true} path={'/blocks/templates/editor'} component={BlocksEditorTemplatesPage}/>
        <RolesRoute roles={[AccessLevel.admin]} exact={true} path={'/blocks/templates/editor/:id'} component={BlocksEditorTemplatesPage}/>
        <Route exact={true} path={'/blocks/editor'} component={BlocksEditorPage}/>
        <Route exact={true} path={'/blocks/editor/:id'} component={BlocksEditorPage}/>
        <Route exact={true} path={'/exercises'} component={ExercisesPage}/>
        <Route exact={true} path={'/invites'} component={InvitesPage}/>
        <RolesRoute roles={[AccessLevel.admin]} exact={true} path={'/programs/templates/editor'} component={ProgramTemplatesEdit}/>
        <RolesRoute roles={[AccessLevel.admin]} exact={true} path={'/programs/templates/editor/:id'} component={ProgramTemplatesEdit}/>
        <Route exact={true} path={'/programs/templates'} component={ProgramListTemplatesPage}/>
        <Route exact={true} path={'/programs/editor'} component={ProgramEdit}/>
        <Route exact={true} path={'/programs/editor/:id'} component={ProgramEdit}/>
        <Route exact={true} path={'/programs/history'} component={PlayedHistory}/>
        <Route exact={true} path={'/programs'} component={ProgramListPage}/>
        <Route exact={true} path={'/profile'} component={ProfilePage}/>
        <Route exact={true} path={'/screens'} component={ScreensPage}/>
        <Route exact={true} path={'/team'} component={TeamPage}/>
        <Route exact={true} path={'/gym-settings'} component={GymSettingsPage}/>
        <RolesRoute exact={true} path={'/gyms-list'} component={GymsListPage} roles={[AccessLevel.admin]}/>
        <Redirect to={'/screens'}/>
      </Switch>
      <AppTour/>
    </GymListener>
  );
};

export default MainPage;
