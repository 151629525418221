import { takeEvery, put, all, call } from 'redux-saga/effects';
import { AuthActionType } from '../../action-types';
import { API } from '../../../api';
import { push } from 'connected-react-router';
import { Action } from '../../utils';
import { authActions } from '../../actions';

function* logout() {
  yield call(API.auth.logout);

  yield put(push('/login'));
}

export function* logOut() {
  yield takeEvery(AuthActionType.LOGOUT, logout);
}

function* restorePassword() {
  yield takeEvery(AuthActionType.FORGOT_PASSWORD, function* ({ payload }: Action) {
    try {
      const email = payload;

      yield put(authActions.error(''));
      yield call(API.auth.resetPassword, email);
      yield put(push('/login'));
    } catch (e) {
      yield put(authActions.error(e.message));
    }
  });
}

export default function* () {
  yield all([
    logOut(),
    restorePassword(),
  ]);
}
