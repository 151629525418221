function getItem<T = any>(key: string, defaultValue?: T): T | null {
  const string = localStorage.getItem(key);

  if (!string) {
    return defaultValue || null;
  }

  return JSON.parse(string) as T;
}

function setItem(key: string, value: Object): void {
  localStorage.setItem(key, JSON.stringify(value));
}

function removeItem(key: string) {
  localStorage.removeItem(key);
}

export const storage = {
  getItem,
  setItem,
  removeItem,
};

export const StorageKeys = {
  userData: 'tc-user',
  screenId: 'screen-id',
};
