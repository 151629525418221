import React, { useCallback } from 'react';
import styles from './button-group.module.scss';
import classNames from 'classnames';
import { Option } from '../../model/Option';

type Props = {
  items: Option[];
  value: Option | string;
  onChange: (value: string) => void;
};

const RadioGroup = (props: Props) => {
  const {
    items,
    value,
    onChange,
  } = props;

  const wrapperClasses = classNames({
    [styles.wrapper]: true,
  });

  const handleChange = useCallback((option) => {
    return () => {
      onChange(option.value);
    };
  }, []);

  return (
    <div className={wrapperClasses}>
      {items.map(item => {
        const isSelected = typeof value === 'string' ? item.value === value : item.value === value.value;

        const itemClasses = classNames({
          [styles.button]: true,
          [styles.selected]: isSelected,
        });

        return (
          <div key={item.value} onClick={handleChange(item)} className={itemClasses}>{item.label}</div>
        );
      })}
    </div>
  )
};

export default RadioGroup;
