import { Option } from '../Option';

const items = [
  'Strength - Upper Body Push',
  'Strength - Upper Body Pull',
  'Strength - Lower Body Push',
  'Strength - Lower Body Pull',
  'Strength - Full body',
  'Strength - Full Body Push',
  'Strength - Full Body Pull',
  'Strength - Strongman/woman Style',
  'Strength - Rehabilitation',
  'Strength - Foundation',
  'Strength - other',
  'Conditioning - Aerobic',
  'Conditioning - Anaerobic Threshold',
  'Conditioning - ATP/PC',
  'Conditioning - ESD/Multi',
  'Strength and Conditioning',
  'Conditioning - Other',
  'Other - Pilates',
  'Other - Musculoskeletal/Rehab',
  'Other - Yoga',
  'Other - Occupational Health and Safety',
  'Other - Other',
];

export const ProgramFocusOptions: Option<string>[] = items.map(s => ({ label: s, value: s }));
