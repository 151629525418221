import React from 'react';
import { match } from 'react-router';
import { Dispatch } from 'redux';
import styles from './edit.module.scss';
import { connect } from 'react-redux';
import EditorBlock from '../../../components/editor';
import { Program } from '../../../model/program/Program';
import { editorBlockActions } from '../../../store/actions';
import { push, replace } from 'connected-react-router';

type Params = {
  id: string;
};

type Props = {
  match: match<Params>;
} & ReturnType<typeof mapActions>;

class BlocksEditorPage extends React.PureComponent<Props> {
  componentDidMount() {
    const { match: { params } } = this.props;

    if (params.id) {
      this.props.fetchBlock(params.id, (err: Error, data: Program) => {
        if (err) {
          alert(`${err.message}\nRedirect to new`);
          this.props.redirectToNew();
        }
      });
    } else {
      this.props.fetchBlocks();
    }
  }

  render() {
    const {
      saveExistingBlock,
      goToBlockList,
    } = this.props;
    return (
      <div className={styles.page}>
        <EditorBlock
          saveExistingBlock={saveExistingBlock}
          goToBlockList={goToBlockList}
        />
      </div>
    );
  }
}

const mapState = () => ({});

const mapActions = (dispatch: Dispatch) => ({
  fetchBlock: (id: string, resolve: Function) => dispatch(editorBlockActions.fetchBlockForEdit(id, resolve)),
  fetchBlocks: () => dispatch(editorBlockActions.fetchBlocks()),
  redirectToNew: () => dispatch(replace('/editor')),
  saveExistingBlock: (resolve: Function) => dispatch(editorBlockActions.saveExistingBlock(resolve)),
  goToBlockList: () => dispatch(push('/blocks')),
});

export default connect(mapState, mapActions)(BlocksEditorPage);
