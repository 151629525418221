import { createAction } from '../utils';
import { DesignerTourType } from '../action-types';
import { Component } from '../../model/program/Component';
import { Timer } from '../../model/Timer';

export const setActive = (isActive: boolean) => createAction<boolean>(DesignerTourType.DESIGNER_TOUR_SET_ACTIVE, isActive);

export const designerTourAddComponentToCanvas = (component: Component) => createAction(DesignerTourType.DESIGNER_TOUR_ADD_COMPONENT_TO_CANVAS, component);
export const designerTourAddTimerToCanvas = (timer: Timer) => createAction(DesignerTourType.DESIGNER_TOUR_ADD_TIMER_TO_CANVAS, timer);
export const designerTourSetStepNumber = (step: number) => createAction(DesignerTourType.DESIGNER_TOUR_SET_STEP_NUMBER, step);

