import React from 'react';
import styles from './logo.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';

const Logo = () => {
  const { gym } = useSelector((state: RootState) => state.gym);

  return (
    <div className={styles.logoWrapper}>
      {gym && <div className={styles.logo} style={{backgroundImage: `url(${gym.imageUrl})`}}/>}
    </div>
  )
};

export default Logo;
