import React, { ChangeEvent } from 'react';
import { Dispatch } from 'redux';
import styles from './button-upload.module.scss';
import { connect } from 'react-redux';
import { RootState } from '../../../../../../store/reducers';
import { ComponentImage } from '../../../../../../model/program/ComponentImage';
import { editorBlockActions } from '../../../../../../store/actions';
import { getProgramEditorPresentState } from '../../../../../../store/reducers/program';

type Props = {
} & ReturnType<typeof mapState> & ReturnType<typeof mapActions>;

type State = {
  loading: boolean;
};

class UploadButton extends React.PureComponent<Props, State> {
  state = {
    loading: false,
  };

  handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (!files || !files.length) {
      return;
    }

    const {
      component,
    } = this.props;

    this.setState({ loading: true });

    this.props.uploadImage(files[0], component.imageUrl, (err?: Error) => {
      if (err) {
        alert(err.message);
      }
      this.setState({ loading: false });
    });
  };
  render() {
    const {
      loading,
    } = this.state;

    return (
      <div className={styles.wrapper}>
        <div className={styles.text}>{loading ? 'Loading...' : 'Upload Image'}</div>
        <input
          className={styles.input}
          type={'file'}
          accept={'image/*'}
          onChange={this.handleChange}
          disabled={loading}
        />
      </div>
    );
  }
}

const mapState = (state: RootState) => ({
  component: getProgramEditorPresentState(state).selectedComponent as ComponentImage,
});

const mapActions = (dispatch: Dispatch) => ({
  uploadImage: (file: File, oldImageUrl?: string, resolve?: Function) => dispatch(editorBlockActions.uploadImageForSelected(file, oldImageUrl, resolve))
});

export default connect(mapState, mapActions)(UploadButton);
