import axios from 'axios';
import firebase from 'firebase/app';
import { Invite, InviteParams, InviteSaveDTO, InviteStatus, mapInviteFromDTO } from '../../model/Invite';
import { API } from '../index';
import { UserCreateParams } from '../../model/User';
import { getBaseUrl } from '../../utils/app';

const DEFAULT_AXIOS_OPTIONS = {
  baseURL: 'https://us-central1-tcpro-31053.cloudfunctions.net/express',
  timeout: 15 * 1000
};

const axiosInstance = axios.create(DEFAULT_AXIOS_OPTIONS);

const collectionInvites = 'invites';
const temporaryGymsCollectionName = 'gyms-temporary';

const db = firebase.firestore();

export const createInvite = async (data: InviteParams): Promise<Invite> => {
  try {
    if (!data) {
      throw new Error('Data not provided');
    }

    if (!data.fromUserId) {
      throw new Error('User From not provided');
    }

    if (!data.email) {
      throw new Error('Email not provided')
    }

    if (!data.accessLevel) {
      throw new Error('AccessLevel not provided');
    }

    const toSave: InviteSaveDTO = {
      ...data,
      dateCreated: new Date(),
      dateUpdated: new Date(),
      status: InviteStatus.requested,
    };

    const ref = db.collection(collectionInvites)
      .doc();

    await ref.set(toSave, { merge: true });

    return {
      ...toSave,
      id: ref.id,
    };
  } catch (e) {
    console.log('Error create invite', e);
    throw e;
  }
};

export const sendInvite = async (email: string, url: string): Promise<void> => {
  try {
    const { currentUser } = firebase.auth();

    if (!currentUser) {
      throw new Error('currentUser is null');
    }

    const token = await currentUser.getIdToken(true);

    return axiosInstance.request({
      url: '/send-invite',
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: {
        email,
        url
      }
    });
  } catch (e) {
    throw e;
  }
};

export const getInvites = async (userId: string): Promise<Invite[]> => {
  try {
    if (!userId) {
      throw new Error('User Id not provided');
    }

    const response = await db.collection(collectionInvites)
      .where('fromUserId', '==', userId)
      .get();

    return response.docs.map(mapInviteFromDTO);
  } catch (e) {
    console.log('Error get invites', e);
    throw e;
  }
};

export const getInvite = async (inviteId: string): Promise<Invite> => {
  try {
    if (!inviteId) {
      throw new Error('Invite id not provided');
    }

    const response = await db.collection(collectionInvites)
      .doc(inviteId)
      .get();

    if (!response) {
      throw new Error('Invite not exist');
    }
    const data = mapInviteFromDTO(response);

    if (data.status === InviteStatus.accepted) {
      throw new Error('Invite already accepted');
    }

    return data;
  } catch (e) {
    console.log('Error get invite', e);
    throw e;
  }
};

export const createUserFromInvite = async (invite: Invite, name: string, password: string): Promise<void> => {
  try {
    if (!invite) {
      throw new Error('Invite not provided');
    }

    if (!name) {
      throw new Error('Name not provided');
    }

    if (!password) {
      throw new Error('Password not provided');
    }

    const uid = await API.auth.registerUser(invite.email, password);

    const newUser: UserCreateParams = {
      name,
      roles: [invite.accessLevel],
      email: invite.email,
      invitedBy: invite.fromUserId,
      gymId: invite.gymId,
    };

    await API.user.createUserFromInvite(newUser, uid);
    await db.collection(collectionInvites)
      .doc(invite.id)
      .set({
        dateUpdated: new Date(),
        status: InviteStatus.accepted,
      }, { merge: true });
  } catch (e) {
    console.log('Error create User', e);
    throw e;
  }
};

type CreateData = {
  name: string;
} & Record<string, string | number | Array<number | string>>;

export const sendCreateGymConfirmation = async (email: string, data: CreateData) => {
  try {
    const doc = await db.collection(temporaryGymsCollectionName)
      .add(data);

    const url = `${getBaseUrl()}/creating/${doc.id}`;

    return await axiosInstance.request({
      url: '/send-creating-confirmation',
      method: 'post',
      data: {
        email,
        url,
        name: data.name,
      },
    });
  } catch (e) {
    const message = e.response?.data?.error;

    if (message) {
      throw new Error(message);
    }

    throw e;
  }
};

export const sendUserRegisteredConfirmation = async (userId: string, gymName: string) => {
  try {
    if (!userId) {
      throw new Error('User not provided');
    }

    return await axiosInstance.request({
      url: '/send-user-registered',
      method: 'post',
      data: {
        gymName,
        userId,
      },
    });
  } catch (e) {
    console.debug(`Confirmation error`, e);
  }
}

type ConfirmationData = {
  createdBy: string;
  gymName: string;
};

export const completeGymConfirmation = async (confirmationId: string) => {
  await db.runTransaction(async (transaction) => {
    const confirmationResponse = await db.collection(temporaryGymsCollectionName)
      .doc(confirmationId)
      .get();

    if (!confirmationResponse.exists) {
      throw new Error('Data not found.');
    }

    const confirmationData = confirmationResponse.data() as ConfirmationData;

    const gym = await API.gyms.createGym(confirmationData.gymName, confirmationData.createdBy);
    await API.user.updateUser(confirmationData.createdBy, { gymId: gym.id });
    await confirmationResponse.ref.delete();

    try {
      sendUserRegisteredConfirmation(confirmationData.createdBy, confirmationData.gymName);
    } catch (e) {
      console.debug(`[Error send email confirmation]`, e.message);
    }
  });
}
