import React from 'react';
import { Rnd } from 'react-rnd';
import styles from './timer.module.scss';
import { isTimerSelected, mergeTimerStyleWithDefault, Timer } from '../../../../model/Timer';
import { getResizingObj, GRID, ResizeBox } from '../helpers';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducers';
import { editorBlockActions } from '../../../../store/actions';
import { getSize } from '../../../../model/program/Size';
import { getPosition, isPositionEqual } from '../../../../model/program/Position';
import helpersStyles from '../helpers.module.scss';
import { getProgramEditorPresentState } from '../../../../store/reducers/program';

type Props = {
  timer: Timer;
  editable?: boolean;
  scale?: number;
};

const TimerComponent = (props: Props) => {
  const {
    timer,
    editable = true,
    scale: componentScale
  } = props;

  const dispatch = useDispatch();

  const selectedComponent = useSelector((state: RootState) => getProgramEditorPresentState(state).selectedComponent);
  const canvas = useSelector((state: RootState) => getProgramEditorPresentState(state).canvas);
  const isPreview = useSelector((state: RootState) => getProgramEditorPresentState(state).preview);

  const scale = componentScale ? componentScale : (isPreview ? 1 : canvas.scale / 100);

  const isEdit = editable && isTimerSelected(selectedComponent);
  const isWarmUpEdit = editable && timer.showWarmUpComponent && selectedComponent && selectedComponent.id === timer.warmUpComponent.id;

  const resizing = isEdit ? getResizingObj(true) : getResizingObj(false);
  const warmUpResizing = isWarmUpEdit ? getResizingObj(true) : getResizingObj(false);

  const handleClick = () => {
    if (!editable) {
      return;
    }

    if (!isEdit) {
      dispatch(editorBlockActions.selectTimer(timer));
    }
  };

  const handleClickWarmUp = () => {
    if (!editable) {
      return;
    }

    if (!isWarmUpEdit) {
      dispatch(editorBlockActions.selectTimer(timer.warmUpComponent));
    }
  };

  const handleResizeStop = (e: MouseEvent | TouchEvent, dir: any, element: HTMLDivElement, delta: any, position: any) => {
    const { size } = timer;
    const width = size.width + delta.width;
    const height = size.height + delta.height;

    dispatch(editorBlockActions.updateTimerProperties({
      size: getSize(width, height),
      position: position,
    }));
  };

  const handleResizeWarmUpStop = (e: MouseEvent | TouchEvent, dir: any, element: HTMLDivElement, delta: any, position: any) => {
    const { size } = timer.warmUpComponent;
    const width = size.width + delta.width;
    const height = size.height + delta.height;

    dispatch(editorBlockActions.updateWarmUpProperties({
      size: getSize(width, height),
      position: position,
    }));
  };

  const handleDragStop = (e: any, data: any) => {
    const { position: oldPosition } = timer;
    const position = getPosition(data.x, data.y);
    const isEqual = isPositionEqual(oldPosition, position);

    if (!isEqual) {
      dispatch(editorBlockActions.updateTimerProperties({
        position,
      }));
    }
  };

  const handleDragWarmUpStop = (e: any, data: any) => {
    const { position: oldPosition } = timer.warmUpComponent;
    const position = getPosition(data.x, data.y);
    const isEqual = isPositionEqual(oldPosition, position);

    if (!isEqual) {
      dispatch(editorBlockActions.updateWarmUpProperties({
        position,
      }));
    }
  };

  const style = {
    zIndex: 1000,
  };

  return (
    <>
      <Rnd
        bounds={'parent'}
        onClick={handleClick}
        enableResizing={resizing}
        disableDragging={!isEdit}
        resizeGrid={GRID}
        dragGrid={GRID}
        position={{
          ...timer.position,
        }}
        size={{
          ...timer.size,
        }}
        style={style}
        resizeHandleClasses={{
          topRight: helpersStyles.resizeClass,
          topLeft: helpersStyles.resizeClass,
          bottomLeft: helpersStyles.resizeClass,
          bottomRight: helpersStyles.resizeClass,
          right: helpersStyles.lineRight,
          left: helpersStyles.lineLeft,
          top: helpersStyles.lineTop,
          bottom: helpersStyles.lineBottom,
        }}
        resizeHandleComponent={{
          topRight: <ResizeBox/>,
          topLeft: <ResizeBox/>,
          bottomLeft: <ResizeBox/>,
          bottomRight: <ResizeBox/>,
        }}
        onResizeStop={handleResizeStop}
        onDragStop={handleDragStop}
        scale={scale}
      >
        <div style={mergeTimerStyleWithDefault(timer.style)} className={styles.wrapper}>
          <div className={styles.time}>
            00:00
          </div>
          <div>
            <div className={styles.interval}>Low interval</div>
            <div className={styles.interval}>1/{timer.intervals[0].numberOfSets}</div>
          </div>
        </div>
      </Rnd>
      {timer.showWarmUpComponent &&
      <Rnd
        bounds={'parent'}
        onClick={handleClickWarmUp}
        enableResizing={warmUpResizing}
        disableDragging={!isWarmUpEdit}
        resizeGrid={GRID}
        dragGrid={GRID}
        position={{
          ...timer.warmUpComponent.position,
        }}
        size={{
          ...timer.warmUpComponent.size,
        }}
        style={style}
        resizeHandleClasses={{
          topRight: helpersStyles.resizeClass,
          topLeft: helpersStyles.resizeClass,
          bottomLeft: helpersStyles.resizeClass,
          bottomRight: helpersStyles.resizeClass,
          right: helpersStyles.lineRight,
          left: helpersStyles.lineLeft,
          top: helpersStyles.lineTop,
          bottom: helpersStyles.lineBottom,
        }}
        resizeHandleComponent={{
          topRight: <ResizeBox/>,
          topLeft: <ResizeBox/>,
          bottomLeft: <ResizeBox/>,
          bottomRight: <ResizeBox/>,
        }}
        onResizeStop={handleResizeWarmUpStop}
        onDragStop={handleDragWarmUpStop}
        scale={scale}
      >
        <pre className={styles.warmUp} style={timer.warmUpComponent.style}>
          {timer.warmUpComponent.text}
        </pre>
      </Rnd>
      }
    </>
  )
};

export default TimerComponent;
