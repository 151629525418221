import React, { ReactElement, ReactNode } from 'react';
import intersection from 'lodash/intersection';
import { useSelector } from 'react-redux';

import { AccessLevel } from '../../model/AccessLevel';

import { RootState } from '../../store/reducers';

type Props = {
  children: ReactNode;
  placeholder?: ReactNode;
  roles: AccessLevel[];
}

const OnlyRole = ({children, placeholder, roles}: Props): ReactElement => {
  const user = useSelector((state: RootState) => state.auth.user);

  if (user && intersection(user.roles, roles).length) {
    return (
      <>
        {children}
      </>
    );
  }

  return (
    <>
      {placeholder || null}
    </>
  );
};

export default OnlyRole;
