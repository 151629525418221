import React, { ChangeEvent } from 'react';
import styles from './value-wrapper.module.scss';

type Props = {
  value?: number | string;
  postfix?: string | React.ReactNode;
  className?: string;
  type?: 'text' | 'number';
  onUpdate: (value: string | number) => void;
  multi?: boolean;
  validate?: (value: string | number | undefined) => string | null;
};

type State = {
  focused: boolean;
  oldValue?: string | number;
};

class ValueWrapper extends React.PureComponent<Props, State> {
  state = {
    focused: false,
    oldValue: undefined,
  };

  handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      type,
    } = this.props;

    const { value } = e.target;
    const v = type === 'number' ? +value : value;

    this.props.onUpdate(v);
  };

  handleFocus = () => {
    const {
      focused,
    } = this.state;

    const {
      value,
    } = this.props;

    if (!focused) {
      this.setState({ focused: true, oldValue: value });
    }
  };

  handleBlur = () => {
    const {
      focused,
      oldValue,
    } = this.state;

    const {
      validate,
      value,
    } = this.props;

    if (validate) {
      const response = validate(value);
      if (response !== null) {
        window.alert(response);
        this.props.onUpdate(oldValue as any);
      }
    }

    if (focused) {
      this.setState({ focused: false });
    }
  };

  handleChangeMulti = (e: ChangeEvent<HTMLTextAreaElement>) => {
    this.props.onUpdate(e.target.value);
  };

  render() {
    const {
      value,
      postfix,
      className = '',
      type = 'text',
      multi = false,
    } = this.props;

    const {
      focused,
    } = this.state;

    const classes = [styles.wrapper, className];

    if (focused) {
      classes.push(styles.focused);
    }

    return multi ? (
      <textarea
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onChange={this.handleChangeMulti}
        className={styles.textarea}
        value={value}
      />
    ) : (
      <div className={classes.join(' ')}>
        <div className={styles.value}>
          <input
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            className={styles.input}
            type={type}
            value={value}
            onChange={this.handleChange}
          />
        </div>
        {postfix &&
        <div className={styles.postfix}>
          {postfix}
        </div>
        }
      </div>
    );
  }
}


export default ValueWrapper;
