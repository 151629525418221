import { Action as ReduxAction } from 'redux';

export interface Action<T = any> extends ReduxAction {
  payload?: T;
}

export function createReducer<T>(initialState: T, handlers: any) {
  return function reducer(state = initialState, action: Action) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action)
    } else {
      return state
    }
  }
}

export function createAction<T = any>(name: string, data?: T): { type: string, payload?: T } {
  return {
    type: name,
    payload: data,
  };
}

export function addNamespace(obj: Object, namespace: string) {
  return Object.keys(obj)
    .reduce((acc, key) => {
      acc[key] = `${namespace}__${key}`;
      return acc;
    }, {} as any);
}
