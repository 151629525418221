import React, { useEffect, useMemo, useState } from 'react';
import styles from './paging.module.scss';

type Props<T> = {
  data: T[];
  onPageChanged: (page: number) => void;
  itemsPerPage: number;
};

function Paging<T>(props: Props<T>) {
  const { data, onPageChanged, itemsPerPage } = props;

  const [page, setPage] = useState(0);

  useEffect(() => {
    handleChangePage(0);
  }, []);

  const pages = useMemo(() => {
    const num = Math.ceil(data.length / itemsPerPage);

    return new Array(num).fill(undefined).map((_, index) => index);
  }, [data]);

  const handleChangePage = (page: number) => () => {
    setPage(page);
    onPageChanged(page);
  };

  useEffect(() => {
    handleChangePage(0)();
  }, [data.length]);

  return (
    <div className={styles.wrapper}>
      {pages.map((p, index) => {
        const active = p === page;

        if (pages.length < 7) {
          return (
            <div key={p} className={`${styles.item} ${active ? styles.active : ''}`} onClick={handleChangePage(p)}>
              {p + 1}
            </div>
          );
        }

        if (index === 0 || index === pages.length - 1) {
          return (
            <div key={p} className={`${styles.item} ${active ? styles.active : ''}`} onClick={handleChangePage(p)}>
              {p + 1}
            </div>
          );
        }

        if (index > page - 3 && index < page + 3) {
          return (
            <div key={p} className={`${styles.item} ${active ? styles.active : ''}`} onClick={handleChangePage(p)}>
              {p + 1}
            </div>
          );
        }

        if (index > page - 4 && index < page + 4) {
          return (
            <div key={p} className={`${styles.itemDivider}`} onClick={handleChangePage(p)}>
              ...
            </div>
          );
        }

        return null;
      })}
    </div>
  );
}

export default Paging;
