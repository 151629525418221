import React from 'react';
import { Dispatch } from 'redux';
import { match } from 'react-router';
import { push, replace } from 'connected-react-router';
import { connect } from 'react-redux';
import styles from './editor-page.module.scss';
import EditorBlock from '../../../components/editor';
import { editorBlockActions } from '../../../store/actions';
import { Program } from '../../../model/program/Program';
import AppEditorTour from '../../../components/app-editor-tour';

type Params = {
  id: string;
};

type Props = {
  match: match<Params>;
} & ReturnType<typeof mapActions>;

class EditorPage extends React.PureComponent<Props> {
  componentDidMount() {
    const { match: { params } } = this.props;

    this.props.setPreferences();

    if (params.id) {
      this.props.fetchEditor(params.id, (err: Error, data: Program) => {
        if (err) {
          alert(`${err.message}\nRedirect to new`);
          this.props.redirectToNew();
        }
      });
    } else {
      this.props.fetchBlocks();
    }
  }

  render() {
    const {
      getExercisesList,
      save,
      replace,
      goToList,
      goToBlockList,
      saveExistingBlock,
      createNewBlock,
    } = this.props;
    return (
      <div className={styles.page}>
        <EditorBlock
          save={save}
          replace={replace}
          goToList={goToList}
          goToBlockList={goToBlockList}
          saveExistingBlock={saveExistingBlock}
          getExercisesList={getExercisesList}
          createNewBlock={createNewBlock}
        />
        <AppEditorTour/>
      </div>
    );
  }
}

const mapActions = (dispatch: Dispatch) => ({
  fetchEditor: (id: string, resolve: Function) => dispatch(editorBlockActions.fetchEditor(id, resolve)),
  fetchBlocks: () => dispatch(editorBlockActions.fetchBlocks()),
  redirectToNew: () => dispatch(replace('/programs/editor')),
  setPreferences: () => dispatch(editorBlockActions.updatePreferences()),
  getExercisesList: () => dispatch(editorBlockActions.fetchExercises()),
  save: (resolve: Function) => dispatch(editorBlockActions.saveCanvas(resolve)),
  replace: (id: string) => dispatch(replace(`/programs/editor/${id}`)),
  goToList: () => dispatch(push('/programs')),
  goToBlockList: () => dispatch(push('/blocks')),
  saveExistingBlock: (resolve: Function) => dispatch(editorBlockActions.saveExistingBlock(resolve)),
  createNewBlock: (name: string, resolve: Function) => dispatch(editorBlockActions.createNewBlock(name, resolve)),
});

export default connect(null, mapActions)(EditorPage);
