import React from 'react';
import styles from './table-action-list.module.scss';
import Popup from '../../popup';

export type TableActionType = {
  title: string;
  onClick: () => void;
  className?: string;
  kind?: TableActionKind;
};

export enum TableActionKind {
  Default = 'default',
  Warning = 'warning',
}

type Props = {
  onClick: () => void;
  actions?: TableActionType[];
};

export const TableActionList = (props: Props) => {
  const {
    onClick,
    actions = [],
  } = props;

  const handleClick = (action: TableActionType) => () => {
    onClick();
    action.onClick();
  };

  return (
    <Popup>
      <ul className={styles.list}>
        {actions.map((action, index) => {
          const { kind = TableActionKind.Default } = action;
          const kindStyle = styles[kind] || '';
          return (
            <li key={index} className={`${styles.item} ${kindStyle} ${action.className || ''}`} onClick={handleClick(action)}>{action.title}</li>
          );
        })}
      </ul>
    </Popup>
  );
};
