import merge from 'lodash/merge';
import { Size } from './Size';
import { Option } from '../Option';
import { ComponentBaseUpdateParams, ComponentBaseUpdatePropertyKey, Structure } from './Component';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import { getGymPreferences, GymPreferences } from '../Gym';

export type CanvasType = {
  name: string;
  size: Size;
  color: string;
  mode: Mode;
  scale: number;
};

export enum Mode {
  landscape = 'landscape',
  portrait = 'portrait'
}

const modeMap = {
  [Mode.landscape]: 'Landscape',
  [Mode.portrait]: 'Portrait',
};

export const getModeLabel = (mode: Mode): string => {
  return modeMap[mode];
};

export const CanvasModeOptions: Option[] = [
  { label: getModeLabel(Mode.landscape), value: Mode.landscape },
  { label: getModeLabel(Mode.portrait), value: Mode.portrait },
];

export const getDefaultCanvas = (): Canvas => {
  return new Canvas({
    name: 'Untitled',
    size: {
      width: 1920,
      height: 1080,
      unit: 'px',
    },
    color: '#000000',
    mode: Mode.landscape,
    scale: 60,
  });
};

export class Canvas implements CanvasType, Structure<CanvasType> {
  constructor(
    data: CanvasType = getDefaultCanvas(),
  ) {
    const {
      name,
      size,
      color,
      mode,
      scale,
    } = data;

    this.name = name;
    this.size = size;
    this.color = color;
    this.mode = mode;
    this.scale = scale;
  }

  id?: string;
  name: string;
  size: Size;
  color: string;
  mode: Mode;
  scale: number;

  clone() {
    return cloneDeep(this);
  }

  updatePreferences(prefs: GymPreferences = getGymPreferences()): void {
    const { mode, bgColor } = prefs;

    if (mode === Mode.portrait && this.mode !== mode) {
      const size = {
        width: this.size.height,
        height: this.size.width,
        unit: this.size.unit,
      };

      this.updateProperty('size', size);
    }

    this.updateProperty('mode', mode);
    this.updateProperty('color', bgColor);
  }

  updateProperties(props: ComponentBaseUpdateParams): void {
    Object.keys(props)
      .forEach((key) => {
        const k = key as ComponentBaseUpdatePropertyKey;
        const properties = props[k];

        // @ts-ignore
        merge(this[key], properties);
      });
  }

  updateProperty(path: string, value: any): void {
    set(this, path, value);
  }

  getStructure(): CanvasType {
    return {
      name: this.name,
      size: this.size,
      color: this.color,
      mode: this.mode,
      scale: this.scale,
    };
  };
}
