import React, { createRef, RefObject } from 'react';
import ReactDOM from 'react-dom';
import styles from './program-preview.module.scss';
import { Program } from '../../model/program/Program';
import { IconType, SvgIcon } from '../icon';
import { ComponentBase } from '../../model/program/Component';
import Component from '../editor/component';
import TimerPreviewComponent from '../editor/canvas/timer/timer-preview';
import { TintColor } from '../../model/TintColor';
import { getTileScale } from '../../utils/helper';


type Props = {
  program: Program;
  onClose: () => void;
};

type State = {
  scale: number;
};

class ProgramPreview extends React.PureComponent<Props, State> {
  state = {
    scale: 1,
  };

  pageRef: RefObject<HTMLDivElement> = createRef();

  componentDidMount() {
    const { program } = this.props;
    const scale = getTileScale(this.pageRef.current, program.canvas.mode, program.canvas.size, 0.9);

    this.setState({ scale });
  }

  renderComponent = (component: ComponentBase) => {
    const { scale } = this.state;
    return (
      <Component editable={false} preview={true} scale={scale} key={component.id} component={component} />
    );
  };

  renderComponents = () => {
    const { components } = this.props.program;

    if (!components.length) {
      return null;
    }

    return components.map((component: ComponentBase) => this.renderComponent(component));
  };

  renderTimer = () => {
    const { timer } = this.props.program;
    if (!timer) {
      return null;
    }

    const { scale } = this.state;

    return (
      <TimerPreviewComponent scale={scale} interval={null} timer={timer}/>
    );
  };

  render() {
    const {
      program,
      onClose,
    } = this.props;

    const { scale } = this.state;

    return ReactDOM.createPortal(
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.name}>{program.canvas.name}</div>
          <div>
            <SvgIcon tintColor={TintColor.Transparent} type={IconType.PreviewClose} onClick={onClose}/>
          </div>
        </div>
        <div ref={this.pageRef} className={styles.content}>
            <div
              className={styles.canvas}
              style={{
                width: program.canvas.size.width,
                height: program.canvas.size.height,
                backgroundColor: program.canvas.color,
                transform: `scale(${scale})`,
                marginLeft: `-${program.canvas.size.width * (1 - scale) / 4}px`,
                marginTop: `-${program.canvas.size.height * (1 - scale) / 2}px`,
              }}
            >
              {this.renderComponents()}
              {program.timer && this.renderTimer()}
            </div>
          </div>
      </div>
    , window.document.body);
  }
}

export default ProgramPreview;
