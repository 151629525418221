import React, { useCallback, useRef, useState } from 'react';

import classNames from 'classnames';

import { Screen } from '../../../model/Screen';

import { IconType, SvgIcon } from '../../../components/icon';

import styles from './screen-tile.module.scss';
import MenuContainer from '../../../components/menu-container';
import { createNewComponent, ProgramType } from '../../../model/program/Program';
import { BlockType } from '../../../model/program/Block';
import Component from '../../../components/editor/component';
import { getTileScale } from '../../../utils/helper';
import TimerPreviewComponent from '../../../components/editor/canvas/timer/timer-preview';
import useScreenTimer from '../../../hooks/use-screen-timer';

type Props = {
  screen: Screen;
  masterScreen?: Screen;
  onChangeProgramRequested: () => void;
  onDeleteRequested: () => void;
  onEditRequested: () => void;
  onRestoreRequested: () => void;
  programs: ProgramType[];
  blocks: BlockType[];
  onDisconnectRequested: () => void;
  onConnectRequested: () => void;
}

const ScreenTile = (props: Props) => {
  const {
    screen,
    onChangeProgramRequested,
    onDeleteRequested,
    onEditRequested,
    onRestoreRequested,
    programs,
    blocks,
    masterScreen,
    onConnectRequested,
    onDisconnectRequested,
  } = props;

  const previewClassNames = classNames({
    [styles.preview]: true,
    [styles.landscape]: screen.orientation === 'landscape',
    [styles.portrait]: screen.orientation === 'portrait'
  });

  const renderButton = (isMenuVisible: boolean, setVisible: (visible: boolean) => void, toggleMenu: () => void) => {
    const menuButtonClassNames = classNames({
      [styles.menuButton]: true,
      [styles.active]: isMenuVisible
    });

    const menuButtonIconClassNames = classNames({
      [styles.menuButtonIcon]: true,
      [styles.active]: isMenuVisible
    });

    return (
      <div className={menuButtonClassNames} onClick={toggleMenu}>
        <SvgIcon className={menuButtonIconClassNames} type={IconType.Options}/>
      </div>
    );
  };

  const renderMenu = (isMenuVisible: boolean, setMenuVisible: (visible: boolean) => void) => {
    return (
      <div className={styles.menu}>
        {!masterScreen && <div className={styles.menuItem} onClick={() => {
          setMenuVisible(false);
          onChangeProgramRequested();
        }}>Change Program</div>}
        {masterScreen ? <div className={styles.menuItem} onClick={() => {
          setMenuVisible(false);
          onDisconnectRequested();
        }}>Disconnect master</div> : <div className={styles.menuItem} onClick={() => {
          setMenuVisible(false);
          onConnectRequested();
        }}>Connect master</div>}
        <div className={styles.menuItem} onClick={() => {
          setMenuVisible(false);
          onEditRequested();
        }}>Edit Screen Details</div>
        <div className={styles.menuItem} onClick={() => {
          setMenuVisible(false);
          onRestoreRequested();
        }}>Restore Screen</div>
        <div className={classNames([styles.menuItem, styles.critical])} onClick={() => {
          setMenuVisible(false);
          onDeleteRequested();
        }}>Delete Screen</div>
      </div>
    );
  };

  const statusClassNames = classNames({
    [styles.status]: true,
    [styles.active]: true
  });

  const controlsClassNames = classNames({
    [styles.controls]: true,
    [styles.inactive]: true // TODO
  });

  const screenRef = useRef<HTMLDivElement>(null);

  const program = programs.find(p => p.editor.id === screen.programId);

  const [
    interval,
    {
      isRunning,
      isFinished,
    },
    {
      play,
      pause,
      stop,
      prev,
      next,
    }
  ] = useScreenTimer(program && program.timer, screen.id);

  const renderPreview = () => {
    let component = (<div className={previewClassNames}/>);
    if (!programs || !programs.length || !screen.programId) {
      return component;
    }

    if (!program) {
      return component;
    }

    const scale = getTileScale(screenRef.current, program.canvas.mode, program.canvas.size);

    return (
      <div className={previewClassNames} ref={screenRef}>
        <div className={styles.scale} style={{ transform: `scale(${scale})` }}>
          <div
            style={{
              width: program.canvas.size.width,
              height: program.canvas.size.height,
              backgroundColor: program.canvas.color,
            }}
          >
            {program.components.map((c, index) => {
              const component = createNewComponent(c.type, c, blocks);

              if (!component) {
                return null;
              }

              return (
                <Component scale={scale} key={index} editable={false} component={component}/>
              );
            })}
            {program.timer && (
              <TimerPreviewComponent
                scale={scale}
                timer={program.timer}
                interval={interval}
                isFinished={isFinished}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.screenTile}>
      <div className={styles.topRow}>
        <div className={statusClassNames}>Active</div>
        <MenuContainer
          wrapperClassName={styles.menuWrapper}
          renderButton={renderButton}
          renderMenu={renderMenu}
        />
      </div>
      {renderPreview()}
      <div className={styles.bottomRow}>
        <div className={styles.title}>{screen.name}</div>
        <div className={styles.location}>{screen.location}</div>
        {masterScreen ? <div className={controlsClassNames}>
          Uses controls from {masterScreen.name}
        </div> : <div className={controlsClassNames}>
          {
            program && program.timer ? (
              <>
                <div className={styles.rightColumn}>
                  <SvgIcon className={styles.control} onClick={prev} type={IconType.TimerControlsBackward}/>
                  {isRunning ? (
                    <SvgIcon className={styles.control} onClick={pause} type={IconType.TimerControlsPause}/>
                  ) : (
                    <SvgIcon className={styles.control} onClick={play} type={IconType.TimerControlsPlay}/>
                  )}
                  <SvgIcon className={styles.control} onClick={next} type={IconType.TimerControlsForward}/>
                  <SvgIcon viewBox={'10 8 28 28'} onClick={stop} className={styles.control} type={IconType.Square}/>
                </div>
              </>
            ) : (
              <div className={styles.controlsPlaceholderText}>No Timer</div>
            )
          }
        </div>}

      </div>
    </div>
  );
};

export default ScreenTile;
