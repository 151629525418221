import { AccessLevel } from './AccessLevel';
import { Timestamp } from './Firebase';

export type User = {
  uid: string;
  dateCreated: Date;
  dateUpdated: Date;
  gymId?: string;
  isDeleted: boolean;
  appTourSeen?: boolean;
  designerTourSeen?: boolean;
} & UserCreateParams;

export type UserDTO = {
  dateCreated: Timestamp;
  dateUpdated: Timestamp;
} & UserCreateParams;

export type UserCreateParams = {
  email: string;
  roles: AccessLevel[];
  name: string;
  lastName?: string;
  invitedBy?: string;
  phone?: string;
  avatarUrl?: string;
  gymId?: string;
};

export const mapUserDtoToExercise = (data: User): User => {
  return {
    ...data,
    dateCreated: (data.dateCreated as unknown as Timestamp).toDate(),
    dateUpdated: (data.dateUpdated as unknown as Timestamp).toDate(),
  };
};
