import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import styles from './component-settings.module.scss';
import { ComponentBase, ComponentBaseUpdateParams } from '../../../../model/program/Component';
import Expandable from '../../expandable';
import Row from '../row';
import { ComponentType } from '../../../../model/program/Type';
import ShapeSettings from './shape';
import LabelSettings from './label';
import ImageSettings from './image';
import Select from '../../../select';
import { editorBlockActions } from '../../../../store/actions';
import { Button, ButtonKind } from '../../../button';
import { RootState } from '../../../../store/reducers';
import ValueWrapper from '../value-wrapper';
import ExpandableWithCheck from '../../expandable-with-check';
import { ComponentBlock } from '../../../../model/program/ComponentBlock';
import {
  getVariableValue,
  Variable,
  VariableOption,
  VariableType,
  VariableTypeOptions
} from '../../../../model/program/Variable';
import { Option } from '../../../../model/Option';
import { Exercise } from '../../../../model/Exercise';
import { ControlColor } from '../../../../model/ControlColor';
import { ControlSize } from '../../../../model/ControlSize';
import CommonSettings from './common-settings';
import ColorPicker from '../../../color-picker';
import { getProgramEditorPresentState } from '../../../../store/reducers/program';

type Props = {
  component: ComponentBase;
} & ReturnType<typeof mapActions>
  & ReturnType<typeof mapState>;

class ComponentSettings extends React.PureComponent<Props> {
  renderSpecificSettings = () => {
    const {
      component,
    } = this.props;

    switch (component.type) {
      case ComponentType.Square:
      case ComponentType.Circle: {
        return (
          <ShapeSettings component={component}/>
        );
      }
      case ComponentType.Label: {
        return (
          <LabelSettings component={component}/>
        );
      }
      case ComponentType.Image: {
        return (
          <ImageSettings component={component}/>
        );
      }
      default: {
        return null;
      }
    }
  };

  handleChangeOpacity = (value: number) => {
    this.props.updateOpacity(value / 100);
  };

  handleEditBlock = () => {
    this.props.toggleToBlockEdit();
  };

  handleChangeComponentAsVariable = (value: boolean) => {
    this.props.updatePropertyForSelected('variable.active', value);
  };

  handleUpdateValue = (path: string) => (value: string | number) => {
    this.props.updatePropertyForSelected(path, value);
  };

  handleChangeVariableType = (option: VariableType) => {
    this.props.updatePropertyForSelected('variable.type', option);
  };

  handleUpdateBlockInfo = (key: string) => (value: string | number) => {
    this.props.updateSelectedBlockValue(key, value);
  };

  handleChangeBlockColor = (value: string | number) => {
    this.props.updateSelectedBlockComponentValue('style.backgroundColor', value);
  };

  renderBlockSettings = () => {
    const {
      selectedComponentInsideBlock: sCIB,
      selectedBlock,
    } = this.props;
    return (
      <>
        {sCIB ?
          (
            <>
              <ExpandableWithCheck
                value={sCIB.variable.active}
                onChange={this.handleChangeComponentAsVariable}
              >
                <Row
                  title={'Type'}
                  column={true}
                  col1={<Select
                    size={ControlSize.ExtraSmall}
                    color={ControlColor.Dark}
                    options={VariableTypeOptions}
                    value={sCIB.variable.type}
                    onChange={this.handleChangeVariableType}
                  />}
                />
                <Row
                  title={'Name'}
                  column={true}
                  col1={<ValueWrapper
                    postfix={''}
                    onUpdate={this.handleUpdateValue('variable.name')}
                    value={sCIB.variable.name}
                  />
                  }
                />
                <Row
                  title={'Order'}
                  column={true}
                  col1={<ValueWrapper
                    postfix={''}
                    type={'number'}
                    onUpdate={this.handleUpdateValue('variable.order')}
                    value={sCIB.variable.order}
                  />
                  }
                />
              </ExpandableWithCheck>
              {this.renderCommonSettings()}
              {this.renderSpecificSettings()}
            </>
          ) : (
            selectedBlock &&
            <>
              <Expandable
                title={'Block Properties'}
                defaultOpened={true}
              >
                <Row
                  title={'Name'}
                  column={true}
                  col1={<ValueWrapper
                    postfix={''}
                    onUpdate={this.handleUpdateBlockInfo('name')}
                    value={selectedBlock.info?.name}
                  />
                  }
                />
                <Row
                  title={'Color'}
                  col1={
                    <ValueWrapper
                      value={selectedBlock.component.style.backgroundColor}
                      postfix={
                        <ColorPicker value={selectedBlock.component.style.backgroundColor} onChange={this.handleChangeBlockColor}/>
                      }
                      onUpdate={this.handleChangeBlockColor}
                    />
                  }
                />
              </Expandable>
            </>
          )
        }
      </>
    );
  };

  handleUpdateCommonSetting = (path: string, value: any) => {
    const { component } = this.props;

    const isWarmUpComponent = component.type === ComponentType.TimerWarmUp;

    if (isWarmUpComponent) {
      this.props.updateWarmUpProperty(path, value);
    } else {
      this.props.updatePropertyForSelected(path, value);
    }
  };

  renderCommonSettings = () => {
    return (
      <CommonSettings
        component={this.props.component}
        onChange={this.handleUpdateCommonSetting}
        onIncreaseZIndex={this.props.increaseZIndex}
        onDecreaseZIndex={this.props.decreaseZIndex}
        onRemoveComponent={this.props.removeComponent}
      />
    );
  };

  handleUpdateVariableValue = (variable: Variable) => (value: string | number) => {
    this.props.updatePropertyForSelected(`blockVariables.${variable.id}.value`, value);
  };

  handleChangeExercise = (variable: Variable, options: Option[]) => (value: string) => {
    this.props.updatePropertiesForSelected({
      blockVariables: {
        [variable.id]: {
          id: value,
          value: (options.find((item: Option) => item.value === value) as Option).label,
        },
      },
    });
  };

  renderBlockVariables = () => {
    const { component } = this.props;

    if (component.type !== ComponentType.Block) {
      return null;
    }

    const c = component as ComponentBlock;

    return (
      <Expandable
        title={'Variables'}
      >
        {c.getChildVariables().map(v => {
          switch (v.type) {
            case VariableType.LongText:
            case VariableType.Number:
            case VariableType.ShortText: {
              const type = v.type === VariableType.Number ? 'number' : 'text';
              const multi = v.type === VariableType.LongText;
              return (
                <Row
                  key={v.id}
                  title={v.name}
                  column={true}
                  col1={<ValueWrapper
                    type={type}
                    multi={multi}
                    value={getVariableValue(c.blockVariables[v.id], '') as string}
                    onUpdate={this.handleUpdateVariableValue(v)}
                  />}
                />
              );
            }
            case VariableType.Exercises: {
              const { exercises } = this.props;
              let option = c.blockVariables[v.id] as VariableOption;

              return (
                <Row
                  key={v.id}
                  title={v.name}
                  column={true}
                  col1={<Select
                    color={ControlColor.Dark}
                    size={ControlSize.ExtraSmall}
                    options={exercises}
                    value={(option && option.id as string) || undefined}
                    onChange={this.handleChangeExercise(v, exercises)}
                    search={true}
                  />}
                />
              )
            }
            default: {
              console.log('Variable component not implemented', v.type);
              return null;
            }
          }
        })}
      </Expandable>
    );
  };

  renderComponentSettings = () => {
    return (
      <>
        {this.renderBlockVariables()}
        {this.renderCommonSettings()}
        {this.renderSpecificSettings()}
      </>
    );
  };

  render() {
    const {
      isBlockSelected,
      isBlockEditorActive,
    } = this.props;

    return (
      <div className={styles.wrapper}>
        <div className={styles.main}>
          {isBlockEditorActive ?
            (
              this.renderBlockSettings()
            ) : (
              this.renderComponentSettings()
            )}
        </div>
        {isBlockSelected && !isBlockEditorActive &&
        <div className={styles.action}>
          <Button
            color={ControlColor.Dark}
            kind={ButtonKind.Secondary}
            size={ControlSize.ExtraSmall}
            title="Edit dynamic block"
            onClick={this.handleEditBlock}
          />
        </div>
        }
      </div>
    );
  }
}

const mapState = (state: RootState) => {
  const {
    isBlockSelected,
    isBlockEditorActive,
    selectedComponentInsideBlock,
    selectedBlock,
    exercises,
  } = getProgramEditorPresentState(state);

  return {
    isBlockSelected,
    isBlockEditorActive,
    selectedComponentInsideBlock,
    selectedBlock,
    exercises: exercises
      .map((e: Exercise) => ({ label: e.name, value: e.id }))
      .sort((a, b) => {
        return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
      }),
  };
};

const mapActions = (dispatch: Dispatch) => ({
  increaseZIndex: () => dispatch(editorBlockActions.increaseZIndexForSelected()),
  decreaseZIndex: () => dispatch(editorBlockActions.decreaseZIndexForSelected()),
  removeComponent: () => dispatch(editorBlockActions.removeSelectedComponent()),
  updateOpacity: (value: number) => dispatch(editorBlockActions.updatePropertyForSelected('style.opacity', value)),
  toggleToBlockEdit: () => dispatch(editorBlockActions.toggleEditorEditBlock(true)),
  updatePropertyForSelected: (path: string, value: any) => dispatch(editorBlockActions.updatePropertyForSelected(path, value)),
  updatePropertiesForSelected: (params: ComponentBaseUpdateParams) => dispatch(editorBlockActions.updatePropertiesForSelected(params)),
  updateSelectedBlockValue: (path: string, value: string | number) => dispatch(editorBlockActions.updateSelectedBlockInfo(path, value)),
  updateSelectedBlockComponentValue: (path: string, value: string | number) => dispatch(editorBlockActions.updateSelectedBlockComponentValue(path, value)),
  updateWarmUpProperty: (path: string, value: string | number) => dispatch(editorBlockActions.updateWarmUpProperty(path, value)),
});

export default connect(mapState, mapActions)(ComponentSettings);
