import { ComponentBase, createComponentId } from './Component';
import { ComponentType } from './Type';
import { Meta } from './Meta';
import { getSize, Size } from './Size';
import { PositionType } from './Position';
import { Style } from './Style';
import { Option } from '../Option';

export const ComponentImageSizeOptions: Option[] = [
  { label: 'Contain', value: 'contain' },
  { label: 'Cover', value: 'cover' },
];

export const ComponentImageRepeatOptions: Option[] = [
  { label: 'Repeat', value: 'repeat' },
  { label: 'No-repeat', value: 'no-repeat' },
  { label: 'Repeat-x', value: 'repeat-x' },
  { label: 'Repeat-y', value: 'repeat-y' },
];

type ImageStyle = {
  backgroundSize: string;
  backgroundRepeat: string;
} & Style;

type Params = {
  position: PositionType;
  id?: string;
  meta?: Meta;
  size?: Size;
  style?: ImageStyle;
  imageUrl?: string;
  parentId?: string;
};

const defaultMeta: Meta = {
  name: 'Unnamed Image',
};

const defaultSize = getSize(200, 100);

const defaultStyle: ImageStyle = {
  zIndex: 1,
  backgroundColor: '#AEAEC4',
  border: 'none',
  color: '#fff',
  backgroundSize: ComponentImageSizeOptions[0].value,
  backgroundRepeat: ComponentImageRepeatOptions[0].value,
  opacity: 1,

};

export class ComponentImage extends ComponentBase {
  constructor(
    {
      position,
      imageUrl,
      parentId,
      id = createComponentId(),
      meta = defaultMeta,
      size = defaultSize,
      style = defaultStyle,
    }: Params) {

    super({
      position,
      id,
      meta,
      size,
      style,
      parentId,
    });

    this.imageUrl = imageUrl;
    this.style = {
      ...defaultStyle,
      ...style,
    };
  }

  type: ComponentType = ComponentType.Image;
  imageUrl?: string;
  file?: File;
  style: ImageStyle;

  getStructure() {
    return {
      ...super.getStructure(),
      imageUrl: this.imageUrl,
    };
  }
}
