import React from 'react';
import styles from './table-action.module.scss';
import { ReactComponent as Icon } from './options.svg';
import { withClickOutside } from '../../../hoc/withClickOutside';
import { TableActionList, TableActionType } from '../table-action-list';

type Props = {
  actions?: TableActionType[];
};

type State = {
  showPopup: boolean;
};

class TableActionComponent extends React.Component<Props, State> {
  state = {
    showPopup: false,
  };

  handleClickOutside = () => {
    if (this.state.showPopup) {
      this.setState({ showPopup: false });
    }
  };

  handleShowPopup = () => {
    this.setState({ showPopup: true });
  };

  handleClickAnyAction = () => {
    this.setState(() => ({ showPopup: false }));
  };

  render() {
    const {
      showPopup,
    } = this.state;

    const {
      actions,
    } = this.props;

    return (
      <div className={styles.wrapper}>
        <div className={styles.actionBtn} onClick={this.handleShowPopup}>
          <Icon/>
        </div>
        {showPopup && <TableActionList
          onClick={this.handleClickAnyAction}
          actions={actions}
        />}
      </div>
    );
  }
}

export const TableAction = withClickOutside(TableActionComponent);
