import { Timestamp } from './Firebase';
import { Mode } from './program/Canvas';

export type GymPreferences = {
  fontFamily: string;
  fontColor: string;
  mode: Mode;
  bgColor: string;
};

export type Gym = {
  id: string;
  name: string;
  imageUrl?: string;
  addressLine?: string;
  addressLine2?: string;
  suburb?: string;
  state?: string;
  postCode?: string;
  dateCreated: Date;
  dateUpdated: Date;
  createdBy: string;
  preferences?: GymPreferences;
  deleted?: boolean;
};

export const getGymPreferences = (gym?: Gym): GymPreferences => {
  if (gym && gym.preferences) {
    return {
      bgColor: gym.preferences.bgColor,
      fontColor: gym.preferences.fontColor,
      fontFamily: gym.preferences.fontFamily,
      mode: gym.preferences.mode,
    };
  }

  return {
    bgColor: '#000000',
    fontColor: '#ffffff',
    fontFamily: 'Arial, Helvetica, sans-serif',
    mode: Mode.landscape,
  };
};

export const mapGymDtoToGym = (data: Gym): Gym => {
  return {
    ...data,
    dateCreated: (data.dateCreated as unknown as Timestamp).toDate(),
    dateUpdated: (data.dateUpdated as unknown as Timestamp).toDate(),
  };
};

