import React from 'react';
import styles from './page-content.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const PageContent = (props: Props) => {
  const {
    children,
    className = '',
  } = props;

  return (
    <div className={`${styles.content} ${className}`}>
      {children}
    </div>
  )
};

export default PageContent;
