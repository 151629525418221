import React, { ChangeEvent, KeyboardEvent, useRef, useState } from 'react';

import classNames from 'classnames';

import { ControlColor } from '../../model/ControlColor';
import { ControlSize } from '../../model/ControlSize';

import Label from '../label';

import styles from './tags-list.module.scss';

export type Tag = {
  label: string;
  value: string;
  gymId: string;
}

type Props = {
  color?: ControlColor;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  size?: ControlSize;
  tags: (Tag | string)[];
  value?: string[],
  onChange: (value: string[]) => void,
  onCustomTagAdded: (value: string) => Promise<string>
}

const TagsField = (props: Props) => {
  const {
    color = ControlColor.Default,
    disabled = false,
    label,
    placeholder,
    size = ControlSize.Normal,
    tags,
    value = [],
    onChange,
    onCustomTagAdded
  } = props;

  const inputRef = useRef(null as HTMLInputElement | null);

  const [customTag, setCustomTag] = useState('');
  const [isAddingTag, setAddingTag] = useState(false);

  const labelClassNames = classNames({
    [styles.label]: true,
    [styles.sizeNormal]: size === ControlSize.Normal,
    [styles.sizeSmall]: size === ControlSize.Small,
    [styles.sizeExtraSmall]: size === ControlSize.ExtraSmall
  });
  const selectedTagAreaClassNames = classNames({
    [styles.selectedTagArea]: true,
    [styles.colorDark]: color === ControlColor.Dark,
    [styles.colorDefault]: color === ControlColor.Default,
    [styles.sizeNormal]: size === ControlSize.Normal,
    [styles.sizeSmall]: size === ControlSize.Small,
    [styles.sizeExtraSmall]: size === ControlSize.ExtraSmall
  });
  const tagClassNames = classNames({
    [styles.tag]: true,
    [styles.disabled]: disabled,
    [styles.colorDark]: color === ControlColor.Dark,
    [styles.colorDefault]: color === ControlColor.Default,
    [styles.sizeNormal]: size === ControlSize.Normal,
    [styles.sizeSmall]: size === ControlSize.Small,
    [styles.sizeExtraSmall]: size === ControlSize.ExtraSmall
  });
  const placeholderClassNames = classNames({
    [styles.placeholder]: true,
    [styles.colorDark]: color === ControlColor.Dark,
    [styles.colorDefault]: color === ControlColor.Default,
    [styles.sizeNormal]: size === ControlSize.Normal,
    [styles.sizeSmall]: size === ControlSize.Small,
    [styles.sizeExtraSmall]: size === ControlSize.ExtraSmall
  });
  const availableTagAreaClassNames = classNames({
    [styles.availableTagArea]: true,
    [styles.sizeNormal]: size === ControlSize.Normal,
    [styles.sizeSmall]: size === ControlSize.Small,
    [styles.sizeExtraSmall]: size === ControlSize.ExtraSmall
  });
  const tagWrapperClassNames = classNames({
    [styles.tagWrapper]: true,
    [styles.sizeNormal]: size === ControlSize.Normal,
    [styles.sizeSmall]: size === ControlSize.Small,
    [styles.sizeExtraSmall]: size === ControlSize.ExtraSmall
  });
  const inputWrapperClassNames = classNames({
    [styles.inputWrapper]: true,
    [styles.sizeNormal]: size === ControlSize.Normal,
    [styles.sizeSmall]: size === ControlSize.Small,
    [styles.sizeExtraSmall]: size === ControlSize.ExtraSmall
  });
  const inputClassNames = classNames({
    [styles.input]: true,
    [styles.colorDark]: color === ControlColor.Dark,
    [styles.colorDefault]: color === ControlColor.Default,
    [styles.sizeNormal]: size === ControlSize.Normal,
    [styles.sizeSmall]: size === ControlSize.Small,
    [styles.sizeExtraSmall]: size === ControlSize.ExtraSmall
  });

  const availableTags = tags.filter(tag => !value.includes((typeof tag === 'string') ? tag : tag.value));

  const getTag = (tv: Tag | string) => {
    const tag = tags.find(t => (typeof t === 'string') ? (t === tv) : (t.value === tv));

    return ((typeof tag === 'string') ? {value: tag} : tag);
  };

  const selectTag = (tag: string) => () => {
    onChange(value.concat(tag));
  };

  const unselectTag = (tag: string) => () => {
    onChange(value.filter(t => t !== tag));
  };

  const selectCustomTag = async () => {
    if (!customTag) {
      return;
    }

    let tv: string = customTag;

    if (!getTag(customTag)) {
      setAddingTag(true);

      try {
        tv = await onCustomTagAdded(customTag);

        setAddingTag(false);
      } catch (err) {
        console.error(err);

        setAddingTag(false);

        return;
      }
    }

    selectTag(tv)();

    setCustomTag('');
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;

    setCustomTag(value);
  };

  const handleInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Enter':
        e.preventDefault();

        selectCustomTag();

        break;
      case 'Escape':
        setCustomTag('');

        if (inputRef.current) {
          (inputRef.current).blur();
        }

        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.component}>
      {
        (label !== undefined) && (
          <Label className={labelClassNames} color={color} size={size} text={label}/>
        )
      }
      <div className={styles.tagAreaContainer}>
        <div className={selectedTagAreaClassNames}>
          {
            (value.length > 0) ? value.map(tv => {
              const tag = getTag(tv) as Tag;

              if (!tag) {
                return null;
              }

              return (
                <div key={tag.value} className={tagWrapperClassNames}>
                  <div className={tagClassNames} onClick={disabled ? undefined : unselectTag(tag.value)}>
                    {tag.label || tag.value}
                  </div>
                </div>
              );
            }) : (
              Boolean(placeholder) && !customTag && (
                <div className={placeholderClassNames}>{placeholder}</div>
              )
            )
          }
          {
            onCustomTagAdded && !disabled && (
              <div className={inputWrapperClassNames}>
                <input
                  ref={inputRef}
                  className={inputClassNames}
                  disabled={isAddingTag}
                  value={customTag}
                  onBlur={selectCustomTag}
                  onChange={handleInputChange}
                  onKeyPress={handleInputKeyPress}
                />
              </div>
            )
          }
        </div>
        <div className={availableTagAreaClassNames}>
          {
            availableTags.map(tv => {
              const tag = (typeof tv === 'string') ? getTag(tv) as Tag : tv;

              return (
                <div key={tag.value} className={tagWrapperClassNames}>
                  <div className={tagClassNames} onClick={disabled ? undefined : selectTag(tag.value)}>
                    {tag.label || tag.value}
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>
    </div>
  );
};

export default TagsField;
