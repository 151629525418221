import { ComponentBase, createComponentId } from './Component';
import { ComponentType } from './Type';
import { PositionType } from './Position';
import { Meta } from './Meta';
import { getSize, Size } from './Size';
import { Style } from './Style';

type Params = {
  position: PositionType;
  type: ComponentType.Square | ComponentType.Circle;
  id?: string;
  meta?: Meta;
  size?: Size;
  style?: Style;
  parentId?: string;
};

const defaultMeta: Meta = {
  name: 'Unnamed Shape',
};

const defaultStyle: Style = {
  zIndex: 1,
  backgroundColor: '#AEAEC4',
  border: 'none',
  color: '#ffffff',
  opacity: 1,
};

const defaultSize = getSize(50, 50);

export class ComponentShape extends ComponentBase {
  constructor(
    {
      position,
      type,
      parentId,
      id = createComponentId(),
      meta = defaultMeta,
      size = defaultSize,
      style = defaultStyle,
      ...rest
    }: Params) {
    super({
      position,
      id,
      meta,
      size,
      style,
      parentId,
      ...rest
    });

    this.type = type;
  }

  type: ComponentType;
}
