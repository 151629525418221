import React from 'react';
import { Rnd } from 'react-rnd';
import styles from '../timer.module.scss';
import { Timer } from '../../../../../model/Timer';
import { Interval, IntervalType } from '../../../../../hooks/use-screen-timer';
import { parseStringFromTime } from '../../../../../utils/helper';

type Props = {
  interval: Interval | null;
  timer: Timer;
  scale?: number;
  isFinished?: boolean;
  staticTimer?: boolean;
}

const TimerPreviewComponent = (props: Props) => {
  const { timer, scale, interval, isFinished, staticTimer = true } = props;

  const style = {
    zIndex: timer.style.zIndex,
  };

  return (
    <>
      <Rnd
        bounds={'parent'}
        enableResizing={undefined}
        disableDragging={true}
        position={{
          ...timer.position,
        }}
        size={{
          ...timer.size,
        }}
        style={style}
        scale={scale}
      >
        {staticTimer ? (
          <div style={timer.style} className={styles.wrapper}>
            <div className={styles.time}>
              00:00
            </div>
            <div>
              <div className={styles.interval}>Warm Up</div>
              <div
                className={styles.interval}>1/1</div>
            </div>
          </div>
        ) : (
          <div style={timer.style} className={styles.wrapper}>
            <div className={styles.time}>
              {((interval && parseStringFromTime(interval.time)) || '00:00')}
            </div>
            {isFinished ? (
              <div>
                <div className={styles.interval}>Finished</div>
              </div>
            ) : (
              <div>
                <div className={styles.interval}>{interval && interval.name}</div>
                <div
                  className={styles.interval}>{(interval && interval.setNumber) || 1}/{(interval && interval.numberOfSets) || 1}</div>
              </div>
            )}
          </div>
        )}
      </Rnd>
      {timer.showWarmUpComponent && interval && interval.type === IntervalType.WarmUp &&
      <Rnd
        bounds={'parent'}
        enableResizing={undefined}
        disableDragging={true}
        position={{
          ...timer.warmUpComponent.position,
        }}
        size={{
          ...timer.warmUpComponent.size,
        }}
        style={style}
        scale={scale}
      >
        <pre className={styles.warmUp} style={timer.warmUpComponent.style}>
          {timer.warmUpComponent.text}
        </pre>
      </Rnd>
      }
    </>
  );
};

export default TimerPreviewComponent;
