import React from 'react';
import styles from './app-logo.module.scss';
import logoUrl from './tc-pro-logo.png';

const AppLogo = () => {
  return (
    <div className={styles.wrapper}>
      <img className={styles.img} alt={'App Logo'} src={logoUrl}/>
    </div>
  );
};

export default AppLogo;
