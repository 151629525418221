import React, { useMemo, useState } from 'react';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import PageWrapper from '../../../components/page-wrapper';
import { createNewComponent, mapProgramDtoToProgram, Program, ProgramType } from '../../../model/program/Program';
import PageTitle from '../../../components/page-title';
import styles from './list.module.scss';
import InputField from '../../../components/input-field';
import { Button } from '../../../components/button';
import { Table } from '../../../components/table';
import { TableAction } from '../../../components/table/table-action';
import useFirebaseEntity from '../../../hooks/use-firebase-entity';
import { capitalizeFirstLetter, getTotalTimeFromTimer } from '../../../utils/helper';
import { TagList } from '../../../components/tag-list';
import { ControlSize } from '../../../model/ControlSize';
import { IconType } from '../../../components/icon';
import PageContent from '../../../components/page-content';
import { formatDate } from '../../../utils/formatter';
import useGymQuery from '../../../hooks/use-gym-query';
import { TableActionKind, TableActionType } from '../../../components/table/table-action-list';
import { RootState } from '../../../store/reducers';
import { User } from '../../../model/User';
import PlayModal from './play-modal';
import { Tag } from '../../../components/tags-field';
import { mapTagDtoToTag } from '../../../model/Tag';
import ProgramPreview from '../../../components/editor-preview';
import { BlockType, mapBlockDtoToBlock } from '../../../model/program/Block';
import FolderLink from '../../../components/folder-link';
import useOnlyRoles from '../../../hooks/use-only-roles';
import { AccessLevel } from '../../../model/AccessLevel';
import { Gym } from '../../../model/Gym';
import { API } from '../../../api';
import isArray from 'lodash/isArray';
import { Link } from 'react-router-dom';

const HEADER = [
  { title: 'Name' },
  { title: 'Duration' },
  { title: 'Focus' },
  { title: 'Tags' },
  { title: 'Created' },
  { title: 'Updated' },
  { title: 'Last Played' },
  { className: styles.tableAction }
];

type ExtendedProgram = Program & {
  tags: string[];
};

const ProgramListPage = () => {
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.auth.user) as User;
  const isAdmin = useOnlyRoles<boolean>([AccessLevel.admin], true, false);
  const gym = useSelector((state: RootState) => state.gym.gym) as Gym;
  const [search, setSearch] = useState('');
  const query = useGymQuery('editor.gymId');
  const [items, { hasFetched }, { remove, copy }] = useFirebaseEntity(
    'programs',
    [query], mapProgramDtoToProgram,
    (program: ProgramType): ProgramType => {
      return {
        ...program,
        editor: {
          ...program.editor,
          dateCreated: new Date(),
          dateUpdated: new Date(),
          updatedBy: user.uid,
          createdBy: user.uid,
        },
        canvas: {
          ...program.canvas,
          name: `${program.canvas.name} (Copy)`
        },
      };
    }
  ) as [Program[], any, any];

  const copyProgramToTemplate = async (id: string) => {
    try {
      await API.program.copyProgramToTemplates(id, user, gym);
      alert('The program has been copied!');
    } catch (e) {
      window.alert(e.message || 'Error has been occurred.');
    }
  };

  const blocksQuery = useGymQuery('info.gymId');

  const [
    blocks,
  ] = useFirebaseEntity('blocks', [blocksQuery], mapBlockDtoToBlock) as [BlockType[], any];

  const [showPreview, setShowPreview] = useState(false);

  const [selectedProgram, setSelectedProgram] = useState();

  const tagsQuery = useGymQuery();
  const [tags] = useFirebaseEntity('programTags', [tagsQuery], mapTagDtoToTag) as [Tag[]];

  const mappedItems = useMemo(() => {
    let data: ExtendedProgram[] = items.map(item => {
      return {
        ...item,
        tags: tags.filter(t => item.editor.tags?.includes(t.value) || false).map(t => t.label),
      } as ExtendedProgram;
    })
    return data;
  }, [tags, items]);

  const filteredItems = useMemo(() => {
    let items = mappedItems;
    if (search) {
      const s = (search || '').toString();
      items = mappedItems.filter(item => (item.canvas.name || '').toLowerCase().includes(s) || item.tags.filter(str => str.toLowerCase().includes(s)).length);
    }

    return items.sort((a, b) =>
      ((b.editor.dateUpdated?.valueOf() || 0) || (b.editor.dateCreated?.valueOf() || 0)) -
      ((a.editor.dateUpdated?.valueOf() || 0) || (a.editor.dateCreated?.valueOf() || 0)));
  }, [mappedItems, search]);

  const [changeProgramScreenId, setChangeProgramScreenId] = useState(null as string | null);

  const handleAddProgram = () => dispatch(push('/programs/editor'));
  const handleClosePreview = () => {
    setSelectedProgram(undefined);
    setShowPreview(false);
  };

  const handleShowPreview = (data: Program) => {
    data.components = data.components.map(c => createNewComponent(c.type, c, blocks));

    setSelectedProgram(data);
    setShowPreview(true);
  };

  const rowActionsHandler = (data: Program): TableActionType[] => {
    return [
      {
        title: 'Preview',
        onClick: () => {
          handleShowPreview(data);
        }
      },
      {
        title: 'Edit',
        onClick: () => dispatch(push(`/programs/editor/${data.editor.id}`)),
      },
      {
        title: 'Copy',
        onClick: () => {
          if (window.confirm('Are you sure you want to copy program?')) {
            copy(data.editor.id);
          }
        },
      },
      {
        title: 'Play',
        onClick: () => setChangeProgramScreenId(data.id as string)
      },
      {
        title: 'Delete',
        onClick: () => {
          if (window.confirm('Are you sure you want to delete program?')) {
            remove(data.editor.id);
          }
        },
        kind: TableActionKind.Warning,
      },
      isAdmin ? {
        title: 'Copy to templates',
        onClick: () => {
          if (window.confirm('Are you sure you want to copy the program to templates?')) {
            copyProgramToTemplate(data.editor.id as string);
          }
        },
      } : null,
    ].filter(p => !!p) as TableActionType[];
  };

  const closePlayModal = () => setChangeProgramScreenId(null);

  const body = [
    {
      key: (row: Program) => {
        return (
          <div className={styles.link} onClick={() => handleShowPreview(row)}>{row.canvas.name}</div>
        )
      }
    },
    { key: (row: Program) => getTotalTimeFromTimer(row.timer) },
    { key: (row: Program) => row.editor.focus ? isArray(row.editor.focus) ? <TagList className={styles.tagList} tags={row.editor.focus}/> : capitalizeFirstLetter(row.editor.focus) : null},
    {
      key: (row: ExtendedProgram) => <TagList className={styles.tagList} tags={row.tags}/>,
    },
    {
      key: (row: Program) => formatDate(row.editor.dateCreated as Date),
    },
    {
      key: (row: Program) => formatDate(row.editor.dateUpdated as Date),
    },
    {
      key: (row: Program) => row.lastPlayed ? <Link to={{
        pathname: '/programs/history',
        state: {
          program: row,
        },
      }}>
        {formatDate(row.lastPlayed as number)}
      </Link> : '',
    },
    {
      key: (data: Program) => {
        return (
          <TableAction actions={rowActionsHandler(data)}/>
        );
      },
      className: styles.tableAction,
    },
  ];

  return (
    <PageWrapper>
      <PageTitle title={'Programs'}>
        <Button
          size={ControlSize.Small}
          title="Add Program"
          onClick={handleAddProgram}
        />
      </PageTitle>
      <FolderLink className={styles.folder} title={'Templates'} link={'/programs/templates'}/>
      <PageContent className={styles.content}>
        <div className={styles.actions}>
          <div>
            <InputField
              icon={IconType.Search}
              className={styles.search}
              placeholder="Search programs..."
              size={ControlSize.Small}
              value={search}
              onChange={setSearch}
            />
          </div>
        </div>
        <Table header={HEADER} body={body} data={filteredItems} isLoading={!hasFetched}/>
      </PageContent>
      {
        changeProgramScreenId && (
          <PlayModal programId={changeProgramScreenId} onCloseRequested={closePlayModal}/>
        )
      }
      {showPreview && <ProgramPreview program={selectedProgram} onClose={handleClosePreview}/>}
    </PageWrapper>
  );
};

export default ProgramListPage;
