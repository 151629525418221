import React from 'react';
import {
  ComponentImage,
  ComponentImageRepeatOptions,
  ComponentImageSizeOptions
} from '../../../../../model/program/ComponentImage';
import withUpdateProperty, { WithUpdatePropertyProps } from '../withUpdateProperty';
import Expandable from '../../../expandable';
import styles from './image.module.scss';
import UploadButton from './button-upload';
import Row from '../../row';
import Select from '../../../../select';
import ComponentValueWrapper from '../component-value-wrapper';
import ColorPicker from '../../../../color-picker';
import throttle from 'lodash/throttle';
import { ControlSize } from '../../../../../model/ControlSize';
import { ControlColor } from '../../../../../model/ControlColor';

type Props = {
  component: ComponentImage;
} & WithUpdatePropertyProps;

class ImageSettings extends React.PureComponent<Props> {
  handleChangeOption = (key: string) => (option: any) => {
    this.props.updateProperty(key, option);
  };

  handleChangeColor = throttle((value: string) => {
    this.props.updateProperty('style.backgroundColor', value);
  }, 300);

  render() {
    const {
      component,
    } = this.props;

    return (
      <>
        <Expandable id={'editor__component-settings__image'} defaultOpened={true} title={'Image'}>
          <div className={styles.row}>
            <UploadButton/>
          </div>
          <Row
            title={'Color'}
            col1={
              <ComponentValueWrapper
                value={component.style.backgroundColor}
                path={'style.backgroundColor'}
                postfix={<ColorPicker value={component.style.backgroundColor} onChange={this.handleChangeColor}/>}
              />
            }
          />
          <Row
            title={'Size'}
            col1={
              <Select
                options={ComponentImageSizeOptions}
                value={component.style.backgroundSize}
                onChange={this.handleChangeOption('style.backgroundSize')}
                size={ControlSize.ExtraSmall}
                color={ControlColor.Dark}
              />
            }
          />
          <Row
            title={'Repeat'}
            col1={
              <Select
                options={ComponentImageRepeatOptions}
                value={component.style.backgroundRepeat}
                onChange={this.handleChangeOption('style.backgroundRepeat')}
                size={ControlSize.ExtraSmall}
                color={ControlColor.Dark}
              />
            }
          />
        </Expandable>
      </>
    );
  }
}

export default withUpdateProperty(ImageSettings);
