import { Gym } from '../../../model/Gym';
import { Action, createReducer } from '../../utils';
import { AuthActionType, GymActionType } from '../../action-types';

export type GymState = {
  gym: Gym | null;
  loading: boolean;
  error?: Error;
};

const InitialGymState: GymState = {
  gym: null,
  loading: false,
};

const fetchStart = (state: GymState): GymState => {
  return {
    ...state,
    loading: true,
  };
};

const fetchCompleted = (state: GymState, action: Action): GymState => {
  return {
    ...state,
    loading: false,
    gym: action.payload,
  };
};

const fetchError = (state: GymState, action: Action): GymState => {
  return {
    ...state,
    loading: false,
    gym: null,
    error: action.payload,
  };
};

const updateGym = (state: GymState, action: Action): GymState => {
  return {
    ...state,
    loading: false,
    gym: {
      ...state.gym,
      ...action.payload,
    },
  };
};

const setActiveGym = (state: GymState, action: Action): GymState => {
  return {
    ...state,
    gym: action.payload,
  };
};

const logout = (): GymState => {
  return {
    ...InitialGymState,
  };
};

export default createReducer(InitialGymState, {
  [GymActionType.FETCH_GYM_START]: fetchStart,
  [GymActionType.FETCH_GYM_SUCCESS]: fetchCompleted,
  [GymActionType.FETCH_GYM_ERROR]: fetchError,
  [GymActionType.UPDATE_GYM_SUCCESS]: updateGym,
  [GymActionType.CHANGE_ACTIVE_GYM]: setActiveGym,
  [AuthActionType.LOGOUT]: logout,
});
