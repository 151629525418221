import React from 'react';
import styles from './blocks.module.scss';
import { RootState } from '../../../../store/reducers';
import { connect } from 'react-redux';
import { IconType, SvgIcon } from '../../../icon';
import List from './list';
import { TintColor } from '../../../../model/TintColor';
import { getProgramEditorPresentState } from '../../../../store/reducers/program';

type Props = {} & ReturnType<typeof mapState>;

type State = {
  showList: boolean;
};

class BlocksListItem extends React.PureComponent<Props, State> {
  state = {
    showList: false,
  };

  handleClose = () => {
    this.setState({ showList: false });
  };

  handleShow = () => {
    this.setState({ showList: true });
  };

  render() {
    const {
      showList,
    } = this.state;

    const {
      blocks,
    } = this.props;

    return (
      <div className={styles.wrapper}>
        <SvgIcon className={styles.icon} tintColor={TintColor.Purple} type={IconType.Blocks} onClick={this.handleShow}/>
        {showList && <List list={blocks} onClose={this.handleClose}/>}
      </div>
    );
  }
}

const mapState = (state: RootState) => ({
  blocks: getProgramEditorPresentState(state).blocks,
});

export default connect(mapState)(BlocksListItem);
