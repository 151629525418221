import { Mode } from './program/Canvas';

export type ScreenSaveParams = {
  name: string;
  location: string;
  orientation: Mode;
  gymId: string;
};

export enum OperationType {
  Play = 'play',
  Pause = 'pause',
  Stop = 'stop',
  Prev = 'prev',
  Next = 'next',
}

export type Screen = {
  id: string;
  programId?: string;
  restoredScreenId?: string;
  isPlaying?: boolean;
  type?: OperationType;
  masterScreenId?: string;
} & ScreenSaveParams;
