import React, { DragEvent, useCallback } from 'react';
import styles from './timer.module.scss';
import { IconType, SvgIcon } from '../../../icon';
import { TintColor } from '../../../../model/TintColor';
import { ComponentType } from '../../../../model/program/Type';

const noop = () => {};

type Props = {
  className?: string;
}

const Timer = (props: Props) => {
  const { className = '' } = props;
  const handleDragStart = useCallback((e: DragEvent) => {
    e.stopPropagation();

    e.dataTransfer.setData('type', ComponentType.Timer);
  }, []);

  return (
    <div
      id="editor__component-timer"
      draggable={true}
      onDragStart={handleDragStart}
      className={className}
    >
      <SvgIcon
        className={styles.icon}
        type={IconType.Timer}
        tintColor={TintColor.Orange}
        onClick={noop}
      />
    </div>
  );
};

export default Timer;
