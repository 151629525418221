import axios from 'axios';
import firebase from 'firebase/app';
import { User, UserCreateParams } from '../../model/User';

const DEFAULT_AXIOS_OPTIONS = {
  baseURL: 'https://us-central1-tcpro-31053.cloudfunctions.net/express',
  timeout: 15 * 1000
};

const axiosInstance = axios.create(DEFAULT_AXIOS_OPTIONS);
const db = firebase.firestore();

const usersCollection = 'users';

export const createUserFromInvite = async (data: UserCreateParams, uid: string): Promise<User> => {
  try {
    if (!data) {
      throw new Error('Data not provided');
    }

    if (!uid) {
      throw new Error('Not user Id provided');
    }

    const toSave = {
      ...data,
      isDeleted: false,
      dateCreated: new Date(),
      dateUpdated: new Date(),
    };

    await db.collection(usersCollection)
      .doc(uid)
      .set(toSave, { merge: true });

    return {
      ...toSave,
      uid,
    };
  } catch (e) {
    console.log('Error create user', e);
    throw e;
  }
};

export const createUser = async (email: string, password: string, user: UserCreateParams): Promise<User> => {
  try {
    const response = await firebase.auth().createUserWithEmailAndPassword(email, password);

    if (!response.user) {
      throw new Error('Error while create user');
    }

    const uid = response.user.uid;

    const toSave = {
      ...user,
      isDeleted: false,
      dateCreated: new Date(),
      dateUpdated: new Date(),
    };

    await db.collection(usersCollection)
      .doc(uid)
      .set(toSave);

    return {
      ...toSave,
      uid,
    };
  } catch (e) {
    throw e;
  }
};

export const updateUser = async (userId: string, params: Partial<User>): Promise<void> => {
  try {
    if (!userId) {
      throw new Error('User Id not provided');
    }

    if (!params) {
      throw new Error('Params not provided');
    }

    const toSave = {
      ...params,
      dateUpdated: new Date(),
    };

    await db.collection(usersCollection)
      .doc(userId)
      .set(toSave, { merge: true });
  } catch (e) {
    throw e;
  }
};

export const removeUser = async (userId: string): Promise<void> => {
  try {
    const {currentUser} = firebase.auth();

    if (!currentUser) {
      throw new Error('currentUser is null');
    }

    const token = await currentUser.getIdToken(true);

    return axiosInstance.request({
      url: `/users/${userId}`,
      method: 'delete',
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  } catch (e) {
    throw e;
  }
};

export const checkIfEmailAlreadyExist = async (email: string) => {
  const res = await db.collection(usersCollection)
    .where('email', '==', email)
    .get();

  if (!res.empty) {
    throw new Error('Current email already exist.');
  }
}
