import React from 'react';
import { Button } from '../../components/button';
import { connect } from 'react-redux';
import { Invite, mapInviteStatusToLabel } from '../../model/Invite';
import { API } from '../../api';
import withNavigation, { WithNavigation } from '../../hoc/withNavigation';
import { RootState } from '../../store/reducers';
import { User } from '../../model/User';
import { mapAccessLevelToLabel } from '../../model/AccessLevel';
import { displayDate } from '../../model/Date';

type Props = {
  navigate: (url: string) => void;
  user: User;
} & WithNavigation;

type State = {
  invites: Invite[];
};

class InvitesPage extends React.PureComponent<Props, State> {
  state = {
    invites: [],
  };

  componentDidMount = async () => {
    const {
      user,
    } = this.props;

    try {
      const invites = await API.invite.getInvites(user.uid);
      this.setState({ invites });
    } catch (e) {
      this.setState({ invites: [] });
    }
  };

  handleNewInvite = () => {
    const {
      navigate,
    } = this.props;

    navigate('/invites/new');
  };

  render() {
    const {
      invites,
    } = this.state;

    return (
      <div>
        Invites list
        <Button
          title="New Invite"
          onClick={this.handleNewInvite}
        />
        <table>
          <thead>
          <tr>
            <th>Name</th>
            <th>Access Level</th>
            <th>Status</th>
            <th>Date Created</th>
            <th>Date Updated</th>
          </tr>
          </thead>
          <tbody>
          {invites.map((invite: Invite) => {
            return (
              <tr key={invite.id}>
                <td>{invite.email}</td>
                <td>{mapAccessLevelToLabel(invite.accessLevel)}</td>
                <td>{mapInviteStatusToLabel(invite.status)}</td>
                <td>{displayDate(invite.dateCreated)}</td>
                <td>{displayDate(invite.dateUpdated)}</td>
              </tr>
            )
          })}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapState = (state: RootState) => ({
  user: state.auth.user as User,
});


export default connect(mapState)(withNavigation(InvitesPage));
