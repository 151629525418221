import firebase from 'firebase/app';
import { BlockType, mapBlockDtoToBlock } from '../../model/program/Block';
import { Timestamp } from '../../model/Firebase';
import { User } from '../../model/User';
import isEqual from 'lodash/isEqual';
import { Gym } from '../../model/Gym';
import { ComponentBlock } from '../../model/program/ComponentBlock';

const collectionBlocks = 'blocks';
const collectionBlockTemplates = 'blockTemplates';

const db = firebase.firestore();

const _updateOrInsert = async (data: BlockType, collection: string): Promise<BlockType> => {
  const id = data.info.id;

  let ref;

  delete data.info.id;

  if (id) {
    ref = db.collection(collection)
      .doc(id);

    data.component.blockId = ref.id;

    await ref.set(data, { merge: true });
  } else {
    ref = db.collection(collection)
      .doc();

    data.component.blockId = ref.id;

    await ref.set(data, { merge: true });
  }

  return {
    ...data,
    info: {
      ...data.info,
      id: ref.id,
    },
  };
};

export const updateOrInsert = async (data: BlockType): Promise<BlockType> => {
  return await _updateOrInsert(data, collectionBlocks);
};

export const updateOrInsertTemplate = async (data: BlockType): Promise<BlockType> => {
  return await _updateOrInsert(data, collectionBlockTemplates);
};

export const getList = async (userId: string): Promise<BlockType[]> => {
  const response = await db.collection(collectionBlocks)
    .where('info.gymId', '==', userId)
    .get();

  return response.docs.map(d => {
    const data = d.data() as BlockType;
    return {
      ...data,
      info: {
        ...data.info,
        id: d.id,
        dateCreated: (data.info.dateCreated as unknown as Timestamp).toDate(),
        dateUpdated: (data.info.dateUpdated as unknown as Timestamp).toDate(),
      }
    };
  });
};

export const getTemplateList = async (): Promise<BlockType[]> => {
  const response = await db.collection(collectionBlockTemplates)
    .get();


  return response.docs.map(d => ({
    ...d.data(),
    id: d.id,
  })).map(mapBlockDtoToBlock);
}

const _getBlock = async (id: string, collection: string): Promise<BlockType> => {
  if (!id) {
    throw new Error('Id not provided');
  }

  const response = await db.collection(collection)
    .doc(id)
    .get();

  const data = response.data() as BlockType;

  if (!data) {
    throw new Error('Block not found');
  }

  return {
    ...data,
    info: {
      ...data.info,
      id: response.id,
      dateCreated: (data.info.dateCreated as unknown as Timestamp).toDate(),
      dateUpdated: (data.info.dateUpdated as unknown as Timestamp).toDate(),
    },
  };
}

export const getBlock = async (id: string): Promise<BlockType> => {
  return await _getBlock(id, collectionBlocks);
};

export const getTemplateBlock = async (id: string): Promise<BlockType> => {
  return await _getBlock(id, collectionBlockTemplates);
};

export const copyBlockFromTemplate = async (id: string, user: User, gym: Gym) => {
  if (!id) {
    throw new Error('Id not provided');
  }

  if (!user) {
    throw new Error('User not provided');
  }

  return await db.runTransaction<string>(async (transaction) => {
    const ref = db.collection(collectionBlockTemplates).doc(id);
    const response = await transaction.get(ref);
    const data = response.data() as BlockType;

    if (response.exists && data) {
      delete data.info.id;
      delete data.component.blockId;

      const newRef = db.collection(collectionBlocks).doc();
      data.component.blockId = newRef.id;
      data.info.name = `${data.info.name} (Copied from templates)`;
      data.info.updatedBy = user.uid;
      data.info.dateUpdated = new Date();
      data.info.gymId = gym.id;
      data.info._copiedFromTemplate = true;
      data.info._copiedFromId = id;
      await transaction.set(newRef, data);
      return newRef.id;
    }
    throw new Error(`Data doesn't exist`);
  });
};

export const copyBlockFromTemplateIfNeeded = async (component: ComponentBlock, user: User, gym: Gym): Promise<string> => {
  if (!component) {
    throw new Error('Component not provided');
  }

  if (!user) {
    throw new Error('User not provided');
  }

  return await db.runTransaction(async (transaction): Promise<string> => {
    const blockTemplateRef = db.collection(collectionBlockTemplates).doc(component.blockId);

    const response = await transaction.get(blockTemplateRef);
    const blockTemplateData = response.data() as BlockType;

    if (response.exists && blockTemplateData) {
      // Try to find current block in already created blocks
      const existsBlocks = await db.collection(collectionBlocks)
        .where('info._copiedFromTemplate', '==', true)
        .where('info._copiedFromId', '==', blockTemplateRef.id)
        .where('info.gymId', '==', gym.id)
        .get();

      if (existsBlocks.empty) {
        return await copyBlockFromTemplate(blockTemplateRef.id, user, gym);
      } else {
        // If exist try to check components
        const firstComponent = existsBlocks.docs[0].data() as BlockType;
        // If equal return current link
        if (isEqual(firstComponent.component.components, blockTemplateData.component.components)) {
          return existsBlocks.docs[0].id;
        } else {
          // If not create new copy
          return await copyBlockFromTemplate(blockTemplateRef.id, user, gym);
        }
      }
    }

    throw new Error(`Data doesn't exist`);
  });
}

export const copyBlockToTemplateIfNeeded = async (component: ComponentBlock, user: User, gym: Gym): Promise<string> => {
  if (!component) {
    throw new Error('Component not provided');
  }

  if (!user) {
    throw new Error('User not provided');
  }

  return await db.runTransaction(async (transaction): Promise<string> => {
    const blockTemplateRef = db.collection(collectionBlocks).doc(component.blockId);

    const response = await transaction.get(blockTemplateRef);
    const blockTemplateData = response.data() as BlockType;

    if (response.exists && blockTemplateData) {
      // Try to find current block in already created blocks
      const existsBlocks = await db.collection(collectionBlockTemplates)
        .where('info._copiedFromId', '==', blockTemplateRef.id)
        .where('info.gymId', '==', gym.id)
        .get();

      if (existsBlocks.empty) {
        return await copyBlockToTemplate(blockTemplateRef.id, user, gym);
      } else {
        // If exist try to check components
        const firstComponent = existsBlocks.docs[0].data() as BlockType;
        // If equal return current link
        if (isEqual(firstComponent.component.components, blockTemplateData.component.components)) {
          return existsBlocks.docs[0].id;
        } else {
          // If not create new copy
          return await copyBlockToTemplate(blockTemplateRef.id, user, gym);
        }
      }
    }

    throw new Error(`Data doesn't exist`);
  });
}

export const copyBlockToTemplate = async (id: string, user: User, gym: Gym): Promise<string> => {
  if (!id) {
    throw new Error('Id not provided');
  }

  if (!user) {
    throw new Error('User not provided');
  }

  return await db.runTransaction<string>(async (transaction) => {
    const ref = db.collection(collectionBlocks).doc(id);
    const response = await transaction.get(ref);
    const data = response.data() as BlockType;

    if (response.exists && data) {
      delete data.info.id;
      delete data.component.blockId;

      const newRef = db.collection(collectionBlockTemplates).doc();
      data.component.blockId = newRef.id;
      data.info.name = `${data.info.name} (Copied from gym)`;
      data.info.updatedBy = user.uid;
      data.info.dateUpdated = new Date();
      data.info.gymId = gym.id;
      data.info._copiedFromId = id;
      await transaction.set(newRef, data);
      return newRef.id;
    }
    throw new Error(`Data doesn't exist`);
  });
}
