import { Action, createReducer } from '../../utils';
import { User } from '../../../model/User';
import { AuthActionType, UserActionType } from '../../action-types';

export type AuthState = {
  loading: boolean;
  user: User | null;
  error: string | null;
};

const InitialState: AuthState = {
  loading: false,
  user: null,
  error: null,
};

const loginRequest = (state: AuthState): AuthState => {
  return {
    ...state,
    loading: true,
    error: null,
  };
};

const loginSuccess = (state: AuthState, action: Action<User>): AuthState => {
  return {
    ...state,
    loading: false,
    error: null,
    user: action.payload as User,
  };
};

const loginError = (state: AuthState, action: Action<string>): AuthState => {
  return {
    ...state,
    loading: false,
    error: action.payload as string,
    user: null,
  };
};

const logout = (state: AuthState): AuthState => {
  return {
    ...state,
    user: null,
    loading: false,
  };
};

const updateUser = (state: AuthState, action: Action): AuthState => {
  return {
    ...state,
    user: {
      ...state.user as User,
      ...action.payload,
    },
  };
};

export default createReducer(InitialState, {
  [AuthActionType.REQUEST]: loginRequest,
  [AuthActionType.SUCCESS]: loginSuccess,
  [AuthActionType.ERROR]: loginError,
  [AuthActionType.LOGOUT]: logout,
  [UserActionType.UPDATE_USER_SUCCESS]: updateUser,
});
