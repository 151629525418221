import React from 'react';
import styles from './form-wrapper.module.scss';
import AppLogo from './app-logo';

type Props = {
  title: string;
  children: React.ReactNode;
  footerText: React.ReactNode;
  footerLink: React.ReactNode;
};

class FormWrapper extends React.PureComponent<Props> {
  render() {
    const {
      title,
      children,
      footerText,
      footerLink,
    } = this.props;

    return (
      <div className={styles.wrapper}>
        <AppLogo/>
        <div className={styles.title}>
          {title}
        </div>
        <div className={styles.content}>
          {children}
        </div>
        <div className={styles.footer}>
          {footerText}
          {footerLink}
        </div>
      </div>
    );
  }
}

export default FormWrapper;
