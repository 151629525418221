import firebase from 'firebase/app';
import { User } from '../../model/User';

const collectionUsers = 'users';

const userNotFoundError = 'User not found';

const auth = firebase.auth();

export const onUserChanged = (cb: any) => {
  firebase.auth().onAuthStateChanged(response => {
    cb(response && response.uid);
  });
};

export const login = async (email: string, password: string): Promise<User> => {
  try {
    const { user: authUser } = await auth.signInWithEmailAndPassword(email, password);

    if (!authUser || !authUser.uid) {
      throw new Error(userNotFoundError);
    }

    const db = firebase.firestore();

    const response = await db.collection(collectionUsers)
      .doc(authUser.uid)
      .get();

    const data = response.data();

    if (!data) {
       throw new Error(userNotFoundError);
    }

    return {
      ...data,
      uid: response.id,
    } as User;
  } catch (e) {
    console.log('Error login', e);
    throw e;
  }
};

export const logout = async (): Promise<void> => {
  try {
    await auth.signOut();
  } catch (e) {
    console.log('Error logout', e);
    throw e;
  }
};

export const registerUser = async (email: string, password: string): Promise<string> => {
  try {
    if (!email) {
      throw new Error('Email not provided');
    }

    if (!password) {
      throw new Error('Password not provided');
    }

    const response = await auth.createUserWithEmailAndPassword(email, password);

    return (response.user as firebase.User).uid;
  } catch (e) {
    console.log('Error register user', e);
    throw e;
  }
};

export const resetPassword = async (email: string) => {
  try {
    await auth.sendPasswordResetEmail(email);
  } catch (e) {
    console.log('Error while reset password', e);
    throw e;
  }
};
