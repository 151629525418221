import firebase from 'firebase/app';
import Compressor from 'compressorjs';

const storage = firebase.storage();

export const compressImage = (image: File, options = {}): Promise<Blob> => {
  try {
    if (!image) {
      throw new Error('File not provided');
    }

    if (!(image instanceof File)) {
      throw new Error('Image must be an instance of File');
    }

    return new Promise((resolve, reject) => {
      new Compressor(image, {
        quality: 0.8,
        ...options,
        success(file) {
          return resolve(file);
        },
        error(error) {
          return reject(error);
        },
      });
    });
  } catch (e) {
    throw e;
  }
};

export const uploadImage = async (image: File, prefix?: string): Promise<string> => {
  try {
    if (!(image instanceof File)) {
      throw new Error('Image must be an instance of File');
    }

    const compressed = await compressImage(image);
    const name = `${prefix ? prefix + '/' : ''}${Date.now()}-${image.name}`;

    const response = await storage
      .ref()
      .child(name)
      .put(compressed);

    return await response.ref.getDownloadURL();
  } catch (e) {
    console.log('Error', e);
    throw e;
  }
};

export const removeImage = async (url: string): Promise<void> => {
  try {
    const ref = storage
      .refFromURL(url);

    await ref.delete();
  } catch (e) {
    throw e;
  }
};
