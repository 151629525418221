import { MeasureUnit } from '../Unit';
import { numberToFixedValue } from '../../utils/formatter';

export type Size = {
  width: number;
  height: number;
  unit: MeasureUnit;
};

export const getSize = (width: number, height: number, unit: MeasureUnit = 'px'): Size => {
  return {
    width: numberToFixedValue(width),
    height: numberToFixedValue(height),
    unit,
  };
};
