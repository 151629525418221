import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import * as firebase from 'firebase/app';

import { BlockType, mapBlockDtoToBlock } from '../../model/program/Block';
import { createNewComponent, mapProgramDtoToProgram, Program } from '../../model/program/Program';
import { Screen } from '../../model/Screen';

import { generateScreenId, getTileScale } from '../../utils/helper';

import { storage, StorageKeys } from '../../api/localstorage';

import useFirebaseEntity from '../../hooks/use-firebase-entity';

import Component from '../../components/editor/component';
import { IconType, SvgIcon } from '../../components/icon';

import styles from './client.module.scss';
import TimerPreviewComponent from '../../components/editor/canvas/timer/timer-preview';
import useScreenTimer from '../../hooks/use-screen-timer';

const ClientPage = () => {
  const [screenId, setScreenId] = useState(storage.getItem(StorageKeys.screenId));
  const [scale, setScale] = useState(1);
  const [fullScreen, setFullScreen] = useState(false);

  const pageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (screenId) {
      return;
    }

    const id = generateScreenId();

    storage.setItem(StorageKeys.screenId, id);

    setScreenId(id);
  }, [screenId]);

  const screensQuery = [[firebase.firestore.FieldPath.documentId(), screenId || '-']];

  let [
    [screen],
    {hasFetched: hasFetchedScreens}
  ] = useFirebaseEntity('screens', screensQuery) as [Screen[], any];

  const masterQuery = [[firebase.firestore.FieldPath.documentId(), screen?.masterScreenId || '-']];

  const [
    [masterScreen],
  ] = useFirebaseEntity('screens', masterQuery) as [Screen[], any];

  if (masterScreen) {
    screen = masterScreen;
  }

  const restoredScreensQuery = [['restoredScreenId', screenId || '-']];

  const [[restoredScreen]] = useFirebaseEntity('screens', restoredScreensQuery) as [Screen[]];

  useEffect(() => {
    if (!restoredScreen) {
      return;
    }

    storage.setItem(StorageKeys.screenId, restoredScreen.id);

    setScreenId(restoredScreen.id);
  }, [restoredScreen]);

  const programsQuery = [[firebase.firestore.FieldPath.documentId(), (screen && screen.programId) || '-']];

  const [
    [program],
    {hasFetched: hasFetchedPrograms}
  ] = useFirebaseEntity('programs', programsQuery, mapProgramDtoToProgram) as [Program[], any];

  useEffect(() => {
    if (!pageRef.current || !program) {
      return;
    }

    const scale = getTileScale(pageRef.current, program.canvas.mode, program.canvas.size);

    setScale(scale);
  }, [pageRef.current, program, fullScreen]);


  const [
    interval,
    {
      isFinished,
    },
    {
      stop,
    }
  ] = useScreenTimer(program && program.timer, screen && screen.id, () => {
    stop();
  }, true);

  const blocksQuery = [['info.gymId', screen ? screen.gymId : null]];

  const [
    blocks,
    {hasFetched: hasFetchedBlocks}
  ] = useFirebaseEntity('blocks', blocksQuery, mapBlockDtoToBlock) as [BlockType[], any];

  const pageClassNames = classNames({
    [styles.page]: true,
    [styles.placeholder]: !screen || !hasFetchedPrograms || !hasFetchedBlocks
  });

  const contentClassNames = classNames({
    [styles.content]: true,
    [styles.placeholder]: !screen || !hasFetchedPrograms || !hasFetchedBlocks
  });

  const toggleFullScreen = async () => {
    if (!document.fullscreenElement) {
      await document.documentElement.requestFullscreen();
      setFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        await document.exitFullscreen();
        setFullScreen(false);
      }
    }
  };

  if (!screenId || !hasFetchedScreens || !hasFetchedPrograms || !hasFetchedBlocks) {
    return (
      <div ref={pageRef} className={pageClassNames}>
        <div className={contentClassNames}>
          <SvgIcon className={styles.screenIcon} type={IconType.Screen}/>
          <div className={styles.screenId}>Loading...</div>
        </div>
      </div>
    );
  }

  if (!screen) {
    return (
      <div ref={pageRef} className={pageClassNames}>
        <div className={contentClassNames}>
          <SvgIcon className={styles.screenIcon} type={IconType.Screen}/>
          <div className={styles.screenId}>
            {
              screenId
                .split('')
                .map((symbol: string, index: number) => (
                  <div key={index} className={styles.screenIdItem}>{symbol}</div>
                ))
            }
          </div>
        </div>
      </div>
    );
  }

  return (
    <div ref={pageRef} className={pageClassNames}>
      {
        program && (
          <div style={{transform: `scale(${scale})`}}>
            <div
              style={{
                width: program.canvas.size.width,
                height: program.canvas.size.height,
                backgroundColor: program.canvas.color,
              }}
            >
              {
                program.components.map((c, index) => {
                  const component = createNewComponent(c.type, c, blocks);

                  if (!component) {
                    return null;
                  }

                  return (
                    <Component key={index} component={component} editable={false} scale={scale} preview={true}/>
                  );
                })
              }
              {program.timer && <TimerPreviewComponent staticTimer={false} isFinished={isFinished} interval={interval} timer={program.timer} scale={scale}/>}
            </div>
          </div>
        )
      }
      <div className={styles.fullScreenBtn} onClick={toggleFullScreen} title={'Toggle full screen'}/>
    </div>
  );
};

export default ClientPage;
