import { all, takeEvery, select, call, takeLatest, put } from "redux-saga/effects";
import { GymActionType } from '../../action-types';
import { Action } from '../../utils';
import { RootState } from '../../reducers';
import { getGymPreferences, Gym } from '../../../model/Gym';
import { API } from '../../../api';
import { editorBlockActions, gymActions } from '../../actions';
import { User } from '../../../model/User';

function* fetchGym() {
  yield takeLatest(GymActionType.FETCH_GYM, function* () {
    try {
      yield put(gymActions.fetchGymStart());

      const user = (yield select(state => state.auth.user)) as User;
      const gymId = user.gymId;

      if (!gymId) {
        throw new Error('Gym Id not provided');
      }

      const gym = (yield call(API.gyms.getGym, gymId)) as Gym;
      yield put(editorBlockActions.updatePreferences(getGymPreferences(gym)));
      yield put(gymActions.fetchGymSuccess(gym));
    } catch (e) {
      yield put(gymActions.fetchGymError(e));
    }
  });
}

function* uploadLogo() {
  yield takeEvery(GymActionType.UPLOAD_LOGO, function* ({ payload }: Action) {
    const { file, resolve } = payload;

    try {
      const gym = (yield select((state: RootState) => state.gym.gym)) as Gym;
      const oldLogo = gym && gym.imageUrl;

      if (oldLogo) {
        yield call(API.storage.removeImage, oldLogo);
      }

      let newUrl = '';

      if (file) {
        newUrl = yield call(API.storage.uploadImage, file, 'logo');
      }

      yield put(gymActions.updateGym({ imageUrl: newUrl }));
      resolve && resolve(null, gym);
    } catch (e) {
      resolve && resolve(e);
    }
  });
}

function* updateGym() {
  yield takeEvery(GymActionType.UPDATE_GYM, function* ({ payload }: Action) {
    const { gym: data, resolve } = payload;

    let gym = (yield select((state: RootState) => state.gym.gym)) as Gym;
    yield call(API.gyms.updateGym, gym.id, data);

    if (data.preferences) {
      yield put(editorBlockActions.updatePreferences(data.preferences));
    }

    yield put(gymActions.updateGymSuccess(data));
    resolve && resolve();
  });
}

export default function* () {
  yield all([
    fetchGym(),
    updateGym(),
    uploadLogo(),
  ]);
}
