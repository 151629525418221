const isDevelopment = process.env.NODE_ENV === 'development';

const getBaseUrl = () => {
  return isDevelopment ? 'http://localhost:3000' : 'https://app.tcprohome.com';
}

export {
  isDevelopment,
  getBaseUrl,
}
