import styles from './helpers.module.scss';
import React from 'react';

export const ResizeBox = () => (
  <div className={styles.box}/>
);

export const getResizingObj = (value: boolean) => ({
  bottom: value,
  right: value,
  top: value,
  left: value,
  bottomRight: value,
  bottomLeft: value,
  topRight: value,
  topLeft: value,
});

export const GRID: [number, number] = [5, 5];
