import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { RootState } from '../../store/reducers';

interface Props extends RouteProps {
  component: any;
  isLoggedIn: boolean;
}

class PrivateRoute extends React.Component<Props> {
  render() {
    const {
      component: Wrapper,
      isLoggedIn,
      ...rest
    } = this.props;

    return (
      <Route
        {...rest}
        render={(props) => {
          return (
            isLoggedIn ? <Wrapper {...props} />
              : <Redirect to={{
                pathname: '/login',
                state: {
                  from: props.location
                }
              }}/>
          );
        }}
      />
    );
  }
}

const mapState = (state: RootState) => {
  return {
    isLoggedIn: !!state.auth.user,
  };
};

export default connect(mapState)(PrivateRoute);
