import { takeLatest, call, put } from 'redux-saga/effects';
import { API } from '../../../api';
import { AuthActionType } from '../../action-types';
import { authActions } from '../../actions';
import { push } from 'connected-react-router';

type AuthorizePayload = {
  email: string;
  password: string;
}

type AuthorizeAction = {
  payload: AuthorizePayload;
}

function* authorize({ payload: { email, password } }: AuthorizeAction) {
  try {
    const user = yield call(API.auth.login, email, password);

    yield put(authActions.loggedIn(user));
    yield put(push('/screens'));
  } catch (error) {
    yield put(authActions.error(error.message));
  }
}

export default function* () {
  yield takeLatest(AuthActionType.REQUEST as any, authorize);
}
