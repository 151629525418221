import isObject from 'lodash/isObject';
import { Option } from '../Option';
import { uniqueId } from '../../utils/helper';

export enum VariableType {
  Exercises = 'Exercises',
  ShortText = 'ShortText',
  LongText = 'LongText',
  Number = 'Number',
}

const variableTypeMap = {
  [VariableType.Exercises]: 'Exercises',
  [VariableType.ShortText]: 'Short Text',
  [VariableType.LongText]: 'Long Text',
  [VariableType.Number]: 'Number',
};

export const VariableTypeOptions: Option[] = [
  { label: variableTypeMap[VariableType.ShortText], value: VariableType.ShortText},
  { label: variableTypeMap[VariableType.Exercises], value: VariableType.Exercises},
  { label: variableTypeMap[VariableType.LongText], value: VariableType.LongText},
  { label: variableTypeMap[VariableType.Number], value: VariableType.Number},
];

export type VariableOption = {
  id: string;
  value: string;
};

export type Variable = {
  active: boolean;
  type: VariableType;
  name: string;
  id: string;
  order: number;
}

export type VariableValue = {
  value: string | number | VariableOption;
};

export type BlockVariables = {
  [key: string]: VariableValue;
};

export const getDefaultVariable = (): Variable => {
  return {
    active: false,
    type: VariableType.ShortText,
    name: 'Field Name',
    id: uniqueId('Var'),
    order: 1,
  };
};

export const mergeVariable = (variable: Variable): Variable => {
  return {
    ...getDefaultVariable(),
    ...variable,
  };
};

export const getVariableValue = (variableValue?: VariableValue, defaultValue?: string) => {
  if (!variableValue || !variableValue.value) {
    return defaultValue === undefined ? '*' : defaultValue;
  }

  return variableValue.value;
};

export const isVariableValueIsOption = (value: VariableValue): boolean => {
  if (!value) return false;
  if (isObject(value) && (value as Option).label) return true;
  return false;
};
