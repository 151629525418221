import React from 'react';
import classNames from 'classnames';

import { TintColor } from '../../model/TintColor';

import { ReactComponent as Square } from './svg/editor-icon-tool-square-default.svg';
import { ReactComponent as Ellipse } from './svg/editor-icon-tool-ellipse-default.svg';
import { ReactComponent as Text } from './svg/editor-icon-tool-text-default.svg';
import { ReactComponent as Blocks } from './svg/editor-icon-tool-block-default.svg';
import { ReactComponent as Timer } from './svg/editor-icon-tool-timer-default.svg';
import { ReactComponent as PlusSign } from './svg/editor-icon-canvas-zoom-up-default.svg';
import { ReactComponent as MinusSign } from './svg/editor-icon-canvas-zoom-down-default.svg';
import { ReactComponent as Trash } from './svg/editor-icon-delete-element.svg';
import { ReactComponent as ZIndexUp } from './svg/editor-icon-layer-green-up-default.svg';
import { ReactComponent as ZIndexDown } from './svg/editor-icon-layer-green-down-default.svg';
import { ReactComponent as Image } from './svg/editor-icon-tool-image-default.svg';
import { ReactComponent as Search } from './svg/icon-search.svg';
import { ReactComponent as PreviewClose } from './svg/editor-icon-preview-close.svg';
import { ReactComponent as DownArrowLargeDefault } from './svg/icon-down-arrow-large-default.svg';
import { ReactComponent as ScreenAdd } from './svg/illustration-screen-add.svg';
import { ReactComponent as Screen } from './svg/illustration-screen-large.svg';
import { ReactComponent as Options } from './svg/options.svg';
import { ReactComponent as TimerControlsBackward } from './svg/icon-timer-controls-backward-default.svg';
import { ReactComponent as TimerControlsForward } from './svg/icon-timer-controls-forward-default.svg';
import { ReactComponent as TimerControlsPause } from './svg/icon-timer-controls-pause-default.svg';
import { ReactComponent as TimerControlsPlay } from './svg/icon-timer-controls-play-default.svg';
import { ReactComponent as Back } from './svg/icon-back.svg';
import { ReactComponent as Cancel } from './svg/icon-cancel-large.svg';
import { ReactComponent as LoadingSpinner } from './svg/icon-loading-spinner.svg';
import { ReactComponent as Undo } from './svg/editor-undo.svg';
import { ReactComponent as Redo } from './svg/editor-redo.svg';

import styles from './icon.module.scss';

export enum IconType {
  Ellipse = 'ellipse',
  Square = 'square',
  Text = 'text',
  PlusSign = 'plusSign',
  MinusSign = 'minusSign',
  Trash = 'trash',
  ZIndexUp = 'zIndexUp',
  ZIndexDown = 'zIndexDown',
  Image = 'image',
  Search = 'search',
  PreviewClose = 'previewClose',
  Blocks = 'blocks',
  DownArrow = 'downArrowLarge',
  Screen = 'screen',
  ScreenAdd = 'screenAdd',
  Options = 'options',
  Timer = 'timer',
  TimerControlsBackward = 'timerControlsBackward',
  TimerControlsForward = 'timerControlsForward',
  TimerControlsPause = 'timerControlsPause',
  TimerControlsPlay = 'timerControlsPlay',
  Back = 'back',
  Cancel = 'cancel',
  LoadingSpinner = 'loadingSpinner',
  Undo = 'undo',
  Redo = 'redo',
}

const Icons = {
  [IconType.Ellipse]: Ellipse,
  [IconType.Square]: Square,
  [IconType.Text]: Text,
  [IconType.PlusSign]: PlusSign,
  [IconType.MinusSign]: MinusSign,
  [IconType.Trash]: Trash,
  [IconType.ZIndexUp]: ZIndexUp,
  [IconType.ZIndexDown]: ZIndexDown,
  [IconType.Image]: Image,
  [IconType.Search]: Search,
  [IconType.PreviewClose]: PreviewClose,
  [IconType.Blocks]: Blocks,
  [IconType.DownArrow]: DownArrowLargeDefault,
  [IconType.Screen]: Screen,
  [IconType.ScreenAdd]: ScreenAdd,
  [IconType.Options]: Options,
  [IconType.Timer]: Timer,
  [IconType.TimerControlsBackward]: TimerControlsBackward,
  [IconType.TimerControlsForward]: TimerControlsForward,
  [IconType.TimerControlsPause]: TimerControlsPause,
  [IconType.TimerControlsPlay]: TimerControlsPlay,
  [IconType.Back]: Back,
  [IconType.Cancel]: Cancel,
  [IconType.LoadingSpinner]: LoadingSpinner,
  [IconType.Undo]: Undo,
  [IconType.Redo]: Redo,
};

type Props = {
  active?: boolean;
  className?: string;
  style?: object;
  tintColor?: TintColor;
  type: IconType;
  onClick?: () => void;
  viewBox?: string;
};

export const SvgIcon = (props: Props) => {
  const {
    active,
    className,
    style,
    tintColor = TintColor.Green,
    type,
    onClick,
    ...rest
  } = props;

  const Icon = Icons[type];

  const componentClassNames = classNames({
    [styles.component]: true,
    [styles.clickable]: onClick,
    [className as any]: className
  });

  const iconClassNames = classNames({
    [styles.icon]: true,
    [styles.active]: active,
    [styles.clickable]: onClick,
    [styles.tintGreen]: tintColor === TintColor.Green,
    [styles.tintOrange]: tintColor === TintColor.Orange,
    [styles.tintPurple]: tintColor === TintColor.Purple
  });

  return (
    <div className={componentClassNames} style={style} onClick={onClick}>
      <Icon className={iconClassNames} {...rest}/>
    </div>
  );
};
