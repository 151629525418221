import { Action, createReducer } from '../../utils';
import { AbstractComponent, ComponentBase } from '../../../model/program/Component';
import { EditorBlockActionType } from '../../action-types';
import { Canvas, getDefaultCanvas } from '../../../model/program/Canvas';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import { createNewComponent, getDefaultProgramInfo, Program } from '../../../model/program/Program';
import { BlocksBuilder, ComponentBlock } from '../../../model/program/ComponentBlock';
import { Block, BlockType, updateBlockInfoValue } from '../../../model/program/Block';
import { ComponentType } from '../../../model/program/Type';
import { Exercise } from '../../../model/Exercise';
import { getGymPreferences, GymPreferences } from '../../../model/Gym';
import { Timer } from '../../../model/Timer';
import { RootState } from '../index';
import { StateWithHistory } from 'redux-undo';
import { getPosition } from '../../../model/program/Position';

export type ProgramBlockState = {
  selectedComponent: AbstractComponent | null;
  selectedComponentInsideBlock: ComponentBase | null;
  selectedComponents: BlocksBuilder; // Block builder
  saving: boolean;
  loading: boolean;
  notSavedVersion: Program;
  multiMode: boolean; // Mode for selecting components to block
  blocks: BlockType[]; // List of available Blocks
  isBlockSelected: boolean; // Component with Type.Block selected
  isBlockEditorActive: boolean; // Component with Type.Block selected & button Edit dynamic block active
  selectedBlock: BlockType | null; // Selected block. Founded from BlockList for selected component with Type.Block
  preview: boolean;
  exercises: Exercise[];
  isBlockEditorPage: boolean; // If we edit block without Program
  preferences: GymPreferences;
} & Program;

export const getProgramEditorPresentState = (state: RootState | StateWithHistory<ProgramBlockState>): ProgramBlockState => {
  return (state as RootState).editorBlock ? (state as RootState).editorBlock.present : (state as StateWithHistory<ProgramBlockState>).present;
};

const getDefaultState = (state?: ProgramBlockState): ProgramBlockState => {
  const editor = getDefaultProgramInfo();
  const canvas = getDefaultCanvas();
  const components: ComponentBase[] = [];
  return {
    editor,
    canvas,
    components: [],
    selectedComponent: null,
    selectedComponentInsideBlock: null,
    selectedComponents: new BlocksBuilder(),
    saving: false,
    loading: false,
    notSavedVersion: {
      canvas,
      editor,
      components,
    },
    multiMode: false,
    blocks: [],
    isBlockSelected: false,
    isBlockEditorActive: false,
    selectedBlock: null,
    preview: false,
    exercises: [],
    isBlockEditorPage: false,
    preferences: state ? state.preferences : getGymPreferences(),
  };
};

const InitialState: ProgramBlockState = getDefaultState();

const addToCanvas = (state: ProgramBlockState, action: Action<ComponentBase>): ProgramBlockState => {
  if (state.isBlockEditorActive) {
    return addToBlock(state, action);
  }

  const comp = action.payload as ComponentBase;
  return {
    ...state,
    components: [
      ...state.components,
      comp,
    ],
    selectedComponent: comp,
    isBlockSelected: comp.type === ComponentType.Block,
  };
};

const addToBlock = (state: ProgramBlockState, action: Action<ComponentBase>): ProgramBlockState => {
  const selectedComponent = state.selectedComponent as ComponentBlock;

  const item = action.payload as ComponentBase;

  const blockPosition = selectedComponent.position;
  const itemPosition = item.position;

  const newPosition = getPosition(Math.abs(blockPosition.x - itemPosition.x), Math.abs(blockPosition.y - itemPosition.y));

  const insideComponent = createNewComponent(item.type, {
    ...action.payload as ComponentBase,
    position: newPosition,
    parentId: selectedComponent.id,
  });

  const newBlock = createNewComponent(selectedComponent.type, {
    ...selectedComponent,
    components: [
      ...selectedComponent.components,
      insideComponent,
    ],
  });

  const components = state.components;
  const index = components.findIndex(c => c.id === selectedComponent.id);
  if (index !== -1) {
    components.splice(index, 1, newBlock);
  }

  return {
    ...state,
    components: components,
    selectedComponentInsideBlock: insideComponent,
  };
};

const selectComponent = (state: ProgramBlockState, action: Action<ComponentBase>): ProgramBlockState => {
  const newState = {
    ...state,
  } as ProgramBlockState;

  const component = action.payload as ComponentBase;

  if (state.multiMode) {
    newState.selectedComponents.insertOrRemove(component);
    newState.selectedComponents = newState.selectedComponents.clone();
  } else {
    if (state.isBlockEditorActive) {
      newState.selectedComponentInsideBlock = component;
    } else {
      newState.selectedComponent = component;
      newState.isBlockSelected = component.type === ComponentType.Block;

      if (newState.isBlockSelected) {
        const block = newState.blocks.find(b => b.info.id === (component as ComponentBlock).blockId);

        if (block) {
          newState.selectedBlock = block;
        }
      }
    }
  }

  return newState;
};

const selectTimer = (state: ProgramBlockState, action: Action) => {
  return {
    ...state,
    selectedComponent: action.payload,
  };
};

const deselectComponent = (state: ProgramBlockState): ProgramBlockState => {
  state.selectedComponents.clear();

  if (state.isBlockEditorActive) {
    return {
      ...state,
      selectedComponentInsideBlock: null,
    };
  }

  return {
    ...state,
    selectedComponent: null,
    selectedComponents: state.selectedComponents.clone(),
    isBlockSelected: false,
    selectedComponentInsideBlock: null,
  };
};

const updateProperties = (state: ProgramBlockState, action: Action): ProgramBlockState => {
  if (state.isBlockEditorActive) {
    return updatePropertiesInsideBlock(state, action);
  }
  const selected = state.selectedComponent as ComponentBase;
  const components = [...state.components];
  const payload = action.payload;
  const properties = payload;

  if (!selected) {
    return state;
  }

  const clone = selected.clone();
  clone.updateProperties(properties);

  const i = components.findIndex(c => c.id === selected.id);

  components.splice(i, 1, clone);

  return {
    ...state,
    components,
    selectedComponent: clone,
  };
};

const updateProperty = (state: ProgramBlockState, action: Action): ProgramBlockState => {
  if (state.isBlockEditorActive) {
    return updatePropertyInsideBlock(state, action);
  }

  const selected = state.selectedComponent as ComponentBase;
  const components = [...state.components];

  const { path, value } = action.payload;

  if (!selected) {
    return state;
  }

  const clone = selected.clone();
  clone.updateProperty(path, value);

  const i = components.findIndex(c => c.id === selected.id);

  components.splice(i, 1, clone);

  return {
    ...state,
    components,
    selectedComponent: clone,
  };
};

const createStateAfterUpdateInsideBlock = (state: ProgramBlockState, selectedBlockComponent: ComponentBlock, componentInsideBlock: ComponentBase, blockClone: ComponentBlock, componentClone: ComponentBase): ProgramBlockState => {
  const filtered = selectedBlockComponent.components.filter(c => c.id !== componentInsideBlock.id);

  blockClone.components = [
    ...filtered,
    componentClone,
  ];

  return {
    ...state,
    components: [
      ...state.components.filter(c => c.id !== selectedBlockComponent.id),
      blockClone,
    ],
    selectedComponent: blockClone,
    selectedComponentInsideBlock: componentClone,
    selectedBlock: {
      ...state.selectedBlock as BlockType,
      component: blockClone,
    }
  };
};

const updatePropertiesInsideBlock = (state: ProgramBlockState, action: Action): ProgramBlockState => {
  const selectedBlockComponent = state.selectedComponent as ComponentBlock;
  const componentInsideBlock = state.selectedComponentInsideBlock as ComponentBase;

  const payload = action.payload;
  const properties = payload;

  const blockClone = selectedBlockComponent.clone();
  const componentClone = componentInsideBlock.clone();

  // Update components in block
  componentClone.updateProperties(properties);

  return createStateAfterUpdateInsideBlock(state, selectedBlockComponent, componentInsideBlock, blockClone, componentClone);
};

const updateSelectedBlockInfo = (state: ProgramBlockState, action: Action): ProgramBlockState => {
  const { path, value } = action.payload;

  const selectedBlock = state.selectedBlock as Block;

  const newBlock = updateBlockInfoValue(selectedBlock, path, value);

  return {
    ...state,
    blocks: [
      ...state.blocks.filter(b => b.info.id !== selectedBlock.info.id),
      newBlock,
    ],
    selectedBlock: newBlock,
  };
};

const updateEditorSelectedBlockInfoComponentValue = (state: ProgramBlockState, action: Action): ProgramBlockState => {
  const { path, value } = action.payload;

  const selectedComponent = state.selectedComponent as ComponentBase;
  const newComponent = selectedComponent.clone();

  newComponent.updateProperty(path, value);

  const components = state.components;
  const indexC = components.findIndex(c => c.id === selectedComponent.id);
  if (indexC !== -1) {
    components.splice(indexC, 1, newComponent);
  }

  const selectedBlock = state.selectedBlock as Block;
  const newBlock = { info: selectedBlock.info, component: newComponent } as Block;

  const blocks = state.blocks;
  const index = blocks.findIndex(b => b.info.id !== selectedBlock.info.id);
  if (index !== -1) {
    blocks.splice(index, 1, newBlock);
  }

  return {
    ...state,
    components: components,
    selectedComponent: newComponent,
    blocks: blocks,
    selectedBlock: newBlock,
  };
};

const updatePropertyInsideBlock = (state: ProgramBlockState, action: Action): ProgramBlockState => {
  const selectedBlockComponent = state.selectedComponent as ComponentBlock;
  const componentInsideBlock = state.selectedComponentInsideBlock as ComponentBase;

  const { path, value } = action.payload;

  const blockClone = selectedBlockComponent.clone();
  const componentClone = componentInsideBlock.clone();

  componentClone.updateProperty(path, value);

  return createStateAfterUpdateInsideBlock(state, selectedBlockComponent, componentInsideBlock, blockClone, componentClone);
};

const removeSelectedComponent = (state: ProgramBlockState): ProgramBlockState => {
  if (state.isBlockEditorActive) {
    const selectedComponent = state.selectedComponent as ComponentBlock;
    const selectedComponentInsideBlock = state.selectedComponentInsideBlock as ComponentBase;

    const newComp = createNewComponent(selectedComponent.type, {
      ...selectedComponent,
      components: selectedComponent.components.filter(c => c.id !== selectedComponentInsideBlock.id),
    } as ComponentBlock);

    return {
      ...state,
      components: [
        ...state.components.filter(c => c.id !== selectedComponent.id),
        newComp,
      ],
      selectedComponent: newComp,
      selectedComponentInsideBlock: null,
    };
  }

  const { selectedComponent, components } = state;

  const filtered = components.filter(c => c.id !== (selectedComponent as ComponentBase).id);

  return {
    ...state,
    selectedComponent: null,
    components: filtered,
  };
};

const updateCanvasProperty = (state: ProgramBlockState, action: Action): ProgramBlockState => {
  const { path, value } = action.payload;

  const newCanvas = new Canvas(state.canvas.getStructure());

  set(newCanvas, path, value);

  return {
    ...state,
    canvas: newCanvas,
  };
};

const updateCanvasProperties = (state: ProgramBlockState, action: Action): ProgramBlockState => {
  const { payload } = action;

  const newCanvas = new Canvas({ ...state.canvas.getStructure(), ...payload });

  return {
    ...state,
    canvas: newCanvas,
  };
};

const setCanvasSaving = (state: ProgramBlockState, action: Action<boolean>): ProgramBlockState => {
  return {
    ...state,
    saving: !!action.payload,
  };
};

const setCanvasLoading = (state: ProgramBlockState, action: Action<boolean>): ProgramBlockState => {
  return {
    ...state,
    loading: !!action.payload,
  };
};

const fetchSuccess = (state: ProgramBlockState, action: Action): ProgramBlockState => {
  const { editor, canvas, components, timer } = action.payload;

  const newEditor = { ...editor };
  const newCanvas = new Canvas(canvas);
  const newComponents = [...components];

  return {
    ...state,
    ...(timer ? {timer} : {}),
    editor: newEditor,
    canvas: newCanvas,
    components: newComponents,
    notSavedVersion: {
      ...(timer ? {timer} : {}),
      editor: newEditor,
      canvas: newCanvas,
      components: newComponents,
    }
  };
};

const clearData = (state: ProgramBlockState): ProgramBlockState => {
  return getDefaultState(state);
};

const changeMultiMode = (state: ProgramBlockState, action: Action): ProgramBlockState => {
  return {
    ...state,
    multiMode: action.payload,
  };
};

const removeComponents = (state: ProgramBlockState, action: Action): ProgramBlockState => {
  return {
    ...state,
    components: state.components.filter(c => !(action.payload as string[]).includes(c.id)),
  };
};

const addBlockToList = (state: ProgramBlockState, action: Action) => {
  return {
    ...state,
    blocks: [
      ...state.blocks,
      action.payload,
    ],
  };
};

const addBlocksToList = (state: ProgramBlockState, action: Action) => {
  return {
    ...state,
    blocks: [
      ...action.payload,
    ],
  };
};

const addExercises = (state: ProgramBlockState, action: Action): ProgramBlockState => {
  return {
    ...state,
    exercises: action.payload,
  };
};

const toggleEditorEditBlock = (state: ProgramBlockState, action: Action<boolean>): ProgramBlockState => {
  return {
    ...state,
    isBlockEditorActive: action.payload as boolean,
  };
};

const togglePreview = (state: ProgramBlockState, action: Action<boolean>): ProgramBlockState => {
  return {
    ...state,
    preview: !!action.payload,
  };
};

const updateEditorInfo = (state: ProgramBlockState, action: Action): ProgramBlockState => {
  const { path, value } = action.payload;
  const editor = { ...state.editor };
  set(editor, path, value);
  return {
    ...state,
    editor,
  };
};

const setEditorToBlockPage = (state: ProgramBlockState): ProgramBlockState => {
  return {
    ...state,
    isBlockEditorPage: true,
  };
};

const updateBlocksOnCanvas = (state: ProgramBlockState, action: Action): ProgramBlockState => {
  const component = action.payload;

  const components = state.components.map((c: any) => {
    if (c.blockId && (c.blockId === component.blockId)) {
      return createNewComponent(c.type, {
        ...c,
        // Block style could changed
        style: {
          ...c.style,
          ...component.style,
        },
        components: component.components,
      });
    }
    return c;
  });

  return {
    ...state,
    components,
  };
};

const updatePreferences = (state: ProgramBlockState, action: Action<GymPreferences>): ProgramBlockState => {
  const canvas = state.canvas.clone();
  const pref = action.payload as GymPreferences || state.preferences;

  canvas.updatePreferences(pref);

  return {
    ...state,
    preferences: pref,
    canvas: canvas,
    notSavedVersion: {
      ...state.notSavedVersion,
      canvas,
    },
  };
};

const addTimer = (state: ProgramBlockState, action: Action<Timer>): ProgramBlockState => {
  return {
    ...state,
    timer: action.payload,
    selectedComponent: action.payload as Timer,
  };
};

const updateTimerProperties = (state: ProgramBlockState, action: Action): ProgramBlockState => {
  const props = action.payload;
  const { timer } = state;

  const newTimer = cloneDeep(timer) as Timer;

  Object.keys(props)
    .forEach((key: string) => {
      const value = props[key];

      //@ts-ignore
      newTimer[key] = value;
    });

  return {
    ...state,
    selectedComponent: newTimer,
    timer: newTimer,
  };
};

const updateTimerWarmUpProperties = (state: ProgramBlockState, action: Action): ProgramBlockState => {
  const props = action.payload;
  const { timer } = state;

  const component = cloneDeep((timer as Timer).warmUpComponent);

  Object.keys(props)
    .forEach((key: string) => {
      const value = props[key];

      //@ts-ignore
      component[key] = value;
    });

  return {
    ...state,
    selectedComponent: component,
    timer: {
      ...timer as Timer,
      warmUpComponent: component,
    },
  };
};

const updateTimerWarmUpProperty = (state: ProgramBlockState, action: Action): ProgramBlockState => {
  const { path, value } = action.payload;
  const { timer } = state;

  const component = cloneDeep((timer as Timer).warmUpComponent);

  set(component, path, value);

  return {
    ...state,
    selectedComponent: component,
    timer: {
      ...timer as Timer,
      warmUpComponent: component,
    },
  };
};

const updateTimerProperty = (state: ProgramBlockState, action: Action): ProgramBlockState => {
  const { path, value } = action.payload;
  const { timer } = state;

  const newTimer = cloneDeep(timer) as Timer;

  set(newTimer, path, value);

  return {
    ...state,
    selectedComponent: newTimer,
    timer: newTimer,
  };
};

const removeTimer = (state: ProgramBlockState): ProgramBlockState => {
  const newState = {...state, selectComponent: null};
  delete newState.timer;
  return newState;
};

export default createReducer(InitialState, {
  [EditorBlockActionType.ADD_TO_CANVAS]: addToCanvas,
  [EditorBlockActionType.SELECT_COMPONENT]: selectComponent,
  [EditorBlockActionType.DESELECT_COMPONENT]: deselectComponent,
  [EditorBlockActionType.UPDATE_PROPERTIES_FOR_SELECTED]: updateProperties,
  [EditorBlockActionType.UPDATE_PROPERTY_FOR_SELECTED]: updateProperty,
  [EditorBlockActionType.UPDATE_SELECTED_BLOCK_INFO]: updateSelectedBlockInfo,
  [EditorBlockActionType.REMOVE_SELECTED_COMPONENT]: removeSelectedComponent,
  [EditorBlockActionType.UPDATE_PROPERTY_FOR_CANVAS]: updateCanvasProperty,
  [EditorBlockActionType.UPDATE_PROPERTIES_FOR_CANVAS]: updateCanvasProperties,
  [EditorBlockActionType.SAVE_CANVAS_PROCESSING]: setCanvasSaving,
  [EditorBlockActionType.FETCH_DATA_SUCCESS]: fetchSuccess,
  [EditorBlockActionType.LOAD_CANVAS_PROCESSING]: setCanvasLoading,
  [EditorBlockActionType.CLEAR_DATA]: clearData,
  [EditorBlockActionType.CHANGE_MULTI_MODE]: changeMultiMode,
  [EditorBlockActionType.REMOVE_COMPONENTS]: removeComponents,
  [EditorBlockActionType.ADD_BLOCK_TO_LIST]: addBlockToList,
  [EditorBlockActionType.ADD_BLOCKS_TO_LIST]: addBlocksToList,
  [EditorBlockActionType.ADD_EXERCISES_TO_LIST]: addExercises,
  [EditorBlockActionType.TOGGLE_EDITOR_EDIT_BLOCK]: toggleEditorEditBlock,
  [EditorBlockActionType.TOGGLE_PROGRAM_PREVIEW]: togglePreview,
  [EditorBlockActionType.UPDATE_EDITOR_INFO]: updateEditorInfo,
  [EditorBlockActionType.UPDATE_SELECTED_BLOCK_COMPONENT_VALUE]: updateEditorSelectedBlockInfoComponentValue,
  [EditorBlockActionType.SET_EDITOR_TO_BLOCK_PAGE]: setEditorToBlockPage,
  [EditorBlockActionType.UPDATE_BLOCKS_ON_CANVAS]: updateBlocksOnCanvas,
  [EditorBlockActionType.UPDATE_PREFERENCES]: updatePreferences,
  [EditorBlockActionType.ADD_TIMER]: addTimer,
  [EditorBlockActionType.SELECT_TIMER]: selectTimer,
  [EditorBlockActionType.UPDATE_TIMER_PROPS]: updateTimerProperties,
  [EditorBlockActionType.UPDATE_TIMER_PROP]: updateTimerProperty,
  [EditorBlockActionType.REMOVE_TIMER]: removeTimer,
  [EditorBlockActionType.UPDATE_WARM_UP_PROPERTIES]: updateTimerWarmUpProperties,
  [EditorBlockActionType.UPDATE_WARM_UP_PROPERTY]: updateTimerWarmUpProperty,
});
