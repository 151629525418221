import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PageWrapper from '../../components/page-wrapper';
import PageTitle from '../../components/page-title';
import styles from './team.module.scss';
import InputField from '../../components/input-field';
import { IconType } from '../../components/icon';
import { Table } from '../../components/table';
import useFirebaseEntity from '../../hooks/use-firebase-entity';
import Select from '../../components/select';
import { ControlSize } from '../../model/ControlSize';
import { RootState } from '../../store/reducers';
import { AccessLevel, AccessLevelFullOptions, mapAccessLevelToLabel } from '../../model/AccessLevel';
import { mapUserDtoToExercise } from '../../model/User';
import { formatDate } from '../../utils/formatter';
import { Button, ButtonKind } from '../../components/button';
import { Modal } from '../../components/modal';
import { API } from '../../api';
import { getBaseUrl } from '../../utils/app';
import useOnlyRoles from '../../hooks/use-only-roles';
import UserAvatar from '../../components/user-avatar';
import { TableAction } from '../../components/table/table-action';
import { TableActionKind, TableActionType } from '../../components/table/table-action-list';
import { removeUser } from '../../api/firebase/user';
import useGymQuery from '../../hooks/use-gym-query';
import { Invite, InviteStatus, mapInviteDtoToInvite, mapInviteStatusToLabel } from '../../model/Invite';

const HEADER = [
  { title: 'Name' },
  { title: 'Email' },
  { title: 'Role' },
  { title: 'Registered' },
  { title: 'Status' }
] as {
  title?: string;
  className?: string;
}[];

interface TeamMember {
  name: string;
  email: string;
  roles: AccessLevel[];
  dateCreated: Date;
  avatarUrl?: string;
  isInvite?: boolean;
  status?: InviteStatus;
  id: string;
}

const TeamPage = () => {
  const [isInviteModalVisible, setInviteModalVisible] = useState(false);

  const [inviteEmail, setInviteEmail] = useState('');
  const [inviteRole, setInviteRole] = useState(AccessLevel.owner);

  const [isCreatingInvite, setIsCreatingInvite] = useState(false);

  const user = useSelector((state: RootState) => state.auth.user);
  const gym = useSelector((state: RootState) => state.gym);

  const gymQuery = useGymQuery();

  const [userSearch, setUserSearch] = useState('');

  const usersQuery = [
    ['isDeleted', false],
    ['gymId', gym.gym ? gym.gym.id : null]
  ];

  const [users, { hasFetched }] = useFirebaseEntity('users', usersQuery, mapUserDtoToExercise) as [TeamMember[], any];
  const [invites,, { remove: removeInvite }] = useFirebaseEntity('invites', [gymQuery, ['status', InviteStatus.requested, '==']], mapInviteDtoToInvite) as [Invite[], any, any];

  const filteredUsers = (users.concat(invites.map(invite => ({
      dateCreated: invite.dateCreated,
      email: invite.email,
      name: invite.email,
      roles: [invite.accessLevel],
      isInvite: true,
      status: invite.status,
      id: invite.id,
    } as TeamMember),
  ))).filter(item => {
    return !userSearch || item.name.toLowerCase().includes(userSearch.toLowerCase());
  });

  const isButtonDisabled = useOnlyRoles([AccessLevel.admin], !gym.gym, false);
  const actionsAvailable = useOnlyRoles([AccessLevel.admin, AccessLevel.owner], true, false);

  if (!user) {
    return null;
  }

  const closeInviteModal = () => setInviteModalVisible(false);

  const handleInviteTeamMember = () => {
    setInviteEmail('');
    setInviteRole(AccessLevel.owner);

    setInviteModalVisible(true);
  };

  const handleSendInvite = async () => {
    setIsCreatingInvite(true);

    try {
      const invite = await API.invite.createInvite({
        email: inviteEmail,
        accessLevel: inviteRole,
        fromUserId: user.uid,
        gymId: (gym.gym && gym.gym.id) as string,
      });

      const url = `${getBaseUrl()}/invites/${invite.id}`;

      await API.invite.sendInvite(inviteEmail, url);

      window.alert('Invite has been sent');

      closeInviteModal();
    } catch (err) {
      console.error(err);

      window.alert('Something went wrong. Please, try again later.');
    }

    setIsCreatingInvite(false);
  };

  const header = [...HEADER];

  const body = [
    {
      key: (row: TeamMember) => (
        <div className={styles.userNameContainer}>
          {
            Boolean(row.avatarUrl) && (
              <UserAvatar url={row.avatarUrl}/>
            )
          }
          <div>{row.name}</div>
        </div>
      )
    },
    { key: (row: TeamMember) => row.email },
    { key: (row: TeamMember) => mapAccessLevelToLabel(row.roles[0]) },
    { key: (row: TeamMember) => formatDate(row.dateCreated) },
    { key: (row: TeamMember) => row.status ? mapInviteStatusToLabel(row.status) : '' },
  ] as any;

  if (actionsAvailable) {
    header.push({ className: styles.tableAction });

    const rowActionsHandler = (data: TeamMember): TableActionType[] => {
      return [
        {
          title: 'Delete',
          onClick: () => {
            if (!window.confirm('Are you sure you want to delete this user?')) {
              return;
            }

            if (data.isInvite) {
              removeInvite(data.id).catch((err: Error) => {
                console.error(err);

                window.alert('Something went wrong. Please, try again later.');
              })
            } else {
              removeUser((data as any).id).catch(err => {
                console.error(err);

                window.alert('Something went wrong. Please, try again later.');
              });
            }
          },
          kind: TableActionKind.Warning,
        },
      ];
    };

    body.push({
      key: (data: TeamMember) => {
        if (data.roles.includes(AccessLevel.admin)) {
          return null;
        }

        return (
          <TableAction actions={rowActionsHandler(data)}/>
        );
      },
      className: styles.tableAction,
    });
  }

  return (
    <PageWrapper>
      <PageTitle title={'Team Members'}>
        <Button
          disabled={isButtonDisabled}
          size={ControlSize.Small}
          title="Invite Team Member"
          onClick={handleInviteTeamMember}
        />
      </PageTitle>
      <div className={styles.content}>
        <div className={styles.actions}>
          <div>
            <InputField
              className={styles.search}
              icon={IconType.Search}
              placeholder="Search team members..."
              size={ControlSize.Small}
              value={userSearch}
              onChange={setUserSearch}
            />
          </div>
        </div>
        <Table body={body} data={filteredUsers} header={header} isLoading={!hasFetched}/>
      </div>
      {
        isInviteModalVisible && (
          <Modal color="#fff">
            <div className={styles.modalContent}>
              <div className={styles.modalTitle}>
                Invite a team member
              </div>
              <div className={styles.modalBody}>
                <div className={styles.modalBodyRow}>
                  <InputField
                    label="Email"
                    placeholder="Enter their company email address..."
                    value={inviteEmail}
                    onChange={setInviteEmail}
                  />
                </div>
                <div className={styles.modalBodyRow}>
                  <Select
                    label="Role"
                    placeholder="Select..."
                    options={AccessLevelFullOptions.filter(item => item.value !== AccessLevel.admin)}
                    value={inviteRole}
                    onChange={setInviteRole}
                  />
                </div>
              </div>
              <div className={styles.modalActionContainer}>
                <Button
                  kind={ButtonKind.Secondary}
                  title="Cancel"
                  onClick={closeInviteModal}
                />
                <Button
                  disabled={isCreatingInvite || !inviteEmail}
                  title="Send Invite"
                  onClick={handleSendInvite}
                />
              </div>
            </div>
          </Modal>
        )
      }
    </PageWrapper>
  );
};

export default TeamPage;
