import firebase from 'firebase/app';

// prod
const config = {
  apiKey: 'AIzaSyC4UbnwAoOCD_6SVPo0H-aSGNcFRbO12oE',
  authDomain: 'tcpro-31053.firebaseapp.com',
  databaseURL: 'https://tcpro-31053.firebaseio.com',
  projectId: 'tcpro-31053',
  storageBucket: 'tcpro-31053.appspot.com',
  messagingSenderId: '867508819118',
  appId: '1:867508819118:web:92b8cc351b7c5bac25093f',
  measurementId: 'G-0WGF2EQZ0B',
};

// dev
// const config = {
//   apiKey: "AIzaSyASZnXW4QuuJwnFYOsYfJdMnIEC5yYL4rI",
//   authDomain: "tcpro-dev.firebaseapp.com",
//   projectId: "tcpro-dev",
//   storageBucket: "tcpro-dev.appspot.com",
//   messagingSenderId: "935255402894",
//   appId: "1:935255402894:web:333ecb965e89b726cf9cdf"
// };

firebase.initializeApp(config);

