import React, { ChangeEvent } from 'react';
import styles from './expandable-with-check.module.scss';

type Props = {
  value: boolean;
  children: React.ReactNode;
  onChange: (value: boolean) => void;
};

class ExpandableWithCheck extends React.PureComponent<Props> {
  handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(e.target.checked);
  };

  render() {
    const {
      value = false,
      children,
    } = this.props;

    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <label className={styles.title}>
            <input type="checkbox" checked={value} onChange={this.handleChange}/>
            Set control as variable
          </label>
        </div>
        {
          Boolean(value) && (
            <div className={styles.content}>
              {children}
            </div>
          )
        }
      </div>
    );
  }
}

export default ExpandableWithCheck;
