import React from 'react';
import { ComponentImage } from '../../../../model/program/ComponentImage';
import Wrapper from '../wrapper';
import styles from './image.module.scss';
import { VariableValue } from '../../../../model/program/Variable';

type Props = {
  component: ComponentImage;
  editable?: boolean;
  variableValue?: VariableValue;
  scale?: number;
};

class Image extends React.PureComponent<Props> {
  render() {
    const {
      component,
      editable = true,
      scale,
    } = this.props;

    return (
      <Wrapper scale={scale} editable={editable} component={component}>
        <div
          className={styles.image}
          style={{
            ...component.style,
            ...(component.imageUrl ? {
              backgroundImage: `url(${component.imageUrl})`,
            } : {}),
          }}
        />
      </Wrapper>
    );
  }
}

export default Image;
