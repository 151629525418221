import React from 'react';
import Expandable from '../../expandable';
import Row from '../row';
import ValueWrapper from '../value-wrapper';
import Select from '../../../select';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducers';
import { CanvasModeOptions, Mode } from '../../../../model/program/Canvas';
import { editorBlockActions } from '../../../../store/actions';
import ColorPicker from '../../../color-picker';
import throttle from 'lodash/throttle';
import { ControlColor } from '../../../../model/ControlColor';
import { ControlSize } from '../../../../model/ControlSize';
import { Size } from '../../../../model/program/Size';
import { ProgramFocusOptions } from '../../../../model/program/ProgramFocus';
import { ProgramFormatOptions } from '../../../../model/program/ProgramFormat';
import TagsField, { Tag } from '../../../tags-field';
import useFirebaseEntity from '../../../../hooks/use-firebase-entity';
import useGymQuery from '../../../../hooks/use-gym-query';
import { Gym } from '../../../../model/Gym';
import { mapTagDtoToTag } from '../../../../model/Tag';
import { getProgramEditorPresentState } from '../../../../store/reducers/program';
import MultiSelect from '../../../multi-select';
import isArray from 'lodash/isArray';

const CanvasSettings = () => {
  const dispatch = useDispatch();

  const gym = useSelector((state: RootState) => state.gym.gym) as Gym;
  const { canvas, editor } = useSelector((state: RootState) => getProgramEditorPresentState(state));

  const { size, mode } = canvas;

  const query = useGymQuery();
  const [tags, , {create: createTag}] = useFirebaseEntity('programTags', [query], mapTagDtoToTag) as [Tag[], any, any];

  const handleChangeColor = throttle((value: any) => {
    dispatch(editorBlockActions.updatePropertyForCanvas('color', value));
  }, 300);

  const handleChangeMode = (option: Mode) => {
    if (option === canvas.mode) {
      return;
    }

    const size: Size = {
      width: canvas.size.height,
      height: canvas.size.width,
      unit: canvas.size.unit,
    };

    dispatch(editorBlockActions.updatePropertiesForCanvas({
      mode: option,
      size,
    }));
  };

  const handleChangeInput = (path: string) => (value: any) => {
    dispatch(editorBlockActions.updatePropertyForCanvas(path, value));
  };

  const handleUpdateCanvasName = (value: any) => {
    dispatch(editorBlockActions.updatePropertyForCanvas('name', value));
  };

  const handleChangeEditor = (path: string) => (value: any) => {
    dispatch(editorBlockActions.updateEditorInfo(path, value));
  };

  const handleCustomTagAdded = (name: string) => createTag({name, gymId: gym.id});

  return (
    <div id="editor__canvas-settings">
      <Expandable id={`editor__canvas-settings__program`} title={'Program Settings'} defaultOpened={true}>
        <Row
          id={'editor__canvas-settings__program__name'}
          column={true}
          title={'Name'}
          col1={
            <ValueWrapper
              value={canvas.name}
              onUpdate={handleUpdateCanvasName}
            />
          }
        />
        <Row
          column={true}
          title={'Duration'}
          col1={
            <ValueWrapper
              value={editor.duration || ''}
              onUpdate={handleChangeEditor('duration')}
            />
          }
        />
        <Row
          column={true}
          title={'Focus'}
          col1={
            <MultiSelect
              options={ProgramFocusOptions}
              value={!editor.focus ? undefined : isArray(editor.focus) ? editor.focus : [editor.focus]}
              color={ControlColor.Dark}
              size={ControlSize.Small}
              onChange={handleChangeEditor('focus')}
            />
          }
        />
        <Row
          column={true}
          title={'Format'}
          col1={
            <Select
              color={ControlColor.Dark}
              size={ControlSize.ExtraSmall}
              options={ProgramFormatOptions}
              value={editor.format}
              onChange={handleChangeEditor('format')}
            />
          }
        />
        <Row
          id={'editor__canvas-settings__program__tags'}
          column={true}
          title={'Tags'}
          col1={
            <TagsField
              color={ControlColor.Dark}
              placeholder="Input tags here..."
              size={ControlSize.ExtraSmall}
              tags={tags}
              value={editor.tags}
              onChange={handleChangeEditor('tags')}
              onCustomTagAdded={handleCustomTagAdded}
            />
          }
        />
      </Expandable>
      <Expandable id={`editor__canvas-settings__canvas`} title={'Canvas'} defaultOpened={true}>
        <Row
          title={'Size'}
          col1={
            <ValueWrapper
              postfix={'W'}
              type={'number'}
              value={size.width}
              onUpdate={handleChangeInput('size.width')}
            />
          }
          col2={
            <ValueWrapper
              postfix={'H'}
              type={'number'}
              value={size.height}
              onUpdate={handleChangeInput('size.height')}
            />
          }
        />
        <Row
          title={'Color'}
          col1={
            <ValueWrapper
              value={canvas.color}
              postfix={
                <ColorPicker value={canvas.color} onChange={handleChangeColor}/>
              }
              onUpdate={handleChangeColor}
            />
          }
        />
        <Row
          title={'Mode'}
          col1={
            <Select
              color={ControlColor.Dark}
              size={ControlSize.ExtraSmall}
              options={CanvasModeOptions}
              value={mode}
              onChange={handleChangeMode}
            />
          }
        />
      </Expandable>
    </div>
  );
};

export default CanvasSettings;
