import { Action, createReducer } from '../../utils';
import { ListPageActionType } from '../../action-types';

export type ListPageState = {
  loading: boolean;
  items: any[];
};

export const InitialListPageState: ListPageState = {
  loading: false,
  items: [],
};

const loadStart = (state: ListPageState, action: Action): ListPageState => {
  return {
    ...state,
    loading: true,
  };
};

const loadSuccess = (state: ListPageState, action: Action): ListPageState => {
  return {
    ...state,
    loading: false,
    items: action.payload,
  };
};

const clearData = (state: ListPageState) => ({ ...state, loading: false, items: [] });

export default createReducer(InitialListPageState, {
  [ListPageActionType.LOAD_DATA]: loadStart,
  [ListPageActionType.LOAD_DATA_SUCCESS]: loadSuccess,
  [ListPageActionType.CLEAR_DATA]: clearData,
});
