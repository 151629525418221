import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageWrapper from '../../../components/page-wrapper';
import PageTitle from '../../../components/page-title';
import styles from './list.module.scss';
import InputField from '../../../components/input-field';
import { Table } from '../../../components/table';
import { formatDate } from '../../../utils/formatter';
import { TableAction } from '../../../components/table/table-action';
import { Block, BlockType, mapBlockDtoToBlock } from '../../../model/program/Block';
import useFirebaseEntity from '../../../hooks/use-firebase-entity';
import { TagList } from '../../../components/tag-list';
import { ControlSize } from '../../../model/ControlSize';
import { IconType } from '../../../components/icon';
import { push } from 'connected-react-router';
import { TableActionKind, TableActionType } from '../../../components/table/table-action-list';
import { RootState } from '../../../store/reducers';
import { User } from '../../../model/User';
import useOnlyRoles from '../../../hooks/use-only-roles';
import { AccessLevel } from '../../../model/AccessLevel';
import { API } from '../../../api';
import { Gym } from '../../../model/Gym';

const HEADER = [
  { title: 'Name' },
  { title: 'Tags' },
  { title: 'Created' },
  { className: styles.tableAction }
];

const BlockTemplatesPage = () => {
  const dispatch = useDispatch();

  const isAdmin = useOnlyRoles<boolean>([AccessLevel.admin], true, false);
  const user = useSelector((state: RootState) => state.auth.user) as User;
  const gym = useSelector((state: RootState) => state.gym.gym) as Gym;
  const [search, setSearch] = useState('');
  const [items, {hasFetched}, {remove}] = useFirebaseEntity('blockTemplates', undefined, mapBlockDtoToBlock) as [Block[], any, any];
  const copy = async (id: string) => {
    try {
      await API.block.copyBlockFromTemplate(id, user, gym);
      alert('The block has been copied!');
    } catch (e) {
      console.log(e);
      alert(e.message || 'Error has been occurred.');
    }
  };

  const filteredItems = items.filter(item => !search || item.info.name.toLowerCase().includes(search.toLowerCase()))
    .sort((a, b) => {
      return a.info.name.toLowerCase().localeCompare(b.info.name.toLowerCase());
    });

  const rowActionsHandler = (data: Block): TableActionType[] => {
    return [
      isAdmin ? {
        title: 'Edit',
        onClick: () => {
          dispatch(push(`/blocks/templates/editor/${data.info.id}`));
        }
      } : null,
      {
        title: 'Copy to my gym',
        onClick: () => {
          if (window.confirm('Are you sure you want to copy the block?')) {
            copy(data.id as string);
          }
        },
      },
      isAdmin ? {
        title: 'Delete',
        onClick: () => {
          if (window.confirm('Are you sure you want to remove the block?')) {
            remove(data.info.id);
          }
        },
        kind: TableActionKind.Warning,
      } : null,
    ].filter(a => !!a) as TableActionType[];
  };

  const body = [
    { key: (row: Block) => row.info.name },
    {
      key: (row: Block) => row.info.tags && (
        <TagList className={styles.tagList} tags={row.info.tags}/>
      )
    },
    { key: (row: Block) => formatDate(row.info.dateCreated as Date) },
    {
      key: (data: Block) => {
        return (
          <TableAction actions={rowActionsHandler(data)}/>
        );
      },
      className: styles.tableAction,
    },
  ];

  return (
    <PageWrapper>
      <PageTitle title={'Block Templates'}>
        {/*<Button*/}
        {/*  size={ControlSize.Small}*/}
        {/*  title="Add Block"*/}
        {/*  onClick={handleAddBlock}*/}
        {/*/>*/}
      </PageTitle>
      <div className={styles.content}>
        <div className={styles.actions}>
          <div>
            <InputField
              className={styles.search}
              icon={IconType.Search}
              size={ControlSize.Small}
              placeholder={'Search blocks...'}
              value={search}
              onChange={setSearch}
            />
          </div>
        </div>
        <Table header={HEADER} body={body} data={filteredItems} isLoading={!hasFetched}/>
      </div>
    </PageWrapper>
  );
};

export default BlockTemplatesPage;
