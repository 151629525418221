import { useEffect, useMemo, useReducer, useState } from 'react';

import firebase from 'firebase/app';

function _itemsReducer(state = [], {type, data}) {
  switch (type) {
  case 'added_bulk':
    return [...state, ...data];
  case 'clear':
    return [];
  case 'modified': {
    const {id, ...rest} = data;

    const index = state.findIndex(item => item.id === id);

    if (index < 0) {
      return state;
    }

    const newState = [...state];

    newState.splice(index, 1, {
      id,
      ...newState[index],
      ...rest
    });

    return newState;
  }
  case 'removed': {
    const index = state.findIndex(item => item.id === data.id);

    if (index < 0) {
      return state;
    }

    const newState = [...state];

    newState.splice(index, 1);

    return newState;
  }

  default:
    return state;
  }
}

export default (collectionName, query = [], mapData = (item) => item, mapCreateData = (item, id) => item) => {
  const [items, dispatchItems] = useReducer(_itemsReducer, []);
  const [hasFetched, setHasFetched] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const queryJson = JSON.stringify(query);

  useEffect(() => {
    if (!collectionName) {
      return;
    }

    let q = firebase.firestore().collection(collectionName);

    query.forEach(([key, value, op = '==']) => {
      q = q.where(key, op, value);
    });

    const unsubscribe = q.onSnapshot(snapshot => {
      const list = [];

      snapshot.docChanges().forEach(({type, doc}) => {
        const item = mapData({
          id: doc.id,
          ...doc.data()
        });

        if (type === 'added') {
          list.push(item);
        } else {
          dispatchItems({
            type,
            data: item
          });
        }
      });

      if (list.length > 0) {
        dispatchItems({
          type: 'added_bulk',
          data: list
        });
      }

      setHasFetched(true);
    }, err => {
      console.warn('Realtime updates error:', err);
    });

    return () => {
      unsubscribe();

      dispatchItems({type: 'clear'});

      setHasFetched(false);
    };
  }, [collectionName, queryJson]);

  const create = useMemo(() => async (data, id = null, handleExists = null) => {
    setIsCreating(true);

    try {
      const collection = firebase.firestore().collection(collectionName);

      if (id) {
        const doc = await collection.doc(id);

        if (handleExists && (await doc.get()).exists) {
          handleExists();

          setIsCreating(false);

          return;
        }

        await doc.set(data);

        return id;
      }

      const doc = await collection.add(data);

      return doc.id;
    } catch (err) {
      console.error(err);
    } finally {
      setIsCreating(false);
    }
  }, [collectionName]);

  const update = useMemo(() => async (id, data) => {
    setIsUpdating(true);

    try {
      const collection = firebase.firestore().collection(collectionName);

      await collection.doc(id).update(data);
    } catch (err) {
      console.error(err);
    } finally {
      setIsUpdating(false);
    }
  }, [collectionName]);

  const remove = useMemo(() => async id => {
    setIsRemoving(true);

    try {
      const collection = firebase.firestore().collection(collectionName);

      await collection.doc(id).delete();
    } catch (err) {
      console.error(err);
    } finally {
      setIsRemoving(false);
    }
  }, [collectionName]);

  const copy = useMemo(() => async (id) => {
    setIsCreating(true);

    try {
      const collection = firebase.firestore().collection(collectionName);

      const ref = collection.doc(id);

      const response = await ref.get();
      const data = response.data();

      const newRef = collection.doc();

      const newData = mapCreateData(data, newRef.id);


      return await create(newData, newRef.id);
    } catch (err) {
      console.error(err);
    } finally {
      setIsCreating(false);
    }
  }, [collectionName]);

  return [items, {hasFetched, isCreating, isRemoving, isUpdating}, {create, remove, update, copy}];
};
