import React from 'react';
import { ActionCreators } from 'redux-undo';
import { Dispatch } from 'redux';
import styles from './header.module.scss';
import TitleEditable from './title-editable';
import Components from '../components';
import Checkbox from '../../checkbox';
import { Button, ButtonKind } from '../../button';
import { editorBlockActions } from '../../../store/actions';
import { connect } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { ProgramType } from '../../../model/program/Program';
import SelectedProgramPreview from '../selected-program-preview';
import { ControlColor } from '../../../model/ControlColor';
import { ControlSize } from '../../../model/ControlSize';
import { getProgramEditorPresentState } from '../../../store/reducers/program';
import { IconType, SvgIcon } from '../../icon';

type Props = {
  save: (resolve: Function) => void;
  replace: (id: string) => void;
  goToList: () => void;
  goToBlockList: () => void;
  saveExistingBlock: (resolve: Function) => void;
} & ReturnType<typeof mapActions> & ReturnType<typeof mapState>;

const SAVE_TIME = 30 * 1000;

class Header extends React.PureComponent<Props> {
  saveTimer?: NodeJS.Timeout;
  componentDidMount() {
    this.props.clearHistory();
    this.saveTimer = setInterval(() => {
      if (this.props.isBlockEditorPage) {
        // this.handleSaveBlock(); TODO: Do we need to save the block automatically?
      } else {
        this.props.isProgramSaved((err: Error, saved: boolean) => {
          if (!saved && !this.props.isBlockEditorActive) {
            this.handleSave();
          }
        });
      }
    }, SAVE_TIME);
  }

  componentWillUnmount() {
    if (this.saveTimer) {
      clearInterval(this.saveTimer);
    }
  }

  handleSave = () => {
    if (this.props.isDesignerTourActive) return;

    const isNew = this.props.isNew;
    this.props.save((err: Error, response: ProgramType) => {
      if (err) {
        alert('Error save the program');
      } else {
        if (isNew) {
          this.props.replace(response.editor.id as string);
        }
      }
    });
  };

  handleGoBack = () => {
    this.props.isProgramSaved((err: Error, isSaved: boolean) => {
      if (err) {
        return window.alert(err.message);
      }

      if (isSaved) {
        this.props.goToList();
      } else {
        if (window.confirm('You have not saved changes. Are you sure you want to leave this page?')) {
          this.props.goToList();
        }
      }
    });
  };

  handleShowPreview = () => {
    this.props.togglePreview(true);
  };

  handleClose = () => {
    this.props.togglePreview(false);
  };

  handleToggleEditorBack = () => {
    this.props.toggleEditorBackToEditProgram();
  };

  handleSaveBlock = () => {
    this.props.saveExistingBlock((err: Error) => {
      if (err) {
        window.alert(err.message);
      }
    });
  };

  handleBackToBlocks = () => {
    this.props.goToBlockList();
  };

  handleUndo = () => {
    this.props.undo();
  };

  handleRedo = () => {
    this.props.redo();
  };

  render() {
    const {
      saving,
      loading,
      multiMode,
      changeMode,
      isBlockEditorActive,
      preview,
      isBlockEditorPage,
    } = this.props;

    return (
      <div className={styles.wrapper}>
        <div className={styles.title}>
          <TitleEditable/>
        </div>
        <div className={styles.list}>
          <div className={styles.actions}>
            <SvgIcon onClick={this.handleUndo} type={IconType.Undo}/>
            <SvgIcon onClick={this.handleRedo} type={IconType.Redo}/>
          </div>
          <div className={styles.mode}>
            <Checkbox checked={multiMode} onChange={changeMode} label={'Multi mode'}/>
          </div>
          <Components/>
        </div>
        <div className={styles.action}>
          <div className={styles.actionTextWrapper}>
            {(loading || saving) &&
            <>
              <div className={`${styles.dot} ${loading || saving ? styles.dotProcessing : ''}`}/>
              <div className={styles.actionText}>
                {loading ? 'Loading...' :
                  saving ? 'Saving...' :
                    ''
                }
              </div>
            </>
            }
          </div>
          {loading || saving ? null : (
            isBlockEditorActive ?
              (
                <>
                  {isBlockEditorPage ?
                    <Button
                      color={ControlColor.Dark}
                      disabled={saving}
                      kind={ButtonKind.Secondary}
                      size={ControlSize.ExtraSmall}
                      title="Back to Blocks"
                      onClick={this.handleBackToBlocks}
                    /> :
                    <Button
                      color={ControlColor.Dark}
                      disabled={saving}
                      kind={ButtonKind.Secondary}
                      size={ControlSize.ExtraSmall}
                      title="Back to Program Editor"
                      onClick={this.handleToggleEditorBack}
                    />
                  }
                  <Button
                    color={ControlColor.Dark}
                    disabled={saving}
                    size={ControlSize.ExtraSmall}
                    title="Save Block"
                    onClick={this.handleSaveBlock}
                  />
                </>
              ) :
              (<>
                  <Button
                    color={ControlColor.Dark}
                    disabled={saving}
                    kind={ButtonKind.Secondary}
                    size={ControlSize.ExtraSmall}
                    title="Preview"
                    onClick={this.handleShowPreview}
                  />
                  <Button
                    color={ControlColor.Dark}
                    disabled={saving}
                    size={ControlSize.ExtraSmall}
                    title="Save"
                    onClick={this.handleSave}
                  />
                  <Button
                    color={ControlColor.Dark}
                    disabled={saving}
                    size={ControlSize.ExtraSmall}
                    title="Close"
                    onClick={this.handleGoBack}
                  />
                </>
              )
          )}
        </div>
        {preview && <SelectedProgramPreview onClose={this.handleClose}/>}
      </div>
    );
  }
}

const mapState = (state: RootState) => {
  const {
    saving,
    loading,
    editor,
    multiMode,
    isBlockEditorActive,
    preview,
    isBlockEditorPage,
  } = getProgramEditorPresentState(state);

  const isDesignerTourActive = state.designerTour.active;

  return {
    saving,
    multiMode,
    isBlockEditorActive,
    preview,
    isBlockEditorPage,
    loading: loading || state.gym.loading,
    isNew: !editor.id,
    isDesignerTourActive,
  };
};

const mapActions = (dispatch: Dispatch) => ({
  isProgramSaved: (resolve: Function) => dispatch(editorBlockActions.isProgramSaved(resolve)),
  changeMode: (mode: boolean) => {
    dispatch(editorBlockActions.deselectComponent());
    dispatch(editorBlockActions.changeMultiMode(mode));
  },
  toggleEditorBackToEditProgram: () => dispatch(editorBlockActions.toggleEditorEditBlock(false)),
  togglePreview: (show: boolean) => dispatch(editorBlockActions.toggleShowPreview(show)),
  undo: () => dispatch(ActionCreators.undo()),
  redo: () => dispatch(ActionCreators.redo()),
  clearHistory: () => dispatch(ActionCreators.clearHistory()),
});

export default connect(mapState, mapActions)(Header);
