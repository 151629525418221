import React from 'react';
import styles from './settings.module.scss';
import { RootState } from '../../../store/reducers';
import { connect } from 'react-redux';
import ComponentSettings from './component-settings';
import { ComponentBase } from '../../../model/program/Component';
import CanvasSettings from './canvas-settings';
import { Button } from '../../button';
import DynamicPopup from './dynamic-popup';
import { ControlSize } from '../../../model/ControlSize';
import { ControlColor } from '../../../model/ControlColor';
import { isTimerSelected } from '../../../model/Timer';
import TimerSettings from './timer-settings';
import { getProgramEditorPresentState } from '../../../store/reducers/program';

type Props = ReturnType<typeof mapState> & {
  createNewBlock: (name: string, resolve: Function) => void;
};

type State = {
  showDynamicPopup: boolean;
};

class Settings extends React.PureComponent<Props, State> {
  state = {
    showDynamicPopup: false,
  };

  handleShowPopup = () => {
    this.setState({ showDynamicPopup: true });
  };

  handleHidePopup = () => {
    this.setState({ showDynamicPopup: false });
  };

  renderComponentSettings = () => {
    const {
      selectedComponent,
      isBlockEditorActive,
      selectedComponentInsideBlock,
    } = this.props;

    const comp = isBlockEditorActive ? selectedComponentInsideBlock : selectedComponent;

    if (isTimerSelected(selectedComponent)) {
      return (
        <TimerSettings/>
      );
    }

    return (
      <ComponentSettings component={comp as ComponentBase}/>
    );
  };

  renderCanvasSettings = () => {
    return (
      <CanvasSettings/>
    );
  };

  renderMultiSettings = () => {
    return (
      <div className={styles.multi}>
        <div className={styles.multiTextWrapper}>
          <div className={styles.multiText}>Multiple elements selected</div>
        </div>
        <div className={styles.multiActions}>
          <Button
            color={ControlColor.Dark}
            size={ControlSize.ExtraSmall}
            title="Create Dynamic Block"
            onClick={this.handleShowPopup}
          />
        </div>
      </div>
    );
  };

  render() {
    const {
      selectedComponent,
      multiMode,
      selectedComponents,
      createNewBlock,
    } = this.props;

    const {
      showDynamicPopup,
    } = this.state;

    return (
      <div className={styles.wrapper}>
        {multiMode && selectedComponents.length ? this.renderMultiSettings() :
          selectedComponent ? this.renderComponentSettings() : this.renderCanvasSettings()
        }
        {showDynamicPopup && <DynamicPopup
          onClose={this.handleHidePopup}
          createNewBlock={createNewBlock}
        />}
      </div>
    );
  }
}

const mapState = (state: RootState) => {
  const {
    selectedComponent,
    selectedComponentInsideBlock,
    isBlockEditorActive,
    multiMode,
    selectedComponents,
  } = getProgramEditorPresentState(state);

  return {
    selectedComponent,
    selectedComponentInsideBlock,
    isBlockEditorActive,
    multiMode,
    selectedComponents,
  };
};

export default connect(mapState)(Settings);
