import React, { DragEvent } from 'react';
import styles from './list.module.scss';
import Popup from '../../../../popup';
import { BlockType } from '../../../../../model/program/Block';
import { withClickOutside } from '../../../../../hoc/withClickOutside';
import InputField from '../../../../input-field';
import { ComponentType } from '../../../../../model/program/Type';
import { ControlSize } from '../../../../../model/ControlSize';
import { IconType } from '../../../../icon';
import { ControlColor } from '../../../../../model/ControlColor';

type Props = {
  list: BlockType[];
  onClose: () => void;
};

type State = {
  search: string;
};

class List extends React.PureComponent<Props, State> {
  state = {
    search: '',
  };

  handleChangeSearch = (value: string) => {
    this.setState({ search: value });
  };

  handleClickOutside = () => {
    this.props.onClose();
  };

  getList = () => {
    const { search } = this.state;

    if (!search) {
      return this.props.list;
    }

    const s = search.toLowerCase();

    return this.props.list.filter(b => b.info.name.toLowerCase().includes(s));
  };

  handleDragStart = (block: BlockType) => (e: DragEvent) => {
    e.stopPropagation();

    e.dataTransfer.setData('type', ComponentType.Block);
    e.dataTransfer.setData('data', JSON.stringify(block.component));
  };

  render() {
    const {
      search,
    } = this.state;

    const list = this.getList();

    return (
      <Popup className={styles.wrapper}>
        <InputField
          className={styles.input} // TODO
          icon={IconType.Search}
          size={ControlSize.ExtraSmall}
          color={ControlColor.Dark}
          value={search}
          onChange={this.handleChangeSearch}
          placeholder={'Search blocks...'}
        />
        {list.map(b => {
          return (
            <div
              key={b.info.id}
              className={styles.name}
              draggable={true}
              onDragStart={this.handleDragStart(b)}
            >
              {b.info.name}
            </div>
          );
        })}
      </Popup>
    );
  }
}

export default withClickOutside(List);
