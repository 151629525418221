import { ComponentBase, createComponentId, Component } from './Component';
import clone from 'lodash/clone';
import { PositionType } from './Position';
import { Meta } from './Meta';
import { ComponentType } from './Type';
import { Size } from './Size';
import { BlockVariables, Variable } from './Variable';

type Params = {
  id: string;
  position: PositionType;
  components: ComponentBase[];
  meta: Meta;
  size?: Size;
  blockId?: string;
  parentId?: string;
  blockVariables?: BlockVariables;
};

const defaultMeta: Meta = {
  name: 'Unnamed Block',
};

export type ComponentBlockType = {
  blockId?: string;
  components: Component[];
} & Component;

const componentMinMaxX = (c: Component) => {
  return [+c.position.x, +c.position.x + +c.size.width];
};

const componentMinMaxY = (c: Component) => {
  return [+c.position.y, +c.position.y + +c.size.height];
};

const calculateMinMaxX = (components: Component[]): [number, number] => {
  return components.reduce((acc, c) => {
    const [minX, maxX] = componentMinMaxX(c);

    if (minX < acc[0]) {
      acc[0] = minX;
    }

    if (maxX > acc[1]) {
      acc[1] = maxX;
    }

    return acc;
  }, componentMinMaxX(components[0])) as [number, number];
};

const calculateMinMaxY = (components: Component[]): [number, number] => {
  return components.reduce((acc, c) => {
    const [minY, maxY] = componentMinMaxY(c);

    if (minY < acc[0]) {
      acc[0] = minY;
    }

    if (maxY > acc[1]) {
      acc[1] = maxY;
    }

    return acc;
  }, componentMinMaxY(components[0])) as [number, number];
};

export class BlocksBuilder {
  get length() {
    return this.components.length;
  }

  components: Component[] = [];

  clear = () => {
    this.components = [];
  };

  insertOrRemove = (component: ComponentBase) => {
    const index = this.components.findIndex(c => c.id === component.id);

    if (index === -1) {
      this.addComponent(component);
    } else {
      this.removeComponent(component, index);
    }
  };

  addComponent = (component: ComponentBase) => {
    this.components.push(component);
  };

  removeComponent = (component: ComponentBase, index?: number) => {
    const position = index || this.components.findIndex(c => c.id = component.id);
    if (position !== -1) {
      this.components.splice(position, 1);
    }
  };

  clone = () => {
    return clone(this);
  };

  calculateBlockCoordinates = (): [number, number, number, number] => {
    const [minX, maxX] = calculateMinMaxX(this.components);
    const [minY, maxY] = calculateMinMaxY(this.components);

    return [minX, minY, Math.abs(maxX - minX), Math.abs(maxY - minY)];
  };
}

export class ComponentBlock extends ComponentBase {
  constructor(
    {
      position,
      id = createComponentId(),
      components,
      size,
      blockId,
      parentId,
      blockVariables = {},
      meta = defaultMeta,
      ...rest
    }: Params) {
    super({
      position,
      id,
      meta,
      size,
      parentId,
      ...rest
    });

    this.components = components;
    this.blockId = blockId;
    this.blockVariables = blockVariables
  }

  type: ComponentType = ComponentType.Block;
  components: ComponentBase[] = [];
  blockId?: string;
  blockVariables: BlockVariables;

  getStructure() {
    return {
      ...super.getStructure(),
      blockId: this.blockId,
      blockVariables: this.blockVariables,
    };
  }

  getStructureToSaveBlock(): ComponentBlockType {
    return {
      ...super.getStructure(),
      components: this.components.map(c => c.getStructure()),
      blockId: this.blockId,
    };
  };

  getChildVariables() {
    const variables: Variable[] = [];

    this.components.forEach(c => {
      if (c.variable.active) {
        variables.push(c.variable);
      }
    });

    return variables.sort((a, b) => b.order - a.order);
  }
}
