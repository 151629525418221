import React from 'react';
import { Dispatch } from 'redux';
import { match } from 'react-router';
import { push, replace } from 'connected-react-router';
import { connect } from 'react-redux';
import styles from './editor-templates-page.module.scss';
import EditorBlock from '../../../components/editor';
import { editorBlockActions } from '../../../store/actions';
import { Program } from '../../../model/program/Program';

type Params = {
  id: string;
};

type Props = {
  match: match<Params>;
} & ReturnType<typeof mapActions>;

class EditorTemplatesPage extends React.PureComponent<Props> {
  componentDidMount() {
    const { match: { params } } = this.props;

    this.props.setPreferences();

    if (params.id) {
      this.props.fetchEditor(params.id, (err: Error, data: Program) => {
        if (err) {
          alert(`${err.message}\nRedirect to new`);
          this.props.redirectToNew();
        }
      });
    } else {
      this.props.fetchBlocks();
    }
  }

  render() {
    const {
      getExercisesList,
      save,
      saveExistingBlock,
      goToBlockList,
      goToList,
      replace,
      createNewBlock,
    } = this.props;

    return (
      <div className={styles.page}>
        <EditorBlock
          getExercisesList={getExercisesList}
          saveExistingBlock={saveExistingBlock}
          save={save}
          goToBlockList={goToBlockList}
          goToList={goToList}
          replace={replace}
          createNewBlock={createNewBlock}
        />
      </div>
    );
  }
}

const mapActions = (dispatch: Dispatch) => ({
  fetchEditor: (id: string, resolve: Function) => dispatch(editorBlockActions.fetchTemplatesEditor(id, resolve)),
  fetchBlocks: () => dispatch(editorBlockActions.fetchTemplateBlocks()),
  redirectToNew: () => dispatch(replace('/programs/templates/editor')),
  setPreferences: () => dispatch(editorBlockActions.updatePreferences()),
  getExercisesList: () => {},
  save: (resolve: Function) => dispatch(editorBlockActions.saveTemplateCanvas(resolve)),
  replace: (id: string) => dispatch(replace(`/programs/templates/editor/${id}`)),
  goToList: () => dispatch(push('/programs/templates')),
  goToBlockList: () => dispatch(push('/blocks/templates')),
  saveExistingBlock: (resolve: Function) => dispatch(editorBlockActions.saveExistingTemplateBlock(resolve)),
  createNewBlock: (name: string, resolve: Function) => dispatch(editorBlockActions.createNewTemplateBlock(name, resolve)),
});

export default connect(null, mapActions)(EditorTemplatesPage);
