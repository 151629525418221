import React, { useRef, useState } from 'react';
import useOnClickOutside from '../../../hooks/use-click-outside';
import styles from './master-screen-popup.module.scss';
import Popup from '../../../components/popup';
import { Option } from '../../../model/Option';
import Select from '../../../components/select';
import { Button, ButtonKind } from '../../../components/button';

type Props = {
  onClose: () => void;
  onSave: (id: string) => void;
  options: Option<string>[];
  value: string;
};

const MasterScreenPopup = (props: Props) => {
  const { onClose, options, value, onSave } = props;
  const [currentValue, setCurrentValue] = useState(value);

  const ref = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(ref, () => onClose());

  const handleChange = (v: string) => {
    setCurrentValue(v);
  }

  const handleSave = () => {
    onSave(currentValue);
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <div className={styles.overlay}>
      <Popup innerRef={ref} className={styles.wrapper}>
        <Select
          label="Master"
          placeholder="Select master..."
          options={options}
          value={currentValue}
          onChange={handleChange}
        />
        <div className={styles.actions}>
          <Button title={'Save'} onClick={handleSave}/>
          <Button className={styles.cancel} title={'Cancel'} kind={ButtonKind.Secondary} onClick={handleCancel}/>
        </div>
      </Popup>
    </div>
  )
};

export {
  MasterScreenPopup,
}
