import React, { MouseEvent, useRef, useState } from 'react';
import * as firebase from 'firebase/app';

import { ControlSize } from '../../../../model/ControlSize';
import { Program } from '../../../../model/program/Program';
import { Screen } from '../../../../model/Screen';

import useFirebaseEntity from '../../../../hooks/use-firebase-entity';

import { IconType } from '../../../../components/icon';
import InputField from '../../../../components/input-field';
import Popup from '../../../../components/popup';

import styles from './play-modal.module.scss';

type Props = {
  programId: string;
  onCloseRequested: () => void;
};

const PlayModal = ({programId, onCloseRequested}: Props) => {
  const [search, setSearch] = useState('');

  const overlayRef = useRef(null as HTMLDivElement | null);

  const programsQuery = [[firebase.firestore.FieldPath.documentId(), programId]];

  const [
    [program],
    {isUpdating: isUpdatingProgram},
    {update: updateProgram}
  ] = useFirebaseEntity('programs', programsQuery) as [Program[], any, any];

  const screensQuery = program ? [
    ['gymId', program.editor.gymId],
    ['orientation', program.canvas.mode]
  ] : [['orientation', null]];

  const [
    screens,
    {isUpdating: isUpdatingScreen},
    {update: updateScreen}
  ] = useFirebaseEntity('screens', screensQuery) as [Screen[], any, any];

  const isUpdating = isUpdatingScreen || isUpdatingProgram;

  const handleChange = (id: string) => async () => {
    try {
      await updateScreen(id, {programId});

      await updateProgram(programId, {
        editor: {
          ...program.editor,
          dateLastPlayed: new Date()
        }
      });

      onCloseRequested();
    } catch (err) {
      console.error(err);

      window.alert('Error. please, try again later.');
    }
  };

  const handleOverlayClick = (e: MouseEvent) => {
    if (e.target === overlayRef.current) {
      onCloseRequested();
    }
  };

  const filtered = search ? screens.filter((s) => (s.name || '').toLowerCase().includes(search.toLowerCase())) : screens;

  return (
    <div ref={overlayRef} className={styles.overlay} onClick={isUpdating ? undefined : handleOverlayClick}>
      <Popup className={styles.wrapper}>
        <InputField
          className={styles.search}
          icon={IconType.Search}
          placeholder="Search screens..."
          size={ControlSize.ExtraSmall}
          value={search}
          onChange={setSearch}
        />
        {
          filtered.map(screen => {
            const active = screen && screen.programId === programId;

            return (
              <div
                key={screen.id}
                className={`${styles.item} ${active ? styles.active : ''}`}
                onClick={isUpdating ? undefined : handleChange(screen.id as string)}
              >
                <div className={styles.title}>{screen.name}</div>
              </div>
            );
          })
        }
      </Popup>
    </div>
  );
};

export default PlayModal;
