import { useCallback, useEffect, useRef, useState } from 'react';
import firebase from 'firebase/app';
import { Timer, TimerType } from '../model/Timer';
import LinkedList from '../utils/linkedList';
import useInterval from './use-interval';
import useFirebaseEntity from './use-firebase-entity';
import { OperationType, Screen } from '../model/Screen';
import { parseTimeFromString, MsInSecond } from '../utils/helper';
import { API } from '../api';

export enum IntervalType {
  WarmUp = 'warmup',
  Low = 'low',
  High = 'high',
  Rest = 'rest',
}

export type Interval = {
  time: number;
  name: string;
  type: IntervalType;
  setNumber?: number;
  numberOfSets?: number;
};

type Operation = {
  isPlaying: boolean;
  type: OperationType;
  commandUpdatedTime: number;
};

type ReturnType = [
  Interval | null,
  {
    isRunning: boolean;
    isFinished: boolean;
  },
  {
    play: () => void;
    pause: () => void;
    stop: () => void;
    prev: () => void;
    next: () => void;
  }
];

// @ts-ignore
const ctx = new (window.AudioContext || window.webkitAudioContext)();

const playSound = (twice: boolean) => {
  function sound(vol: number, freq: number, duration: number) {
    const v = ctx.createOscillator();
    const u = ctx.createGain();
    v.connect(u);
    v.frequency.value = freq;
    v.type = 'square';
    u.connect(ctx.destination);
    u.gain.value = vol * 0.01;
    v.start(ctx.currentTime);
    v.stop(ctx.currentTime + duration * 0.001);
  }

  const beep = sound.bind(null, 50, 500, 300);

  beep();
  if (twice) {
    setTimeout(beep, 300);
  }
};

function useScreenTimer(timer: Timer | undefined, screenId?: string, finishedCb: () => void = () =>  {}, useSound: boolean = false): ReturnType {
  const [isRunning, setIsRunning] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(0);
  const [interval, setInterval] = useState<Interval | null>(null);
  const [init, setInit] = useState(true);

  const listRef = useRef<LinkedList>();

  useEffect(() => {
    if (!timer) {
      return;
    }

    if (listRef.current) {
      listRef.current.clear();
    } else {
      listRef.current = new LinkedList();
    }

    const list = listRef.current;

    list.add({
      name: 'Warm Up',
      type: IntervalType.WarmUp,
      time: parseTimeFromString(timer.warmUp),
    });

    timer.intervals.forEach((interval, index) => {
      for (let ii = 0; ii < interval.numberOfSets; ii++) {
        const setNumber = ii + 1;

        const addLowInterval = () => {
          list.add({
            name: 'Low Interval',
            type: IntervalType.Low,
            time: parseTimeFromString(interval.lowDuration),
            numberOfSets: interval.numberOfSets,
            setNumber,
          });
        };

        const addHighInterval = () => {
          list.add({
            name: 'High Interval',
            type: IntervalType.High,
            time: parseTimeFromString(interval.highDuration),
            numberOfSets: interval.numberOfSets,
            setNumber,
          });
        };

        if (interval.highIntervalFirst) {
          addHighInterval();
          addLowInterval();
        } else {
          addLowInterval();
          addHighInterval();
        }
      }

      if (timer.timerType === TimerType.Complex && (timer.intervals.length - 1 !== index)) {
        list.add({
          name: 'Rest Time',
          type: IntervalType.Rest,
          time: parseTimeFromString(timer.restTime as string),
        });
      }
    });

    setCurrentPosition(0);
    const nextInterval = (listRef.current as LinkedList).get(0);
    setInterval(nextInterval);
  }, [timer]);

  const [data, , { update: screenUpdate }] = useFirebaseEntity('screens', [[firebase.firestore.FieldPath.documentId(), screenId || '-']]) as [Screen[], any, any];

  useEffect(() => {
    if (data && data[0] && listRef.current) {
      const screen = data[0] as Screen;

      if (init) {
        setInterval((listRef.current as LinkedList).get(currentPosition));
        setInit(false);

        if (screen.isPlaying) {
          setIsRunning(true);
        }
      } else {
        switch (screen.type) {
          case OperationType.Play: {
            setIsRunning(true);
            return;
          }
          case OperationType.Stop: {
            setIsRunning(false);
            reset();
            return;
          }
          case OperationType.Prev: {
            prevPosition();
            return;
          }
          case OperationType.Next: {
            nextPosition();
            return;
          }
          case OperationType.Pause: {
            setIsRunning(false);
            return;
          }
          default: {
            console.log(`Command ${screen.type} not exist`);
          }
        }
      }
    }
  }, [data, listRef.current]);

  const reset = () => {
    const newPosition = 0;
    setCurrentPosition(newPosition);
    const nextInterval = (listRef.current as LinkedList).get(newPosition);
    setInterval(nextInterval);
    setIsRunning(false);
    setIsFinished(false);
  };

  const nextPosition = () => {
    const newPosition = currentPosition + 1;
    setCurrentPosition(newPosition);

    const nextInterval = (listRef.current as LinkedList).get(newPosition);
    setInterval(nextInterval);

    if (!nextInterval) {
      setIsFinished(true);
      finishedCb && finishedCb();
    }
  };

  const prevPosition = () => {
    const newPosition = Math.max(currentPosition - 1, 0);
    setCurrentPosition(newPosition);

    const nextInterval = (listRef.current as LinkedList).get(newPosition);
    setInterval(nextInterval);

    if (!nextInterval && newPosition !== 0) {
      setIsFinished(true);
    }
  };

  useInterval(() => {
    if (interval && listRef.current) {
      const newTime = interval.time - MsInSecond;

      setInterval({
        ...interval,
        time: newTime,
      });

      if (newTime <= 0) {
        nextPosition();

        if (useSound) {
          switch (interval.type) {
            case IntervalType.High: {
              playSound(true);
              break;
            }
            case IntervalType.Low:
            case IntervalType.Rest:
            case IntervalType.WarmUp: {
              playSound(false);
              break;
            }
          }
        }
      }
    } else {
      setIsRunning(false);
    }
  }, isRunning ? MsInSecond : null);

  const play = useCallback(async () => {
    if (!screenId) {
      return;
    }

    screenUpdate(screenId, {
      isPlaying: true,
      commandUpdatedTime: Date.now(),
      type: OperationType.Play,
    } as Operation);

    try {
      await API.program.addProgramLastPlayedAndUpdateHistory(screenId);
    } catch (e) {
      console.log('Error: ', e);
    }
  }, [screenId, currentPosition]);

  const pause = useCallback(async () => {
    if (!screenId) {
      return;
    }

    screenUpdate(screenId, {
      isPlaying: false,
      commandUpdatedTime: Date.now(),
      type: OperationType.Pause,
    } as Operation);
  }, [screenId, currentPosition]);

  const stop = useCallback(async () => {
    if (!screenId) {
      return;
    }

    screenUpdate(screenId, {
      isPlaying: false,
      commandUpdatedTime: Date.now(),
      type: OperationType.Stop,
    } as Operation);
  }, [screenId, timer]);

  const next = useCallback(async () => {
    if (!screenId) {
      return;
    }

    screenUpdate(screenId, {
      commandUpdatedTime: Date.now(),
      type: OperationType.Next,
    } as Operation);
  }, [screenId, timer]);

  const prev = useCallback(async () => {
    if (!screenId) {
      return;
    }

    screenUpdate(screenId, {
      commandUpdatedTime: Date.now(),
      type: OperationType.Prev,
    } as Operation);
  }, [screenId, timer]);

  return [
    interval,
    {
      isRunning,
      isFinished,
    },
    {
      play,
      pause,
      stop,
      prev,
      next,
    }
  ];
}

export default useScreenTimer;
