import { Option } from '../model/Option';
import { Mode } from '../model/program/Canvas';
import { Size } from '../model/program/Size';
import { Timer } from '../model/Timer';

export function capitalizeFirstLetter(s: string): string {
  if (s.length < 1) {
    return s;
  }

  return `${s.substr(0, 1).toUpperCase()}${s.substr(1)}`;
}

export function createOptions<T, U>(t: T, getLabel: (key: U) => string, anyLabel?: string): Option<U>[] {
  const list = (Object.values(t).map((key: U) => ({
    value: key,
    label: getLabel(key)
  })) as any) as Array<{value: T | null; label: string}>;

  if (anyLabel) {
    list.unshift({
      value: null,
      label: anyLabel
    });
  }

  return (list as any) as Option<U>[];
}

export function findElement(target: HTMLElement, element: HTMLElement) {
  let el: Node | null = target;

  while (el) {
    if (el === element) {
      return true;
    }

    el = el.parentNode;
  }

  return false;
}

export function uniqueId(prefix: string = 'ID') {
  return `${prefix}-${Math.random().toString(36).substr(2, 9)}`;
}

export function generateScreenId(length = 8): string {
  const SYMBOLS = '0123456789abcdefghigklmnopqrstuvwxyz';

  let id = '';

  for (let i = 0; i < length; ++i) {
    id += SYMBOLS[Math.floor(Math.random() * SYMBOLS.length)];
  }

  return id;
}

export function getTileScale(el: HTMLDivElement | null, mode: Mode, size: Size, inaccuracy: number = 1) {
  let scale = 0.1;
  if (!el) {
    return scale;
  }

  const { width, height } = el.getBoundingClientRect();

  if (mode === Mode.landscape) {
    scale = width * inaccuracy / size.width;
  } else {
    scale = height * inaccuracy / size.height;
  }

  return +scale.toFixed(3);
}

export function arraysEqual<T>(a1: T[], a2: T[]) {
  return a1.every(item => a2.includes(item)) && a2.every(item => a1.includes(item));
}

export const parseTimeFromString = (time: string): number => {
  return new Date(`1970-01-01T00:${time}Z`).valueOf();
};

export const MsInSecond = 1000;
export const SecondsInMinute = 60;

export const parseStringFromTime = (time: number): string => {
  const allSeconds = time / MsInSecond;
  const fullMinutes = Math.floor(allSeconds / SecondsInMinute);
  const rest = allSeconds % SecondsInMinute;

  return `${addZero(fullMinutes)}:${addZero(rest)}`;
};

const addZero = (num: number) => {
  if (num <= 9) {
    return `0${num}`;
  }
  return num;
};

export const getTotalTimeFromTimer = (timer: Timer | undefined): string => {
  if (!timer) {
    return '';
  }

  let summary = 0;
  summary += parseTimeFromString(timer.restTime || '00:00');
  summary += parseTimeFromString(timer.warmUp || '00:00');
  timer.intervals.forEach(interval => {
    for (let ii = 0; ii < interval.numberOfSets; ii++) {
      summary += parseTimeFromString(interval.highDuration);
      summary += parseTimeFromString(interval.lowDuration);
    }
  });

  if (Number.isNaN(summary)) {
    return '';
  }

  return parseStringFromTime(summary);
}
