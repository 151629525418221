import React from 'react';
import styles from './row.module.scss';

type Props = {
  title: string;
  col1: React.ReactNode;
  col2?: React.ReactNode;
  className?: string;
  column?: boolean;
  id?: string;
};

export default (props: Props) => {
  const {
    title,
    col1,
    col2,
    className = '',
    column = false,
    id,
  } = props;
  return (
    <div className={`${styles.row} ${column ? styles.column : ''} ${className}`} id={id}>
      <div className={`${styles.title} ${column ? styles.titleColumn : ''}`}>{title}</div>
      <div className={styles.content}>
        <div className={styles.col1}>{col1}</div>
        {col2 &&
        <div className={styles.col2}>{col2}</div>
        }
      </div>
    </div>
  );
};
