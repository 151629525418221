import React, { useCallback } from 'react';
import styles from './color-picker.module.scss';
// @ts-ignore
import ColorPicker from 'rc-color-picker';
import 'rc-color-picker/assets/index.css';
import debounce from 'lodash/debounce';

type Props = {
  value: string;
  onChange: (v: string) => void;
};

type CbParams = {
  color: string;
  alpha: number;
  open: boolean;
};

export default (props: Props) => {
  const {
    value,
    onChange,
  } = props;

  const handleChange = useCallback(debounce((data: CbParams) => {
    onChange(data.color);
  }, 200), [onChange]);

  return (
    <ColorPicker
      onChange={handleChange}
      color={value}
      enableAlpha={false}
    >
      <div className={styles.wrapper} style={{backgroundColor: value}}/>
    </ColorPicker>
  );
};
