import React from 'react';
import classes from 'classnames';
import styles from './block.module.scss';
import { ComponentBlock } from '../../../../model/program/ComponentBlock';
import Wrapper from '../wrapper';
import Component from '../../component';
import { connect } from 'react-redux';
import { RootState } from '../../../../store/reducers';
import { getProgramEditorPresentState } from '../../../../store/reducers/program';

type OuterProps = {
  component: ComponentBlock;
  editable?: boolean;
  scale?: number;
  preview?: boolean;
};

type Props = {
} & ReturnType<typeof mapState> & OuterProps;

class Block extends React.PureComponent<Props> {
  render() {
    const {
      component,
      editable,
      canEditInsideBlock,
      isSelected,
      scale,
      preview,
    } = this.props;

    const className = classes({
      [styles.border]: isSelected,
    });

    const style = {
      backgroundColor: component.style.backgroundColor,
    };

    return (
      <Wrapper preview={preview} scale={scale} className={className} component={component} editable={editable}>
        <div className={styles.content} style={style}>
          {component.components.map(c => {
            return (
              <Component scale={scale} variableValue={component.blockVariables[c.variable.id]} editable={canEditInsideBlock} key={c.id} component={c}/>
            );
          })}
        </div>
      </Wrapper>
    );
  }
}

const mapState = (state: RootState, props: OuterProps) => {
  const {
    isBlockEditorActive,
    selectedComponent,
    preview,
  } = getProgramEditorPresentState(state);

  const isSelected = selectedComponent && selectedComponent.id === props.component.id;

  const canEditInsideBlock = !preview &&
    !!(isBlockEditorActive && isSelected);

  return {
    canEditInsideBlock,
    isSelected,
  };
};

export default connect(mapState)(Block)
