import React from 'react';
import { Dispatch } from 'redux';
import styles from './forgot-password.module.scss';
import FormWrapper from '../form-wrapper';
import { NavLink } from 'react-router-dom';
import InputField from '../../../components/input-field';
import { connect } from 'react-redux';
import { Button } from '../../../components/button';
import { RootState } from '../../../store/reducers';
import { authActions } from '../../../store/actions';

type Props = {} & ReturnType<typeof mapState> & ReturnType<typeof mapActions>;

type State = {
  email: string;
};

class ForgotPasswordPage extends React.PureComponent<Props, State> {
  state = {
    email: '',
  };

  componentWillUnmount(): void {
    this.props.clearError();
  }

  handleChange = (value: string) => {
    this.setState({ email: value });
  };

  handleReset = () => {
    const { email } = this.state;
    this.props.reset(email);
  };

  render() {
    const {
      email,
    } = this.state;

    const {
      loading,
      error,
    } = this.props;

    return (
      <FormWrapper
        title={'Reset password'}
        footerLink={null}
        footerText={null}
      >
        {!!error &&
        <div className={styles.error}>{error}</div>
        }
        <InputField placeholder={'Email'} value={email} onChange={this.handleChange}/>
        <NavLink to={'/login'} className={styles.link}>
          Back to login page
        </NavLink>
        <div className={styles.action}>
          <Button
            disabled={loading}
            title="Reset"
            onClick={this.handleReset}
          />
        </div>
      </FormWrapper>
    );
  }
}

const mapState = (state: RootState) => ({
  loading: state.auth.loading,
  error: state.auth.error,
});

const mapActions = (dispatch: Dispatch) => ({
  reset: (email: string) => dispatch(authActions.forgotPassword(email)),
  clearError: () => dispatch(authActions.error('')),
});

export default connect(mapState, mapActions)(ForgotPasswordPage);
