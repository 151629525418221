import { createAction } from '../utils';
import { User } from '../../model/User';
import { AuthActionType } from '../action-types';

export const login = (email: string, password: string) =>
  createAction<{ email: string, password: string }>(AuthActionType.REQUEST, {
    email,
    password
  });
export const loggedIn = (user: User) => createAction<User>(AuthActionType.SUCCESS, user);
export const error = (error: string) => createAction<string>(AuthActionType.ERROR, error);
export const logOut = () => createAction<void>(AuthActionType.LOGOUT);
export const forgotPassword = (email: string) => createAction<string>(AuthActionType.FORGOT_PASSWORD, email);
