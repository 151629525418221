import React from 'react';
import { Modal } from '../../../modal';
import styles from './dynamic-popup.module.scss';
import InputField from '../../../input-field';
import { Button, ButtonKind } from '../../../button';
import { connect } from 'react-redux';
import { ControlColor } from '../../../../model/ControlColor';

type Props = {
  onClose: () => void;
  createNewBlock: (name: string, resolve: Function) => void;
};

type State = {
  name: string;
};

class DynamicPopup extends React.PureComponent<Props, State> {
  state = {
    name: '',
  };

  handleChange = (key: keyof State) => (value: string) => {
    this.setState({ [key]: value });
  };

  handleCancel = () => {
    this.props.onClose();
  };

  handleCreate = () => {
    const {
      name,
    } = this.state;

    this.props.createNewBlock(name, (err: Error) => {
      if (!err) {
        this.props.onClose();
      } else {
        alert(err.message);
      }
    });
  };

  isValid = () => {
    return !!this.state.name;
  };

  render() {
    const {
      onClose,
    } = this.props;

    const {
      name,
    } = this.state;

    const formValid = this.isValid();

    return (
      <Modal
        onClose={onClose}
      >
        <div className={styles.content}>
          <div className={styles.title}>Create a Dynamic Block</div>
          <InputField
            label={'Block name'}
            placeholder={'Block name...'}
            className={styles.input}
            onChange={this.handleChange('name')}
            color={ControlColor.Dark}
            value={name}/>
          <div className={styles.actions}>
            <Button
              kind={ButtonKind.Secondary}
              title="Cancel"
              onClick={this.handleCancel}
              color={ControlColor.Dark}
            />
            <Button
              disabled={!formValid}
              title="Create block"
              onClick={this.handleCreate}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(null)(DynamicPopup);
