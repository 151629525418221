import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import intersection from 'lodash/intersection';
import { AccessLevel } from '../../model/AccessLevel';

type Props = {
  component: any;
  roles: AccessLevel[];
} & RouteProps;

const RolesRoute = (props: Props) => {
  const { roles, component: Wrapper, ...rest } = props;
  const user = useSelector((state: RootState) => state.auth.user);

  return <Route
    {...rest}
    render={(p) => {
      return (user && intersection(user.roles, roles).length) ? <Wrapper {...p} /> : <Redirect to={'/screens'}/>
    }}
  />
};

export default RolesRoute;
