import React from 'react';
import styles from './tag-list.module.scss';

type Props = {
  className?: string;
  style?: object;
  tags: string[];
};

export const TagList = ({className, style, tags}: Props) => {
  const classes = [styles.tagContainer, className || ''];

  return (
    <div className={classes.join(' ')} style={style}>
      {
        tags.map(tag => (
          <div key={tag} className={styles.tagWrapper}>
            <div className={styles.tag}>{tag}</div>
          </div>
        ))
      }
    </div>
  );
};
