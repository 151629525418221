import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PageWrapper from '../../components/page-wrapper';
import PageTitle from '../../components/page-title';
import styles from './screens.module.scss';
import { IconType, SvgIcon } from '../../components/icon';
import useFirebaseEntity from '../../hooks/use-firebase-entity';
import { RootState } from '../../store/reducers';
import { Screen } from '../../model/Screen';
import ScreenTile from './screen-tile';
import InputField from '../../components/input-field';
import { Button } from '../../components/button';
import Select from '../../components/select';
import { CanvasModeOptions, Mode } from '../../model/program/Canvas';
// @ts-ignore
import ReactCodeInput from 'react-code-input';
import ChangeProgramModal from './change-program-modal';
import { mapProgramDtoToProgram, ProgramType } from '../../model/program/Program';
import { BlockType, mapBlockDtoToBlock } from '../../model/program/Block';
import { Gym } from '../../model/Gym';
import { MasterScreenPopup } from './master-screen-popup';

const PlaceholderPositionMap = [0, 66, 132, 198, 288, 354, 420, 486];

const ScreensPage = () => {
  const [selectedScreenId, setSelectedScreenId] = useState(null as string | null);
  const [changeProgramScreenId, setChangeProgramScreenId] = useState(null as string | null);

  const [screenName, setScreenName] = useState('');
  const [screenLocation, setScreenLocation] = useState('');
  const [screenOrientation, setScreenOrientation] = useState(Mode.landscape);
  const [masterScreenId, setMasterScreenId] = useState<string>('');

  const [isAddingNewScreen, setIsAddingNewScreen] = useState(false);

  const [addNewScreenStage, setAddNewScreenStage] = useState(0);

  const [newScreenId, setNewScreenId] = useState('');
  const [showMasterScreenPopup, setShowMasterScreenPopup] = useState<{ screenId: string, show: boolean }>({
    screenId: '',
    show: false,
  });

  const handleNewScreenIdChange = (value: string) => {
    setNewScreenId(value.toLowerCase());
  };

  useEffect(() => {
    if (newScreenId.length !== 8) {
      return;
    }

    setAddNewScreenStage(1);
  }, [newScreenId]);

  const user = useSelector((state: RootState) => state.auth.user);
  const gym = useSelector((state: RootState) => state.gym);

  const screensQuery = [['gymId', gym.gym ? gym.gym.id : null]];

  const [
    screens,
    {
      isCreating: isCreatingScreen,
      isRemoving: isRemovingScreen,
      isUpdating: isUpdatingScreen
    },
    {
      create: createScreen,
      remove: removeScreen,
      update: updateScreen
    }
  ] = useFirebaseEntity('screens', screensQuery) as [Screen[], any, any];

  const masterOptions = useMemo(() => {
    if (!screens || !screens.length) return [];

    return screens.map(s => ({
      label: s.name,
      value: s.id,
    }));
  }, [screens]);

  const programsQuery = [['editor.gymId', gym.gym ? gym.gym.id : null]];
  const [programs] = useFirebaseEntity('programs', programsQuery, mapProgramDtoToProgram) as [ProgramType[]];

  const blocksQuery = [['info.gymId', gym.gym ? gym.gym.id : null]];
  const [blocks] = useFirebaseEntity('blocks', blocksQuery, mapBlockDtoToBlock) as [BlockType[]];

  const selectedScreen = selectedScreenId && screens.find(item => item.id === selectedScreenId);

  useEffect(() => {
    setScreenName((!isAddingNewScreen && selectedScreen) ? selectedScreen.name : '');
    setScreenLocation((!isAddingNewScreen && selectedScreen) ? selectedScreen.location : '');
    setScreenOrientation((!isAddingNewScreen && selectedScreen) ? selectedScreen.orientation : Mode.landscape);
  }, [selectedScreen, isAddingNewScreen]);

  if (!user) {
    return null;
  }

  const handleSaveChanges = async () => {
    await updateScreen(selectedScreenId, {
      name: screenName,
      location: screenLocation,
      orientation: screenOrientation
    });

    setSelectedScreenId(null);
  };

  const handleChangeProgramRequested = (id: string) => () => {
    setChangeProgramScreenId(id);
  };

  const closeChangeProgramModal = () => setChangeProgramScreenId(null);

  const handleDeleteRequested = (id: string) => () => {
    if (!window.confirm('Are you sure you want to delete this screen? This action is irreversible.')) {
      return;
    }

    removeScreen(id);
  };

  const handleEditRequested = (id: string) => () => {
    setSelectedScreenId(id);
  };

  const handleBack = () => setSelectedScreenId(null);

  const isScreenDataValid = Boolean(selectedScreen && screenName && screenOrientation) && (
    (screenName !== (selectedScreen as Screen).name) ||
    (screenLocation !== (selectedScreen as Screen).location) ||
    (screenOrientation !== (selectedScreen as Screen).orientation)
  );

  const handleAddNewScreen = () => {
    setIsAddingNewScreen(true);
  };

  const handleCancelAddNewScreen = () => {
    setIsAddingNewScreen(false);
  };

  const handleCreateScreen = async () => {
    await createScreen({
      gymId: (gym.gym as Gym).id,
      location: screenLocation,
      name: screenName,
      orientation: screenOrientation,
      masterScreenId: masterScreenId,
    }, newScreenId, () => {
      window.alert('Screen with this ID already exists');
    });

    setNewScreenId('');
    setAddNewScreenStage(0);
    setIsAddingNewScreen(false);
    setMasterScreenId('');
  };

  const handleRestoreRequested = (id: string) => () => {
    const restoredScreenId = window.prompt('Enter new screen ID');

    if (!restoredScreenId || (restoredScreenId.length !== 8)) {
      return;
    }

    updateScreen(id, { restoredScreenId });
  };

  const handleConnectRequested = (id: string) => () => {
    setShowMasterScreenPopup({
      screenId: id,
      show: true,
    });
  };

  const handleDisconnectRequested = (id: string) => () => {
    updateScreen(id, { masterScreenId: null })
  };

  return (
    <PageWrapper>
      {
        selectedScreen ? (
          <>
            <div className={styles.contentContainer}>
              <div>
                <div className={styles.backContainer}>
                  <SvgIcon className={styles.backIcon} type={IconType.Back} onClick={handleBack}/>
                  <div className={styles.backText} onClick={handleBack}>Back to Screens</div>
                </div>
                <PageTitle title={selectedScreen.name}/>
                <div className={styles.screenDetails}>
                  <div>
                    <div className={styles.row}>
                      <InputField
                        label="Screen Name"
                        placeholder="Enter screen name..."
                        value={screenName}
                        onChange={setScreenName}
                      />
                    </div>
                    <div className={styles.row}>
                      <InputField
                        label="Location (optional)"
                        placeholder="Enter screen location..."
                        value={screenLocation}
                        onChange={setScreenLocation}
                      />
                    </div>
                    <div className={styles.row}>
                      <Select
                        label="Orientation"
                        placeholder="Select orientation..."
                        options={CanvasModeOptions}
                        value={screenOrientation}
                        onChange={setScreenOrientation}
                      />
                    </div>
                    <div className={styles.row}>
                      <Select
                        label="Master"
                        placeholder="Select master..."
                        options={masterOptions}
                        value={masterScreenId}
                        onChange={setMasterScreenId}
                      />
                    </div>
                  </div>
                  <div className={styles.screenDetailsActionContainer}>
                    <Button
                      disabled={isRemovingScreen || isUpdatingScreen || !isScreenDataValid}
                      title="Save Changes"
                      onClick={handleSaveChanges}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <PageTitle title={'Screens'}/>
            <div className={styles.content}>
              {
                screens.map(screen => {
                  let parent;
                  if (screen.masterScreenId) {
                    parent = screens.find(s => s.id === screen.masterScreenId) as Screen;
                  }

                  const newScreen: Screen = {
                    ...screen,
                    ...(parent ? {
                      programId: parent.programId,
                    } : undefined),
                  };

                  return (
                    <div key={screen.id} className={styles.tileWrapper}>
                      <ScreenTile
                        screen={newScreen}
                        onChangeProgramRequested={handleChangeProgramRequested(screen.id)}
                        onDeleteRequested={handleDeleteRequested(screen.id)}
                        onEditRequested={handleEditRequested(screen.id)}
                        onRestoreRequested={handleRestoreRequested(screen.id)}
                        onConnectRequested={handleConnectRequested(screen.id)}
                        onDisconnectRequested={handleDisconnectRequested(screen.id)}
                        programs={programs}
                        blocks={blocks}
                        masterScreen={parent}
                      />
                    </div>
                  );
                })
              }
              <div className={styles.tileWrapper}>
                <div className={styles.addScreenTile} onClick={handleAddNewScreen}>
                  <SvgIcon className={styles.addScreenTileIcon} type={IconType.ScreenAdd}/>
                  <div className={styles.addScreenTileText}>Add a Screen</div>
                </div>
              </div>
            </div>
          </>
        )
      }
      {
        isAddingNewScreen && (
          <div className={styles.newScreen}>
            {
              (addNewScreenStage === 0) && (
                <SvgIcon
                  className={styles.newScreenCancelIcon}
                  type={IconType.Cancel}
                  onClick={handleCancelAddNewScreen}
                />
              )
            }
            <PageTitle
              className={styles.newScreenTitle}
              title={(addNewScreenStage === 0) ? 'Link a new screen' : 'Screen details'}
            />
            <div className={styles.newScreenDescription}>
              {
                (addNewScreenStage === 0) ?
                  'Insert the USB drive into your TV or monitor, ' +
                  'then enter the 8-digit code displayed on your screen below.' :
                  'Just a few more details and you\'re done!'
              }
            </div>
            {
              (addNewScreenStage === 0) ? (
                <div className={styles.newScreenContentContainer}>
                  <SvgIcon className={styles.newScreenIcon} type={IconType.Screen}/>
                  <div className={styles.newScreenContent}>
                    <div className={styles.newScreenIdInputContainer}>
                      <ReactCodeInput
                        className={styles.newScreenIdInput}
                        fields={8}
                        value={newScreenId}
                        onChange={handleNewScreenIdChange}
                      />
                      {
                        PlaceholderPositionMap.map((offset, index) => !newScreenId[index] && (
                          <div
                            key={offset}
                            className={styles.newScreenIdInputItemPlaceholder}
                            style={{ left: `${offset}px` }}
                          >
                            •
                          </div>
                        ))
                      }
                    </div>
                    {/* <SvgIcon className={styles.loadingSpinnerIcon} type={IconType.LoadingSpinner}/> */}
                  </div>
                </div>
              ) : (
                <div className={styles.newScreenDetails}>
                  <div>
                    <div className={styles.row}>
                      <InputField
                        label="Screen Name"
                        placeholder="Enter screen name..."
                        value={screenName}
                        onChange={setScreenName}
                      />
                    </div>
                    <div className={styles.row}>
                      <InputField
                        label="Location (optional)"
                        placeholder="Enter screen location..."
                        value={screenLocation}
                        onChange={setScreenLocation}
                      />
                    </div>
                    <div className={styles.row}>
                      <Select
                        label="Orientation"
                        placeholder="Select orientation..."
                        options={CanvasModeOptions}
                        value={screenOrientation}
                        onChange={setScreenOrientation}
                      />
                    </div>
                    <div className={styles.row}>
                      <Select
                        label="Master"
                        placeholder="Select master..."
                        options={masterOptions}
                        value={masterScreenId}
                        onChange={setMasterScreenId}
                      />
                    </div>
                  </div>
                  <div className={styles.screenDetailsActionContainer}>
                    <Button
                      disabled={isCreatingScreen || !screenName || !screenOrientation}
                      title="Done"
                      onClick={handleCreateScreen}
                    />
                  </div>
                </div>
              )
            }
          </div>
        )
      }
      {
        changeProgramScreenId && (
          <ChangeProgramModal screenId={changeProgramScreenId} onCloseRequested={closeChangeProgramModal}/>
        )
      }
      {
        showMasterScreenPopup.show && <MasterScreenPopup
          options={masterOptions}
          value={masterScreenId}
          onClose={() => setShowMasterScreenPopup({ screenId: '', show: false })}
          onSave={(id) => {
            if (id) {
              updateScreen(showMasterScreenPopup.screenId, {masterScreenId: id});
              setShowMasterScreenPopup({ screenId: '', show: false });
            }
          }}
        />
      }
    </PageWrapper>
  );
};

export default ScreensPage;
