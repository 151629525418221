import React from 'react';
import { ComponentBase } from '../../../model/program/Component';
import { ComponentType } from '../../../model/program/Type';
import Shape from '../canvas/shape';
import { ComponentShape } from '../../../model/program/ComponentShape';
import Label from '../canvas/label';
import { ComponentLabel } from '../../../model/program/ComponentLabel';
import Image from '../canvas/image';
import { ComponentImage } from '../../../model/program/ComponentImage';
import Block from '../canvas/block';
import { ComponentBlock } from '../../../model/program/ComponentBlock';
import { connect } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { VariableValue } from '../../../model/program/Variable';
import { getProgramEditorPresentState } from '../../../store/reducers/program';

type Props = {
  component: ComponentBase;
  editable?: boolean;
  variableValue?: VariableValue;
  scale?: number;
  preview?: boolean;
} & ReturnType<typeof mapState>;

const Component = (props: Props) => {
  const {
    component,
    editable,
    blockEditable,
    variableValue,
    scale,
    preview,
  } = props;

  switch (component.type) {
    case ComponentType.Square: {
      return (
        <Shape scale={scale} variableValue={variableValue} editable={editable} key={component.id} type={'square'} component={component as ComponentShape}/>
      );
    }
    case ComponentType.Circle: {
      return (
        <Shape scale={scale} variableValue={variableValue} editable={editable} key={component.id} type={'circle'} component={component as ComponentShape}/>
      );
    }
    case ComponentType.Label: {
      return (
        <Label scale={scale} variableValue={variableValue} editable={editable} key={component.id} component={component as ComponentLabel}/>
      );
    }
    case ComponentType.Image: {
      return (
        <Image scale={scale} variableValue={variableValue} editable={editable} key={component.id} component={component as ComponentImage}/>
      );
    }
    case ComponentType.Block: {
      return (
        <Block preview={preview} scale={scale} editable={blockEditable} key={component.id} component={component as ComponentBlock}/>
      );
    }
    default: {
      return null;
    }
  }
};

const mapState = (state: RootState) => ({
  blockEditable: !getProgramEditorPresentState(state).isBlockEditorActive,
});

export default connect(mapState)(Component);
