import React from 'react';
import Wrapper from '../wrapper';
import styles from './label.module.scss';
import { ComponentLabel } from '../../../../model/program/ComponentLabel';
import { getVariableValue, VariableValue } from '../../../../model/program/Variable';

type Props = {
  component: ComponentLabel;
  editable?: boolean;
  variableValue?: VariableValue;
  scale?: number;
};

class Label extends React.PureComponent<Props> {
  render() {
    const {
      component,
      variableValue,
      editable = true,
      scale,
    } = this.props;

    return (
      <Wrapper scale={scale} editable={editable} component={component}>
        <pre className={styles.label} style={{
          ...component.style,
          ...(component.style.textAlign === 'justify' ? { whiteSpace: 'normal' } : undefined),
        }}>
          {component.variable.active ? getVariableValue(variableValue) : component.value}
        </pre>
      </Wrapper>
    );
  }
}

export default Label;
