import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Tour from 'reactour';
import styles from './app-tour.module.scss';
import { useHistory } from 'react-router-dom';
import { Button } from '../button';
import { ControlSize } from '../../model/ControlSize';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import { User } from '../../model/User';
import { userActions } from '../../store/actions';
import { AccessLevel } from '../../model/AccessLevel';

type Props = {};

function AppTour(props: Props) {
  const { location: { pathname } } = useHistory();

  const dispatch = useDispatch();
  const user = useSelector<RootState, User>(state => state.auth.user as User);

  const [isOpen, setIsOpen] = useState(pathname === '/screens' &&
    !user.appTourSeen &&
    user.roles.some(role => [AccessLevel.admin, AccessLevel.owner].includes(role)));

  const firstSteps = useMemo(() => {
    return [
      { selector: '#menu__screens', content: 'This is where you connect your screens.' },
      { selector: '#menu__programs', content: 'Design your programs here.' },
      { selector: '#menu__teams', content: 'Add your coaches and staff here.' },
      {
        selector: '#user-info__menu__popup-wrapper',
        content: 'Please click the highlighted section to show the menu and then click Gym settings',
        mutationObservables: ['#user-popup'],
        highlightedSelectors: ['#user-popup'],
      },
    ];
  }, []);

  const [steps, setSteps] = useState(firstSteps);

  useEffect(() => {
    if (pathname === '/gym-settings') {
      const newSteps = ([] as any[]).concat(firstSteps).concat([
        {
          selector: '#gym-settings__avatar-uploader',
          content: 'Add your logo and save it.',
        },
        {
          content: () => {
            return (
              <div className={styles.closeWrapper}>
                <div>Now you can continue to use the app.</div>
                <Button size={ControlSize.ExtraSmall} onClick={handleCloseTour} title={'Close'}/>
              </div>
            );
          },
        }
      ]);
      setSteps(newSteps);
      setTimeout(() => {
        tourRef.current?.gotoStep(newSteps.length - 2);
      }, 100);
    } else {
      setSteps(firstSteps);
    }
  }, [firstSteps, pathname]);


  const tourRef = useRef<any>(null);

  const handleCloseTour = useCallback(() => {
    dispatch(userActions.updateUser({
      appTourSeen: true,
    }));
    setIsOpen(false);
  }, [dispatch]);

  return (
    <Tour
      ref={tourRef}
      isOpen={isOpen}
      steps={steps}
      closeWithMask={false}
      onRequestClose={handleCloseTour}
      showNavigation={false}
      showCloseButton={false}
    />
  );
}

export default AppTour;
